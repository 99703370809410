import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { emailCarrierNetwork } from 'lib/api/emailCarrierNetwork';
import { getCustomers } from 'lib/api/getCustomers';
import { searchLocations } from 'lib/api/searchLocations';
import { Email } from 'types/Email';
import { TMSCustomer, TMSLocationWithDistance } from 'types/Load';
import { Address, QuoteRequest, TransportType } from 'types/QuoteRequest';
import { Maybe } from 'types/UtilityTypes';
import captureException from 'utils/captureException';
import getUserName from 'utils/getUserName';
import {
  GenericCompanySearchableFields,
  customerSearchHandler,
} from 'utils/loadInfoAndBuilding';

import FindCarrierForm from './FindCarrierForm';
import MessageCarrierForm from './MessageCarrierForm';
import { constructEmailBody, constructEmailSubject } from './emailTemplate';

type RequestCarrierQuoteProps = {
  email: Maybe<Email>;
  request: Maybe<QuoteRequest>;
}; // QuoteRequest may be null if there's a transient network error getting the parsed values. In those cases, still show the form

// Helper function to parse location input (similar to the one in helperFunctions)
const parseLocation = (location: string) => {
  if (!location) return { zip: '', city: '', state: '' };

  // Check if input is a 5-digit ZIP code
  if (/^\d{5}$/.test(location.trim())) {
    return {
      zip: location.trim(),
      city: '',
      state: '',
    };
  }

  // Parse city, state format (e.g. "Boston, MA")
  const match = location.match(/^([^,]+),\s*([A-Z]{2})$/i);
  if (match) {
    return {
      zip: '',
      city: match[1].trim(),
      state: match[2].toUpperCase(),
    };
  }

  return { zip: '', city: '', state: '' };
};

export interface FormConditionProps {
  showCustomerSearch: boolean;
  showItemDescription: boolean;
  showDeliverySection: boolean;
  requireDeliveryLocation: boolean;
  useCustomEmailDefaults: boolean;
  showPickupAddressLine1: boolean;
  defaultFromEmail: string;
  defaultCCEmail: string;
}

export interface CarrierQuoteInputs {
  // Load details
  customerId: Maybe<string>;
  itemDescription: Maybe<string>;
  commodity: Maybe<string>;
  transportType: Maybe<TransportType>;
  weightLbs: Maybe<number>;
  weightUnit: Maybe<string>;

  // Pickup details
  pickupLocation: Address & { location: Maybe<string> };
  pickupStartDate: Maybe<Date>;
  pickupEndDate: Maybe<Date>;
  mileRadius: number;

  // Delivery details
  deliveryLocation: Maybe<Address & { location: Maybe<string> }>;
  deliveryStartDate: Maybe<Date>;
  deliveryEndDate: Maybe<Date>;

  // Email details
  from: string;
  cc: string;
  subject: string;
  emailBody: string;

  // Carrier details
  carriers: TMSLocationWithDistance[];
  carrierEmails: string[];
}

export interface CarrierSelection {
  email: string;
  isSelected: boolean;
}

export default function RequestCarrierNetworkQuotesSection({
  email,
  request,
}: RequestCarrierQuoteProps) {
  const { toast } = useToast();
  const [customers, setCustomers] = useState<Maybe<TMSCustomer[]>>(null);
  const [initialCustomers, setInitialCustomers] =
    useState<Maybe<TMSCustomer[]>>(null);

  const [loading, setLoading] = useState(false);
  const [carrierLocations, setCarrierLocations] = useState<
    TMSLocationWithDistance[]
  >([]);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [showMessageCarrier, setShowMessageCarrier] = useState(false);
  const [selectedCarriers, setSelectedCarriers] = useState<
    Record<string, boolean>
  >({});
  const [lastSearchedLocation, setLastSearchedLocation] = useState<{
    city: string;
    state: string;
    zip: string;
  }>({ city: '', state: '', zip: '' });

  const { serviceID, tmsIntegrations } = useServiceFeatures();

  if (tmsIntegrations.length > 0) {
    if (tmsIntegrations.length > 1 && isProd()) {
      captureException(
        new Error(
          'Service with multiple TMS integrations trying to find carriers'
        ),
        { serviceID: serviceID, tmsIntegrations: tmsIntegrations }
      );
    }
  }

  const isSDS = serviceID === 595;

  // Define form conditions based on service requirements
  const formConditions: FormConditionProps = {
    showCustomerSearch: !isSDS, // hide customer search for SDS until we debrief its for metrics
    showItemDescription: isSDS,
    showDeliverySection: !isSDS,
    requireDeliveryLocation: !isSDS,
    useCustomEmailDefaults: isSDS,
    showPickupAddressLine1: !isSDS,
    defaultFromEmail: isSDS ? 'dispatch@sdslogistics.com' : '',
    defaultCCEmail: isSDS ? 'dispatch@sdslogistics.com' : '',
  };

  const handleRefreshCustomers = async () => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id, true);
    if (res.isOk()) {
      setInitialCustomers(res.value.customerList);
      setCustomers(res.value.customerList);
      toast({
        description: 'Successfully refreshed customer list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing customer list.',
        variant: 'destructive',
      });
    }
  };

  const handleResetCustomerSearch = () => {
    setCustomers(initialCustomers);
  };

  const handleCustomerSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return customerSearchHandler({
      tmsID: tmsIntegrations?.[0]?.id,
      customers,
      setCustomers,
      field,
      value,
    });
  };

  useEffect(() => {
    if (tmsIntegrations && tmsIntegrations.length > 0) {
      const fetchCustomers = async () => {
        const res = await getCustomers(tmsIntegrations[0].id);
        if (res.isOk()) {
          setInitialCustomers(res.value.customerList);
          setCustomers(res.value.customerList);
        }
      };
      fetchCustomers();
    }
  }, [tmsIntegrations]);

  const defaultValues = useMemo<CarrierQuoteInputs>(() => {
    const baseValues: CarrierQuoteInputs = {
      // Load details
      customerId: null,
      itemDescription: '',
      commodity: null,
      transportType: null,
      weightLbs: 0,
      weightUnit: 'lbs',

      // Pickup details
      pickupLocation: {
        name: '',
        addressLine1: '',
        addressLine2: '',
        zip: '',
        city: '',
        state: '',
        country: 'USA',
        location: '',
      },
      pickupStartDate: null,
      pickupEndDate: null,
      mileRadius: 50,

      // Delivery details
      deliveryLocation: {
        name: '',
        addressLine1: '',
        addressLine2: '',
        zip: '',
        city: '',
        state: '',
        country: 'USA',
        location: '',
      },
      deliveryStartDate: null,
      deliveryEndDate: null,

      // Email details
      from: formConditions.defaultFromEmail,
      cc: formConditions.defaultCCEmail,
      subject: '',
      emailBody: '',

      // Carrier details
      carriers: [],
      carrierEmails: [],
    };

    if (request) {
      return {
        ...baseValues,
        transportType: request.transportType || baseValues.transportType,
        commodity: request.commodity || baseValues.commodity,
        weightLbs: request.weightLbs || baseValues.weightLbs,
        weightUnit: request.weightUnit || baseValues.weightUnit,
        pickupStartDate: request.pickupStartDate || baseValues.pickupStartDate,
        pickupEndDate: request.pickupEndDate || baseValues.pickupEndDate,
        deliveryStartDate:
          request.deliveryStartDate || baseValues.deliveryStartDate,
        deliveryEndDate: request.deliveryEndDate || baseValues.deliveryEndDate,
        pickupLocation: {
          name: request.pickupLocation?.name || '',
          addressLine1: request.pickupLocation?.addressLine1 || '',
          addressLine2: request.pickupLocation?.addressLine2 || '',
          zip: request.pickupLocation?.zip || '',
          city: request.pickupLocation?.city || '',
          state: request.pickupLocation?.state || '',
          country: request.pickupLocation?.country || 'USA',
          location:
            request.pickupLocation?.zip ||
            (request.pickupLocation?.city && request.pickupLocation?.state
              ? `${request.pickupLocation.city}, ${request.pickupLocation.state}`
              : ''),
        },
        deliveryLocation: {
          name: request.deliveryLocation?.name || '',
          addressLine1: request.deliveryLocation?.addressLine1 || '',
          addressLine2: request.deliveryLocation?.addressLine2 || '',
          zip: request.deliveryLocation?.zip || '',
          city: request.deliveryLocation?.city || '',
          state: request.deliveryLocation?.state || '',
          country: request.deliveryLocation?.country || 'USA',
          location:
            request.deliveryLocation?.zip ||
            (request.deliveryLocation?.city && request.deliveryLocation?.state
              ? `${request.deliveryLocation.city}, ${request.deliveryLocation.state}`
              : ''),
        },
        carriers: [],
      };
    }

    return baseValues;
  }, [request, formConditions]);

  // Form for finding carriers
  const findCarrierFormMethods = useForm<CarrierQuoteInputs>({
    defaultValues,
    mode: 'onChange',
    resolver: async (values) => {
      const errors: Record<string, any> = {};

      // Validate pickup location
      if (!values.pickupLocation?.location) {
        errors.pickupLocation = {
          location: {
            type: 'required',
            message: 'Pickup location is required',
          },
        };
      }

      // Validate mile radius
      if (!values.mileRadius) {
        errors.mileRadius = {
          type: 'required',
          message: 'Search radius is required',
        };
      } else if (values.mileRadius < 1) {
        errors.mileRadius = {
          type: 'min',
          message: 'Search radius must be at least 1 mile',
        };
      }

      // For non-SDS, validate delivery location
      if (!isSDS && !values.deliveryLocation?.location) {
        errors.deliveryLocation = {
          location: {
            type: 'required',
            message: 'Delivery location is required',
          },
        };
      }

      return {
        values,
        errors: Object.keys(errors).length > 0 ? errors : {},
      };
    },
  });

  // Form for messaging carriers
  const messageCarrierFormMethods = useForm<CarrierQuoteInputs>({
    defaultValues,
    mode: 'onChange',
  });

  // Find carriers handler for FindCarrierForm button
  const onSubmitFindCarriers = async (data: CarrierQuoteInputs) => {
    setLoading(true);
    // Reset carrier selections
    setSelectedCarriers({});
    messageCarrierFormMethods.setValue('carrierEmails', []);

    if (data.customerId) {
      messageCarrierFormMethods.setValue('customerId', data.customerId);
    }

    // Parse the pickup location input to extract city, state, and zip
    const pickupLocationInput = data.pickupLocation.location || '';
    const parsedPickupLocation = parseLocation(pickupLocationInput);

    // Store the last searched location
    setLastSearchedLocation(parsedPickupLocation);

    // Update both the form values and the data object with the parsed pickup location data
    messageCarrierFormMethods.setValue(
      'pickupLocation.city',
      parsedPickupLocation.city
    );
    messageCarrierFormMethods.setValue(
      'pickupLocation.state',
      parsedPickupLocation.state
    );
    messageCarrierFormMethods.setValue(
      'pickupLocation.zip',
      parsedPickupLocation.zip
    );
    data.pickupLocation.city = parsedPickupLocation.city;
    data.pickupLocation.state = parsedPickupLocation.state;
    data.pickupLocation.zip = parsedPickupLocation.zip;

    // Parse the delivery location input to extract city, state, and zip
    const deliveryLocationInput = data.deliveryLocation?.location || '';
    const parsedDeliveryLocation = parseLocation(deliveryLocationInput);

    // Update both the form values and the data object with the parsed delivery location data
    messageCarrierFormMethods.setValue(
      'deliveryLocation.city',
      parsedDeliveryLocation.city
    );
    messageCarrierFormMethods.setValue(
      'deliveryLocation.state',
      parsedDeliveryLocation.state
    );
    messageCarrierFormMethods.setValue(
      'deliveryLocation.zip',
      parsedDeliveryLocation.zip
    );
    if (data.deliveryLocation) {
      data.deliveryLocation.city = parsedDeliveryLocation.city;
      data.deliveryLocation.state = parsedDeliveryLocation.state;
      data.deliveryLocation.zip = parsedDeliveryLocation.zip;
    }

    const searchRes = await searchLocations(
      tmsIntegrations[0]?.id ?? 0,
      undefined, // key required as part of interface (for name and addressLine1 searches)
      undefined, // value also required to maintain backwards compatibility with existing searchLocations API
      data.mileRadius,
      parsedPickupLocation.city,
      parsedPickupLocation.state,
      parsedPickupLocation.zip
    );

    let searchedLocations: TMSLocationWithDistance[] = [];
    if (searchRes.isOk()) {
      searchedLocations = searchRes.value.locationList;
      setCarrierLocations(searchedLocations);
      messageCarrierFormMethods.setValue('carriers', searchedLocations);

      // If we searched with ZIP code, update city and state from the API response
      if (parsedPickupLocation.zip && searchRes.value.location) {
        const resolvedCity = searchRes.value.location.city;
        const resolvedState = searchRes.value.location.state;

        messageCarrierFormMethods.setValue('pickupLocation.city', resolvedCity);
        messageCarrierFormMethods.setValue(
          'pickupLocation.state',
          resolvedState
        );

        // Update the pickup location with resolved values for email subject
        data.pickupLocation.city = resolvedCity;
        data.pickupLocation.state = resolvedState;
      }

      if (searchedLocations.length > 0) {
        toast({
          description: `Found ${searchedLocations.length} 
          carrier${searchedLocations.length === 1 ? '' : 's'} near 
          ${messageCarrierFormMethods.getValues('pickupLocation.city') || lastSearchedLocation.zip}, ${messageCarrierFormMethods.getValues('pickupLocation.state')}`,
          variant: 'success',
        });
        setShowEmptyState(false);
        setShowMessageCarrier(true);
      } else {
        setShowEmptyState(true);
        toast({
          description: `No carriers found near ${messageCarrierFormMethods.getValues('pickupLocation.city') || lastSearchedLocation.zip}, ${messageCarrierFormMethods.getValues('pickupLocation.state')}`,
          variant: 'destructive',
        });
      }
    }

    if (searchRes.isErr()) {
      toast({
        description: searchRes.error.message,
        variant: 'destructive',
      });
      setLoading(false);
      return;
    }

    // Construct email after carriers are found and location is resolved
    const userName = await getUserName();
    const newBody = constructEmailBody(data, isSDS, userName);
    messageCarrierFormMethods.setValue('emailBody', newBody);

    // Now construct the subject with the resolved location data
    const newSubject = constructEmailSubject(
      {
        ...data.pickupLocation,
        city: messageCarrierFormMethods.getValues('pickupLocation.city'),
        state: messageCarrierFormMethods.getValues('pickupLocation.state'),
      },
      data.deliveryLocation,
      isSDS
    );
    messageCarrierFormMethods.setValue('subject', newSubject);

    setLoading(false);
  };

  const toggleSelection = (emailOrEmails: string | string[]) => {
    setSelectedCarriers((prev) => {
      const newSelectedCarriers = { ...prev };
      const emails = Array.isArray(emailOrEmails)
        ? emailOrEmails
        : [emailOrEmails];

      // If all emails are selected, unselect all. Otherwise, select all.
      const allSelected = emails.every((email) => prev[email]);
      emails.forEach((email) => {
        newSelectedCarriers[email] = !allSelected;
      });

      // Update the carriers field in the form
      const selectedEmails = Object.keys(newSelectedCarriers)
        .filter((key) => newSelectedCarriers[key])
        .map((email) => email.trim());
      messageCarrierFormMethods.setValue('carrierEmails', selectedEmails);

      return newSelectedCarriers;
    });
  };

  // onSubmitMessageCarriers calls backend endpoint that sends emails to selected carriers
  const onSubmitMessageCarriers: SubmitHandler<CarrierQuoteInputs> = async (
    data
  ) => {
    setLoading(true);

    const res = await emailCarrierNetwork(
      {
        ...data,
        id: request?.id ?? 0,
        threadID: email?.threadID ?? '',
        emailID: email?.id ?? 0,
        pallets: request?.pallets ?? [],
        carrierNetworkEmails: null,
      },
      data.carrierEmails,
      [data.cc],
      data.subject,
      data.emailBody
    );

    if (res.isOk()) {
      toast({
        description: 'Successfully emailed carriers.',
        variant: 'success',
      });
      setLoading(false);

      return;
    }

    if (res.error.countSuccess == 0) {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    } else {
      // Handle partial successes
      toast({
        description: res.error.message,
        variant: 'destructive',
      });

      // if (res.error.results) {
      //   for (const [i, carrier] of carriers.entries()) {
      //     if (res.error.results[carrier]?.duplicate) {
      //       setError(`carriers.${i}.email`, {
      //         type: 'duplicate',
      //         message: 'Carrier has already been contacted for this shipment.',
      //       });
      //     } else if (!res.error.results[carrier]?.success) {
      //       setError(`carriers.${i}.email`, {
      //         type: 'error',
      //         message: 'Error emailing carrier.',
      //       });
      //     }
      //   }
      // }
    }

    setLoading(false);
  };

  return (
    <div className='grid gap-6 grid-cols-1 mx-0 w-full'>
      <ExtendedFormProvider aiDefaultValues={true}>
        {/* Form for finding carriers */}
        <FormProvider {...findCarrierFormMethods}>
          <form
            onSubmit={findCarrierFormMethods.handleSubmit(onSubmitFindCarriers)}
            className='grid grid-cols-1 mx-0 w-full'
          >
            <FindCarrierForm
              loading={loading}
              formConditions={formConditions}
              customers={customers}
              handleRefreshCustomers={handleRefreshCustomers}
              handleResetCustomerSearch={handleResetCustomerSearch}
              handleCustomerSearch={handleCustomerSearch}
              tmsIntegrations={tmsIntegrations}
            />
          </form>
        </FormProvider>

        {/* Empty state message when no carriers found */}
        {!loading && carrierLocations.length === 0 && showEmptyState && (
          <div className='flex flex-col items-center justify-center mt-4 p-3 rounded-lg border border-orange-main'>
            <div className='text-md text-grayscale-content-label font-semibold mb-2 text-center'>
              No carriers found
            </div>
            <p className='text-sm text-grayscale-content-secondary'>
              We couldn't find any carrier locations near{' '}
              {messageCarrierFormMethods.getValues('pickupLocation.city') ||
                lastSearchedLocation.zip}
              , {messageCarrierFormMethods.getValues('pickupLocation.state')}.
              Try adjusting your search radius or location.
            </p>
            <p className='text-xs text-grayscale-content-secondary italic mt-2'>
              If you recently updated locations in Turvo you may need to refresh
              carrier locations.
            </p>
          </div>
        )}

        {/* Form for messaging carriers, only shown after carriers are found */}
        {showMessageCarrier && carrierLocations.length > 0 && (
          <FormProvider {...messageCarrierFormMethods}>
            <form
              onSubmit={messageCarrierFormMethods.handleSubmit(
                onSubmitMessageCarriers
              )}
              className='grid grid-cols-1 mx-0 w-full'
            >
              <MessageCarrierForm
                loading={loading}
                carrierLocations={carrierLocations}
                selectedCarriers={selectedCarriers}
                toggleSelection={toggleSelection}
              />
            </form>
          </FormProvider>
        )}
      </ExtendedFormProvider>
    </div>
  );
}
