import { useCallback, useReducer } from 'react';
import React, { memo } from 'react';

import { format } from 'date-fns';
import dayjs from 'dayjs';
import {
  Calendar as CalendarIcon,
  SlidersHorizontal,
  XCircleIcon,
} from 'lucide-react';

import ButtonLoader from 'components/loading/ButtonLoader';

import { STATE_ABBREVIATIONS } from '../constants/StateTimezones';
import { useLoadSearch } from '../contexts/loadSearchContext';
import { useToast } from '../hooks/useToaster';
import fetchLoadBySearchQuery from '../lib/api/fetchLoadBySearchQuery';
import ButtonNamePosthog from '../types/enums/ButtonNamePosthog';
import { cn } from '../utils/shadcn';
import { Button } from './Button';
import { Calendar } from './Calendar';
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from './Popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './Select';
import { Input } from './input';

// Define state interface and reducer outside component
interface SearchState {
  open: boolean;
  isLoading: boolean;
  routePickupCity: string;
  routePickupState: string;
  routeDropoffCity: string;
  routeDropoffState: string;
  customerName: string;
  pickupDate?: Date;
  dropoffDate?: Date;
  pickupCalendarOpen: boolean;
  dropoffCalendarOpen: boolean;
}

type SearchAction =
  | { type: 'SET_OPEN'; payload: boolean }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_PICKUP_CITY'; payload: string }
  | { type: 'SET_PICKUP_STATE'; payload: string }
  | { type: 'SET_DROPOFF_CITY'; payload: string }
  | { type: 'SET_DROPOFF_STATE'; payload: string }
  | { type: 'SET_CUSTOMER_NAME'; payload: string }
  | { type: 'SET_PICKUP_DATE'; payload: Date | undefined }
  | { type: 'SET_DROPOFF_DATE'; payload: Date | undefined }
  | { type: 'SET_PICKUP_CALENDAR_OPEN'; payload: boolean }
  | { type: 'SET_DROPOFF_CALENDAR_OPEN'; payload: boolean };

const initialState: SearchState = {
  open: false,
  isLoading: false,
  routePickupCity: '',
  routePickupState: '',
  routeDropoffCity: '',
  routeDropoffState: '',
  customerName: '',
  pickupDate: undefined,
  dropoffDate: undefined,
  pickupCalendarOpen: false,
  dropoffCalendarOpen: false,
};

function searchReducer(state: SearchState, action: SearchAction): SearchState {
  switch (action.type) {
    case 'SET_OPEN':
      return { ...state, open: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_PICKUP_CITY':
      return { ...state, routePickupCity: action.payload };
    case 'SET_PICKUP_STATE':
      return { ...state, routePickupState: action.payload };
    case 'SET_DROPOFF_CITY':
      return { ...state, routeDropoffCity: action.payload };
    case 'SET_DROPOFF_STATE':
      return { ...state, routeDropoffState: action.payload };
    case 'SET_CUSTOMER_NAME':
      return { ...state, customerName: action.payload };
    case 'SET_PICKUP_DATE':
      return { ...state, pickupDate: action.payload };
    case 'SET_DROPOFF_DATE':
      return { ...state, dropoffDate: action.payload };
    case 'SET_PICKUP_CALENDAR_OPEN':
      return { ...state, pickupCalendarOpen: action.payload };
    case 'SET_DROPOFF_CALENDAR_OPEN':
      return { ...state, dropoffCalendarOpen: action.payload };
    default:
      return state;
  }
}

// Define MainPopoverContent outside of the main component
const MainPopoverContent = memo(
  ({
    state,
    dispatch,
    handleFetchLoadBySearch,
  }: {
    state: SearchState;
    dispatch: React.Dispatch<SearchAction>;
    handleFetchLoadBySearch: () => Promise<void>;
  }) => (
    <PopoverContent
      className='p-4 pb-1 bg-white w-[268px]'
      align='center'
      side='bottom'
      style={{
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
      }}
    >
      <div className='flex flex-col gap-2'>
        <Input
          type='text'
          placeholder='Customer Name'
          value={state.customerName}
          onChange={(e) =>
            dispatch({ type: 'SET_CUSTOMER_NAME', payload: e.target.value })
          }
          className='h-[30px] text-[13px]'
        />

        <div className='flex gap-2'>
          <Input
            type='text'
            placeholder='Pickup City *'
            value={state.routePickupCity}
            onChange={(e) =>
              dispatch({ type: 'SET_PICKUP_CITY', payload: e.target.value })
            }
            className='h-[30px] text-[13px]'
          />
          <Select
            value={state.routePickupState}
            onValueChange={(value) =>
              dispatch({ type: 'SET_PICKUP_STATE', payload: value })
            }
          >
            <SelectTrigger className='min-w-[80px] w-[80px] h-[30px] text-[13px]'>
              <SelectValue placeholder='State' className='text-[13px]' />
            </SelectTrigger>
            <SelectContent>
              {STATE_ABBREVIATIONS.map((state) => (
                <SelectItem key={state} value={state} className='text-[13px]'>
                  {state}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className='grid gap-2'>
          <Popover
            open={state.pickupCalendarOpen}
            onOpenChange={(open) =>
              dispatch({ type: 'SET_PICKUP_CALENDAR_OPEN', payload: open })
            }
          >
            <PopoverTrigger asChild>
              <Button
                variant='outline'
                className={cn(
                  'w-full justify-start text-left font-normal h-[30px] text-[13px]',
                  !state.pickupDate
                )}
              >
                <CalendarIcon className='mr-2 h-3.5 w-3.5' />
                {state.pickupDate ? (
                  format(state.pickupDate, 'MM/dd/yyyy')
                ) : (
                  <span className='text-gray-500 opacity-50 text-[13px]'>
                    Pickup Date *
                  </span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0'>
              <Calendar
                mode='single'
                selected={state.pickupDate}
                onSelect={(date) => {
                  dispatch({ type: 'SET_PICKUP_DATE', payload: date });
                  dispatch({
                    type: 'SET_PICKUP_CALENDAR_OPEN',
                    payload: false,
                  });
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className='flex gap-2'>
          <Input
            type='text'
            placeholder='Dropoff City *'
            value={state.routeDropoffCity}
            onChange={(e) =>
              dispatch({ type: 'SET_DROPOFF_CITY', payload: e.target.value })
            }
            className='h-[30px] text-[13px]'
          />
          <Select
            value={state.routeDropoffState}
            onValueChange={(value) =>
              dispatch({ type: 'SET_DROPOFF_STATE', payload: value })
            }
          >
            <SelectTrigger className='min-w-[80px] w-[80px] h-[30px] text-[13px]'>
              <SelectValue placeholder='State' />
            </SelectTrigger>
            <SelectContent>
              {STATE_ABBREVIATIONS.map((state) => (
                <SelectItem key={state} value={state}>
                  {state}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className='grid gap-2'>
          <Popover
            open={state.dropoffCalendarOpen}
            onOpenChange={(open) =>
              dispatch({ type: 'SET_DROPOFF_CALENDAR_OPEN', payload: open })
            }
          >
            <PopoverTrigger asChild>
              <Button
                variant='outline'
                className={cn(
                  'w-full justify-start text-left font-normal h-[30px] text-[13px]',
                  !state.dropoffDate
                )}
              >
                <CalendarIcon className='mr-2 h-3.5 w-3.5' />
                {state.dropoffDate ? (
                  format(state.dropoffDate, 'MM/dd/yyyy')
                ) : (
                  <span className='text-gray-500 opacity-50 text-[13px]'>
                    Dropoff Date
                  </span>
                )}
                {state.dropoffDate && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent opening the calendar
                      dispatch({
                        type: 'SET_DROPOFF_DATE',
                        payload: undefined,
                      });
                    }}
                    className='text-gray-500 hover:text-orange-pressed focus:outline-none'
                    title='Clear dropoff date'
                    aria-label='Clear dropoff date'
                  >
                    <XCircleIcon className='h-3.5 w-3.5' />
                  </button>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0'>
              <Calendar
                mode='single'
                selected={state.dropoffDate}
                onSelect={(date) => {
                  dispatch({ type: 'SET_DROPOFF_DATE', payload: date });
                  dispatch({
                    type: 'SET_DROPOFF_CALENDAR_OPEN',
                    payload: false,
                  });
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <Button
          variant='default'
          className='bg-orange-pressed text-white h-6 rounded-[4px] text-[12px]'
          disabled={state.isLoading}
          buttonNamePosthog={ButtonNamePosthog.SearchLoad}
          onClick={handleFetchLoadBySearch}
        >
          {state.isLoading ? <ButtonLoader /> : 'Search'}
        </Button>
        <span className='text-[10px] italic text-center text-grayscale-content-3 mb-0 pt-0'>
          Max 10 results shown
        </span>
      </div>
    </PopoverContent>
  )
);

// FIXME: Don't fetch TMS objects for each load tab
const AdvancedSearchPopover = () => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  const { addLoadTab } = useLoadSearch();
  const { toast } = useToast();

  const handleFetchLoadBySearch = useCallback(async () => {
    if (
      !state.pickupDate ||
      !state.routePickupCity ||
      !state.routeDropoffCity
    ) {
      toast({
        description: 'Pickup date, pickup city, and dropoff city are required.',
        variant: 'destructive',
      });
      return;
    }

    dispatch({ type: 'SET_LOADING', payload: true });

    const resp = await fetchLoadBySearchQuery({
      customerName: state.customerName,
      routePickupCity: state.routePickupCity,
      routePickupState: state.routePickupState,
      routeDropoffCity: state.routeDropoffCity,
      routeDropoffState: state.routeDropoffState,
      pickupDate: state.pickupDate ? dayjs(state.pickupDate) : null,
      dropoffDate: state.dropoffDate ? dayjs(state.dropoffDate) : null,
    });

    if (resp.isOk()) {
      const {
        value: { loadIDs },
      } = resp;

      if (loadIDs?.length === 0) {
        toast({
          description: 'No loads were found.',
          variant: 'destructive',
        });
        dispatch({ type: 'SET_LOADING', payload: false });
        return;
      }

      for (const loadID of loadIDs || []) {
        try {
          await addLoadTab(loadID);
        } catch (error) {
          console.error('Error adding load tab:', error);
        }
      }

      // Close the popover after successful search
      dispatch({ type: 'SET_OPEN', payload: false });
    } else {
      toast({
        description: resp.error.message,
        variant: 'destructive',
      });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
  }, [
    addLoadTab,
    toast,
    state.customerName,
    state.routePickupCity,
    state.routePickupState,
    state.routeDropoffCity,
    state.routeDropoffState,
    state.pickupDate,
    state.dropoffDate,
  ]);

  /**
   * The popover should be appended to the drumkit root container so we don't run into clipping issues
   * when the sidebar has a smaller height - e.g. when sidebar only has it's header loaded.
   *
   * If we fail to fetch said root container, append as a child of the trigger button like before.
   *
   * We differ from Radix's documentation here and don't append to document.body since that would prevent
   * the popover from using our CSS, since that is encapsulated in the root container.
   */
  const popoverRootContainer = document.getElementById(
    'drumkit-content-view-root'
  );

  const setOpen = useCallback((open: boolean) => {
    dispatch({ type: 'SET_OPEN', payload: open });
  }, []);

  return (
    <Popover open={state.open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button className='flex items-center justify-center cursor-pointer'>
          <SlidersHorizontal className='h-3.5 w-3.5 stroke-grayscale-icon-stroke' />
        </button>
      </PopoverTrigger>
      {popoverRootContainer ? (
        <PopoverPortal container={popoverRootContainer}>
          <MainPopoverContent
            state={state}
            dispatch={dispatch}
            handleFetchLoadBySearch={handleFetchLoadBySearch}
          />
        </PopoverPortal>
      ) : (
        <MainPopoverContent
          state={state}
          dispatch={dispatch}
          handleFetchLoadBySearch={handleFetchLoadBySearch}
        />
      )}
    </Popover>
  );
};

export default memo(AdvancedSearchPopover);
