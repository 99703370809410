import { toUpper } from 'lodash';

import CheckCallSource from 'types/enums/CheckCallSource';
import { titleCase } from 'utils/formatStrings';
import { normalizeDatesForTMSForm } from 'utils/parseDatesForTMSForm';

import { Maybe, MaybeUndef } from './UtilityTypes';

export type Load = {
  // Capitalized only because of underlying gorm.Model
  ID: MaybeUndef<number>;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: MaybeUndef<string>;

  serviceID: number;
  tmsID: number;
  freightTrackingID: string;
  isPlaceholder: boolean;
  externalTMSID: string;
  status: string;
  mode: string;
  moreThanTwoStops: boolean;
  poNums: MaybeUndef<string>;
  operator: string;
  rateData: RateData;
  customer: Customer;
  billTo: CompanyCoreInfo;
  pickup: Pickup;
  consignee: Dropoff;
  carrier: LoadCarrier;
  specifications: Specifications;
  notes: Note[];
};

export type CompanyCoreInfo = {
  externalTMSID: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country?: string;
  contact: string;
  phone: string;
  email: string;
};

export const formatAddress = (address: CompanyCoreInfo): string => {
  if (!address) {
    return '';
  }

  const parts: string[] = [];
  if (address.addressLine1) parts.push(titleCase(address.addressLine1));
  if (address.addressLine2) parts.push(titleCase(address.addressLine2));
  if (address.city) parts.push(titleCase(address.city));
  if (address.state) parts.push(address.state);
  if (address.zipCode) parts.push(toUpper(address.zipCode));

  return parts.join(', ');
};

export const formatAddressCityStateZip = (address: CompanyCoreInfo): string => {
  if (!address) {
    return '';
  }

  const parts: string[] = [];
  if (address.city) parts.push(titleCase(address.city));
  if (address.state) parts.push(address.state);
  if (address.zipCode) parts.push(toUpper(address.zipCode));

  return parts.join(', ');
};

export type Customer = CompanyCoreInfo & {
  refNumber: string;
};

export type Note = {
  updatedBy: string;
  createdAt: string;
  note: string;
  isException: Maybe<boolean>;
  isOnTime: Maybe<boolean>;
  source: string; // Primarily for Relay, e.g. dispatcher, driver
};

export type LoadCarrier = {
  externalTMSID: string;
  name: string;
  mcNumber: string;
  dotNumber: string;
  phone: string;
  dispatcher: string;
  notes: string;
  sealNumber: string;
  scac: string;
  firstDriverName: string;
  firstDriverPhone: string;
  secondDriverName: string;
  secondDriverPhone: string;
  email: string;
  dispatchCity: string;
  dispatchState: string;
  dispatchSource: CheckCallSource;
  truckNumber: string;
  trailerNumber: string;
  rateConfirmationSent: boolean;
  confirmationSentTime: MaybeUndef<string>;
  confirmationReceivedTime: MaybeUndef<string>;
  dispatchedTime: MaybeUndef<string>;
  expectedPickupTime: MaybeUndef<string>;
  pickupStart: MaybeUndef<string>;
  pickupEnd: MaybeUndef<string>;
  expectedDeliveryTime: MaybeUndef<string>;
  deliveryStart: MaybeUndef<string>;
  deliveryEnd: MaybeUndef<string>;
  signedBy: string;
};

export type Pickup = LoadStop & {
  readyTime: MaybeUndef<string>;
};

export type Dropoff = LoadStop & {
  mustDeliver: string; // Must deliver by datetime
};

export type LoadStop = CompanyCoreInfo & {
  businessHours: string;
  refNumber: string;
  apptRequired: Maybe<boolean>;
  apptStartTime: MaybeUndef<string>;
  apptEndTime: MaybeUndef<string>;
  apptNote: string;
  timezone: string; // IANA timezone e.g. America/New_York
};

export type ValueUnit = {
  val: number;
  unit: Maybe<Unit>;
};

export enum Unit {
  // Currency
  //---------------
  USD = 'USD',
  CAD = 'CAD',

  // Weight
  //---------------
  Kg = 'kg',
  Pounds = 'lbs',
  // Turvo specific
  Tonne = 't',
  Ounce = 'oz',
  Ton = 'ton',
  Gram = 'g',
  Pound = 'lb',

  // Pieces
  //---------------
  Boxes = 'boxes',
  Crates = 'crates',
  Pallets = 'pallets',
  // Turvo specific
  MetricTons = 'Metric tons',
  Bottles = 'Bottles',
  Packages = 'Packages',
  Racks = 'Racks',
  Bin = 'Bin',
  Piece = 'Piece',
  Ream = 'Ream',
  Spack = 'Spack',
  Spool = 'Spool',
  Bushels = 'Bushels',
  Containers = 'Containers',
  Gallons = 'Gallons',
  Tons = 'Tons',
  Kegs = 'Kegs',
  Cases = 'Cases',
  Truckload = 'Truckload',
  Blocks = 'Blocks',
  Bulk = 'Bulk',
  Barrels = 'Barrels',
  Units = 'Units',
  Each = 'Each',
  TurvoPallets = 'Pallets',
  BaseUnits = 'Base units',
  TurvoBoxes = 'Boxes',
  Loose = 'Loose',
  Bags = 'Bags',
  Bales = 'Bales',
  Bundles = 'Bundles',
  Cans = 'Cans',
  Carboys = 'Carboys',
  Carpets = 'Carpets',
  Cartons = 'Cartons',
  Coils = 'Coils',
  TurvoCrates = 'Crates',
  Cylinders = 'Cylinders',
  Drums = 'Drums',
  Pails = 'Pails',
  Reels = 'Reels',
  Lbs = 'Lbs',
  Rolls = 'Rolls',
  Kgs = 'Kgs',
  Skids = 'Skids',
  Liters = 'Liters',
  Totes = 'Totes',
  TubesPipes = 'Tubes/pipes',
  Vehicles = 'Vehicles',
  Other = 'Other',
  Kit = 'Kit',
  Pack = 'Pack',
  Pair = 'Pair',
  Feet = 'Feet',
  InnerPack = 'Inner Pack',
  Grams = 'Grams',
  Layer = 'Layer',
  Items = 'Items',
  Buckets = 'Buckets',
  Combinations = 'Combinations',
  HundredweightOnNet = 'Hundredweight on Net',
  Pouches = 'Pouches',
  Trays = 'Trays',
  Tubs = 'Tubs',

  // Distance
  //---------------
  Miles = 'miles',
  Km = 'km',
}

export type Specifications = {
  totalInPalletCount: MaybeUndef<number>;
  totalOutPalletCount: MaybeUndef<number>;
  totalPieces: Maybe<ValueUnit>;
  commodities: string;
  numCommodities: MaybeUndef<number>;
  transportType: string; // Van, Flatbed, Reefer, etc
  serviceType: string;
  transportLength: string;
  totalWeight: Maybe<ValueUnit>;
  netWeight: Maybe<ValueUnit>;
  billableWeight: Maybe<ValueUnit>;
  totalDistance: MaybeUndef<ValueUnit>;
  minTempFahrenheit: MaybeUndef<number>;
  maxTempFahrenheit: MaybeUndef<number>;
};

export type RateData = {
  collectionMethod: string; // e.g. Prepaid, Collect, Third-Party, etc
  revenueCode: string; // Department/team in brokerage receiving the revenue
  customerRateType: string; // e.g. FlatRate, All In, Hourly, Mileage

  // Customer rate info
  customerLineHaulCharge: Maybe<ValueUnit>; // ValueUnit with currency (e.g. USD)
  customerRateNumUnits: Maybe<number>; // Number of units for rate type (e.g. miles, hours, etc.)
  customerLineHaulRate: Maybe<number>; // Per unit rate (e.g. $/mile or $/hour)
  customerLineHaulUnit: string; // Unit for the rate (e.g. miles, hours, etc.)
  customerTotalCharge: Maybe<ValueUnit>; // ValueUnit.Unit is currency (e.g. USD).

  fscPercent: Maybe<number>; // 0 - 100
  fscPerMile: Maybe<number>;
  fscFlatRate: Maybe<number>;

  // Carrier rate info
  carrierRateType: string; // e.g. FlatRate, All In, Hourly, Mileage
  carrierLineHaulCharge: Maybe<ValueUnit>; // ValueUnit with currency (e.g. USD)
  carrierRateNumUnits: Maybe<number>; // Number of units for rate type (e.g. miles, hours, etc.)
  carrierLineHaulRate: Maybe<number>; // Per unit rate (e.g. $/mile or $/hour)
  carrierLineHaulUnit: string; // Unit for the rate (e.g. miles, hours, etc.)
  carrierTotalCost: Maybe<ValueUnit>; // ValueUnit.Unit is currency (e.g. USD).

  carrierMaxRate: Maybe<number>;

  netProfitUSD: Maybe<number>;
  profitPercent: Maybe<number>; // 0 - 100
};

// For Aljex loads whose timestamps are timezone-agnostic, normalizes all timestamps to be in UTC,
// regardless of the user's locale
export type NormalizedLoad = {
  // Capitalized only because of underlying gorm.Model
  ID: MaybeUndef<number>;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: MaybeUndef<string>;

  serviceID: number;
  tmsID: number;
  freightTrackingID: string;
  isPlaceholder: boolean;
  externalTMSID: string;
  status: string;
  mode: string;
  moreThanTwoStops: boolean;
  poNums: MaybeUndef<string>;
  operator: string;
  rateData: RateData;
  customer: Customer;
  billTo: CompanyCoreInfo;
  pickup: NormalizedPickup;
  consignee: NormalizedDropoff;
  carrier: NormalizedLoadCarrier;
  specifications: Specifications;
  notes: Note[];
};

export type NormalizedLoadCarrier = {
  name: string;
  externalTMSID: string;
  mcNumber: string;
  dotNumber: string;
  phone: string;
  dispatcher: string;
  notes: string;
  sealNumber: string;
  scac: string;
  firstDriverName: string;
  firstDriverPhone: string;
  secondDriverName: string;
  secondDriverPhone: string;
  email: string;
  dispatchSource: CheckCallSource;
  dispatchCity: string;
  dispatchState: string;
  truckNumber: string;
  trailerNumber: string;
  rateConfirmationSent: boolean;
  confirmationSentTime: Maybe<Date>;
  confirmationReceivedTime: Maybe<Date>;
  dispatchedTime: Maybe<Date>;
  expectedPickupTime: Maybe<Date>;
  pickupStart: Maybe<Date>;
  pickupEnd: Maybe<Date>;
  expectedDeliveryTime: Maybe<Date>;
  deliveryStart: Maybe<Date>;
  deliveryEnd: Maybe<Date>;
  signedBy: string;
};

export type NormalizedPickup = NormalizedLoadStop & {
  readyTime: Maybe<Date>;
};

export type NormalizedDropoff = NormalizedLoadStop & {
  mustDeliver: string; // Must deliver by datetime
};

export type NormalizedLoadStop = CompanyCoreInfo & {
  businessHours: string;
  refNumber: string;
  apptRequired: Maybe<boolean>;
  apptStartTime: Maybe<Date>;
  apptEndTime: Maybe<Date>;
  apptNote: string;
  timezone: string; // IANA timezone e.g. America/New_York
};

export function normalizeLoad(tmsName: string, load: Load) {
  return {
    ...load,
    customer: normalizeDatesForTMSForm(tmsName, load.customer),
    billTo: normalizeDatesForTMSForm(tmsName, load.billTo),
    pickup: normalizeDatesForTMSForm(tmsName, load.pickup),
    consignee: normalizeDatesForTMSForm(tmsName, load.consignee),
    carrier: normalizeDatesForTMSForm(tmsName, load.carrier),
  } as NormalizedLoad;
}

export function mapUnit(unitStr: string): Maybe<Unit> {
  switch (unitStr.toLowerCase()) {
    case 'kg':
      return Unit.Kg;
    case 'lbs':
      return Unit.Pounds;
    case 'boxes':
      return Unit.Boxes;
    case 'miles':
      return Unit.Miles;
    case 'km':
      return Unit.Km;
    case 'pallets':
      return Unit.Pallets;
    case 'crates':
      return Unit.Crates;
    default:
      return null;
  }
}

export type TMSCustomer = CompanyCoreInfo;

export type TMSLocation = CompanyCoreInfo & {
  apptRequired: Maybe<boolean>;
  isShipper?: boolean;
  isConsignee?: boolean;
  driverLoadingResponsibility?: string; // e.g. No (un)loading, driver load, drop & hook trailer. Primarily for Mcleod
  carrier?: TMSCarrier;
};

export type TMSLocationWithDistance = TMSLocation & {
  milesDistance?: number;
  emails?: Maybe<string[]>;
};

export type TMSCarrier = CompanyCoreInfo & { dotNumber: string };

export function createInitCompanyCoreInfo(): CompanyCoreInfo {
  return {
    externalTMSID: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    contact: '',
    phone: '',
    email: '',
  };
}

// Use factory functions to avoid mutating const object (alternative is using Object.freeze(),
// but this made intent more explicit)
export function createInitRateData(): RateData {
  return {
    revenueCode: '',
    collectionMethod: '',

    customerRateType: '',
    customerLineHaulCharge: null,
    customerRateNumUnits: null,
    customerLineHaulRate: null,
    customerLineHaulUnit: '',
    customerTotalCharge: null,

    fscPercent: null,
    fscPerMile: null,
    fscFlatRate: null,

    carrierRateType: '',
    carrierLineHaulCharge: null,
    carrierRateNumUnits: null,
    carrierLineHaulRate: null,
    carrierLineHaulUnit: '',
    carrierTotalCost: null,

    carrierMaxRate: null,

    netProfitUSD: null,
    profitPercent: null,
  };
}

export function createInitSpecs(): Specifications {
  return {
    totalInPalletCount: 0,
    totalOutPalletCount: 0,
    totalPieces: null,
    commodities: '',
    numCommodities: 0,
    totalWeight: null,
    billableWeight: null,
    netWeight: null,
    totalDistance: null,
    transportType: '',
    serviceType: '',
    transportLength: '',
    minTempFahrenheit: 0,
    maxTempFahrenheit: 0,
  };
}

export function createInitLoad(): Load {
  return {
    ID: null,
    CreatedAt: '',
    UpdatedAt: '',
    DeletedAt: null,
    serviceID: 0,
    tmsID: 0,
    freightTrackingID: '',
    isPlaceholder: false,
    externalTMSID: '',
    status: '',
    mode: '',
    moreThanTwoStops: false,
    poNums: '',
    operator: '',
    rateData: {
      revenueCode: '',
      collectionMethod: '',

      customerRateType: '',
      customerLineHaulCharge: null,
      customerRateNumUnits: null,
      customerLineHaulRate: null,
      customerLineHaulUnit: '',
      customerTotalCharge: null,

      fscPercent: null,
      fscPerMile: null,
      fscFlatRate: null,
      carrierRateType: '',
      carrierLineHaulCharge: null,
      carrierRateNumUnits: null,
      carrierLineHaulRate: null,
      carrierLineHaulUnit: '',
      carrierTotalCost: null,

      carrierMaxRate: null,

      netProfitUSD: null,
      profitPercent: null,
    },
    customer: {
      externalTMSID: '',
      name: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      contact: '',
      phone: '',
      email: '',
      refNumber: '',
    },
    billTo: {
      externalTMSID: '',
      name: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      contact: '',
      phone: '',
      email: '',
    },
    pickup: {
      externalTMSID: '',
      name: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      contact: '',
      phone: '',
      email: '',
      businessHours: '',
      refNumber: '',
      readyTime: null,
      apptRequired: null,
      apptStartTime: null,
      apptEndTime: null,
      apptNote: '',
      timezone: '',
    },
    consignee: {
      externalTMSID: '',
      name: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      contact: '',
      phone: '',
      email: '',
      businessHours: '',
      refNumber: '',
      mustDeliver: '',
      apptRequired: null,
      apptStartTime: null,
      apptEndTime: null,
      apptNote: '',
      timezone: '',
    },
    carrier: {
      externalTMSID: '',
      mcNumber: '',
      dotNumber: '',
      name: '',
      phone: '',
      dispatcher: '',
      notes: '',
      sealNumber: '',
      scac: '',
      firstDriverName: '',
      firstDriverPhone: '',
      secondDriverName: '',
      secondDriverPhone: '',
      email: '',
      dispatchSource: CheckCallSource.Dispatcher,
      dispatchCity: '',
      dispatchState: '',
      truckNumber: '',
      trailerNumber: '',
      rateConfirmationSent: false,
      confirmationSentTime: null,
      confirmationReceivedTime: null,
      dispatchedTime: null,
      expectedPickupTime: null,
      pickupStart: null,
      pickupEnd: null,
      expectedDeliveryTime: null,
      deliveryStart: null,
      deliveryEnd: null,
      signedBy: '',
    },
    specifications: {
      totalInPalletCount: 0,
      totalOutPalletCount: 0,
      totalPieces: null,
      commodities: '',
      numCommodities: 0,
      totalWeight: null,
      billableWeight: null,
      netWeight: null,
      totalDistance: null,
      transportType: '',
      serviceType: '',
      transportLength: '',
      minTempFahrenheit: 0,
      maxTempFahrenheit: 0,
    },
    notes: [],
  };
}
