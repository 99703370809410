import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CheckIcon, CopyIcon } from 'lucide-react';

import { Button } from 'components/Button';
import { CarrierQuoteCard } from 'components/CarrierQuoteCard';
import { Label } from 'components/Label';
import { Textarea } from 'components/Textarea';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Tooltip';
import CarrierPriceCalculator from 'components/pricing/CarrierPriceCalculator';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { useToast } from 'hooks/useToaster';
import { CarrierNetworkEmail, CarrierQuote } from 'types/QuoteRequest';
import { MaybeUndef } from 'types/UtilityTypes';
import { copyToClipboard } from 'utils/copyToClipboard';
import { formatCurrency } from 'utils/formatCurrency';
import { cn } from 'utils/shadcn';

import { CarrierCostType, MarginType } from '../RequestQuickQuote/types';

interface mappedQuote {
  carrierEmails: string[];
  threadID: string;
  bounced: boolean;
  responded: boolean;
  available: MaybeUndef<boolean>;
  price: MaybeUndef<number>;
  notes: MaybeUndef<string>;
  url: string; // URL-encoded
}

type ReviewCarrierQuotesSectionProps = {
  requestId: number;
  emails: CarrierNetworkEmail[];
  carrierQuotes: CarrierQuote[];
};

export default function ReviewCarrierQuotesSection({
  requestId,
  emails,
  carrierQuotes,
}: ReviewCarrierQuotesSectionProps) {
  const { toast } = useToast();
  const [sortedQuotes, setSortedQuotes] = useState<mappedQuote[]>([]);
  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState<number | null>(
    null
  );
  const [carrierCost, setCarrierCost] = useState(0);
  const [margin, setMargin] = useState(10);
  const [marginType, setMarginType] = useState<MarginType>(
    MarginType.Percentage
  );
  const [finalPrice, setFinalPrice] = useState(
    carrierCost * (1 + margin / 100)
  );
  const [isCarrierButtonClicked, setIsCarrierButtonClicked] = useState(true);
  const [draftResponseBody, setDraftResponseBody] = useState('');
  const [hasCopiedDraftResponse, setHasCopiedDraftResponse] = useState(false);

  const formMethods = useForm({
    defaultValues: {},
  });

  // TODO: Use Gmail/Outlook compose mode
  const draftMessage = useCallback(() => {
    setDraftResponseBody(
      `Hey,\n\nThanks for reaching out to us! We're able to do this shipment for ${formatCurrency(finalPrice, 'USD')}.`
    );
  }, [finalPrice]);

  useEffect(() => {
    // Map quotes based on email recipients
    const unsortedQuotes: mappedQuote[] = emails?.map((email) => {
      const q = carrierQuotes.find((q) => q.threadID === email.threadID);
      return {
        carrierEmails: email.recipients,
        threadID: email.threadID,
        bounced: email.bounced,
        responded: !!q && !!q.emailID, // Check if q is truthy (i.e., if quote is found)
        available: q?.suggestedQuote?.isAvailable,
        price: q?.suggestedQuote?.totalCost,
        notes: q?.suggestedQuote?.notes,
        url: email.url,
      };
    });

    if (!unsortedQuotes) {
      return;
    }

    // Sort the Quotes array by availability and price
    const sortedQuotes = unsortedQuotes?.sort((a, b) => {
      // If one of the emails bounced, place it at the bottom
      if (a.bounced && !b.bounced) return 1;
      if (!a.bounced && b.bounced) return -1;

      // If one of the carriers did not respond, place it at the bottom
      if (!a.responded && b.responded) return 1;
      if (a.responded && !b.responded) return -1;

      // If one of the carriers is unavailable, place it at the bottom
      if (!a.available && b.available) return 1;
      if (a.available && !b.available) return -1;

      // If both carriers responded and are available, sort by price
      return (a.price || 0) - (b.price || 0);
    });

    setSortedQuotes(sortedQuotes);
    if (sortedQuotes.length > 0 && sortedQuotes[0].price) {
      setSelectedQuoteIndex(0);
      setCarrierCost(sortedQuotes[0].price);
    }
  }, [emails, carrierQuotes]);

  // Set body first time sidebar is opened to this section
  useEffect(() => {
    if (isCarrierButtonClicked) {
      draftMessage();
    }
  }, []);

  // Update draft message when final price changes
  useEffect(() => {
    draftMessage();
  }, [finalPrice, draftMessage]);

  const onSelectCarrier = (index: number) => {
    if (sortedQuotes[index].price === 0) {
      return;
    }

    setSelectedQuoteIndex(index);
    setIsCarrierButtonClicked(true);
    setCarrierCost(sortedQuotes[index].price ?? 0);
  };

  const onBlur = () => {
    draftMessage();
  };

  const handleViewThread = (url: MaybeUndef<string>) => {
    if (!url) {
      return;
    }

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleCopyToClipboard = async () => {
    try {
      const success = await copyToClipboard(draftResponseBody);
      if (success) {
        setHasCopiedDraftResponse(true);
        // Reset copied state after a delay
        setTimeout(() => setHasCopiedDraftResponse(false), 2000);
      }
    } catch {
      toast({
        description: 'Failed to copy to clipboard.',
        variant: 'destructive',
      });
    }
  };

  if (sortedQuotes.length === 0) {
    return (
      <div className='mb-6'>
        <h2 className='text-md whitespace-nowrap text-grayscale-content-label font-semibold'>
          Review Carrier Responses
        </h2>
        <p className='text-sm text-grayscale-content-label'>
          No carrier responses to view.
        </p>
      </div>
    );
  }

  return (
    <ExtendedFormProvider aiDefaultValues={true}>
      <FormProvider {...formMethods}>
        <div>
          <p className='text-xs text-grayscale-content-label mb-1 italic'>
            View email threads and quotes. Respond to customer.
          </p>

          {/* Show carrier responses */}
          <div className='mb-6 grid grid-cols-1 mx-0 w-full gap-2 mt-2'>
            {sortedQuotes.map((quote, index) => {
              const carrierQuote = carrierQuotes.find(
                (q) => q.threadID === quote.threadID
              );

              return (
                // margin right because accordion width is smaller and cuts off the check icon when card selected
                <div
                  className='mr-1'
                  key={`quote-${quote.carrierEmails}-${index}`}
                >
                  <CarrierQuoteCard
                    name={carrierQuote?.carrierLocation.name}
                    city={carrierQuote?.carrierLocation.city}
                    state={carrierQuote?.carrierLocation.state}
                    emails={quote.carrierEmails}
                    price={quote.price || undefined}
                    available={quote.available ?? undefined}
                    notes={quote.notes}
                    responded={quote.responded}
                    bounced={quote.bounced}
                    selected={selectedQuoteIndex === index}
                    onViewThread={() => handleViewThread(quote.url)}
                    onSelect={() => onSelectCarrier(index)}
                  />
                </div>
              );
            })}
          </div>

          {/* Calculate price to shipper */}
          <h2 className='my-1 text-sm font-medium'>Calculate Final Price:</h2>

          <CarrierPriceCalculator
            parentQuoteRequestId={requestId}
            maxDistance={0}
            showTitle={false}
            mileage={0} // Not using mileage since we're only supporting flat rates
            finalPrice={finalPrice}
            fuelEstimate={0}
            datFuelSurcharge={0}
            margin={margin}
            marginType={marginType}
            setMarginTypeHandler={setMarginType}
            carrierCost={carrierCost}
            carrierCostType={CarrierCostType.Flat}
            setCarrierCostTypeHandler={() => {}} // No-op since we're only supporting flat rates
            setCarrierCostHandler={setCarrierCost}
            setMarkupHandler={setMargin}
            setFinalPriceHandler={setFinalPrice}
            setIsCarrierButtonClickedHandler={setIsCarrierButtonClicked}
            setSelectedQuoteIndexHandler={setSelectedQuoteIndex}
            onBlurHandler={onBlur}
          />

          <div className='mt-4'>
            <Label name='' className='text-sm'>
              Draft Response to Customer
            </Label>
            <div className='relative'>
              <Button
                className={cn(
                  'absolute h-6 w-6 p-0 -top-6 right-0 border-none',
                  hasCopiedDraftResponse ? 'cursor-default' : 'cursor-pointer'
                )}
                variant='ghost'
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  !hasCopiedDraftResponse && handleCopyToClipboard();
                }}
              >
                {hasCopiedDraftResponse ? (
                  <Tooltip open={true}>
                    <TooltipTrigger asChild>
                      <CheckIcon className='h-4 w-4' />
                    </TooltipTrigger>
                    <TooltipContent>Copied!</TooltipContent>
                  </Tooltip>
                ) : (
                  <CopyIcon className='h-4 w-4' />
                )}
              </Button>
              <Textarea
                name='draftResponse'
                className='p-2 h-36 mt-1'
                value={draftResponseBody}
                onChange={(e) => {
                  setDraftResponseBody(e.target.value);
                }}
              />
            </div>
          </div>
          {/* TODO Sophie: (nice to have) send email reply to customer/initials ender in gmail */}
          {/* TODO: Signpost user to build load and redirect to tab*/}
        </div>
      </FormProvider>
    </ExtendedFormProvider>
  );
}
