import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { CalendarIcon, MailsIcon, RouteIcon, XCircleIcon } from 'lucide-react';

import SuggestionsCarousel from 'components/AISuggestions/SuggestionsCarousel';
import ErrorBoundary from 'components/ErrorBoundary';
import LoadContextProvider from 'components/LoadContextProvider';
import StarredLoadList from 'components/StarredLoadList';
import StarredLoadToggle from 'components/StarredLoadToggle';
import TMSProvider from 'components/TMSProvider';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TabsTriggerVariants,
} from 'components/Tabs';
import { Toaster } from 'components/ToasterProvider';
import SidebarLoader from 'components/loading/SidebarLoader';
import { AvailableTabs } from 'constants/SidebarTabs';
import {
  SidebarStateContext,
  isEmailPlatform,
} from 'contexts/sidebarStateContext';
import { TMSContextType } from 'contexts/tms';
import useFetchLoadByFreightTrackingID from 'hooks/useFetchLoadByFreightTrackingID';
import { fetchStarredLoads } from 'hooks/useFetchStarredLoads';
import useFetchSuggestions from 'hooks/useFetchSuggestions';
import { fetchViewedLoads } from 'hooks/useFetchViewedLoads';
import { useServiceFeatures } from 'hooks/useServiceContext';
import InfoCircleIcon from 'icons/InfoCircle';
import { createTMSInstance } from 'lib/hosts/interface';
import AppointmentSchedulingSection from 'pages/LoadView/AppointmentTab';
import LoadInformationSection from 'pages/LoadView/LoadInformationTab';
import EmailsSection from 'pages/LoadView/OutboxTab';
import CarrierCheckInSection from 'pages/LoadView/TrackAndTrace/CarrierCheckIn';
import CheckCallSection from 'pages/LoadView/TrackAndTrace/CheckCalls';
import ShipperCheckInSection from 'pages/LoadView/TrackAndTrace/ShipperCheckIn';
import { Email } from 'types/Email';
import { ExternalLinks } from 'types/ExternalLinks';
import { Load, normalizeLoad } from 'types/Load';
import { LoadAttributes } from 'types/LoadAttributes';
import { PendingOutboxEmails } from 'types/PendingOutboxEmail';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';
import CheckCallSource from 'types/enums/CheckCallSource';
import { TMS } from 'types/enums/Integrations';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import {
  CheckCallSuggestion,
  SuggestedLoadChange,
} from 'types/suggestions/LoadSuggestions';
import captureException from 'utils/captureException';
import { cn } from 'utils/shadcn';

import { useLoadSearch } from '../../contexts/loadSearchContext';
import OverviewSection from './OverviewSection';
import ExceptionSection from './TrackAndTrace/Exceptions';
import NoteSection from './TrackAndTrace/Notes';

interface LoadsSidebarProps {
  email: Maybe<Email>;
}

export default function LoadsSidebar({ email }: LoadsSidebarProps) {
  const { addLoadTab, proNumbers, setProNumbers, activeTab, setActiveTab } =
    useLoadSearch();

  const {
    currentState: { starredLoads },
    setCurrentState,
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: { isAdvancedSearchEnabled },
  } = useServiceFeatures();

  // Fetch starred and viewed loads only when advanced search is enabled
  useEffect(() => {
    if (isAdvancedSearchEnabled) {
      fetchStarredLoads(setCurrentState);
      fetchViewedLoads(setCurrentState);
    }
  }, [isAdvancedSearchEnabled, setCurrentState]);

  const closeTab = useCallback(
    (pro: string) => {
      setProNumbers((prevProNumbers: string[]) => {
        const newProNumbers = prevProNumbers.filter((x: string) => x !== pro);
        setActiveTab(newProNumbers[0] || '');
        return newProNumbers;
      });
    },
    [setProNumbers, setActiveTab]
  );

  // Auto-detect load open on in Planning Board on Relay
  const curRelayModalLoadID = useRef<string>(''); // Ref to track current schedule plan ID

  useEffect(() => {
    const handleModalEvent = () => {
      const tms = createTMSInstance(TMS.Relay);
      // Try parsing both IDs because depending on the TMS webpage we're on,
      // one may be available and the other night
      const parsedID = tms.parseExternalTMSID() ?? tms.parseFreightTrackingID();
      if (parsedID && parsedID !== curRelayModalLoadID.current) {
        curRelayModalLoadID.current = parsedID;
        addLoadTab(parsedID);
      }
    };

    document.addEventListener('loadModalEvent', handleModalEvent);
    return () => {
      document.removeEventListener('loadModalEvent', handleModalEvent);
    };
  }, [addLoadTab]);

  return (
    <div style={{ flex: '1' }}>
      <div className='flex flex-col'>
        <div className='w-full flex-1 shrink-0 flex flex-col'>
          <Tabs
            value={activeTab}
            onValueChange={setActiveTab}
            className='w-full flex-1 shrink-0 flex flex-col mt-2'
          >
            <TabsList className='w-full gap-[12px] relative flex items-center justify-between pr-4'>
              <div className='flex gap-[12px] flex-1 items-center overflow-x-auto'>
                {proNumbers?.length > 0 &&
                  proNumbers.map((proNumber) => (
                    <>
                      <TabsTrigger
                        value={proNumber}
                        key={proNumber}
                        className={cn(
                          'text-sm !rounded-none inline-block w-auto shrink-1 text-ellipsis overflow-hidden',
                          proNumber === activeTab && 'shrink-0'
                        )}
                      >
                        {proNumber}
                      </TabsTrigger>
                      {proNumber === activeTab && (
                        <XCircleIcon
                          className='h-4 w-4 shrink-0 -ml-1 stroke-orange-main cursor-pointer'
                          onClick={() => closeTab(proNumber)}
                        />
                      )}
                    </>
                  ))}
              </div>

              {isAdvancedSearchEnabled && (
                <div className='flex items-center gap-3 ml-4 shrink-0 py-2'>
                  <StarredLoadToggle
                    tab={activeTab}
                    starredLoads={starredLoads}
                  />
                  <StarredLoadList
                    tab={activeTab}
                    starredLoads={starredLoads}
                    addLoadTab={addLoadTab}
                  />
                </div>
              )}
            </TabsList>

            <div className='flex-1 shrink-0'>
              {proNumbers.map((proNumber) => (
                <TabsContent value={proNumber} key={proNumber} className='pt-4'>
                  <LoadsSidebarLoadTab
                    email={email}
                    freightTrackingID={proNumber}
                  />
                </TabsContent>
              ))}
            </div>
          </Tabs>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

type LoadsSidebarLoadTabProps = {
  email: MaybeUndef<Email>;
  freightTrackingID: string;
};

function LoadsSidebarLoadTab({
  email,
  freightTrackingID,
}: LoadsSidebarLoadTabProps) {
  const {
    tmsID,
    tmsName,
    load,
    loadAttributes,
    externalLinks,
    invalidate,
    invalidateKey,
    isLoading,
    pendingOutboxEmails,
    pickupWarehouse: loadPickupWarehouse,
    dropoffWarehouse: loadDropoffWarehouse,
    error: fetchError,
  } = useFetchLoadByFreightTrackingID(freightTrackingID);

  const loadNotFound =
    !isLoading &&
    fetchError &&
    isAxiosError(fetchError) &&
    fetchError.response?.status === 404;

  const invalidateLoad = async (
    load?: Maybe<Load>,
    attrs?: Maybe<LoadAttributes>
  ) => {
    if (load && attrs) {
      await invalidate({
        tmsID,
        tmsName,
        externalLinks,
        load,
        loadAttributes: attrs,
        pendingOutboxEmails,
        pickupWarehouse: loadPickupWarehouse,
        dropoffWarehouse: loadDropoffWarehouse,
      });
    } else {
      invalidate();
    }
  };

  return (
    <div className='flex-1 shrink-0'>
      {!load && isLoading && <SidebarLoader />}

      {/* {!isLoading && load && load.moreThanTwoStops && (
        <div className='px-4 text-red-main'>
          {'We will soon support multi-stop loads!'}
        </div>
      )} */}

      {load && !isLoading && !load.isPlaceholder && (
        <LoadTabContent
          tmsID={tmsID}
          tmsName={tmsName}
          email={email!}
          load={load!}
          loadAttributes={loadAttributes!}
          externalLinks={externalLinks}
          loadPickupWarehouse={loadPickupWarehouse!}
          loadDropoffWarehouse={loadDropoffWarehouse!}
          invalidate={invalidateLoad}
          invalidateKey={invalidateKey}
          pendingOutboxEmails={pendingOutboxEmails!}
        />
      )}

      {loadNotFound && (
        <div className='px-4 text-red-main'>
          Load not found for #{freightTrackingID}.
        </div>
      )}

      {/* We live lookup a load from TMS when a user opens email/searches for it. So if it's still a placeholder at this point,
  something went wrong with the live lookup (429, network timeout, etc) */}
      {(!loadNotFound && freightTrackingID && !load && !isLoading) ||
        (load?.isPlaceholder && (
          <div className='px-4 text-red-main'>Error fetching load.</div>
        ))}
    </div>
  );
}

type LoadTabContentProps = TMSContextType & {
  email: Email;
  load: Load;
  loadAttributes: LoadAttributes;
  externalLinks: MaybeUndef<ExternalLinks>;
  loadPickupWarehouse: MaybeUndef<Warehouse>;
  loadDropoffWarehouse: MaybeUndef<Warehouse>;
  invalidate: (load?: Load, loadAttributes?: LoadAttributes) => Promise<void>;
  invalidateKey: number;
  pendingOutboxEmails: MaybeUndef<PendingOutboxEmails>;
};

export function LoadTabContent({
  tmsID,
  tmsName,
  email,
  load,
  loadAttributes,
  externalLinks,
  loadPickupWarehouse,
  loadDropoffWarehouse,
  invalidate,
  invalidateKey,
  pendingOutboxEmails,
}: LoadTabContentProps) {
  const {
    serviceFeaturesEnabled: {
      isAppointmentSchedulingEnabled,
      isTrackAndTraceEnabled,
      isExceptionsEnabled,
      isCheckCallCarrierSOPEnabled,
      isCheckCallShipperSOPEnabled,
      isCheckCallNotesEnabled,
      isCarrierEmailOutboxEnabled,
    },
  } = useServiceFeatures();

  const {
    suggestions,
    isLoading,
    invalidate: suggestionsInvalidate,
  } = useFetchSuggestions(email?.threadID, load.ID);

  const [tab, setTab] = useState(AvailableTabs.LoadInformation);

  const [modifiedSuggestions, setModifiedSuggestions] = useState<
    SuggestedLoadChange[]
  >([]);

  useEffect(() => {
    if (suggestions) {
      const updatedSuggestions = suggestions
        .filter((sug) => sug.status === 'pending')
        .map((sug) => {
          if (sug.pipeline === SuggestionPipelines.CarrierInfo) {
            return {
              ...sug,
              suggested: {
                ...sug.suggested,
                dispatchedTime: sug.suggested.dispatchedTime
                  ? sug.suggested.dispatchedTime
                  : dayjs().second(0).millisecond(0).toDate(),
                dispatchSource:
                  sug.suggested.dispatchSource ?? CheckCallSource.Dispatcher,
              },
            };
          }
          return sug;
        });

      setModifiedSuggestions(updatedSuggestions);
    }
  }, [suggestions]);

  const {
    currentState: { drumkitPlatform },
  } = useContext(SidebarStateContext);

  useEffect(() => {
    if (!isEmailPlatform(drumkitPlatform) && tmsName) {
      setTab(createTMSInstance(tmsName as TMS).determineDefaultLoadTab());
    }
  }, [tmsName]);

  const loadBelongsToCurrentEmail =
    email &&
    !load?.isPlaceholder &&
    email.freightTrackingIDs &&
    email.freightTrackingIDs.includes(load.freightTrackingID);

  const normalizedLoad = normalizeLoad(tmsName, load);

  // Reformat load attributes for context provider
  const fieldAttrs = useMemo(() => {
    const customerFieldAttrs = Object.entries(loadAttributes.customer).map(
      ([key, value]) => ({
        [`customer.${key}`]: value,
      })
    );
    const billToFieldAttrs = Object.entries(loadAttributes.billTo).map(
      ([key, value]) => ({
        [`billTo.${key}`]: value,
      })
    );
    const specificationsFieldAttrs = Object.entries(
      loadAttributes.specifications
    ).map(([key, value]) => ({
      [`specifications.${key}`]: value,
    }));
    const pickupFieldAttrs = Object.entries(loadAttributes.pickup).map(
      ([key, value]) => ({
        [`pickup.${key}`]: value,
      })
    );
    const consigneeFieldAttrs = Object.entries(loadAttributes.consignee).map(
      ([key, value]) => ({
        [`consignee.${key}`]: value,
      })
    );
    const carrierFieldAttrs = Object.entries(loadAttributes.carrier).map(
      ([key, value]) => ({
        [`carrier.${key}`]: value,
      })
    );
    const rateDataFieldAttrs = Object.entries(loadAttributes.rateData).map(
      ([key, value]) => ({
        [`rateData.${key}`]: value,
      })
    );

    return [
      { poNums: loadAttributes.poNums },
      ...customerFieldAttrs,
      ...billToFieldAttrs,
      ...specificationsFieldAttrs,
      ...pickupFieldAttrs,
      ...consigneeFieldAttrs,
      ...carrierFieldAttrs,
      ...rateDataFieldAttrs,
    ];
  }, [loadAttributes]);

  const {
    currentState: { clickedSuggestion },
  } = useContext(SidebarStateContext);

  // Redirecting to correct tab after Email AI card is clicked
  useEffect(() => {
    if (!clickedSuggestion) return;

    switch (clickedSuggestion.pipeline) {
      case SuggestionPipelines.CarrierInfo:
      case SuggestionPipelines.ApptConfirmation:
        setTab(AvailableTabs.LoadInformation);
        break;
      case SuggestionPipelines.CheckCall:
        setTab(AvailableTabs.TrackAndTrace);
        break;
      default:
        captureException(
          new Error('Invalid suggestion pipeline: ' + clickedSuggestion)
        );
    }
  }, [clickedSuggestion]);

  const checkCallSuggestions = useMemo(() => {
    if (!suggestions) return [];

    return suggestions.filter(
      (s): s is CheckCallSuggestion =>
        s.status === 'pending' && s.pipeline == SuggestionPipelines.CheckCall
    );
  }, [suggestions]);

  // Initialize tab refs
  const tabRefs = Object.fromEntries(
    Object.values(AvailableTabs).map((tab) => [
      tab,
      useRef<HTMLButtonElement>(null),
    ])
  ) as Record<AvailableTabs, React.RefObject<HTMLButtonElement>>;

  return (
    <>
      {isLoading && <SidebarLoader />}

      {!isLoading && load && modifiedSuggestions?.length > 0 && (
        <SuggestionsCarousel suggestions={modifiedSuggestions} />
      )}

      {!isLoading && load && !load.isPlaceholder && (
        <OverviewSection
          tmsName={tmsName as TMS}
          freightTrackingID={load.freightTrackingID}
          externalTMSID={load.externalTMSID}
          labels={
            loadBelongsToCurrentEmail && email.labels
              ? email.labels.split(',')
              : []
          }
          loadStatus={load.status}
          poNumbers={load.poNums ? load.poNums.split(',') : []}
          fromEmail={email?.from}
        />
      )}

      {!isLoading && load && (
        <TMSProvider tmsName={tmsName} tmsID={tmsID}>
          <LoadContextProvider
            fieldAttributes={fieldAttrs}
            loadAttrsObj={loadAttributes}
            invalidateLoad={invalidate}
          >
            <Tabs
              value={tab}
              onValueChange={(tab) => setTab(tab as AvailableTabs)}
              className='w-full h-full flex-1 shrink-0 flex flex-col'
            >
              <TabsList className='w-full overflow-x-auto flex gap-0'>
                <TabsTrigger
                  value={AvailableTabs.LoadInformation}
                  ref={tabRefs.loadInformation}
                  variant={TabsTriggerVariants.LoadTabs}
                >
                  <InfoCircleIcon
                    className='min-h-[18px] max-h-[18px] min-w-[18px] max-w-[18px]'
                    strokeWidth={1}
                  />
                  <p className='overflow-x-hidden'>Load Info</p>
                </TabsTrigger>
                {isAppointmentSchedulingEnabled && (
                  <TabsTrigger
                    value={AvailableTabs.AppointmentScheduling}
                    ref={tabRefs.appointmentScheduling}
                    variant={TabsTriggerVariants.LoadTabs}
                  >
                    <CalendarIcon
                      className='min-h-[18px] max-h-[18px] min-w-[18px] max-w-[18px]'
                      strokeWidth={1}
                    />
                    <p className='overflow-x-hidden'>Appointments</p>
                  </TabsTrigger>
                )}
                {isTrackAndTraceEnabled && (
                  <TabsTrigger
                    value={AvailableTabs.TrackAndTrace}
                    ref={tabRefs.trackAndTrace}
                    variant={TabsTriggerVariants.LoadTabs}
                  >
                    <RouteIcon
                      className='min-h-[18px] max-h-[18px] min-w-[18px] max-w-[18px]'
                      strokeWidth={1}
                    />
                    <p className='overflow-x-hidden'>Track & Trace</p>
                  </TabsTrigger>
                )}
                {isCarrierEmailOutboxEnabled && (
                  <TabsTrigger
                    value={AvailableTabs.Outbox}
                    ref={tabRefs.outbox}
                    variant={TabsTriggerVariants.LoadTabs}
                  >
                    <MailsIcon
                      className='min-h-[18px] max-h-[18px] min-w-[18px] max-w-[18px]'
                      strokeWidth={1}
                    />
                    <p className='overflow-x-hidden'>Outbox</p>
                  </TabsTrigger>
                )}
              </TabsList>
              <div className='flex-1 shrink-0'>
                <TabsContent
                  value={AvailableTabs.LoadInformation}
                  className='px-5'
                >
                  <ErrorBoundary>
                    <LoadInformationSection
                      load={load}
                      externalLinks={externalLinks}
                      key={`${load.ID}-${invalidateKey}`}
                    />
                  </ErrorBoundary>
                </TabsContent>
                {isAppointmentSchedulingEnabled && (
                  <TabsContent value={AvailableTabs.AppointmentScheduling}>
                    <ErrorBoundary>
                      <AppointmentSchedulingSection
                        normalizedLoad={normalizedLoad}
                        key={load.freightTrackingID}
                        loadPickupWarehouse={
                          loadPickupWarehouse?.warehouseID
                            ? loadPickupWarehouse
                            : null
                        }
                        loadDropoffWarehouse={
                          loadDropoffWarehouse?.warehouseID
                            ? loadDropoffWarehouse
                            : null
                        }
                      />
                    </ErrorBoundary>
                  </TabsContent>
                )}
                {isTrackAndTraceEnabled && (
                  <TabsContent
                    value={AvailableTabs.TrackAndTrace}
                    className='px-5'
                  >
                    {isCheckCallCarrierSOPEnabled && (
                      <ErrorBoundary>
                        <CarrierCheckInSection
                          normalizedLoad={normalizedLoad}
                          key={load.freightTrackingID}
                          setTab={setTab}
                        />
                      </ErrorBoundary>
                    )}
                    <ErrorBoundary>
                      <CheckCallSection
                        normalizedLoad={normalizedLoad}
                        key={normalizedLoad.freightTrackingID}
                        suggestions={checkCallSuggestions}
                        invalidateSuggestions={suggestionsInvalidate}
                      />
                    </ErrorBoundary>
                    {isExceptionsEnabled && (
                      <ErrorBoundary>
                        <ExceptionSection
                          normalizedLoad={normalizedLoad}
                          key={normalizedLoad.freightTrackingID}
                        />
                      </ErrorBoundary>
                    )}
                    {isCheckCallShipperSOPEnabled && (
                      <ErrorBoundary>
                        <ShipperCheckInSection
                          normalizedLoad={normalizedLoad}
                          key={normalizedLoad.freightTrackingID}
                        />
                      </ErrorBoundary>
                    )}
                    {isCheckCallNotesEnabled && (
                      <ErrorBoundary>
                        <NoteSection
                          normalizedLoad={normalizedLoad}
                          key={normalizedLoad.freightTrackingID}
                        />
                      </ErrorBoundary>
                    )}
                  </TabsContent>
                )}
                {isCarrierEmailOutboxEnabled &&
                  pendingOutboxEmails?.carrierEmails && (
                    <TabsContent value={AvailableTabs.Outbox}>
                      <ErrorBoundary>
                        <EmailsSection
                          normalizedLoad={normalizedLoad}
                          carrierEmails={pendingOutboxEmails?.carrierEmails}
                          setTab={setTab}
                        />
                      </ErrorBoundary>
                    </TabsContent>
                  )}
              </div>
            </Tabs>
          </LoadContextProvider>
        </TMSProvider>
      )}
    </>
  );
}
