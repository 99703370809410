import { useMemo, useState } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { Cog } from 'lucide-react';

import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { Switch } from 'components/Switch';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { useToast } from 'hooks/useToaster';
import { updateShipperCheckInSOPs } from 'lib/api/updateShipperCheckInSOPs';
import { NormalizedLoad } from 'types/Load';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';

import { LoadSectionAccordionItem } from '../LoadInformationTab';

enum TimeUnits {
  Hours = 'Hours',
  Days = 'Days',
}

type ShipperCheckInSectionProps = { normalizedLoad: NormalizedLoad };

interface ShipperCheckInInputs {
  freightTrackingId: string;
  frequency: string;
  timeUnit: string;
  defaultCarrierCheckInSettings: boolean;
  dropoffAppt: boolean;
  pickupAppt: boolean;
  loaded: boolean;
  defaultFrequency: string;
  unloaded: boolean;
}

type ShipperCheckInTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: FieldPath<ShipperCheckInInputs>;
};
const SOPTextInput = (props: ShipperCheckInTextInputProps) => (
  <RHFTextInput {...props} />
);

export default function ShipperCheckInSection({
  normalizedLoad: load,
}: ShipperCheckInSectionProps) {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>([
    'updateShipperCheckInSOPs',
  ]);
  const defaultValues = useMemo<ShipperCheckInInputs>(() => {
    const parsedValues = {
      freightTrackingId: load.freightTrackingID,
      frequency: '',
      timeUnit: '',
      defaultCarrierCheckInSettings: false,
      pickupAppt: false,
      loaded: false,
      dropoffAppt: false,
      unloaded: false,
    };
    return parsedValues as ShipperCheckInInputs;
  }, [load]);
  const formMethods = useForm<ShipperCheckInInputs>({ defaultValues });
  const { control, handleSubmit } = formMethods;
  const onSubmitUpdateSOPs: SubmitHandler<ShipperCheckInInputs> = async (
    data
  ) => {
    setLoading(true);

    const res = await updateShipperCheckInSOPs(
      load.ID!,
      data.freightTrackingId,
      data.frequency,
      data.timeUnit,
      data.defaultCarrierCheckInSettings,
      data.pickupAppt,
      data.loaded,
      data.dropoffAppt,
      data.unloaded
    );

    if (res.isOk()) {
      toast({
        description: 'Successfully updated Shipper Check-in SOPs',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setLoading(false);
  };

  return (
    <Accordion type='multiple' value={activeTabs} onValueChange={setActiveTabs}>
      <LoadSectionAccordionItem
        label='Shipper Check-ins'
        icon={<Cog className='h-6 w-6' strokeWidth={1} />}
        name='configureShipperCheckInSOPs'
        activeTabs={activeTabs}
      >
        <div>
          <FormProvider {...formMethods}>
            <div>
              <form
                onSubmit={handleSubmit(onSubmitUpdateSOPs)}
                className='grid gap-4 grid-cols-1 mt-4 mx-0 w-full'
              >
                <div className='grid gap-6 grid-cols-1 mb-4 w-full mx-0'>
                  <div>
                    <h2 className='text-base font-semibold leading-7 text-gray-900'>
                      Time triggers
                    </h2>
                    <p className='mt-1 text-sm leading-6 text-gray-600'>
                      The time in between sending check-in emails.
                    </p>
                  </div>
                  <SOPTextInput
                    name='frequency'
                    label='Frequency'
                    inputType='number'
                    defaultValue='12'
                  />
                  <div>
                    <label className='mb-2 text-grayscale-content-description'>
                      Unit of time
                    </label>
                    <Controller
                      name='timeUnit'
                      control={control}
                      render={({ field }) => (
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger className='w-full mt-1'>
                            <SelectValue placeholder={TimeUnits.Hours} />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.keys(TimeUnits).map((unit) => (
                              <SelectItem key={unit} value={unit}>
                                {unit}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                  <Controller
                    name='defaultCarrierCheckInSettings'
                    control={control}
                    render={({ field }) => (
                      <div className='flex items-center space-x-2'>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          className='focus-visible:ring-green-950 data-[state=checked]:bg-green-400 data-[state=unchecked]:bg-green-200 dark:focus-visible:ring-green-300 dark:focus-visible:ring-offset-green-950 dark:data-[state=checked]:bg-green-50 dark:data-[state=unchecked]:bg-green-800'
                        />
                        <label
                          htmlFor='defaultCarrierCheckInSettings'
                          className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                        >
                          Set check-in cadence as default
                        </label>
                      </div>
                    )}
                  />
                </div>
                <div className='grid gap-6 grid-cols-1 mt-4 mb-4 w-full mx-0'>
                  <div>
                    <h2 className='text-base font-semibold leading-7 text-gray-900'>
                      Event Triggers
                    </h2>
                    <p className='mt-1 text-sm leading-6 text-gray-600'>
                      Select specific check-in points in the load process.
                    </p>
                  </div>
                  <Controller
                    name='pickupAppt'
                    control={control}
                    render={({ field }) => (
                      <div className='flex items-center space-x-2'>
                        <Checkbox
                          onCheckedChange={field.onChange}
                          checked={field.value}
                        />
                        <label
                          htmlFor='pickupAppt'
                          className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                        >
                          At pickup appointment
                        </label>
                      </div>
                    )}
                  />
                  <Controller
                    name='loaded'
                    control={control}
                    render={({ field }) => (
                      <div className='flex items-center space-x-2'>
                        <Checkbox
                          onCheckedChange={field.onChange}
                          checked={field.value}
                        />
                        <label
                          htmlFor='loaded'
                          className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                        >
                          Loaded
                        </label>
                      </div>
                    )}
                  />
                  <Controller
                    name='dropoffAppt'
                    control={control}
                    render={({ field }) => (
                      <div className='flex items-center space-x-2'>
                        <Checkbox
                          onCheckedChange={field.onChange}
                          checked={field.value}
                        />
                        <label
                          htmlFor='dropoffAppt'
                          className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                        >
                          At dropoff appointment
                        </label>
                      </div>
                    )}
                  />
                  <Controller
                    name='unloaded'
                    control={control}
                    render={({ field }) => (
                      <div className='flex items-center space-x-2'>
                        <Checkbox
                          onCheckedChange={field.onChange}
                          checked={field.value}
                        />
                        <label
                          htmlFor='loaded'
                          className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                        >
                          Unloaded
                        </label>
                      </div>
                    )}
                  />
                </div>
                <Button
                  buttonNamePosthog={ButtonNamePosthog.ScheduleShipperEmails}
                  type='submit'
                  className='w-full mt-8'
                  disabled={loading}
                  logProperties={{
                    loadID: load.ID,
                    freightTrackingID: load.freightTrackingID,
                    serviceID: load.serviceID,
                  }}
                >
                  {loading ? (
                    <ButtonLoader />
                  ) : (
                    ButtonText.ScheduleShipperEmails
                  )}
                </Button>
              </form>
            </div>
          </FormProvider>
        </div>
      </LoadSectionAccordionItem>
    </Accordion>
  );
}
