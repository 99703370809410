import { FocusEventHandler, useContext, useEffect, useState } from 'react';

import { Label } from 'components/Label';
import { Input } from 'components/input';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import {
  CarrierCostType,
  MarginType,
} from 'pages/QuoteView/Quoting/RequestQuickQuote/types';

export type CarrierPriceCalculatorProps = {
  showTitle: boolean;
  mileage: number;
  finalPrice: number;
  margin: number;
  marginType: MarginType;
  setMarginTypeHandler: React.Dispatch<React.SetStateAction<MarginType>>;
  carrierCost: number;
  carrierCostType: CarrierCostType;
  setCarrierCostTypeHandler: React.Dispatch<
    React.SetStateAction<CarrierCostType>
  >;
  onBlurHandler?: FocusEventHandler<HTMLInputElement>;
  setIsCarrierButtonClickedHandler?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedQuoteIndexHandler?: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setCarrierCostHandler?: React.Dispatch<React.SetStateAction<number>>;
  setMarkupHandler?: React.Dispatch<React.SetStateAction<number>>;
  setFinalPriceHandler?: React.Dispatch<React.SetStateAction<number>>;
};

// Add your dev domain to view alternative labels
// TODO: Add settings page to Drumkit Portal
const altLabelsDomains = ['fetchfreight.com'];

export default function CarrierPriceCalculator({
  showTitle,
  mileage: initMileage,
  finalPrice,
  margin,
  marginType,
  setMarginTypeHandler,
  carrierCost,
  carrierCostType,
  setCarrierCostTypeHandler,
  onBlurHandler,
  setIsCarrierButtonClickedHandler,
  setSelectedQuoteIndexHandler,
  setCarrierCostHandler,
  setMarkupHandler,
  setFinalPriceHandler,
}: CarrierPriceCalculatorProps): JSX.Element {
  const {
    currentState: { inboxEmailAddress },
  } = useContext(SidebarStateContext);

  // Format input values as strings to avoid floating point errors
  const [mileage, setMileage] = useState(initMileage);
  const [buyInputValue, setBuyInputValue] = useState(
    formatCostByType(carrierCost, carrierCostType)
  );
  const [marginInputValue, setMarginInputValue] = useState(
    formatMarginByType(margin, carrierCostType, marginType)
  );

  useEffect(() => {
    setBuyInputValue(formatCostByType(carrierCost, carrierCostType));
  }, [carrierCost]);

  useEffect(() => {
    setMileage(initMileage);
  }, [initMileage]);

  useEffect(() => {
    if (!setFinalPriceHandler) return;

    if (!isNaN(carrierCost) && !isNaN(margin)) {
      let calculatedFinalPrice = 0;

      if (marginType === MarginType.Amount) {
        // Flat margin: Add the margin to the carrier cost
        calculatedFinalPrice = carrierCost + margin;
      } else {
        // Percentage margin: Increase the carrier cost by the margin percentage
        calculatedFinalPrice = carrierCost * (1 + margin / 100);
      }

      setFinalPriceHandler(calculatedFinalPrice);
    }
  }, [margin, carrierCost, marginType, carrierCostType, setFinalPriceHandler]);

  const handleBuyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setBuyInputValue(value);

    if (setSelectedQuoteIndexHandler) {
      setSelectedQuoteIndexHandler(null);
    }
    if (setCarrierCostHandler && value !== '') {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        setCarrierCostHandler(numValue);
      }
    }
    if (setIsCarrierButtonClickedHandler) {
      setIsCarrierButtonClickedHandler(false);
    }
  };

  const handleMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMarginInputValue(value);

    if (setMarkupHandler && value !== '') {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        setMarkupHandler(numValue);
      }
    }
  };

  const handleMarginTypeToggle = () => {
    const newMarginType =
      marginType === MarginType.Amount
        ? MarginType.Percentage
        : MarginType.Amount;

    // Convert margin value when switching types
    if (marginInputValue) {
      const currentValue = margin;
      const carrierValue = carrierCost;

      if (carrierValue > 0) {
        if (newMarginType === MarginType.Percentage) {
          // Converting from flat rate to percentage
          const percentageValue = (currentValue / carrierValue) * 100;
          setMarginInputValue(
            formatMarginByType(percentageValue, carrierCostType, newMarginType)
          );
          setMarkupHandler?.(percentageValue);
        } else {
          // Converting from percentage to flat rate
          const newMarginValue = carrierValue * (currentValue / 100);
          setMarginInputValue(
            formatMarginByType(newMarginValue, carrierCostType, newMarginType)
          );
          setMarkupHandler?.(newMarginValue);
        }
      }
    }

    // Toggle the margin type
    setMarginTypeHandler(newMarginType);
  };

  const handleCarrierCostTypeToggle = () => {
    if (buyInputValue) {
      const currentCarrierCost = carrierCost;
      const currentMargin = margin;
      const newCarrierCostType =
        carrierCostType === CarrierCostType.Flat
          ? CarrierCostType.PerMile
          : CarrierCostType.Flat;

      let newCarrierCost: number;
      let newMargin = margin;

      if (newCarrierCostType === CarrierCostType.PerMile) {
        // Convert from Flat to Per Mile
        newCarrierCost = currentCarrierCost / mileage;
        // Convert margin value when switching types
        if (marginType === MarginType.Amount) {
          newMargin = currentMargin / mileage;
        }
      } else {
        // Convert from Per Mile to Flat
        newCarrierCost = currentCarrierCost * mileage;
        // Convert margin value when switching types
        if (marginType === MarginType.Amount) {
          newMargin = currentMargin * mileage;
        }
      }

      setCarrierCostHandler?.(newCarrierCost);
      setMarkupHandler?.(newMargin);
      setBuyInputValue(formatCostByType(newCarrierCost, newCarrierCostType));
      setMarginInputValue(
        formatMarginByType(newMargin, newCarrierCostType, marginType)
      );
      setCarrierCostTypeHandler(newCarrierCostType);
    }
  };

  return (
    <>
      {showTitle && (
        <h2 className='mb-3 text-sm font-medium text-grayscale-content-description'>
          Calculate Final Price
        </h2>
      )}

      <div className='flex items-baseline gap-0 mb-2 xxs:gap-3'>
        {/* Carrier Cost */}
        <div className='flex flex-col gap-1 min-w-0'>
          <Label
            name='buy'
            className='text-grayscale-content-description !text-xs font-medium'
          >
            {altLabelsDomains.some((domain) =>
              inboxEmailAddress.toLowerCase().includes(domain.toLowerCase())
            )
              ? 'TTT'
              : 'Buy'}
          </Label>
          <div className='flex flex-col items-center gap-1'>
            {' '}
            <div className='flex items-center relative'>
              <span className='absolute left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                $
              </span>
              <Input
                type='number'
                value={buyInputValue}
                onChange={handleBuyChange}
                onBlur={onBlurHandler ?? undefined}
                className='pl-4 pr-1 xxs:pr-2 xxs:pl-5 py-1.5 text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                min={0}
                step={carrierCostType === CarrierCostType.PerMile ? 0.01 : 1}
                aria-label='Carrier cost input'
              />
              {carrierCostType === CarrierCostType.PerMile && (
                <div className='absolute bottom-1.5 right-1 xxs:right-2 text-xs text-grayscale-content-description'>
                  /mi
                </div>
              )}
            </div>
            {Boolean(mileage) && (
              <div className='flex rounded-[4px] overflow-hidden border border-grayscale-border-input text-xs'>
                <button
                  type='button'
                  title={'Flat Rate'}
                  onClick={handleCarrierCostTypeToggle}
                  className={`px-2 transition-colors  ${
                    carrierCostType === CarrierCostType.Flat
                      ? 'text-[#FE9659] font-medium bg-orange-bg'
                      : 'text-grayscale-content-description hover:text-[#FE9659]'
                  }`}
                >
                  Flat
                </button>
                <button
                  title={'Per Mile'}
                  type='button'
                  onClick={handleCarrierCostTypeToggle}
                  className={`px-2 transition-colors  ${
                    carrierCostType === CarrierCostType.PerMile
                      ? 'text-[#FE9659] font-medium bg-orange-bg'
                      : 'text-grayscale-content-description hover:text-[#FE9659]'
                  }`}
                >
                  /mi
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Margin */}
        <div className='flex flex-col items-start gap-1 min-w-0'>
          <Label
            name='margin'
            className='text-grayscale-content-description !text-xs pl-4 font-medium'
          >
            Margin
          </Label>
          <div className='flex flex-col items-center gap-1'>
            <div className='flex flex-row items-center'>
              <span className='mx-1 xxs:mx-0 xxs:mr-2 text-sm text-grayscale-content-description'>
                +
              </span>
              <div className='relative flex items-center'>
                {marginType === MarginType.Amount && (
                  <div className='absolute top-1.5 left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                    $
                  </div>
                )}
                <Input
                  type='number'
                  value={marginInputValue}
                  onChange={handleMarginChange}
                  onBlur={onBlurHandler ?? undefined}
                  className={`min-w-[60px] text-sm pl-4 xxs:pl-5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                  min={0}
                  step={
                    marginType === MarginType.Percentage
                      ? 1
                      : carrierCostType === CarrierCostType.PerMile
                        ? 0.01
                        : 1
                  }
                  aria-label='Margin input'
                />
                {marginType !== MarginType.Amount && (
                  <div className='absolute top-1.5 right-1 xxs:right-2 text-sm text-grayscale-content-description'>
                    %
                  </div>
                )}
              </div>
              <span className='mx-1 xxs:mx-0 xxs:ml-2 text-sm text-grayscale-content-description'>
                =
              </span>
            </div>
            {/* Margin Type Toggle */}
            <div className='flex rounded-[4px] overflow-hidden border border-grayscale-border-input text-xs'>
              <button
                type='button'
                title={'Use Dollar Margin'}
                onClick={handleMarginTypeToggle}
                className={`px-2 transition-colors  ${
                  marginType === MarginType.Amount
                    ? 'text-[#FE9659] font-medium bg-orange-bg'
                    : 'text-grayscale-content-description hover:text-[#FE9659]'
                }`}
              >
                $
              </button>
              <button
                type='button'
                title={'Use Percentage Margin'}
                onClick={handleMarginTypeToggle}
                className={`px-2 transition-colors  ${
                  marginType === MarginType.Percentage
                    ? 'text-[#FE9659] font-medium bg-orange-bg'
                    : 'text-grayscale-content-description hover:text-[#FE9659]'
                }`}
              >
                %
              </button>
            </div>
          </div>
        </div>

        {/* Final Price */}
        <div className='flex flex-col gap-1'>
          <Label
            name='sell'
            className='text-grayscale-content-description !text-xs font-medium'
            required
          >
            {altLabelsDomains.some((domain) =>
              inboxEmailAddress.toLowerCase().includes(domain.toLowerCase())
            )
              ? 'TTC'
              : 'Sell'}
          </Label>
          <div className='flex items-center relative'>
            <span className='absolute left-1 xxs:left-2 text-sm text-grayscale-content-description'>
              $
            </span>
            <Input
              className='pl-4 xxs:pl-5 pr-2 py-1.5 text-sm max-xxs:min-w-[70px] read-only:bg-gray-50 
                read-only:text-grayscale-content-description read-only:border-grayscale-border-input'
              type='text'
              value={
                isNaN(finalPrice)
                  ? ''
                  : formatCostByType(finalPrice, carrierCostType)
              }
              disabled
              aria-label='Final price'
            />
            {carrierCostType === CarrierCostType.PerMile && (
              <div className='absolute bottom-1.5 right-1 xxs:right-2 text-xs text-grayscale-content-description'>
                /mi
              </div>
            )}
          </div>

          {Boolean(mileage) && (
            <div className='flex justify-center align-baseline rounded-[4px] overflow-hidden border border-grayscale-border-input text-xs'>
              {/* Total price shown below when CarrierCostType is Per Mile */}
              {finalPrice && carrierCostType === CarrierCostType.PerMile && (
                <p className='text-xs px-0  text-grayscale-content-description justify-self-center'>
                  {`Total:  `}
                  <span className='text-[#FE9659] font-medium bg-orange-bg'>
                    {`$${(finalPrice * mileage).toFixed(0)}`}
                  </span>
                </p>
              )}
              {/* Per-mile price shown below when CarrierCostType is Flat */}
              {finalPrice && carrierCostType === CarrierCostType.Flat && (
                <span className='text-xs text-grayscale-content-description flex items-center gap-1'>
                  <span className='bg-orange-bg px-1 font-medium text-[#FE9659]'>
                    {`$${formatCostByType(finalPrice / mileage, CarrierCostType.PerMile)}`}
                  </span>
                  <span>/mi</span>
                </span>
              )}
            </div>
          )}

          {!finalPrice && finalPrice <= 0 && (
            <p className='text-red-500 text-xs '>Required</p>
          )}
        </div>
      </div>
    </>
  );
}

export const formatCostByType = (value: number, type: CarrierCostType) => {
  if (type === CarrierCostType.PerMile) {
    // Convert to string with max 2 decimals
    const formatted = value.toFixed(2);

    // Remove trailing zeros and decimal point if not needed
    return formatted.replace(/\.?0{2}$/, '');
  }
  // If flat rate, support only whole numbers
  return value.toFixed(0);
};

const formatMarginByType = (
  value: number,
  costType: CarrierCostType,
  marginType: MarginType
) => {
  if (marginType === MarginType.Percentage) {
    return value.toFixed(0);
  }

  // If cost type is per mile and margin type is flat, format margin to 2 decimal places
  if (costType === CarrierCostType.PerMile) {
    // Convert to string with max 2 decimals
    const formatted = value.toFixed(2);

    // Remove trailing zeros and decimal point if not needed
    return formatted.replace(/\.?0{2}$/, '');
  } else {
    return value.toFixed(0);
  }
};
