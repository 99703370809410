import { FocusEventHandler, useContext, useEffect, useState } from 'react';

import { captureException } from '@sentry/browser';
import _ from 'lodash';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
  InfoIcon,
} from 'lucide-react';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Tooltip';
import { Input } from 'components/input';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { toast } from 'hooks/useToaster';
import { getFuelPriceEIA } from 'lib/api/getFuelPriceEIA';
import { updateQuoteRequestSuggestion } from 'lib/api/updateQuoteRequestSuggestion';
import { FuelType } from 'pages/QuoteView/Quoting/RequestQuickQuote/QuickQuoteForm';
import {
  CarrierCostType,
  MarginType,
} from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { Maybe } from 'types/UtilityTypes';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { copyToClipboard } from 'utils/copyToClipboard';
import { calculatePricing } from 'utils/priceCalculations';
import { cn } from 'utils/shadcn';

export type CarrierPriceCalculatorProps = {
  parentQuoteRequestId: number;
  showTitle: boolean;
  mileage: number;
  finalPrice: number;
  fuelEstimate: number;
  datFuelSurcharge: Maybe<number>;
  margin: number;
  marginType: MarginType;
  maxDistance: number;
  setMarginTypeHandler: React.Dispatch<React.SetStateAction<MarginType>>;
  carrierCost: number;
  carrierCostType: CarrierCostType;
  setCarrierCostTypeHandler: React.Dispatch<
    React.SetStateAction<CarrierCostType>
  >;
  onBlurHandler?: FocusEventHandler<HTMLInputElement>;
  setIsCarrierButtonClickedHandler?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedQuoteIndexHandler?: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setCarrierCostHandler?: React.Dispatch<React.SetStateAction<number>>;
  setMarkupHandler?: React.Dispatch<React.SetStateAction<number>>;
  setFuelEstimateHandler?: React.Dispatch<React.SetStateAction<number>>;
  setFinalPriceHandler?: React.Dispatch<React.SetStateAction<number>>;
};

// Add your dev domain to view alternative labels
// TODO: Add settings page to Drumkit Portal
const altLabelsDomains = ['fetchfreight.com'];

export default function CarrierPriceCalculator({
  parentQuoteRequestId,
  showTitle,
  mileage: initMileage,
  finalPrice,
  fuelEstimate,
  datFuelSurcharge,
  maxDistance,
  margin,
  marginType,
  setMarginTypeHandler,
  carrierCost,
  carrierCostType,
  setCarrierCostTypeHandler,
  onBlurHandler,
  setIsCarrierButtonClickedHandler,
  setSelectedQuoteIndexHandler,
  setCarrierCostHandler,
  setMarkupHandler,
  setFuelEstimateHandler,
  setFinalPriceHandler,
}: CarrierPriceCalculatorProps): JSX.Element {
  const {
    currentState: { inboxEmailAddress },
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: { isFuelTypeToggleEnabled },
  } = useServiceFeatures();

  // Format input values as strings to avoid floating point errors
  const [mileage, setMileage] = useState(initMileage);
  const [buyInputValue, setBuyInputValue] = useState(
    formatCostByType(carrierCost, carrierCostType)
  );
  const [marginInputValue, setMarginInputValue] = useState(
    formatMarginByType(margin, carrierCostType, marginType)
  );
  const [hasCopiedSellPrice, setHasCopiedSellPrice] = useState(false);
  const [hasCopiedLinehaulPrice, setHasCopiedLinehaulPrice] = useState(false);

  const [isFuelDetailsOpen, setIsFuelDetailsOpen] = useState(false);
  const [fuelPriceEIA, setFuelPriceEIA] = useState<number>(0);

  const [fuelSurcharge, setFuelSurcharge] = useState<number>(0.3);
  const [selectedFuelType, setSelectedFuelType] = useState<FuelType>(
    FuelType.None
  );

  const [highlightLinehaulCalculation, setHighlightLinehaulCalculation] =
    useState(false);
  const [linehaulPrice, setLinehaulPrice] = useState<number>(0);

  useEffect(() => {
    setBuyInputValue(formatCostByType(carrierCost, carrierCostType));
  }, [carrierCost]);

  useEffect(() => {
    const roundedInitMileage = _.round(initMileage);
    setMileage(roundedInitMileage);
  }, [initMileage]);

  useEffect(() => {
    setLinehaulPrice(_.round(finalPrice - fuelEstimate));
  }, [finalPrice, fuelEstimate]);

  useEffect(() => {
    fetchEIAFuelPrice();
  }, []);

  useEffect(() => {
    // Default to DAT Fuel surcharge if toggle is enabled, otherwise default to DOE
    if (isFuelTypeToggleEnabled && datFuelSurcharge) {
      handleSelectedFuelToggle(FuelType.DAT);
      return;
    }

    if (fuelPriceEIA) {
      handleSelectedFuelToggle(FuelType.DOE);
    }
  }, [datFuelSurcharge, fuelPriceEIA, isFuelTypeToggleEnabled]);

  useEffect(() => {
    setFuelEstimateHandler &&
      setFuelEstimateHandler(_.round(mileage * fuelSurcharge));
  }, [mileage, fuelSurcharge]);

  const fetchEIAFuelPrice = async () => {
    const res = await getFuelPriceEIA();
    if (res.isOk()) {
      setFuelPriceEIA(res.value.fuelPrice);
    }
  };

  useEffect(() => {
    if (!setFinalPriceHandler) return;

    if (!isNaN(carrierCost) && !isNaN(margin)) {
      let calculatedFinalPrice = 0;

      if (marginType === MarginType.Amount) {
        // Flat margin: Add the margin to the carrier cost
        calculatedFinalPrice = carrierCost + margin;
      } else {
        // Percentage margin: Increase the carrier cost by the margin percentage
        calculatedFinalPrice = carrierCost * (1 + margin / 100);
      }

      setFinalPriceHandler(calculatedFinalPrice);
    }
  }, [margin, carrierCost, marginType, carrierCostType, setFinalPriceHandler]);

  const handleBuyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setBuyInputValue(value);

    if (setSelectedQuoteIndexHandler) {
      setSelectedQuoteIndexHandler(null);
    }
    if (setCarrierCostHandler && value !== '') {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        setCarrierCostHandler(numValue);
      }
    }
    if (setIsCarrierButtonClickedHandler) {
      setIsCarrierButtonClickedHandler(false);
    }
  };

  const handleMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMarginInputValue(value);

    if (setMarkupHandler && value !== '') {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        setMarkupHandler(numValue);
      }
    }
  };

  const handleChangeMileage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMileage(parseFloat(value));
  };

  const handleChangeFuelSurcharge = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setFuelSurcharge(parseFloat(value));
  };

  const handleMarginTypeToggle = () => {
    const newMarginType =
      marginType === MarginType.Amount
        ? MarginType.Percentage
        : MarginType.Amount;

    // Convert margin value when switching types
    if (marginInputValue) {
      const currentValue = margin;
      const carrierValue = carrierCost;

      if (carrierValue > 0) {
        if (newMarginType === MarginType.Percentage) {
          // Converting from flat rate to percentage
          const percentageValue = (currentValue / carrierValue) * 100;
          setMarginInputValue(
            formatMarginByType(percentageValue, carrierCostType, newMarginType)
          );
          setMarkupHandler?.(percentageValue);
        } else {
          // Converting from percentage to flat rate
          const newMarginValue = carrierValue * (currentValue / 100);
          setMarginInputValue(
            formatMarginByType(newMarginValue, carrierCostType, newMarginType)
          );
          setMarkupHandler?.(newMarginValue);
        }
      }
    }

    // Toggle the margin type
    setMarginTypeHandler(newMarginType);
  };

  const handleSelectedFuelToggle = (newType: FuelType) => {
    if (selectedFuelType === newType) return;

    setSelectedFuelType(newType);

    switch (newType) {
      case FuelType.DAT:
        setFuelSurcharge(datFuelSurcharge!);
        break;
      case FuelType.DOE:
        setFuelSurcharge(_.round(fuelPriceEIA * 0.1, 2)); // 10% of Diesel gallon price
        break;
    }
  };

  const handleCarrierCostTypeToggle = () => {
    if (buyInputValue) {
      const currentCarrierCost = carrierCost;
      const currentMargin = margin;
      const newCarrierCostType =
        carrierCostType === CarrierCostType.Flat
          ? CarrierCostType.PerMile
          : CarrierCostType.Flat;

      let newCarrierCost: number;
      let newMargin = margin;

      if (newCarrierCostType === CarrierCostType.PerMile) {
        // Convert from Flat to Per Mile
        newCarrierCost = currentCarrierCost / mileage;
        // Convert margin value when switching types
        if (marginType === MarginType.Amount) {
          newMargin = currentMargin / mileage;
        }
      } else {
        // Convert from Per Mile to Flat
        newCarrierCost = currentCarrierCost * mileage;
        // Convert margin value when switching types
        if (marginType === MarginType.Amount) {
          newMargin = currentMargin * mileage;
        }
      }

      setCarrierCostHandler?.(newCarrierCost);
      setMarkupHandler?.(newMargin);
      setBuyInputValue(formatCostByType(newCarrierCost, newCarrierCostType));
      setMarginInputValue(
        formatMarginByType(newMargin, newCarrierCostType, marginType)
      );
      setCarrierCostTypeHandler(newCarrierCostType);
    }
  };

  const handleCopySellPriceToClipboard = async () => {
    const roundedFinalPrice = _.round(finalPrice, 2);

    try {
      const success = await copyToClipboard(roundedFinalPrice.toString());
      if (success) {
        setHasCopiedSellPrice(true);
        // Use the utility function for final price and margin calculations
        const { flatCarrierCost: finalFlatCarrierCost, finalMargin } =
          calculatePricing(
            carrierCost,
            carrierCostType,
            margin,
            marginType,
            maxDistance
          );

        await updateQuoteRequestSuggestion(
          parentQuoteRequestId,
          SuggestionStatus.Accepted,
          {
            finalQuotePrice: _.round(finalPrice),
            finalMargin: _.round(finalMargin),
            marginType: marginType,
            finalCarrierCost: _.round(finalFlatCarrierCost),
            carrierCostType: carrierCostType,
          }
        );
        // Reset copied state after a delay
        setTimeout(() => setHasCopiedSellPrice(false), 2000);
      }
    } catch (error) {
      captureException(error);

      toast({
        description: 'Failed to copy to clipboard.',
        variant: 'destructive',
      });
    }
  };

  const handleCopyLinehaulToClipboard = async () => {
    const roundedLinehaulPrice = _.round(linehaulPrice);

    try {
      const success = await copyToClipboard(roundedLinehaulPrice.toString());
      if (success) {
        setHasCopiedLinehaulPrice(true);
        // Use the utility function for final price and margin calculations
        const { flatCarrierCost: finalFlatCarrierCost, finalMargin } =
          calculatePricing(
            carrierCost,
            CarrierCostType.Linehaul,
            margin,
            marginType,
            maxDistance
          );

        await updateQuoteRequestSuggestion(
          parentQuoteRequestId,
          SuggestionStatus.Accepted,
          {
            finalQuotePrice: _.round(linehaulPrice),
            finalMargin: _.round(finalMargin),
            marginType: marginType,
            finalCarrierCost: _.round(finalFlatCarrierCost),
            carrierCostType: carrierCostType,
          }
        );
        // Reset copied state after a delay
        setTimeout(() => setHasCopiedLinehaulPrice(false), 2000);
      }
    } catch (error) {
      captureException(error);

      toast({
        description: 'Failed to copy to clipboard.',
        variant: 'destructive',
      });
    }
  };

  return (
    <>
      {showTitle && (
        <h2 className='mb-3 text-sm font-medium text-grayscale-content-description'>
          Calculate Final Price
        </h2>
      )}

      <div className='flex flex-col gap-2'>
        <div className='flex items-baseline gap-0 mb-2 xxs:gap-3'>
          {/* Carrier Cost */}
          <div className='flex flex-col gap-1 min-w-0'>
            <Label
              name='buy'
              className='text-grayscale-content-description !text-xs font-medium'
            >
              {altLabelsDomains.some((domain) =>
                inboxEmailAddress.toLowerCase().includes(domain.toLowerCase())
              )
                ? 'TTT'
                : 'Buy'}
            </Label>
            <div className='flex flex-col items-center gap-1'>
              {' '}
              <div className='flex items-center relative'>
                <span className='absolute left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                  $
                </span>
                <Input
                  type='number'
                  value={buyInputValue}
                  onChange={handleBuyChange}
                  onBlur={onBlurHandler ?? undefined}
                  className='pl-4 pr-1 xxs:pr-2 xxs:pl-5 py-1.5 text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                  min={0}
                  step={carrierCostType === CarrierCostType.PerMile ? 0.01 : 1}
                  aria-label='Carrier cost input'
                />
                {carrierCostType === CarrierCostType.PerMile && (
                  <div className='absolute bottom-1.5 right-1 xxs:right-2 text-xs text-grayscale-content-description'>
                    /mi
                  </div>
                )}
              </div>
              {Boolean(mileage) && (
                <div className='flex rounded-[4px] overflow-hidden border border-grayscale-border-input text-xs'>
                  <button
                    type='button'
                    title={'Flat Rate'}
                    onClick={handleCarrierCostTypeToggle}
                    className={`px-2 transition-colors  ${
                      carrierCostType === CarrierCostType.Flat
                        ? 'text-[#FE9659] font-medium bg-orange-bg'
                        : 'text-grayscale-content-description hover:text-[#FE9659]'
                    }`}
                  >
                    Flat
                  </button>
                  <button
                    title={'Per Mile'}
                    type='button'
                    onClick={handleCarrierCostTypeToggle}
                    className={`px-2 transition-colors  ${
                      carrierCostType === CarrierCostType.PerMile
                        ? 'text-[#FE9659] font-medium bg-orange-bg'
                        : 'text-grayscale-content-description hover:text-[#FE9659]'
                    }`}
                  >
                    /mi
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Margin */}
          <div className='flex flex-col items-start gap-1 min-w-0'>
            <Label
              name='margin'
              className='text-grayscale-content-description !text-xs pl-4 font-medium'
            >
              Margin
            </Label>
            <div className='flex flex-col items-center gap-1'>
              <div className='flex flex-row items-center'>
                <span className='mx-1 xxs:mx-0 xxs:mr-2 text-sm text-grayscale-content-description'>
                  +
                </span>
                <div className='relative flex items-center'>
                  {marginType === MarginType.Amount && (
                    <div className='absolute top-1.5 left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                      $
                    </div>
                  )}
                  <Input
                    type='number'
                    value={marginInputValue}
                    onChange={handleMarginChange}
                    onBlur={onBlurHandler ?? undefined}
                    className={`min-w-[60px] text-sm pl-4 xxs:pl-5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                    min={0}
                    step={
                      marginType === MarginType.Percentage
                        ? 1
                        : carrierCostType === CarrierCostType.PerMile
                          ? 0.01
                          : 1
                    }
                    aria-label='Margin input'
                  />
                  {marginType !== MarginType.Amount && (
                    <div className='absolute top-1.5 right-1 xxs:right-2 text-sm text-grayscale-content-description'>
                      %
                    </div>
                  )}
                </div>
                <span className='mx-1 xxs:mx-0 xxs:ml-2 text-sm text-grayscale-content-description'>
                  =
                </span>
              </div>
              {/* Margin Type Toggle */}
              <div className='flex rounded-[4px] overflow-hidden border border-grayscale-border-input text-xs'>
                <button
                  type='button'
                  title={'Use Dollar Margin'}
                  onClick={handleMarginTypeToggle}
                  className={`px-2 transition-colors  ${
                    marginType === MarginType.Amount
                      ? 'text-[#FE9659] font-medium bg-orange-bg'
                      : 'text-grayscale-content-description hover:text-[#FE9659]'
                  }`}
                >
                  $
                </button>
                <button
                  type='button'
                  title={'Use Percentage Margin'}
                  onClick={handleMarginTypeToggle}
                  className={`px-2 transition-colors  ${
                    marginType === MarginType.Percentage
                      ? 'text-[#FE9659] font-medium bg-orange-bg'
                      : 'text-grayscale-content-description hover:text-[#FE9659]'
                  }`}
                >
                  %
                </button>
              </div>
            </div>
          </div>

          {/* Final Price */}
          <div className='flex flex-col gap-1'>
            <Label
              name='sell'
              className='text-grayscale-content-description !text-xs font-medium'
            >
              {altLabelsDomains.some((domain) =>
                inboxEmailAddress.toLowerCase().includes(domain.toLowerCase())
              )
                ? 'TTC'
                : 'Sell'}
            </Label>
            <div className='flex items-center relative'>
              <span className='absolute left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                $
              </span>
              <Input
                className={cn(
                  'pl-4 xxs:pl-5 pr-2 py-1.5 transition-all text-sm max-xxs:min-w-[70px] read-only:bg-gray-50 read-only:text-grayscale-content-description read-only:border-grayscale-border-input',
                  highlightLinehaulCalculation &&
                    '!bg-green-bg !border-green-main'
                )}
                type='text'
                value={
                  isNaN(finalPrice)
                    ? ''
                    : formatCostByType(finalPrice, carrierCostType)
                }
                disabled
                aria-label='Final price'
              />
              {carrierCostType === CarrierCostType.PerMile && (
                <div className='absolute bottom-1.5 right-1 xxs:right-2 text-xs text-grayscale-content-description'>
                  /mi
                </div>
              )}

              <Button
                className={cn(
                  'absolute h-4 p-0 -top-5 right-1 border-none',
                  hasCopiedSellPrice ? 'cursor-default' : 'cursor-pointer'
                )}
                variant='ghost'
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  !hasCopiedSellPrice && handleCopySellPriceToClipboard();
                }}
              >
                {hasCopiedSellPrice ? (
                  <Tooltip open={true}>
                    <TooltipTrigger asChild>
                      <CheckIcon className='h-4 w-4' />
                    </TooltipTrigger>
                    <TooltipContent>Copied!</TooltipContent>
                  </Tooltip>
                ) : (
                  <CopyIcon className='h-4 w-4' />
                )}
              </Button>
            </div>

            {Boolean(mileage) && (
              <div className='flex justify-center align-baseline rounded-[4px] overflow-hidden border border-grayscale-border-input text-xs'>
                {/* Total price shown below when CarrierCostType is Per Mile */}
                {finalPrice && carrierCostType === CarrierCostType.PerMile && (
                  <p className='text-xs px-0  text-grayscale-content-description justify-self-center'>
                    {`Total:  `}
                    <span className='text-[#FE9659] font-medium bg-orange-bg'>
                      {`$${(finalPrice * mileage).toFixed(0)}`}
                    </span>
                  </p>
                )}
                {/* Per-mile price shown below when CarrierCostType is Flat */}
                {finalPrice && carrierCostType === CarrierCostType.Flat && (
                  <span className='text-xs text-grayscale-content-description flex items-center gap-1'>
                    <span className='bg-orange-bg px-1 font-medium text-[#FE9659]'>
                      {`$${formatCostByType(finalPrice / mileage, CarrierCostType.PerMile)}`}
                    </span>
                    <span>/mi</span>
                  </span>
                )}
              </div>
            )}

            {!finalPrice && finalPrice <= 0 && (
              <p className='text-red-500 text-xs '>Required</p>
            )}
          </div>
        </div>

        {isFuelDetailsOpen && setFuelEstimateHandler && (
          <div>
            <div className='flex items-baseline gap-0 mb-2 xxs:gap-3'>
              {/* Mileage for Fuel */}
              <div className='flex flex-col gap-1 min-w-0'>
                <Label
                  name='mileage'
                  className='text-grayscale-content-description !text-xs font-medium'
                >
                  Distance
                </Label>
                <div className='flex flex-col items-center gap-1'>
                  {' '}
                  <div className='flex items-center relative'>
                    <Input
                      type='number'
                      value={mileage}
                      onChange={handleChangeMileage}
                      onBlur={onBlurHandler ?? undefined}
                      className='pl-2 pr-2 py-1.5 text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                      min={0}
                      aria-label='Mileage input'
                    />
                    <div className='absolute bottom-1.5 right-1 xxs:right-2 text-xs text-grayscale-content-description'>
                      mi
                    </div>
                  </div>
                </div>
              </div>

              {/* Fuel Surcharge */}
              <div className='flex flex-col items-start gap-1 min-w-0'>
                <Label
                  name='fsc'
                  className='w-full text-grayscale-content-description !text-xs px-4 font-medium'
                >
                  <div className='w-full flex justify-between items-center'>
                    <p>FSC</p>

                    <Tooltip delayDuration={10}>
                      <TooltipTrigger asChild>
                        <InfoIcon className='h-4 w-4' />
                      </TooltipTrigger>
                      <TooltipContent>
                        <div>
                          <p>10% of DOE per gallon price</p>
                          <p className='text-[12px] italic text-grayscale-content-3 mt-2'>
                            {`This week's DOE Diesel Price: $${fuelPriceEIA}/gallon`}
                          </p>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </Label>

                <div className='flex flex-col items-center gap-1'>
                  <div className='flex flex-row items-center'>
                    <span className='mx-1 xxs:mx-0 xxs:mr-2 text-sm text-grayscale-content-description'>
                      *
                    </span>
                    <div className='relative flex items-center'>
                      <div className='absolute top-1.5 left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                        $
                      </div>
                      <Input
                        type='number'
                        value={fuelSurcharge}
                        onChange={handleChangeFuelSurcharge}
                        onBlur={onBlurHandler ?? undefined}
                        className={`min-w-[60px] text-sm pl-4 xxs:pl-5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                        min={0}
                        step={0.01}
                        aria-label='Fuel Surcharge input'
                      />
                    </div>
                    <span className='mx-1 xxs:mx-0 xxs:ml-2 text-sm text-grayscale-content-description'>
                      =
                    </span>
                  </div>
                </div>

                {isFuelTypeToggleEnabled && datFuelSurcharge && (
                  <div className='flex mx-auto rounded-[4px] overflow-hidden border border-grayscale-border-input text-xs'>
                    <button
                      type='button'
                      title={'Use DOE Fuel Rate'}
                      onClick={() => handleSelectedFuelToggle(FuelType.DOE)}
                      className={`text-[10px] px-2 transition-colors  ${
                        selectedFuelType === FuelType.DOE
                          ? 'text-[#FE9659] font-medium bg-orange-bg'
                          : 'text-grayscale-content-description hover:text-[#FE9659]'
                      }`}
                    >
                      DOE
                    </button>
                    <button
                      type='button'
                      title={'Use DAT Fuel Rate'}
                      onClick={() => handleSelectedFuelToggle(FuelType.DAT)}
                      className={`text-[10px] px-2 transition-colors  ${
                        selectedFuelType === FuelType.DAT
                          ? 'text-[#FE9659] font-medium bg-orange-bg'
                          : 'text-grayscale-content-description hover:text-[#FE9659]'
                      }`}
                    >
                      DAT
                    </button>
                  </div>
                )}
              </div>

              {/* Final Fuel Price */}
              <div className='flex flex-col gap-1'>
                <Label
                  name='totalFuel'
                  className='text-grayscale-content-description !text-xs font-medium'
                >
                  Fuel Estimate
                </Label>
                <div className='flex items-center relative'>
                  <span className='absolute left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                    $
                  </span>
                  <Input
                    className={cn(
                      'pl-4 xxs:pl-5 pr-2 py-1.5 transition-all text-sm max-xxs:min-w-[70px] read-only:bg-gray-50 read-only:text-grayscale-content-description read-only:border-grayscale-border-input',
                      highlightLinehaulCalculation &&
                        '!bg-red-bg !border-red-main'
                    )}
                    type='text'
                    value={_.round(mileage * fuelSurcharge)}
                    disabled
                    aria-label='Final Fuel Price'
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col ml-auto mt-4 pl-3 w-[33%] relative'>
              <Label
                name='totalFuel'
                className='text-grayscale-content-description !text-xs font-medium'
              >
                Linehaul
              </Label>
              <div
                className='flex items-center relative'
                onMouseOver={() => setHighlightLinehaulCalculation(true)}
                onMouseLeave={() => setHighlightLinehaulCalculation(false)}
              >
                <span className='absolute left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                  $
                </span>
                <Input
                  className='pl-4 xxs:pl-5 pr-2 py-1.5 text-sm max-xxs:min-w-[70px] read-only:bg-gray-50 
                    read-only:text-grayscale-content-description read-only:border-grayscale-border-input'
                  type='text'
                  value={linehaulPrice}
                  disabled
                  aria-label='Fuel Estimate'
                />
              </div>

              <Button
                className={cn(
                  'absolute h-4 p-0 top-0 right-1 border-none',
                  hasCopiedLinehaulPrice ? 'cursor-default' : 'cursor-pointer'
                )}
                variant='ghost'
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  !hasCopiedLinehaulPrice && handleCopyLinehaulToClipboard();
                }}
              >
                {hasCopiedLinehaulPrice ? (
                  <Tooltip open={true}>
                    <TooltipTrigger asChild>
                      <CheckIcon className='h-4 w-4' />
                    </TooltipTrigger>
                    <TooltipContent>Copied!</TooltipContent>
                  </Tooltip>
                ) : (
                  <CopyIcon className='h-4 w-4' />
                )}
              </Button>
            </div>
          </div>
        )}

        {setFuelEstimateHandler && (
          <Button
            className='w-40 mx-auto h-8 text-[14px] text-grayscale-content-2 flex gap-2 hover:border-gray-600 hover:bg-gray-200'
            buttonNamePosthog={ButtonNamePosthog.ToggleQuoteFuelDetails}
            onClick={() => setIsFuelDetailsOpen(!isFuelDetailsOpen)}
            type='button'
            variant='ghost'
          >
            {!isFuelDetailsOpen ? (
              <ChevronDownIcon className='h-4 w-4' />
            ) : (
              <ChevronUpIcon className='h-4 w-4' />
            )}
            Adjust Fuel
          </Button>
        )}
      </div>
    </>
  );
}

export const formatCostByType = (value: number, type: CarrierCostType) => {
  if (type === CarrierCostType.PerMile) {
    // Convert to string with max 2 decimals
    const formatted = value.toFixed(2);

    // Remove trailing zeros and decimal point if not needed
    return formatted.replace(/\.?0{2}$/, '');
  }
  // If flat rate, support only whole numbers
  return value.toFixed(0);
};

const formatMarginByType = (
  value: number,
  costType: CarrierCostType,
  marginType: MarginType
) => {
  if (marginType === MarginType.Percentage) {
    return value.toFixed(0);
  }

  // If cost type is per mile and margin type is flat, format margin to 2 decimal places
  if (costType === CarrierCostType.PerMile) {
    // Convert to string with max 2 decimals
    const formatted = value.toFixed(2);

    // Remove trailing zeros and decimal point if not needed
    return formatted.replace(/\.?0{2}$/, '');
  } else {
    return value.toFixed(0);
  }
};
