import { FocusEventHandler, useEffect } from 'react';

import { Label } from 'components/Label';
import { Input } from 'components/input';

export type CarrierPriceCalculatorProps = {
  showTitle: boolean;
  finalPrice: number;
  margin: number;
  carrierCost: number;
  onBlurHandler?: FocusEventHandler<HTMLInputElement>;
  setIsCarrierButtonClickedHandler?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedQuoteIndexHandler?: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setCarrierCostHandler?: React.Dispatch<React.SetStateAction<number>>;
  setMarkupHandler?: React.Dispatch<React.SetStateAction<number>>;
  setFinalPriceHandler?: React.Dispatch<React.SetStateAction<number>>;
};

// For better reusability, this will act as a stateless component
// that can be embedded to multiple pricing tab views
export default function CarrierPriceCalculator({
  showTitle,
  finalPrice,
  margin,
  carrierCost,
  onBlurHandler,
  setIsCarrierButtonClickedHandler,
  setSelectedQuoteIndexHandler,
  setCarrierCostHandler,
  setMarkupHandler,
  setFinalPriceHandler,
}: CarrierPriceCalculatorProps): JSX.Element {
  useEffect(() => {
    if (setFinalPriceHandler)
      setFinalPriceHandler(carrierCost * (1 + margin / 100));
  }, [carrierCost, margin]);

  return (
    <>
      {/* Calculate price to shipper */}
      {showTitle && <h2 className='my-2'>Calculate Final Price:</h2>}

      <div className='flex justify-center items-end mb-4'>
        {/* Carrier Cost */}
        <div className='flex flex-col items-c'>
          <Label name='' className='text-grayscale-content-3 ml-3 !text-[12px]'>
            Carrier Cost
          </Label>
          <div className='flex items-center'>
            <div className='mr-0.5'>$</div>
            <Input
              type='number'
              value={carrierCost}
              onChange={(e) => {
                if (setSelectedQuoteIndexHandler)
                  setSelectedQuoteIndexHandler(null);
                if (setCarrierCostHandler)
                  setCarrierCostHandler(parseFloat(e.target.value));
                if (setIsCarrierButtonClickedHandler)
                  setIsCarrierButtonClickedHandler(false);
              }}
              onBlur={onBlurHandler ?? undefined}
              className='p-1.5'
            />
          </div>
        </div>

        {/* Margin */}
        <div className='flex flex-col'>
          <Label
            name=''
            className='text-grayscale-content-3 ml-[22px] !text-[12px]'
          >
            Margin
          </Label>
          <div className='flex items-center'>
            <div className='mx-1.5'>+</div>
            <Input
              type='number'
              step={1}
              value={margin}
              onChange={(e) => {
                if (setMarkupHandler)
                  setMarkupHandler(parseFloat(e.target.value));
              }}
              onBlur={onBlurHandler ?? undefined}
              className='w-1/2 px-1.5'
            />
            <div className='text-sm pl-1 pr-0.5'>%</div>
            <div className='ml-1 mr-0.5'>=</div>
          </div>
        </div>

        {/* Final Price */}
        <div className='flex flex-col'>
          <Label name='' className='text-grayscale-content-3 ml-4 !text-[12px]'>
            Target Sell
          </Label>
          <div className='flex items-center'>
            <div className='text-sm pl-1 pr-0.5'>$</div>
            <Input
              className='read-only:bg-white read-only:focus-visible:read-only:border-grayscale-border 
                  read-only:text-grayscale-content-2 p-1.5 '
              type='text'
              value={finalPrice.toFixed(0)}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
}
