import * as React from 'react';

export default function StarredFolderIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <g clipPath='url(#clip0_2_2)'>
        <path
          d='M11 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21072 3.46957 3 4 3H7.9C8.23449 2.99673 8.56445 3.07739 8.8597 3.23462C9.15495 3.39186 9.40604 3.62063 9.59 3.9L10.4 5.1C10.5821 5.37653 10.83 5.60353 11.1215 5.76061C11.413 5.91769 11.7389 5.99995 12.07 6H20C20.5304 6 21.0391 6.21072 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V9.5'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18 10.618L18.9838 13.6459C19.1177 14.0579 19.5016 14.3369 19.9349 14.3369H23.1186L20.5429 16.2082C20.1924 16.4628 20.0458 16.9142 20.1796 17.3262L21.1634 20.3541L18.5878 18.4828C18.2373 18.2281 17.7627 18.2281 17.4122 18.4828L14.8366 20.3541L15.8204 17.3262C15.9542 16.9142 15.8076 16.4628 15.4571 16.2082L12.8814 14.3369L16.0651 14.3369C16.4984 14.3369 16.8823 14.0579 17.0162 13.6459L18 10.618Z'
          fill='black'
          stroke='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_2_2'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
