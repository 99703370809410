import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import AuthService from '@auth/AuthService';
import APP_VERSION from '@constants/AppVersion';
import DRUMKIT_API_URL from '@constants/DrumkitApiUrl';

import isProd from './isProd';

export interface AxiosRequestConfigExtended extends AxiosRequestConfig {
  _retry?: boolean;
  _bypassInterceptor?: boolean;
}

const instance = axios.create({
  baseURL: isProd() ? DRUMKIT_API_URL : '/api',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

instance.interceptors.request.use(async (config) => {
  const authHeader = await AuthService.getAuthHeader();
  config.headers['Vesta-Version'] = APP_VERSION;

  config.headers = {
    ...config.headers,
    ...authHeader,
  } as AxiosRequestHeaders;
  return config;
});

export default instance;
