import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FieldArrayWithId,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { ErrorMessage } from '@hookform/error-message';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { Select } from 'antd';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { BaseOptionType, SelectValue } from 'antd/es/select';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Info } from 'lucide-react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { LOAD_MONIKER } from '@constants/LoadMoniker';

import { Button } from 'components/Button';
import {
  DebounceSelect,
  GenericLocationOption,
} from 'components/DebounceSelect';
import { Label } from 'components/Label';
import DateTimeInput from 'components/input/DateTimeInput';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import SidebarLoader from 'components/loading/SidebarLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useAuth } from 'hooks/useAuth';
import useTMSContext from 'hooks/useTMSContext';
import { useToast } from 'hooks/useToaster';
import { confirmSlotAppt } from 'lib/api/confirmSlotAppt';
import { getWarehouseData } from 'lib/api/getWarehouseData';
import {
  WarehouseLoadTypes,
  getWarehouseLoadTypes,
} from 'lib/api/getWarehouseLoadTypes';
import { getWarehouseSearch } from 'lib/api/getWarehouseSearch';
import { getOpenApptSlots } from 'lib/api/openApptSlots';
import {
  CustomApptFieldsTemplate,
  GroupedSlot,
  OrderedSlots,
  SchedulingPortals,
  StopTypes,
  WarehouseSettings,
} from 'types/Appointment';
import { NormalizedLoad } from 'types/Load';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import TMS from 'types/enums/Integrations';
import { LoadTypeDirections } from 'types/enums/LoadTypeDirection';
import { titleCase, toSentenceCase } from 'utils/formatStrings';
import { cn } from 'utils/shadcn';

import {
  SevenElevenMarylandID,
  SevenElevenVirginiaID,
  aiSuggestCustomApptFields,
  removeSchedulerInfo,
  suggestRefNumberField,
} from './helpers';

dayjs.extend(utc);
dayjs.extend(timezone);

interface AppointmentSchedulingInputsWithoutLoad {
  appt: {
    addressLine1: MaybeUndef<string>;
    expectedPickupTime: MaybeUndef<string>;
    expectedDeliveryTime: MaybeUndef<string>;
  };
  externalTMSID: string; // TMS's UUID for the shipment
  freightTrackingId: string; // PRO Number
  // Ref Number requirements is in warehouse settings, not custom appt fields
  // and can be either 1) FreightTrackingID/PRO, 2) PO #, 3) Load.Customer/Pickup/Consignee.RefNumber,
  // based on the warehouse's label/description
  opendockRefNumber: string;
  loadTypeID: string;
  dockId: string;
  startDateTime: Date;
  endDateTime: Date;
  warehouse: any;
  subscribedEmail: string; // TODO: support multiple emails
  customApptFieldsTemplate: CustomApptFieldsTemplate[];
  notes: string;
}

type ApptSchedulingWithoutLoadTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<AppointmentSchedulingInputsWithoutLoad> };

const ApptSchedulingWithoutLoadTextInput = (
  props: ApptSchedulingWithoutLoadTextInputProps
) => <RHFTextInput {...props} />;

export function isSevenElevenWH(whID: string): boolean {
  return whID === SevenElevenMarylandID || whID === SevenElevenVirginiaID;
}

type OpenDockFormProps = {
  type: StopTypes;
  load: NormalizedLoad;
  recentWarehouses: Warehouse[];
  suggestedWarehouse: Maybe<Warehouse>;
  toggleOpenDock: () => void;
};

export default function OpenDockFormV2({
  type,
  load,
  recentWarehouses,
  suggestedWarehouse,
  toggleOpenDock,
}: OpenDockFormProps) {
  const { toast } = useToast();
  const {
    currentState: { inboxEmailAddress },
  } = useContext(SidebarStateContext);
  const { tmsName } = useTMSContext();
  const userEmail = useAuth().user?.email;

  const [loading, setLoading] = useState(false);
  const [suggestion, setSuggestion] =
    useState<Maybe<AppointmentSchedulingInputsWithoutLoad>>(null);

  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>();
  const [shortTZLabel, setShortTZLabel] = useState('');
  const [warehouses, setWarehouses] = useState<Warehouse[]>(
    recentWarehouses && recentWarehouses.length ? recentWarehouses : []
  );
  const [warehouseTimezone, setWarehouseTimezone] = useState<string>('');
  const [warehouseSettings, setWarehouseSettings] =
    useState<Maybe<WarehouseSettings>>(null);

  const [loadTypes, setLoadTypes] = useState<WarehouseLoadTypes[]>([]);
  const [selectedLoadType, setSelectedLoadType] =
    useState<WarehouseLoadTypes>();

  const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);
  const [customApptFieldsTemplate, setCustomApptFieldsTemplate] = useState<
    CustomApptFieldsTemplate[] | null
  >(null);

  const [orderedSlots, setOrderedSlots] =
    useState<MaybeUndef<OrderedSlots>>(null);
  const [selectedSlot, setSelectedSlot] = useState<Maybe<GroupedSlot>>(null);
  const [apptConfirmationNumber, setApptConfirmationNumber] = useState('');
  const [tmsUpdateSucceeded, setTMSUpdateSucceeded] = useState(true);

  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const [hasOverriddenSuggestedWarehouse, setHasOverriddenSuggestedWarehouse] =
    useState(false);

  // Re-render form AI-filling when
  useEffect(() => {
    const sug = {
      notes: '',
      opendockRefNumber: '',
      externalTMSID: load.externalTMSID,
      freightTrackingId: load.freightTrackingID,
      warehouse: suggestedWarehouse?.warehouseName,
      loadTypeID:
        suggestedWarehouse?.warehouseID === SevenElevenMarylandID &&
        type === StopTypes.Dropoff
          ? '29f0b132-c23f-410d-9cab-1159f678c031' // Genesis Inbound
          : undefined,
    } as AppointmentSchedulingInputsWithoutLoad;

    if (suggestedWarehouse) {
      setSelectedWarehouse(suggestedWarehouse);
    }
    setSuggestion(sug);
  }, [suggestedWarehouse]);

  const memoizedDefaultValues: AppointmentSchedulingInputsWithoutLoad =
    useMemo(() => {
      return (
        hasOverriddenSuggestedWarehouse
          ? { subscribedEmail: inboxEmailAddress }
          : { subscribedEmail: inboxEmailAddress, ...suggestion }
      ) as AppointmentSchedulingInputsWithoutLoad;
    }, [suggestion]);

  const startDateTime = new Date();
  startDateTime.setHours(0, 0, 0, 0);

  const endDateTime = new Date();
  endDateTime.setDate(endDateTime.getDate() + 7);
  endDateTime.setHours(23, 59, 59, 999);

  // Update form values when suggestion changes
  useEffect(() => {
    if (memoizedDefaultValues) {
      reset(memoizedDefaultValues);
      // Set outside of default values so as to not be displayed as AI-filled.
      formMethods.setValue('startDateTime', startDateTime);
      formMethods.setValue('endDateTime', endDateTime);
    }
  }, [memoizedDefaultValues]);

  const formMethods = useForm<AppointmentSchedulingInputsWithoutLoad>({
    defaultValues: memoizedDefaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    resetField,
    setError,
  } = formMethods;

  const { fields, update: updateCustomApptFields } = useFieldArray({
    control,
    name: 'customApptFieldsTemplate',
  });

  // When warehouse selected, fetch its load types
  useEffect(() => {
    if (!selectedWarehouse) {
      return;
    }

    // resetting loadTypeID since it's a warehouse-dependent field.
    // we keep other form fields that could've been picked up by our parsing.
    resetField('loadTypeID', { defaultValue: '' });
    resetField('subscribedEmail', {
      defaultValue: selectedWarehouse.defaultSubscribedEmail ?? userEmail,
    });

    const stop = type === StopTypes.Dropoff ? load.consignee : load.pickup;

    if (isSevenElevenWH(selectedWarehouse.warehouseID)) {
      resetField('notes', {
        defaultValue: `${LOAD_MONIKER} ${load.freightTrackingID}
        ${stop.apptNote ? `. ${removeSchedulerInfo(stop.apptNote)}` : ''}`,
      });
    } else {
      resetField('notes', { defaultValue: '' });
    }
    // resetting dynamic fields whose logic we also control outside of form
    setWarehouseTimezone(selectedWarehouse.warehouseTimezone);
    setCustomApptFieldsTemplate(null);
    setOrderedSlots(null);
    setLoadTypes([]);

    handleFetchLoadTypes();
    handleFetchWarehouse();
  }, [selectedWarehouse]);

  // When warehouse's timezone changes, update datetime input displays
  useEffect(() => {
    // Using browser's native Intl library since dayjs didn't work with all timezones tested
    let shortTZ;
    if (warehouseTimezone) {
      shortTZ = new Intl.DateTimeFormat('en-US', {
        timeZone: warehouseTimezone,
        timeZoneName: 'short',
      })
        .format(Date.now())
        .split(' ')[1];
    } else {
      shortTZ = 'EDT';
    }

    setShortTZLabel(shortTZ);
  }, [warehouseTimezone]);

  // Display AI-filling for warehouse's custom fields
  useEffect(() => {
    fields.forEach((obj, index) => {
      resetField(`customApptFieldsTemplate.${index}.value`, {
        defaultValue: obj.value,
      });
    });
  }, [customApptFieldsTemplate]);

  const handleFetchLoadTypes = async () => {
    if (!selectedWarehouse) {
      return;
    }

    const loadTypesResponse = await getWarehouseLoadTypes(
      selectedWarehouse.warehouseID,
      SchedulingPortals.Opendock
    );
    if (loadTypesResponse.isOk()) {
      // filtering load types by inbound/outbound direction
      const directionLoadTypes = loadTypesResponse.value.loadTypes.filter(
        (lt) =>
          type === StopTypes.Dropoff
            ? lt.direction === LoadTypeDirections.Inbound
            : lt.direction === LoadTypeDirections.Outbound
      );

      // removing dock-selectable load types that have no docks
      const filteredLoadTypes = directionLoadTypes.filter((lt) =>
        lt.allowCarrierDockSelection ? lt.docks.length : true
      );

      setLoadTypes(filteredLoadTypes);
      // Auto-fill load type if possible
      switch (true) {
        case filteredLoadTypes.length === 0:
          setError('loadTypeID', {
            message: `This warehouse does not support ${type.toLowerCase()} appointments.`,
          });
          break;

        case selectedWarehouse.warehouseID === SevenElevenMarylandID &&
          type === StopTypes.Dropoff:
          resetField('loadTypeID', {
            defaultValue: '29f0b132-c23f-410d-9cab-1159f678c031',
          });
          break;

        case filteredLoadTypes.length === 1:
          resetField('loadTypeID', {
            defaultValue: filteredLoadTypes[0].id,
          });
          break;

        default:
          break;
      }
    } else {
      toast({
        description: 'Error fetching Load Types for OpenDock warehouse.',
        variant: 'destructive',
      });
    }
  };

  const handleFetchWarehouse = async () => {
    if (!selectedWarehouse) {
      return;
    }

    setIsLoadingCustomFields(true);

    const warehouseResponse = await getWarehouseData(
      selectedWarehouse.warehouseID,
      SchedulingPortals.Opendock
    );
    if (warehouseResponse.isOk()) {
      const { settings, customApptFieldsTemplate, defaultSubscribedEmail } =
        warehouseResponse.value;

      setWarehouseSettings(settings);

      if (defaultSubscribedEmail) {
        formMethods.setValue('subscribedEmail', defaultSubscribedEmail);
      }

      // Ref Number is either PRO or PO, based on OpenDock's description of the field
      const suggestedRef = suggestRefNumberField(
        settings,
        load.poNums ?? load.customer.refNumber,
        // QN does Relay use Relay # or Booking ID for appts? This assumes the former rn
        tmsName == TMS.Relay ? load.externalTMSID : load.freightTrackingID
      );
      resetField('opendockRefNumber', { defaultValue: suggestedRef });

      const fields = warehouseResponse.value.customApptFieldsTemplate;
      aiSuggestCustomApptFields(load, type, selectedWarehouse, fields);

      setCustomApptFieldsTemplate(customApptFieldsTemplate);
      setValue('customApptFieldsTemplate', customApptFieldsTemplate);
    } else {
      toast({
        description: "Error fetching warehouse's custom form.",
        variant: 'destructive',
      });
    }
    setIsLoadingCustomFields(false);
  };

  const onSubmitFetchSlots: SubmitHandler<
    AppointmentSchedulingInputsWithoutLoad
  > = async (data) => {
    setSelectedSlot(null);

    if (!data.loadTypeID) {
      toast({
        description: 'Please select a Load Type.',
        variant: 'default',
      });
      return;
    }

    setLoading(true);

    const warehouseTimezoneStartDate = convertInputToWarehouseTimezone(
      data.startDateTime,
      warehouseTimezone
    );
    const warehouseTimezoneEndDate = convertInputToWarehouseTimezone(
      data.endDateTime,
      warehouseTimezone
    );

    const res = await getOpenApptSlots({
      loadTypeID: data.loadTypeID,
      warehouseID: selectedWarehouse?.warehouseID ?? '',
      warehouseTimezoneStartDate,
      warehouseTimezoneEndDate,
      freightTrackingID: data.freightTrackingId,
      dockId: data.dockId,
    });

    if (res.isOk()) {
      setOrderedSlots(res.value);

      const slots = res.value?.slots;
      if (!slots || Object.keys(slots).length === 0) {
        toast({
          description: 'No slots were found for the selected date range.',
          variant: 'default',
        });
      }
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setLoading(false);
  };

  const handleConfirmAppointment = async () => {
    if (!selectedSlot || !orderedSlots || !selectedWarehouse) {
      toast({
        description: 'Please select an available time slot',
        variant: 'default',
      });
      return;
    }

    setLoading(true);

    const res = await confirmSlotAppt({
      isTMSLoad: true,
      isV2: true,
      stopType: type,
      start: selectedSlot.startTime,
      loadTypeId: orderedSlots.loadType.id,
      warehouseId: orderedSlots.warehouse.id,
      warehouseTimezone: selectedWarehouse.warehouseTimezone,
      dockId: selectedSlot.dock.id,
      loadID: load.ID!,
      freightTrackingId: getValues('freightTrackingId'), // PRO Number
      refNumber: getValues('opendockRefNumber'),
      customApptFieldsTemplate: getValues('customApptFieldsTemplate'),
      subscribedEmail: getValues('subscribedEmail'),
      notes: getValues('notes'),
    });

    if (res.isOk()) {
      setApptConfirmationNumber(res.value.ConfirmationNo);
    } else {
      if (res.error.message === 'Conflicting Appointments') {
        toast({
          title: 'Conflicting Appointments',
          description:
            "Make sure you don't have an existing appointment for this load.",
          variant: 'destructive',
        });
      } else if (
        res.error.message.toLowerCase().includes('please update tms')
      ) {
        setTMSUpdateSucceeded(false);
        toast({
          description: res.error.message,
          variant: 'destructive',
        });
      } else {
        toast({
          description: res.error.message,
          variant: 'destructive',
        });
      }
    }

    setLoading(false);
  };

  const handleSwapPROAndBOL = (
    field: FieldArrayWithId<
      AppointmentSchedulingInputsWithoutLoad,
      'customApptFieldsTemplate',
      'id'
    >,
    newValue: string
  ) => {
    const fieldIndex = fields.findIndex((f) => f.id === field.id);

    updateCustomApptFields(fieldIndex, { ...field, value: newValue });
  };

  const handleResetWarehouseSearch = () => {
    setIsInitialSearch(true);
    setWarehouses(recentWarehouses);
  };

  const handleWarehouseSearch = async (search: string) => {
    if (search.length > 3) {
      setIsInitialSearch(false);

      const searchRes = await getWarehouseSearch(search);

      if (searchRes.isOk()) {
        const { warehouses: searchedWarehouses } = searchRes.value;

        setWarehouses(searchedWarehouses);

        return searchedWarehouses && searchedWarehouses.length
          ? mapWarehousesToOptions(searchedWarehouses)
          : [];
      }
    }

    /**
     * If we've searched before with the current dropdown and now just want to
     * filter the initial entries, reset state.
     */
    handleResetWarehouseSearch();

    /**
     * Searches that are less than 3 characters dont provide enough context to search amongst
     * all warehouses. In that case, apply that filter to the entries that are already loaded.
     */
    return mapWarehousesToOptions(recentWarehouses).filter((wh) =>
      wh.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  };

  return (
    <ExtendedFormProvider aiDefaultValues={true}>
      <FormProvider {...formMethods}>
        <div className='col-span-6'>
          <div className='mb-3'>
            <h3 className='font-medium mb-1'>Request via OpenDock</h3>
            <Button
              variant={'underline'}
              type='button'
              className='text-xs p-0 h-6 !ml-auto'
              onClick={() => toggleOpenDock()}
            >
              <>{'Switch to email'}</>
            </Button>
          </div>

          <div>
            <form
              onSubmit={handleSubmit(onSubmitFetchSlots)}
              className='grid gap-4 grid-cols-1 mt-4 mx-0 w-full'
            >
              <div className='w-full'>
                <Label name='warehouse'>Warehouse</Label>
                <Controller
                  name='warehouse'
                  control={control}
                  render={({ field }) => (
                    <DebounceSelect
                      showSearch
                      placeholder='Choose'
                      optionFilterProp='children'
                      fetchOptions={handleWarehouseSearch}
                      onFocus={handleResetWarehouseSearch}
                      onSelect={({ value }) => {
                        if (
                          !hasOverriddenSuggestedWarehouse &&
                          value !== suggestion?.warehouse
                        ) {
                          setHasOverriddenSuggestedWarehouse(true);
                        }

                        field.onChange(value);
                        setSelectedWarehouse(
                          warehouses.find((w) => w.warehouseID === value)
                        );
                      }}
                      value={field.value}
                      options={[
                        {
                          label: (
                            <span>
                              {`${isInitialSearch ? 'Recently used' : 'Searched'} warehouses`}
                            </span>
                          ),
                          title: `${isInitialSearch ? 'Recently used' : 'Searched'} warehouses`,
                          options: mapWarehousesToOptions(warehouses),
                        },
                      ]}
                      optionRender={(option) => (
                        <GenericLocationOption option={option.data} />
                      )}
                      notFoundContent={
                        isInitialSearch ? (
                          <p>Start typing to search for a warehouse</p>
                        ) : (
                          <p>No results found</p>
                        )
                      }
                    />
                  )}
                />
              </div>
              {selectedWarehouse && (
                <>
                  <span className='flex flex-row bg-violet-blue-bg rounded-lg space-x-1 py-1 mt-0'>
                    <div>
                      <Info
                        className='h-4 w-4 pl-1'
                        color='#969696'
                        strokeWidth={3}
                      />
                    </div>
                    <span>
                      <p className='text-sm text-grayscale-content-3 font-bold'>
                        Warehouse:
                      </p>
                      <p className='text-xs text-grayscale-content-3 font-medium mt-1'>
                        {selectedWarehouse.warehouseName.trim()}
                      </p>
                      <p className='text-xs text-grayscale-content-3 font-medium mt-1'>
                        {selectedWarehouse.warehouseAddressLine1.trim()}
                      </p>
                      <p className='text-xs text-grayscale-content-3 font-medium mt-1'>
                        {selectedWarehouse.warehouseAddressLine2.trim()}
                      </p>
                    </span>
                  </span>

                  <div className='w-full'>
                    <OpenDockTextInput
                      label='Subscribed Email'
                      name='subscribedEmail'
                      placeholder='email@domain.com'
                    />
                  </div>

                  <div className='w-full'>
                    <div className='col-span-6'>
                      <DateTimeInput
                        control={control}
                        name='startDateTime'
                        label={`Search From (${shortTZLabel})`}
                        preventNormalizedLabelTZ={true}
                      />
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className='col-span-6'>
                      <DateTimeInput
                        control={control}
                        name='endDateTime'
                        label={`Search To (${shortTZLabel})`}
                        preventNormalizedLabelTZ={true}
                      />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Label name='loadTypeID'>Load Type</Label>
                    <Controller
                      name='loadTypeID'
                      control={control}
                      disabled={!loadTypes?.length}
                      rules={{
                        required:
                          loadTypes?.length > 0
                            ? 'This field is required'
                            : false,
                      }}
                      render={({ field }) => (
                        <Select
                          placeholder='Choose'
                          disabled={!loadTypes?.length}
                          onChange={field.onChange}
                          onSelect={(v: SelectValue) =>
                            setSelectedLoadType(
                              loadTypes.find((lt) => lt.id === v)
                            )
                          }
                          value={field.value}
                          options={loadTypes.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={'loadTypeID'}
                      render={({ message }: { message: string }) => (
                        <p className='text-red-500 text-xs'>{message}</p>
                      )}
                    />
                  </div>

                  {selectedLoadType?.allowCarrierDockSelection &&
                  selectedLoadType.docks.length ? (
                    <div className='w-full'>
                      <Label name='dockId'>Dock</Label>
                      <Controller
                        name='dockId'
                        control={control}
                        disabled={!selectedLoadType.docks.length}
                        rules={{
                          required:
                            selectedLoadType.docks.length > 0
                              ? 'This field is required'
                              : false,
                        }}
                        render={({ field }) => (
                          <Select
                            placeholder='Choose Dock'
                            disabled={!selectedLoadType.docks.length}
                            onChange={field.onChange}
                            value={field.value}
                            options={selectedLoadType.docks.map((dock) => ({
                              value: dock.id,
                              label: dock.name,
                            }))}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={'dockId'}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  ) : null}
                </>
              )}

              {isLoadingCustomFields && <SidebarLoader />}

              {/* Show custom fields only if they are required for carrier to provide
              or Drumkit has AI-filled a value */}
              {!isLoadingCustomFields &&
                (customApptFieldsTemplate?.findIndex(
                  (value) => value.requiredForCarrier || value.value
                ) ?? -1) > -1 && (
                  <div>
                    <hr className='my-4' />

                    <Label name='' className='font-medium mb-6 text-black'>
                      Additional Info Required by Warehouse
                    </Label>

                    <div>
                      {/* TODO for debugging, revert */}
                      {(warehouseSettings?.referenceNumberIsVisible ||
                        warehouseSettings?.referenceNumberIsRequired) && (
                        <ApptSchedulingWithoutLoadTextInput
                          name='opendockRefNumber'
                          label={
                            warehouseSettings?.referenceNumberDisplayName ??
                            'Reference Number'
                          }
                          description={
                            warehouseSettings?.referenceNumberHelperText
                          }
                          required={
                            warehouseSettings?.referenceNumberIsRequired
                          }
                        />
                      )}

                      {/* FIXME: Notes and RefNumber not AI-filling on first warehouse selection but does for subsequent ones*/}
                      <ApptSchedulingWithoutLoadTextInput
                        name='notes'
                        label='Notes'
                      />

                      {fields.map((field, index) => {
                        if (
                          // Show required fields or ones with AI suggestions
                          (field.hiddenFromCarrier ||
                            !field.requiredForCarrier) &&
                          !field.value
                        ) {
                          return null;
                        } else {
                          // Render dropdown field
                          if (field.dropDownValues.length > 0) {
                            return (
                              <div
                                key={field.id}
                                className='flex flex-col justify-center mb-3'
                              >
                                <div className='flex flex-col'>
                                  <Label
                                    name={`customApptFieldsTemplate.${index}.value`}
                                  >
                                    {titleCase(field.label)}
                                  </Label>
                                  {field.description &&
                                    // Don't duplicate description if it's the same as label
                                    field.description.toLowerCase() !==
                                      field.label.toLowerCase() && (
                                      <span className='text-xs text-grayscale-content-2'>
                                        {toSentenceCase(field.description)}
                                      </span>
                                    )}
                                </div>
                                <Controller
                                  name={
                                    `customApptFieldsTemplate.${index}.value` as FieldPath<AppointmentSchedulingInputsWithoutLoad>
                                  }
                                  rules={{
                                    required:
                                      field.requiredForCarrier && 'Required',
                                  }}
                                  control={control}
                                  render={({ field: dropdownField }) => (
                                    <Select
                                      showSearch
                                      placeholder='Choose'
                                      optionFilterProp='children'
                                      filterOption={(
                                        input: string,
                                        option: BaseOptionType | undefined
                                      ) =>
                                        (
                                          option?.label.toLocaleLowerCase() ??
                                          ''
                                        ).includes(input.toLocaleLowerCase())
                                      }
                                      filterSort={(
                                        optionA: BaseOptionType,
                                        optionB: BaseOptionType
                                      ) =>
                                        (optionA?.label ?? '')
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? '').toLowerCase()
                                          )
                                      }
                                      onChange={dropdownField.onChange}
                                      value={dropdownField.value}
                                      options={field.dropDownValues.map(
                                        (option) => ({
                                          value: option,
                                          label: option,
                                        })
                                      )}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`customApptFieldsTemplate.${index}.value`}
                                  render={({
                                    message,
                                  }: {
                                    message: string;
                                  }) => (
                                    <p className='text-red-500 text-xs'>
                                      {message}
                                    </p>
                                  )}
                                />
                              </div>
                            );
                          } else {
                            // Render text/number field
                            return (
                              <div
                                key={field.id}
                                className='flex flex-col justify-center space-x-2 mb-3'
                              >
                                <RHFTextInput
                                  name={`customApptFieldsTemplate.${index}.value`}
                                  label={titleCase(field.label)}
                                  required={field.requiredForCarrier}
                                  // Don't duplicate description if it's the same as the label
                                  description={
                                    field.description.toLowerCase() !==
                                    field.label.toLowerCase()
                                      ? titleCase(field.description)
                                      : undefined
                                  }
                                  placeholder={field.placeholder}
                                  inputType={
                                    field.type === 'int' ? 'number' : undefined
                                  }
                                  step={field.type === 'int' ? 1 : undefined}
                                  options={{
                                    // Set value requirements if int
                                    min:
                                      field.type === 'int' &&
                                      field.minLengthOrValue
                                        ? {
                                            value: field.minLengthOrValue,
                                            message: `Minimum is ${field.minLengthOrValue}`,
                                          }
                                        : undefined,
                                    max:
                                      field.type === 'int' &&
                                      field.maxLengthOrValue
                                        ? {
                                            value: field.maxLengthOrValue,
                                            message: `Maximum is ${field.maxLengthOrValue}`,
                                          }
                                        : undefined,

                                    // Set length requirments if not int
                                    minLength:
                                      field.type !== 'int' &&
                                      field.minLengthOrValue
                                        ? {
                                            value: field.minLengthOrValue,
                                            message: `Minimum length is ${field.minLengthOrValue} characters`,
                                          }
                                        : undefined,
                                    maxLength:
                                      field.type !== 'int' &&
                                      field.maxLengthOrValue
                                        ? {
                                            value: field.maxLengthOrValue,
                                            message: `Maximum length is ${field.maxLengthOrValue} characters`,
                                          }
                                        : undefined,
                                  }}
                                />
                                {(field.label
                                  .toUpperCase()
                                  .includes('PRO OR BOL') ||
                                  field.label
                                    .toUpperCase()
                                    .includes('BOL OR PRO')) &&
                                load.externalTMSID &&
                                load.customer.refNumber ? (
                                  <Button
                                    variant={'underline'}
                                    type='button'
                                    className='text-xs p-0 h-6 w-24 !ml-auto'
                                    onClick={() =>
                                      handleSwapPROAndBOL(
                                        field,
                                        field.value === load.externalTMSID
                                          ? load.customer.refNumber
                                          : load.externalTMSID
                                      )
                                    }
                                  >
                                    <>
                                      {field.value === load.externalTMSID
                                        ? 'Use BOL instead'
                                        : 'Use PRO instead'}
                                    </>
                                  </Button>
                                ) : null}
                              </div>
                            );
                          }
                        }
                      })}

                      <div className='flex justify-center'>
                        <ErrorMessage
                          errors={errors}
                          name={`customApptFieldsTemplate.root`}
                          render={({ message }: { message: string }) => (
                            <p className='text-red-500 text-xs'>{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}

              <Button
                buttonName={ButtonName.FindOpenApptSlotsLoadIndependent}
                buttonNamePosthog={
                  ButtonNamePosthog.FindOpenApptSlotsLoadIndependent
                }
                type='submit'
                className='w-full'
                disabled={loading || !loadTypes?.length}
                logProperties={{
                  freightTrackingID: getValues('freightTrackingId'),
                }}
              >
                {loading ? <ButtonLoader /> : 'Get open slots'}
              </Button>
            </form>
          </div>
          <section>
            {loading && null}

            {orderedSlots !== undefined &&
              orderedSlots !== null &&
              Object.keys(orderedSlots.slots).length > 0 && (
                <>
                  <div className='mt-8 mb-4 mr-8'>
                    <p className='mt-2'>
                      <span className='text-grayscale-content-3 font-bold'>
                        Warehouse Timezone:
                      </span>
                      {` ${shortTZLabel}`}
                    </p>
                  </div>
                  {Object.keys(orderedSlots.slots).map((date) => (
                    <div key={date}>
                      <h3 className='text-grayscale-content-3 font-bold uppercase text-sm mt-4'>
                        {date}
                      </h3>
                      <div className='grid grid-cols-3 gap-1 mt-2 mx-0 w-full'>
                        {orderedSlots.slots[date].map(
                          (slot: GroupedSlot, idx: number) => (
                            <button
                              key={idx}
                              onClick={() => {
                                setSelectedSlot(
                                  selectedSlot === slot ? null : slot
                                );
                              }}
                              className={cn(
                                'bg-white border border-grayscale-border p-1 py-2 rounded cursor-pointer text-sm',
                                selectedSlot === slot &&
                                  'bg-orange-pressed border-orange-pressed text-white'
                              )}
                            >
                              {dayjs(slot.startTime)
                                .tz(slot.timezone)
                                .format('HH:mm')}
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                  {selectedSlot ? (
                    <div className='mt-4 text-grayscale-content-3 text-left text-sm'>
                      <p className='my-1 font-bold'>Selected Slot:</p>
                      <p className='mb-2'>
                        {selectedSlot.startTime.toLocaleString('en-US', {
                          timeZone: selectedSlot.timezone,
                          timeZoneName: 'short',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: undefined,
                          hour12: false,
                        })}
                      </p>
                      {apptConfirmationNumber ? (
                        <div className='whitespace-pre-wrap my-3 rounded py-3 text-grayscale-content-1 px-4 bg-green-bg'>
                          <p className='mb-2'>Appointment confirmed 🎉</p>
                          <p className='mb-4 text-[14px]'>
                            <b className='text-[14px]'>
                              Opendock Confirmation #:{' '}
                            </b>
                            {apptConfirmationNumber}
                          </p>

                          <p className='mb-1 text-[14px]'>
                            {tmsUpdateSucceeded
                              ? `Your TMS was also updated with the appointment details`
                              : `Make sure to update your respective TMS with the
                               scheduled appointment!`}
                          </p>
                        </div>
                      ) : null}
                      <Button
                        buttonName={
                          ButtonName.ConfirmSlotApptSchedulingLoadIndependent
                        }
                        buttonNamePosthog={
                          ButtonNamePosthog.ConfirmSlotApptSchedulingLoadIndependent
                        }
                        className='mt-2 w-full'
                        onClick={handleConfirmAppointment}
                        disabled={loading || !loadTypes?.length}
                        // logProperties={{
                        //   loadID: load.ID,
                        //   freightTrackingID: load.freightTrackingID,
                        //   serviceID: load.serviceID,
                        // }}
                      >
                        {loading ? <ButtonLoader /> : 'Confirm appointment'}
                      </Button>
                    </div>
                  ) : null}
                </>
              )}
          </section>
        </div>
      </FormProvider>
    </ExtendedFormProvider>
  );
}

function convertInputToWarehouseTimezone(
  date: Date,
  warehouseTimezone: string
): dayjs.Dayjs {
  return dayjs()
    .tz(warehouseTimezone)
    .date(date.getDate())
    .month(date.getMonth())
    .year(date.getFullYear())
    .hour(date.getHours())
    .minute(date.getMinutes())
    .second(date.getSeconds())
    .millisecond(date.getMilliseconds());
}

type OpenDockTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: FieldPath<AppointmentSchedulingInputsWithoutLoad>;
};
const OpenDockTextInput = (props: OpenDockTextInputProps) => (
  <RHFTextInput {...props} />
);

const mapWarehousesToOptions = (warehouses: Warehouse[]) =>
  warehouses?.map((option: Warehouse) => ({
    value: option.warehouseID,
    name: option.warehouseName,
    mainAddress: option.warehouseAddressLine1,
    secondaryAddress: option.warehouseAddressLine2,
    label: `${option.warehouseName} - ${option.warehouseAddressLine1} - ${option.warehouseAddressLine2}}`,
  }));
