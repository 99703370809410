import { useContext, useEffect, useRef, useState } from 'react';

import {
  CircleDollarSign,
  HammerIcon,
  ListChecksIcon,
  Truck,
} from 'lucide-react';

import SuggestionsCarousel from 'components/AISuggestions/SuggestionsCarousel';
import { Badge } from 'components/Badge';
import ErrorBoundary from 'components/ErrorBoundary';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TabsTriggerVariants,
} from 'components/Tabs';
import { Toaster } from 'components/ToasterProvider';
import { ServiceFeaturesListType } from 'contexts/serviceContext';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { getLoadBuildingSuggestion } from 'lib/api/getLoadBuildingSuggestion';
import { getQuickQuoteSuggestion } from 'lib/api/getQuickQuoteSuggestion';
import getTruckList, { TruckListResponse } from 'lib/api/getTruckList';
import { Email } from 'types/Email';
import { Maybe, Undef } from 'types/UtilityTypes';
import {
  GenericSuggestion,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import { LoadBuildingSuggestions } from 'types/suggestions/LoadBuildingSuggestions';
import { SuggestedQuoteChange } from 'types/suggestions/QuoteSuggestions';
import captureException from 'utils/captureException';

import LoadBuildingTab from './LoadBuilding/LoadBuildingTab';
import CarrierQuoteTab from './Quoting/CarrierQuote/CarrierQuoteTab';
import QuickQuoteTab from './Quoting/RequestQuickQuote/QuickQuoteTab';
import TruckListTab from './TruckList/TruckListTab';

enum AvailableTabs {
  QuickQuote = 'quickQuote',
  TrucksList = 'trucksList',
  LoadBuilding = 'loadBuilding',
  CarrierQuoting = 'carrierQuoting',
  // If tabs are added, update getDefaultTabByFeatureFlags
}

const getDefaultTabByFeatureFlags = (
  features: ServiceFeaturesListType
): AvailableTabs => {
  const {
    isQuoteViewEnabled,
    isQuickQuoteEnabled,
    isCarrierNetworkQuotingEnabled,
    isLoadBuildingEnabled,
    isTruckListEnabled,
  } = features;

  // Check enabled features in priority order
  if (isQuickQuoteEnabled) return AvailableTabs.QuickQuote;
  if (isCarrierNetworkQuotingEnabled) return AvailableTabs.CarrierQuoting;
  if (isLoadBuildingEnabled) return AvailableTabs.LoadBuilding;
  if (isTruckListEnabled) return AvailableTabs.TrucksList;

  // It's possible that the service features are not initialized yet when component is mounted so
  // verify it is before sending to Sentry; heuristic is that if QuoteSidebar is rendering at all, then isQuoteViewEnabled must be true
  if (isQuoteViewEnabled) {
    captureException(
      new Error('Unable to set default tab based on service feature flags:'),
      { features: features }
    );
  }

  return AvailableTabs.QuickQuote;
};

const getDefaultTabBySuggestions = (
  features: ServiceFeaturesListType,
  quoteRequestSuggestions: Maybe<SuggestedQuoteChange[]>,
  loadBuildingSuggestions: Maybe<LoadBuildingSuggestions[]>
): Undef<AvailableTabs> => {
  const {
    isQuickQuoteEnabled,
    isCarrierNetworkQuotingEnabled,
    isLoadBuildingEnabled,
  } = features;

  // TODO: Name fields in load building form are buggy if suggestions are applied before tab is set
  // Show load building suggestions and tab first to
  // 1) workaround bug with and 2) create nice UX where first suggestion and first tab match
  if (
    loadBuildingSuggestions &&
    loadBuildingSuggestions.length > 0 &&
    isLoadBuildingEnabled
  ) {
    return AvailableTabs.LoadBuilding;
  }

  if (quoteRequestSuggestions && quoteRequestSuggestions.length > 0) {
    // QR suggestions can be applied to both QQ and CQ tabs; default to QQ first if enabled
    if (isQuickQuoteEnabled) {
      return AvailableTabs.QuickQuote;
    }
    if (isCarrierNetworkQuotingEnabled) {
      return AvailableTabs.CarrierQuoting;
    }
  }

  // TODO: Truck List suggestions

  return undefined;
};

type QuoteSidebarProps = {
  email: Maybe<Email>;
};

export default function QuoteSidebar({ email }: QuoteSidebarProps) {
  const [quoteSuggestions, setQuoteSuggestions] = useState<
    Maybe<SuggestedQuoteChange[]>
  >([]);

  const [loadBuildingSuggestions, setLoadBuildingSuggestions] = useState<
    Maybe<LoadBuildingSuggestions[]>
  >([]);

  const [allSuggestions, setAllSuggestions] = useState<GenericSuggestion[]>([]);
  const [truckListResult, setTruckListResult] =
    useState<Maybe<TruckListResponse>>();

  const { serviceFeaturesEnabled, serviceID } = useServiceFeatures();

  const {
    isQuickQuoteEnabled,
    isTruckListEnabled,
    isLoadBuildingEnabled,
    isCarrierNetworkQuotingEnabled,
  } = serviceFeaturesEnabled;

  const {
    currentState: { clickedSuggestion },
  } = useContext(SidebarStateContext);

  const [tab, setTab] = useState<Undef<AvailableTabs>>();

  const fetchQuoteSuggestions = async () => {
    if (!email?.threadID || email.threadID === '') {
      return;
    }

    const res = await getQuickQuoteSuggestion(email.threadID);
    setQuoteSuggestions(res.isOk() ? res.value : []);
  };

  const fetchLoadBuildingSuggestions = async () => {
    if (!email?.threadID || email.threadID === '') {
      return;
    }

    const res = await getLoadBuildingSuggestion(email?.threadID);
    setLoadBuildingSuggestions(res.isOk() ? res.value : []);
  };

  const fetchTruckList = async () => {
    if (!email?.id || !email?.threadID || email.threadID === '') return;

    const res = await getTruckList(email.id, email.threadID);
    setTruckListResult(res.isOk() ? res.value : null);
  };

  // Set default tab based on service features
  useEffect(() => {
    setTab(getDefaultTabByFeatureFlags(serviceFeaturesEnabled));
  }, [email, serviceFeaturesEnabled]);

  // Get suggestions based on email labels & features enabled
  useEffect(() => {
    // Don't check most recent email's labels in case there are load building suggestions earlier in the thread
    if (isLoadBuildingEnabled) {
      fetchLoadBuildingSuggestions();
    } else {
      setLoadBuildingSuggestions([]);
    }

    if (
      isQuickQuoteEnabled // TODO: Support CQ, fix glitchy rendering
    ) {
      fetchQuoteSuggestions();
    } else {
      setQuoteSuggestions([]);
    }

    if (isTruckListEnabled) {
      fetchTruckList();
    } else {
      setTruckListResult(null);
    }
  }, [email, serviceFeaturesEnabled]);

  // As suggestions are fetched, update suggestions carousel
  useEffect(() => {
    setAllSuggestions([
      // TODO: Name fields in load building form are buggy if suggestions are applied before tab is set
      // Show load building suggestions and tab first to
      // 1) workaround bug with and 2) create nice UX where first suggestion and first tab match
      ...(loadBuildingSuggestions || []),
      ...(quoteSuggestions || []),
    ]);

    const defaultTab = getDefaultTabBySuggestions(
      serviceFeaturesEnabled,
      quoteSuggestions,
      loadBuildingSuggestions
    );

    if (defaultTab && defaultTab !== tab) {
      setTab(defaultTab);
    }
  }, [quoteSuggestions, loadBuildingSuggestions]);

  // Redirect to correct tab after Email AI card is clicked
  useEffect(() => {
    if (!clickedSuggestion) return;

    switch (clickedSuggestion.pipeline) {
      case SuggestionPipelines.QuickQuote:
        if (isQuickQuoteEnabled) {
          setTab(AvailableTabs.QuickQuote);
          break;
        }
        if (isCarrierNetworkQuotingEnabled) {
          setTab(AvailableTabs.CarrierQuoting);
          break;
        }
        break;
      case SuggestionPipelines.LoadBuilding:
        setTab(AvailableTabs.LoadBuilding);
        break;
      default:
        captureException(
          new Error('Invalid suggestion pipeline: ' + clickedSuggestion)
        );
    }
  }, [clickedSuggestion]);

  // Initialize tab refs
  const tabRefs = Object.fromEntries(
    Object.values(AvailableTabs).map((tab) => [
      tab,
      useRef<HTMLButtonElement>(null),
    ])
  ) as Record<AvailableTabs, React.RefObject<HTMLButtonElement>>;

  const isSDS = serviceID === 595;
  const carrerQuoteTabLabel = isSDS ? 'White Glove' : 'Carrier Quote';

  return (
    <div className='flex-1'>
      <div className='mt-4'>
        <SuggestionsCarousel suggestions={allSuggestions} />

        <Tabs
          value={tab}
          onValueChange={(tab) => setTab(tab as AvailableTabs)}
          className='w-full h-full flex-1 shrink-0 flex flex-col'
        >
          <TabsList className='w-full overflow-x-auto flex gap-0'>
            {isQuickQuoteEnabled && (
              <TabsTrigger
                value={AvailableTabs.QuickQuote}
                ref={tabRefs.quickQuote}
                variant={TabsTriggerVariants.LoadTabs}
              >
                <CircleDollarSign className='w-5 h-5' strokeWidth={1} />
                <p className='overflow-x-hidden'>Quick Quote</p>
                {quoteSuggestions && quoteSuggestions?.length > 0 && (
                  <Badge
                    variant='secondary'
                    className='ml-0.5 !bg-blue-50 !text-blue-main !border-blue-100 !px-1 !py-0 text-[9px] data-[state=inactive]:absolute data-[state=inactive]:right-0 data-[state=inactive]:top-0'
                  >
                    {quoteSuggestions.length}
                  </Badge>
                )}
              </TabsTrigger>
            )}
            {isCarrierNetworkQuotingEnabled && (
              <TabsTrigger
                value={AvailableTabs.CarrierQuoting}
                ref={tabRefs.carrierQuoting}
                variant={TabsTriggerVariants.LoadTabs}
              >
                <Truck className='w-5 h-5' strokeWidth={1} />
                <p className='overflow-x-hidden'>{carrerQuoteTabLabel}</p>
                {/* TODO: quote suggestion for carrier quote */}
              </TabsTrigger>
            )}
            {isLoadBuildingEnabled && (
              <TabsTrigger
                value={AvailableTabs.LoadBuilding}
                ref={tabRefs.loadBuilding}
                variant={TabsTriggerVariants.LoadTabs}
              >
                <HammerIcon className='w-5 h-5' strokeWidth={1} />
                <p className='overflow-x-hidden'>Load Building</p>
                {loadBuildingSuggestions &&
                  loadBuildingSuggestions.length > 0 && (
                    <Badge
                      variant='secondary'
                      className='ml-0.5 !bg-blue-50 !text-blue-main !border-blue-100 !px-1 !py-0 text-[9px] data-[state=inactive]:absolute data-[state=inactive]:right-0 data-[state=inactive]:top-0'
                    >
                      {loadBuildingSuggestions.length}
                    </Badge>
                  )}
              </TabsTrigger>
            )}
            {isTruckListEnabled && (
              <TabsTrigger
                value={AvailableTabs.TrucksList}
                ref={tabRefs.trucksList}
                variant={TabsTriggerVariants.LoadTabs}
              >
                <ListChecksIcon className='w-5 h-5' strokeWidth={1} />
                <p className='overflow-x-hidden'>Truck List</p>
              </TabsTrigger>
            )}
          </TabsList>
          <div className='px-4 flex-1 shrink-0'>
            {isQuickQuoteEnabled && (
              <TabsContent value={AvailableTabs.QuickQuote}>
                <ErrorBoundary>
                  <QuickQuoteTab email={email} />
                </ErrorBoundary>
              </TabsContent>
            )}
            {isCarrierNetworkQuotingEnabled && (
              <TabsContent value={AvailableTabs.CarrierQuoting}>
                <ErrorBoundary>
                  <CarrierQuoteTab email={email} />
                </ErrorBoundary>
              </TabsContent>
            )}
            {isLoadBuildingEnabled && (
              <TabsContent value={AvailableTabs.LoadBuilding}>
                <ErrorBoundary>
                  <LoadBuildingTab />
                </ErrorBoundary>
              </TabsContent>
            )}
            {isTruckListEnabled && (
              <TabsContent value={AvailableTabs.TrucksList}>
                <ErrorBoundary>
                  <TruckListTab email={email} truckList={truckListResult} />
                </ErrorBoundary>
              </TabsContent>
            )}
          </div>
        </Tabs>
      </div>
      <Toaster />
    </div>
  );
}
