import { UseFormGetValues } from 'react-hook-form';

import { PlusIcon } from 'lucide-react';

import { Button } from 'components/Button';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';

import { RedwoodTruckListInputs } from '../RedwoodTruckListForm';

type RedwoodNewGroupFieldsetProps = {
  handleAddNewGroup: () => void;
  getValues: UseFormGetValues<RedwoodTruckListInputs>;
};

export const RedwoodNewGroupFieldset = ({
  handleAddNewGroup,
}: RedwoodNewGroupFieldsetProps) => (
  <Button
    className='w-[90%] mx-auto mt-8 h-8 text-[14px] text-grayscale-content-2 flex gap-2 hover:border-gray-600 hover:bg-gray-200'
    buttonNamePosthog={ButtonNamePosthog.CreatePickupGroupForTruckList}
    onClick={handleAddNewGroup}
    type='button'
    variant='ghost'
  >
    <PlusIcon className='h-4 w-4' />
    {ButtonText.CreatePickupGroupForTruckList}
  </Button>
);
