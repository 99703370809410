import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export async function enableDATIndividualAccess(
  datEmailAddress: string
): Promise<Result<null, ApiError>> {
  try {
    const url = `/user/dat`;
    const { data } = await axios.patch(url, { datEmailAddress });

    return ok(data);
  } catch (error) {
    captureException(error, { functionName: 'enableDATIndividualAccess' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to enable DAT individual access' });
    }

    return err({
      message:
        error.response?.data.message ||
        'Failed to enable DAT individual access',
    });
  }
}
