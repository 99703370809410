import CheckCallSource from 'types/enums/CheckCallSource';
import { normalizeDatesForTMSForm } from 'utils/parseDatesForTMSForm';

import { Maybe, MaybeUndef } from './UtilityTypes';

export type Load = {
  // Capitalized only because of underlying gorm.Model
  ID: MaybeUndef<number>;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: MaybeUndef<string>;

  serviceID: number;
  tmsID: number;
  freightTrackingID: string;
  isPlaceholder: boolean;
  externalTMSID: string;
  status: string;
  mode: string;
  moreThanTwoStops: boolean;
  poNums: MaybeUndef<string>;
  operator: string;
  rateData: RateData;
  customer: Customer;
  billTo: CompanyCoreInfo;
  pickup: Pickup;
  consignee: Dropoff;
  carrier: LoadCarrier;
  specifications: Specifications;
  notes: Note[];
};

export type CompanyCoreInfo = {
  externalTMSID: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country?: string;
  contact: string;
  phone: string;
  email: string;
};

export type Customer = CompanyCoreInfo & {
  refNumber: string;
};

export type Note = {
  updatedBy: string;
  createdAt: string;
  note: string;
  isException: Maybe<boolean>;
  isOnTime: Maybe<boolean>;
  source: string; // Primarily for Relay, e.g. dispatcher, driver
};

export type LoadCarrier = {
  externalTMSID: string;
  name: string;
  mcNumber: string;
  dotNumber: string;
  phone: string;
  dispatcher: string;
  notes: string;
  sealNumber: string;
  scac: string;
  firstDriverName: string;
  firstDriverPhone: string;
  secondDriverName: string;
  secondDriverPhone: string;
  email: string;
  dispatchCity: string;
  dispatchState: string;
  dispatchSource: CheckCallSource;
  truckNumber: string;
  trailerNumber: string;
  rateConfirmationSent: boolean;
  confirmationSentTime: MaybeUndef<string>;
  confirmationReceivedTime: MaybeUndef<string>;
  dispatchedTime: MaybeUndef<string>;
  expectedPickupTime: MaybeUndef<string>;
  pickupStart: MaybeUndef<string>;
  pickupEnd: MaybeUndef<string>;
  expectedDeliveryTime: MaybeUndef<string>;
  deliveryStart: MaybeUndef<string>;
  deliveryEnd: MaybeUndef<string>;
  signedBy: string;
};

export type Pickup = LoadStop & {
  readyTime: MaybeUndef<string>;
};

export type Dropoff = LoadStop & {
  mustDeliver: string; // Must deliver by datetime
};

export type LoadStop = CompanyCoreInfo & {
  businessHours: string;
  refNumber: string;
  apptRequired: Maybe<boolean>;
  apptStartTime: MaybeUndef<string>;
  apptEndTime: MaybeUndef<string>;
  apptNote: string;
  timezone: string; // IANA timezone e.g. America/New_York
};

export type ValueUnit = {
  val: number;
  unit: Maybe<Unit>;
};

export enum Unit {
  // Weight
  Kg = 'kg',
  Pounds = 'lbs',

  // Pieces
  Boxes = 'boxes',
  Crates = 'crates',
  Pallets = 'pallets',

  // Distance
  Miles = 'miles',
  Km = 'km',
}

export type Specifications = {
  totalInPalletCount: MaybeUndef<number>;
  totalOutPalletCount: MaybeUndef<number>;
  totalPieces: Maybe<ValueUnit>;
  commodities: string;
  numCommodities: MaybeUndef<number>;
  transportType: string; // Van, Flatbed, Reefer, etc
  totalWeight: Maybe<ValueUnit>;
  billableWeight: Maybe<ValueUnit>;
  totalDistance: MaybeUndef<ValueUnit>;
  minTempFahrenheit: MaybeUndef<number>;
  maxTempFahrenheit: MaybeUndef<number>;
};

export type RateData = {
  collectionMethod: string; // e.g. Prepaid, Collect, Third-Party, etc
  revenueCode: string; // Department/team in brokerage receiving the revenue
  customerRateType: string; // e.g. FlatRate, All In, Hourly, Mileage

  // Customer rate info
  customerLineHaulCharge: Maybe<ValueUnit>; // ValueUnit with currency (e.g. USD)
  customerRateNumUnits: Maybe<number>; // Number of units for rate type (e.g. miles, hours, etc.)
  customerLineHaulRate: Maybe<number>; // Per unit rate (e.g. $/mile or $/hour)
  customerLineHaulUnit: string; // Unit for the rate (e.g. miles, hours, etc.)
  customerTotalCharge: Maybe<ValueUnit>; // ValueUnit.Unit is currency (e.g. USD).

  fscPercent: Maybe<number>; // 0 - 100
  fscPerMile: Maybe<number>;

  // Carrier rate info
  carrierRateType: string; // e.g. FlatRate, All In, Hourly, Mileage
  carrierLineHaulCharge: Maybe<ValueUnit>; // ValueUnit with currency (e.g. USD)
  carrierRateNumUnits: Maybe<number>; // Number of units for rate type (e.g. miles, hours, etc.)
  carrierLineHaulRate: Maybe<number>; // Per unit rate (e.g. $/mile or $/hour)
  carrierLineHaulUnit: string; // Unit for the rate (e.g. miles, hours, etc.)
  carrierTotalCost: Maybe<ValueUnit>; // ValueUnit.Unit is currency (e.g. USD).

  carrierMaxRate: Maybe<number>;

  netProfitUSD: Maybe<number>;
  profitPercent: Maybe<number>; // 0 - 100
};

// For Aljex loads whose timestamps are timezone-agnostic, normalizes all timestamps to be in UTC,
// regardless of the user's locale
export type NormalizedLoad = {
  // Capitalized only because of underlying gorm.Model
  ID: MaybeUndef<number>;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: MaybeUndef<string>;

  serviceID: number;
  tmsID: number;
  freightTrackingID: string;
  isPlaceholder: boolean;
  externalTMSID: string;
  status: string;
  mode: string;
  moreThanTwoStops: boolean;
  outPalletCount: MaybeUndef<number>;
  poNums: MaybeUndef<string>;
  operator: string;
  rateData: RateData;
  customer: Customer;
  billTo: CompanyCoreInfo;
  pickup: NormalizedPickup;
  consignee: NormalizedDropoff;
  carrier: NormalizedLoadCarrier;
  specifications: Specifications;
  notes: Note[];
};

export type NormalizedLoadCarrier = {
  name: string;
  externalTMSID: string;
  mcNumber: string;
  dotNumber: string;
  phone: string;
  dispatcher: string;
  notes: string;
  sealNumber: string;
  scac: string;
  firstDriverName: string;
  firstDriverPhone: string;
  secondDriverName: string;
  secondDriverPhone: string;
  email: string;
  dispatchSource: CheckCallSource;
  dispatchCity: string;
  dispatchState: string;
  truckNumber: string;
  trailerNumber: string;
  rateConfirmationSent: boolean;
  confirmationSentTime: Maybe<Date>;
  confirmationReceivedTime: Maybe<Date>;
  dispatchedTime: Maybe<Date>;
  expectedPickupTime: Maybe<Date>;
  pickupStart: Maybe<Date>;
  pickupEnd: Maybe<Date>;
  expectedDeliveryTime: Maybe<Date>;
  deliveryStart: Maybe<Date>;
  deliveryEnd: Maybe<Date>;
  signedBy: string;
};

export type NormalizedPickup = NormalizedLoadStop & {
  readyTime: Maybe<Date>;
};

export type NormalizedDropoff = NormalizedLoadStop & {
  mustDeliver: string; // Must deliver by datetime
};

export type NormalizedLoadStop = CompanyCoreInfo & {
  businessHours: string;
  refNumber: string;
  apptRequired: Maybe<boolean>;
  apptStartTime: Maybe<Date>;
  apptEndTime: Maybe<Date>;
  apptNote: string;
  timezone: string; // IANA timezone e.g. America/New_York
};

export function normalizeLoad(tmsName: string, load: Load) {
  return {
    ...load,
    customer: normalizeDatesForTMSForm(tmsName, load.customer),
    billTo: normalizeDatesForTMSForm(tmsName, load.billTo),
    pickup: normalizeDatesForTMSForm(tmsName, load.pickup),
    consignee: normalizeDatesForTMSForm(tmsName, load.consignee),
    carrier: normalizeDatesForTMSForm(tmsName, load.carrier),
  } as NormalizedLoad;
}

export function mapUnit(unitStr: string): Maybe<Unit> {
  switch (unitStr.toLowerCase()) {
    case 'kg':
      return Unit.Kg;
    case 'lbs':
      return Unit.Pounds;
    case 'boxes':
      return Unit.Boxes;
    case 'miles':
      return Unit.Miles;
    case 'km':
      return Unit.Km;
    case 'pallets':
      return Unit.Pallets;
    case 'crates':
      return Unit.Crates;
    default:
      return null;
  }
}

export type TMSCustomer = CompanyCoreInfo;

export type TMSLocation = CompanyCoreInfo & {
  apptRequired: Maybe<boolean>;
  driverLoadingResponsibility?: string; // e.g. No (un)loading, driver load, drop & hook trailer. Primarily for Mcleod
};

export type TMSCarrier = CompanyCoreInfo & { dotNumber: string };

export const initCompanyCoreInfo: CompanyCoreInfo = {
  externalTMSID: '',
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  contact: '',
  phone: '',
  email: '',
};
