// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { ChartConfig } from 'components/Chart';

export const chartConfig: ChartConfig = {
  maxRate: {
    label: 'Max',
    color: '#e92c2c', // tailwind config green-main
  },
  averageRate: {
    label: 'Avg',
    color: '#f98600', // tailwind config orange-main
  },
  lowestRate: {
    label: 'Min',
    color: '#00ba34', // tailwind config red-main
  },
};

// reference: QuoteView/Quoting/RequestQuickQuote.tsx
