import { useEffect, useState } from 'react';
import {
  Controller,
  FieldErrors,
  FieldPath,
  UseFormReturn,
} from 'react-hook-form';

import { Checkbox } from 'components/Checkbox';
import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { LoadDateTimeInput } from 'pages/LoadView/LoadInformationTab';
import { NormalizedLoad, TMSLocation } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import {
  GenericCompanySearchableFields,
  locationSearchHandler,
  mapLocationsToAntdOptions,
} from 'utils/loadInfoAndBuilding';

import { LoadBuildingTextInput } from '../McleodLoadBuildingForm';

export function StopForm({
  stop,
  formMethods,
  isLoadingLocations,
  locations,
  handleRefreshLocations,
  setLocations,
}: {
  stop: 'pickup' | 'consignee';
  formMethods: UseFormReturn<NormalizedLoad>;
  isLoadingLocations: boolean;
  locations: Maybe<TMSLocation[]>;
  handleRefreshLocations: () => void;
  setLocations: React.Dispatch<React.SetStateAction<Maybe<TMSLocation[]>>>;
}): JSX.Element {
  const [isEditingLocation] = useState(false);
  // const [isCreatingLocation, setIsCreatingLocation] = useState(false); // placeholder to demo loading state
  // const { toast } = useToast();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  // const isPickup = stop === 'pickup';
  const watchedLocationID = watch(`${stop}.externalTMSID`);
  const watchedLocObj = watch(stop);
  const { tmsIntegrations } = useServiceFeatures();

  const handleLocationSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return locationSearchHandler({
      tmsID: tmsIntegrations?.[0]?.id,
      locations,
      setLocations,
      field,
      value,
    });
  };

  useEffect(() => {
    if (watchedLocationID) {
      const selectedLoc = locations?.find(
        (loc) => loc.externalTMSID === watchedLocationID
      );
      if (!selectedLoc) {
        return;
      }

      Object.entries(selectedLoc).forEach(([key, value]) => {
        setValue(`${stop}.${key}` as FieldPath<NormalizedLoad>, value, {
          shouldDirty: true, // this ensures the AI-label is handled correctly
        });
      });
    }
  }, [watchedLocationID]);

  useEffect(() => {
    if (watchedLocationID) {
      // Remove ID if address is write-in
      const selectedLoc = locations?.find(
        (loc) => loc.externalTMSID === watchedLocationID
      );
      if (!selectedLoc) {
        return;
      }

      if (
        (selectedLoc?.name &&
          watchedLocObj?.name.toLowerCase() !==
            selectedLoc?.name.toLowerCase()) ||
        (selectedLoc?.addressLine1 &&
          watchedLocObj?.addressLine1.toLowerCase() !==
            selectedLoc?.addressLine1.toLowerCase()) ||
        (selectedLoc?.addressLine2 &&
          watchedLocObj?.addressLine2?.toLowerCase() !==
            selectedLoc?.addressLine2?.toLowerCase()) ||
        (selectedLoc?.city &&
          watchedLocObj?.city?.toLowerCase() !==
            selectedLoc?.city?.toLowerCase()) ||
        (selectedLoc?.state &&
          watchedLocObj?.state?.toLowerCase() !==
            selectedLoc?.state?.toLowerCase()) ||
        (selectedLoc?.zipCode &&
          watchedLocObj?.zipCode?.toLowerCase() !==
            selectedLoc?.zipCode?.toLowerCase())
      ) {
        setValue(`${stop}.externalTMSID` as FieldPath<NormalizedLoad>, '');
      }
    }
  }, [
    watchedLocationID,
    watchedLocObj?.name,
    watchedLocObj?.addressLine1,
    watchedLocObj?.addressLine2,
    watchedLocObj?.city,
    watchedLocObj?.state,
    watchedLocObj?.zipCode,
  ]);

  return (
    <>
      <RHFDebounceSelect
        required={true}
        name={`${stop}.externalTMSID`}
        label='Name'
        control={control}
        errors={errors}
        data={locations}
        isLoading={isLoadingLocations}
        refreshHandler={handleRefreshLocations}
        fetchOptions={handleLocationSearch}
        mapOptions={() => mapLocationsToAntdOptions(locations, true)}
      />

      {isEditingLocation && <hr className='my-2' />}

      <LoadBuildingTextInput
        name={`${stop}.addressLine1`}
        label='Address Line 1'
        readOnly={true}
      />

      <LoadBuildingTextInput
        name={`${stop}.addressLine2`}
        label='Address Line 2'
        readOnly={true}
      />

      <div className='grid grid-cols-2 w-full m-0 gap-2'>
        <LoadBuildingTextInput
          name={`${stop}.city`}
          label='City'
          readOnly={true}
        />

        <LoadBuildingTextInput
          name={`${stop}.state`}
          label='State'
          readOnly={true}
        />
      </div>

      <LoadBuildingTextInput
        name={`${stop}.zipCode`}
        label='Zip Code'
        placeholder='12345'
        readOnly={true}
      />

      {/* Not supported rn. Buggy duplication behavior on update */}
      {/* <LoadBuildingTextInput
        name={`${stop}.refNumber`}
        label='Ref #'
        placeholder='Comma-separated list (e.g. 123,456,789)'
      /> */}

      {stop === 'pickup' && (
        <LoadDateTimeInput
          name={`${stop}.readyTime` as FieldPath<NormalizedLoad>}
          label='Pickup Date'
          required
        />
      )}

      {stop === 'consignee' && (
        <LoadDateTimeInput
          name={`${stop}.mustDeliver` as FieldPath<NormalizedLoad>}
          label='Delivery Date'
          required
        />
      )}

      {isEditingLocation && <hr className='my-2' />}

      <LoadDateTimeInput
        name={`${stop}.apptStartTime` as FieldPath<NormalizedLoad>}
        label='Appointment Start Time'
      />

      <LoadDateTimeInput
        name={`${stop}.apptEndTime` as FieldPath<NormalizedLoad>}
        label='Appointment End Time'
      />

      {(watchedLocObj?.apptRequired ||
        isEditingLocation ||
        !watchedLocObj.externalTMSID) && (
        <Controller
          name={`${stop}.apptRequired`}
          control={control}
          render={({ field }) => (
            <div className='flex items-center space-x-2'>
              <Checkbox
                onCheckedChange={(checked) => {
                  field.onChange(checked);
                }}
                checked={field.value || undefined}
              />
              <label
                htmlFor={`${stop}.apptRequired`}
                className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-grayscale-content-input'
              >
                {'Appointment Required?'}
              </label>
            </div>
          )}
        />
      )}
    </>
  );
}

export function hasNoErrorsInSubsection(
  subsectionPath: string,
  formErrors: FieldErrors
): boolean {
  const subsectionErrors = Object.entries(formErrors).filter(([key]) =>
    key.startsWith(subsectionPath)
  );

  return subsectionErrors.length === 0;
}
