import {
  CarrierCostType,
  MarginType,
} from '../pages/QuoteView/Quoting/RequestQuickQuote/types';

const calculateFlatCarrierCost = (
  carrierCost: number,
  carrierCostType: CarrierCostType,
  distance: number
): number => {
  return carrierCostType === CarrierCostType.Flat
    ? carrierCost
    : carrierCost * distance;
};

const calculateFinalMargin = (
  margin: number,
  marginType: MarginType,
  carrierCostType: CarrierCostType,
  flatCarrierCost: number
): number => {
  return marginType === MarginType.Percentage ||
    carrierCostType === CarrierCostType.Flat
    ? margin // Percentage margin or flat cost stays as-is
    : margin * flatCarrierCost; // Convert to flat margin $ value
};

export const calculatePricing = (
  carrierCost: number,
  carrierCostType: CarrierCostType,
  margin: number,
  marginType: MarginType,
  distance: number
): { flatCarrierCost: number; finalMargin: number } => {
  const flatCarrierCost = calculateFlatCarrierCost(
    carrierCost,
    carrierCostType,
    distance
  );
  const finalMargin = calculateFinalMargin(
    margin,
    marginType,
    carrierCostType,
    flatCarrierCost
  );

  return { flatCarrierCost, finalMargin };
};
