import _ from 'lodash';

import {
  DATTooltipConstructor,
  LaneHistoryTooltipConstructor,
  PriceRangeType,
  QuoteCardType,
} from 'components/QuoteCard';
import { toast } from 'hooks/useToaster';
import DATLogo from 'icons/DAT';
import GlobalTranzLogo from 'icons/GlobalTranz';
import GreenscreensLogo from 'icons/Greenscreens';
import McleodLogo from 'icons/McleodEnterprise';
import TruckstopLogo from 'icons/TruckstopLogo';
import { enableDATIndividualAccess } from 'lib/api/enableDATIndividualAccess';
import { getCustomers } from 'lib/api/getCustomers';
import { LaneHistoryResponse, getLaneHistory } from 'lib/api/getLaneHistory';
import { getLaneRateFromService } from 'lib/api/getLaneRateFromService';
import { SelectedCarrierType } from 'lib/api/getQuickQuote';
import { getQuoteNumber } from 'lib/api/getQuoteNumber';
import {
  GreenscreensQuote,
  sendGreenscreensQuoteToService,
} from 'lib/api/postGreenscreensQuoteToService';
import {
  UserQuote,
  sendUserQuoteToService,
} from 'lib/api/postUserQuoteToService';
import { submitQuoteToTMS } from 'lib/api/submitQuoteToTMS';
import { submitQuoteViaURL } from 'lib/api/submitQuoteViaURL';
import { updateQuoteRequestSuggestion } from 'lib/api/updateQuoteRequestSuggestion';
import { Quoting, TMS } from 'types/enums/Integrations';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { calculatePricing } from 'utils/priceCalculations';

import {
  DATQuoteLocationType,
  DATQuoteTimeframe,
  FetchCustomersProps,
  FetchLaneRateFromServiceProps,
  FetchQuoteNumberProps,
  HandleQuoteSubmissionViaURLProps,
  HelperFunctions,
  MarginType,
  OnSubmitFormProps,
  ProcessQuoteTMSSubmissionProps,
  QuoteTypeInSource,
  SendGreenscreensQuoteProps,
  SendUserQuoteProps,
} from './types';

export const useHelperFunctions: HelperFunctions = {
  toTitleCase: (str: string): string => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  },

  sendGreenscreensQuote: async ({
    quote,
    setGreenscreensQuoteID,
  }: SendGreenscreensQuoteProps) => {
    const gsNetworkQuote = quote.quotes.find(
      (q) => q.type === QuoteTypeInSource.GS_Network
    );
    const gsBuyPowerQuote = quote.quotes.find(
      (q) => q.type === QuoteTypeInSource.GS_BuyPower
    );

    const greenscreensQuoteObject: GreenscreensQuote = {
      quoteRequestId: quote.quoteRequestId,
      stops: quote.stops,
      selectedRateName: quote.selectedRateName,
      networkLaneRateDistance: gsNetworkQuote?.distance || 0,
      networkLaneRateTargetBuy: gsNetworkQuote?.rates.targetPerMile || 0,
      networkLaneRateConfidenceLevel:
        gsNetworkQuote?.metadata?.confidenceLevel || 0,
      laneRateDistance: gsBuyPowerQuote?.distance || 0,
      laneRateTargetBuy: gsBuyPowerQuote?.rates.targetPerMile || 0,
      laneRateConfidenceLevel: gsBuyPowerQuote?.metadata?.confidenceLevel || 0,
    };
    const res = await sendGreenscreensQuoteToService(greenscreensQuoteObject);
    if (res.isOk()) {
      setGreenscreensQuoteID(res.value);
    }
  },

  sendUserQuote: async ({
    email,
    quote,
    parentQuoteRequestId,
    greenscreensQuoteID,
    carrierCost,
    margin,
    marginType,
    finalPrice,
    draftResponse,
  }: SendUserQuoteProps) => {
    if (!greenscreensQuoteID || !email || !quote) return;

    const roundedFinalPrice = _.round(finalPrice);
    const userQuoteObject: UserQuote = {
      quoteRequestId: parentQuoteRequestId,
      gsQuoteID: greenscreensQuoteID,
      draftResponse: draftResponse,
      carrierCost: carrierCost,
      margin: margin,
      marginType: marginType,
      targetSell: roundedFinalPrice,
      stops: quote.stops,
    };

    await sendUserQuoteToService(userQuoteObject);
  },

  fetchQuoteNumber: async ({
    email,
    setHasThirdPartyQuoteURLs,
    setValue,
  }: FetchQuoteNumberProps) => {
    if (!email) return;
    const res = await getQuoteNumber(email.id);
    if (res.isOk()) {
      setHasThirdPartyQuoteURLs(res.value.hasThirdPartyQuoteURLs);
      // can't use resetField here because the form input hasn't been initialized yet
      setValue('quoteNumber', res.value.quoteNumber);
      return;
    }
    setHasThirdPartyQuoteURLs(false);
  },

  fetchLaneRateFromService: async ({
    emailId,
    threadId,
    quoteRequestId,
    setQuoteCards,
    updatedFormValues,
  }: FetchLaneRateFromServiceProps) => {
    const res = await getLaneRateFromService({
      emailId: emailId,
      threadId: threadId,
      quoteRequestId: quoteRequestId,
      transportType: updatedFormValues.transportType,
      originDate: new Date(updatedFormValues.pickupDate).toISOString(),
      originZip: updatedFormValues.stops[0].zip,
      originCity: updatedFormValues.stops[0].city,
      originState: updatedFormValues.stops[0].state,
      destinationDate: new Date(updatedFormValues.deliveryDate).toISOString(),
      destinationZip: updatedFormValues.stops[1].zip,
      destinationCity: updatedFormValues.stops[1].city,
      destinationState: updatedFormValues.stops[1].state,
    });
    if (res.isOk()) {
      const {
        lowPerTrip,
        highPerTrip,
        lowPerMile,
        highPerMile,
        timeframe,
        originName,
        originType,
        destinationName,
        destinationType,
      } = res.value;

      setQuoteCards((prev) => [
        ...prev,
        {
          type: SelectedCarrierType.DAT,
          title: `RateView Median`,
          icon: <DATLogo className='inline-block w-auto h-3' />,
          cost: Number(res.value.ratePerTrip),
          costPerMile: Number(res.value.ratePerMile),
          confidence: null,
          priceRange:
            lowPerTrip && highPerTrip
              ? ({
                  lowEstimate: lowPerTrip,
                  highEstimate: highPerTrip,
                } as PriceRangeType)
              : null,
          priceRangePerMile:
            lowPerMile && highPerMile
              ? ({
                  lowEstimate: lowPerMile,
                  highEstimate: highPerMile,
                } as PriceRangeType)
              : null,
          tooltipContent: {
            timeframe: DATQuoteTimeframe[timeframe],
            originName: originName,
            originType: DATQuoteLocationType[originType],
            destinationName: destinationName,
            destinationType: DATQuoteLocationType[destinationType],
          },
          tooltipConstructor: DATTooltipConstructor,
          inputtedTransportType: updatedFormValues.transportType,
          actualTransportType: updatedFormValues.transportType,
        },
      ]);
    } else {
      let serviceLaneRateError =
        'Oops, something went wrong while fetching tailored lane rates. Showing standard lane rates instead.';

      if (res.error.message.includes("Trident's DAT integration")) {
        serviceLaneRateError =
          'Trident was unable to retrieve lane rate from DAT. Showing standard lane rates instead.';
        toast({
          description: serviceLaneRateError,
          variant: 'info',
        });
      } else if (res.error.message.includes('Unrecognized Service')) {
        serviceLaneRateError =
          'Tailored rates unavailable. Showing standard lane rates instead.';
        toast({
          description: serviceLaneRateError,
          variant: 'info',
        });
      } else {
        toast({
          description: serviceLaneRateError,
          variant: 'info',
        });
      }
    }
  },

  fetchCustomers: async ({
    setInitialCustomers,
    setCustomers,
    setTMSTenant,
    tmsIntegrations,
  }: FetchCustomersProps) => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setInitialCustomers(res.value.customerList);
      setCustomers(res.value.customerList);
      setTMSTenant(res.value.tmsTenant);
    } else {
      toast({
        description: 'Error while fetching customer list.',
        variant: 'destructive',
      });
    }
  },

  onSubmitForm: async ({
    formValues,
    setIsSubmitToTMS,
    setCreatedQuoteId,
    setQuote,
    setQuoteCards,
    isQuoteSubmissionViaURLEnabled,
    email,
    setHasThirdPartyQuoteURLs,
    setValue,
    isGetLaneRateFromServiceEnabled,
    clickedSuggestion,
    formMethods,
    setQuoteNotConfident,
    getQuickQuote,
    isQuoteLaneHistoryEnabled,
    setIsLoadingLaneHistory,
    setLaneHistory,
    setCarrierCost,
    setMargin,
    marginType,
    setError,
    setParentQuoteRequestId,
    setGreenscreensQuoteID,
    isQuoteSubmissionToServiceEnabled,
    setDATFuelSurcharge,
  }: OnSubmitFormProps) => {
    // these state variables should be reset when the form is submitted
    setIsSubmitToTMS(true);
    setCreatedQuoteId(undefined);
    setQuote(null);
    setQuoteCards([]);
    setCarrierCost(0);
    setLaneHistory(null);

    const pickup = useHelperFunctions.parseLocation(
      formValues.stops[0].location || ''
    );
    const delivery = useHelperFunctions.parseLocation(
      formValues.stops[1].location || ''
    );

    const validationErrors = [
      !pickup && [
        'stops.0.location',
        'Please enter valid ZIP code or City, State',
      ],
      !delivery && [
        'stops.1.location',
        'Please enter valid ZIP code or City, State',
      ],
    ].filter(Boolean);
    if (validationErrors.length) {
      (validationErrors as [`stops.${number}.location`, string][]).forEach(
        ([field, message]) => setError(field, { message })
      );
      return;
    }

    const updatedFormValues = {
      ...formValues,
      // validated that pickup/delivery isn't null, we can safely assert it's non-null
      stops: [
        {
          zip: pickup!.zip,
          city: pickup!.city,
          state: pickup!.state,
        },
        {
          zip: delivery!.zip,
          city: delivery!.city,
          state: delivery!.state,
        },
      ],
    };

    // Only need to fetch quote number when submitting quotes via URL is supported
    if (isQuoteSubmissionViaURLEnabled) {
      useHelperFunctions.fetchQuoteNumber({
        email,
        setHasThirdPartyQuoteURLs,
        setValue,
      });
    }

    const newQuote = await getQuickQuote(
      email,
      clickedSuggestion,
      updatedFormValues,
      formMethods,
      setQuoteNotConfident,
      setMargin,
      marginType
    );

    // We want general information about the quote, even if no carrier cards are generated.
    if (!newQuote) return;

    // At this point, there is a parent quote request so we should set it's ID.
    // This is used to update the parent quote request to accepted at the end of the quick quote flow.
    setParentQuoteRequestId(newQuote.quoteRequestId || 0);

    // Set quote details other than carrier cards
    setQuote(newQuote);
    setMargin(
      marginType === MarginType.Amount
        ? newQuote.configuration?.defaultFlatMargin || 100
        : newQuote.configuration?.defaultPercentMargin || 10
    );

    // Parse and add quick quote carrier cards
    if (newQuote.quotes?.length) {
      const newQuoteCards: QuoteCardType[] = [];
      newQuote?.quotes.forEach((quote) => {
        switch (quote.source) {
          case Quoting.Greenscreens: {
            const isBuyPower = quote.type === QuoteTypeInSource.GS_BuyPower;
            newQuoteCards.push({
              type: isBuyPower
                ? SelectedCarrierType.BUYPOWER
                : SelectedCarrierType.NETWORK,
              title: isBuyPower ? 'Buy Power Quote' : 'Network Quote',
              icon: (
                <GreenscreensLogo
                  height={12}
                  width={75}
                  className='inline-block'
                />
              ),
              cost: quote.rates.targetPerMile * quote.distance,
              costPerMile: quote.rates.targetPerMile,
              confidence: quote.metadata?.confidenceLevel || null,
              priceRange: null,
              inputtedTransportType: updatedFormValues.transportType,
              actualTransportType: newQuote.submittedTransportType,
            });
            break;
          }
          case Quoting.DAT: {
            if (quote.metadata?.fuelSurchargePerMile) {
              setDATFuelSurcharge(quote.metadata?.fuelSurchargePerMile);
            }

            newQuoteCards.push({
              type: SelectedCarrierType.DAT,
              title: 'RateView',
              icon: <DATLogo width={64} className='inline-block' />,
              cost: quote.rates.target,
              costPerMile: quote.rates.targetPerMile,
              priceRange: {
                lowEstimate: quote.rates.low,
                highEstimate: quote.rates.high,
              } as PriceRangeType,
              confidence: null,
              priceRangePerMile: {
                lowEstimate: quote.rates.lowPerMile,
                highEstimate: quote.rates.highPerMile,
              } as PriceRangeType,
              tooltipContent: {
                timeframe: quote.metadata?.timeframe || '',
                originName: quote.metadata?.originName || '',
                originType:
                  quote.metadata?.originType || DATQuoteLocationType.REGION,
                destinationName: quote.metadata?.destinationName || '',
                destinationType:
                  quote.metadata?.destinationType ||
                  DATQuoteLocationType.REGION,
                reports: quote.metadata?.reports || 0,
                companies: quote.metadata?.companies || 0,
              },
              tooltipConstructor: DATTooltipConstructor,
              inputtedTransportType: updatedFormValues.transportType,
              actualTransportType: newQuote.submittedTransportType,
            });
            break;
          }
          case Quoting.TruckStop: {
            const isTruckstopBooked =
              quote.type === QuoteTypeInSource.TruckStop_Booked;
            newQuoteCards.push({
              type: isTruckstopBooked
                ? SelectedCarrierType.TRUCKSTOP_BOOKED
                : SelectedCarrierType.TRUCKSTOP_POSTED,
              title: isTruckstopBooked ? 'Booked Quote' : 'Posted Quote',
              icon: <TruckstopLogo width={30} className='inline-block' />,
              cost: quote.rates.target,
              confidence: quote.metadata?.confidenceLevel || null,
              priceRange: null,
              inputtedTransportType: updatedFormValues.transportType,
              actualTransportType: newQuote.submittedTransportType,
            });
          }
        }
      });

      setQuoteCards((prev) => [...prev, ...newQuoteCards]);

      // Handle submit-to-service logic
      if (isQuoteSubmissionToServiceEnabled) {
        // check if any of the quotes are greenscreens quotes
        const greenscreensQuotes = newQuote.quotes.filter(
          (q) => q.source === Quoting.Greenscreens
        );
        if (greenscreensQuotes.length > 0) {
          await useHelperFunctions.sendGreenscreensQuote({
            quote: {
              ...newQuote,
              quotes: greenscreensQuotes,
            },
            setGreenscreensQuoteID: setGreenscreensQuoteID,
          });
        }
      }
    }

    // Lane Rate from Service (e.g. Trident Olympus)
    if (isGetLaneRateFromServiceEnabled) {
      await useHelperFunctions.fetchLaneRateFromService({
        emailId: email?.id ?? 0,
        threadId: email?.threadID ?? '',
        quoteRequestId: newQuote?.quoteRequestId || 0,
        setQuoteCards,
        updatedFormValues,
      });
    }

    // Lane History (e.g. Mcleod, GlobalTranz)
    let laneHistoryCards: QuoteCardType[] = [];
    if (isQuoteLaneHistoryEnabled) {
      setIsLoadingLaneHistory(true);

      const res = await getLaneHistory({
        quoteRequestId: newQuote?.quoteRequestId || 0,
        destinationCity: updatedFormValues.stops[1].city,
        destinationState: updatedFormValues.stops[1].state,
        destinationZip: updatedFormValues.stops[1].zip,
        originCity: updatedFormValues.stops[0].city,
        originState: updatedFormValues.stops[0].state,
        originZip: updatedFormValues.stops[0].zip,
        transportType: updatedFormValues.transportType,
      });
      if (res.isOk()) {
        setLaneHistory(res.value);
        laneHistoryCards = useHelperFunctions.handleQuoteLaneHistory(res.value);
      }

      setIsLoadingLaneHistory(false);
    }

    // Add lane history carrier cards
    if (laneHistoryCards.length) {
      setQuoteCards((prev) => [...prev, ...laneHistoryCards]);
    }
  },

  enableDATIndividualAccess: async ({
    datEmailAddress,
    setHasGrantedDATPermissions,
  }) => {
    const res = await enableDATIndividualAccess(datEmailAddress);
    if (res.isOk()) {
      setHasGrantedDATPermissions(true);
      toast({
        description: 'Successfully enabled DAT!',
        variant: 'success',
      });
    } else {
      const isEmailError = res.error.message.includes(
        'failed to initialize DAT client with email'
      );
      toast({
        description: isEmailError
          ? 'Failed to authenticate DAT email address, please try another'
          : 'Failed to enable DAT individual access',
        variant: 'destructive',
      });
    }
  },

  handleQuoteLaneHistory: (
    laneHistoryResponse: LaneHistoryResponse
  ): QuoteCardType[] => {
    const laneHistoryRespQuotes: QuoteCardType[] = [];

    // Find the first CalculatedQuote and add it to the QuoteCards
    Object.values(laneHistoryResponse.resultsBySource).forEach((source) => {
      for (const history of source) {
        if (history.calculatedQuote) {
          const quote = history.calculatedQuote;

          let carrierIcon = <img />;
          let costPerMile = undefined;
          let priceRangePerMile = null;
          switch (history.source) {
            case TMS.McleodEnterprise:
              carrierIcon = <McleodLogo width={45} className='inline-block' />;
              costPerMile = quote.avgRatePerMile;
              priceRangePerMile =
                quote.minCost && quote.maxCost
                  ? ({
                      lowEstimate: quote.minCost,
                      highEstimate: quote.maxCost,
                    } as PriceRangeType)
                  : null;
              break;
            case Quoting.GlobalTranz:
              carrierIcon = <GlobalTranzLogo height={12} width={75} />;
              break;
          }

          // TODO: Add lane tier toggle to card
          const newCard: QuoteCardType = {
            type: SelectedCarrierType.LANE_HISTORY,
            title: `Lane History`,
            icon: carrierIcon,
            cost: quote.avgCost,
            costPerMile: costPerMile,
            confidence: null,
            priceRange:
              quote.minCost && quote.maxCost
                ? ({
                    lowEstimate: quote.minCost,
                    highEstimate: quote.maxCost,
                  } as PriceRangeType)
                : null,
            priceRangePerMile: priceRangePerMile,
            tooltipContent: {
              timeframe: history.timeframe + ' average',
              originName: 'Origin',
              originType: history.laneTier!,
              destinationName: 'Destination',
              destinationType: history.laneTier!,
            },
            tooltipConstructor: LaneHistoryTooltipConstructor,
            inputtedTransportType: history.inputtedTransportType,
            actualTransportType: history.proxiedTransportType,
          };

          // This takes longer to fetch than other cards, so we're adding it to the end of the list
          // to avoid flickering/pushing user's view
          laneHistoryRespQuotes.push(newCard);

          break;
        }
      }
    });

    return laneHistoryRespQuotes;
  },

  parseLocation: (location: string) => {
    // Check if input is a 5-digit ZIP code
    if (/^\d{5}$/.test(location.trim())) {
      return {
        zip: location.trim(),
        city: '',
        state: '',
      };
    }

    // Parse city, state format (e.g. "Boston, MA")
    const match = location.match(/^([^,]+),\s*([A-Z]{2})$/i);
    if (match) {
      return {
        zip: '',
        city: match[1].trim(),
        state: match[2].toUpperCase(),
      };
    }

    return null;
  },

  processQuoteTMSSubmission: async ({
    customerId,
    finalPrice,
    getValues,
  }: ProcessQuoteTMSSubmissionProps) => {
    const pickup = useHelperFunctions.parseLocation(
      getValues('stops.0.location') || ''
    );
    const delivery = useHelperFunctions.parseLocation(
      getValues('stops.1.location') || ''
    );

    const validationErrors = [
      !pickup && [
        'stops.0.location',
        'Please enter valid ZIP code or City, State',
      ],
      !delivery && [
        'stops.1.location',
        'Please enter valid ZIP code or City, State',
      ],
    ].filter(Boolean);

    // This should only be triggered if user changed location inputs are initial QQ call
    if (validationErrors.length) {
      toast({
        description: 'Unable to submit Quote to TMS due to invalid location.',
        variant: 'destructive',
      });
      return;
    }

    const res = await submitQuoteToTMS({
      customerId: customerId.toString(),
      quotePrice: _.round(finalPrice),
      transportType: getValues('transportType'),
      pickupLocationZip: pickup!.zip,
      pickupLocationCity: pickup!.city,
      pickupLocationState: pickup!.state,
      pickupDate: new Date(getValues('pickupDate')).toISOString(),
      deliveryLocationZip: delivery!.zip,
      deliveryLocationCity: delivery!.city,
      deliveryLocationState: delivery!.state,
      deliveryDate: new Date(getValues('deliveryDate')).toISOString(),
    });

    if (res.isOk()) {
      return res.value;
    } else {
      toast({
        description: 'Error creating Quote in TMS.',
        variant: 'destructive',
      });

      return;
    }
  },

  handleQuoteSubmissionViaURL: async ({
    email,
    getValues,
    setError,
    setLoadingDraftReply,
    finalPrice,
    margin,
    marginType,
    carrierCost,
    carrierCostType,
    maxDistance,
    isTMSQuoteSubmissionEnabled,
    isSubmitToTMS,
    setCreatedQuoteId,
    clickedSuggestion,
    setCurrentState,
    parentQuoteRequestId,
  }: HandleQuoteSubmissionViaURLProps) => {
    if (!email) return;

    if (!getValues('quoteNumber')) {
      setError(
        'quoteNumber',
        {
          message: 'Quote number is required',
        },
        { shouldFocus: true }
      );
      return;
    }

    if (!finalPrice || finalPrice <= 0) {
      return;
    }

    setLoadingDraftReply(true);

    const roundedFinalPrice = _.round(finalPrice);
    const res = await submitQuoteViaURL(email.id, {
      quoteAmount: roundedFinalPrice,
      quoteNumber: getValues('quoteNumber'),
      expirationDate: new Date(getValues('quoteExpirationDate')),
      eta: new Date(getValues('quoteEta')),
    });

    if (res.isOk()) {
      toast({
        description: 'Successfully submitted quote via hyperlink.',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
      setLoadingDraftReply(false);
      return; // don't proceed with TMS submission if quote submission via URL fails
    }

    if (isTMSQuoteSubmissionEnabled && isSubmitToTMS) {
      const customerId = getValues('customerName');
      if (!customerId) {
        setLoadingDraftReply(false);
        return;
      }

      const createdQuote = await useHelperFunctions.processQuoteTMSSubmission({
        customerId: customerId.toString(),
        finalPrice: finalPrice,
        getValues,
      });

      setLoadingDraftReply(false);

      if (createdQuote?.quoteId) {
        setCreatedQuoteId(createdQuote.quoteId);
      } else {
        return; // don't proceed with suggestion update if TMS submission fails
      }
    }

    // Use the utility function for final carrier cost and margin calculations
    const { flatCarrierCost: finalFlatCarrierCost, finalMargin } =
      calculatePricing(
        carrierCost,
        carrierCostType,
        margin,
        marginType,
        maxDistance
      );

    await updateQuoteRequestSuggestion(
      parentQuoteRequestId,
      SuggestionStatus.Accepted,
      {
        finalQuotePrice: _.round(finalPrice),
        finalMargin: _.round(finalMargin),
        marginType: marginType,
        finalCarrierCost: _.round(finalFlatCarrierCost),
        carrierCostType: carrierCostType,
      }
    );

    // only remove suggestion from list at the very end of a successful quote submission
    if (clickedSuggestion) {
      setCurrentState((prevState) => ({
        ...prevState,
        clickedSuggestion: null,
        curSuggestionList: prevState.curSuggestionList.filter(
          (s) => s.id !== clickedSuggestion.id
        ),
      }));
    }

    setLoadingDraftReply(false);
  },
};
