import { Controller, useFormContext } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { Textarea } from 'components/Textarea';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Tooltip';
import { Input } from 'components/input/Input';
import ButtonLoader from 'components/loading/ButtonLoader';
import { TMSLocationWithDistance } from 'types/Load';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';

import EligibleCarrierList from './EligibleCarrierList';

export interface MessageCarrierFormProps {
  loading: boolean;
  carrierLocations: TMSLocationWithDistance[];
  selectedCarriers: Record<string, boolean>;
  toggleSelection: (email: string | string[]) => void;
}

export default function MessageCarrierForm({
  loading,
  carrierLocations,
  selectedCarriers,
  toggleSelection,
}: MessageCarrierFormProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className='grid gap-3 grid-cols-1 w-full mx-0 mt-2'>
      {/* Eligible Carriers section */}
      <div className='flex flex-col gap-1 mt-2'>
        <h2 className='text-md whitespace-nowrap text-grayscale-content-label font-semibold'>
          Eligible Carriers
        </h2>
        <p className='text-xs text-grayscale-content-label mb-1'>
          Select at least 1 carrier to email for a quote.
        </p>
        <div className='mr-1'>
          <EligibleCarrierList
            selectedCarriers={selectedCarriers}
            toggleSelection={toggleSelection}
            locations={carrierLocations}
          />
        </div>
      </div>

      {/* Message Carriers */}
      <div className='flex flex-col gap-1 mt-2'>
        <h2 className='text-md whitespace-nowrap text-grayscale-content-label font-semibold'>
          Message Carriers
        </h2>
        <p className='text-xs text-grayscale-content-label italic mb-1'>
          Sending as separate emails to each carrier.
        </p>

        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Label name='from'>From:</Label>
            <Controller
              name='from'
              control={control}
              rules={{
                required: { value: true, message: 'Required' },
                minLength: {
                  value: 5,
                  message: 'Minimum length is 5 characters',
                },
              }}
              render={({ field: { ref, onChange, value } }) => (
                <Input
                  type='text'
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  placeholder='email@example.com'
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name='from'
              render={({ message }: { message: string }) => (
                <p className='text-red-500 text-xs'>{message}</p>
              )}
            />
          </div>

          {/* To field */}
          <div className='flex flex-col gap-1'>
            <Label name='to'>To:</Label>
            <Tooltip delayDuration={10}>
              <TooltipTrigger className='w-full'>
                <Textarea
                  placeholder='Select carrier cards to email'
                  value={Object.entries(selectedCarriers)
                    .filter(([_email, isSelected]) => isSelected)
                    .map(([email]) => email)
                    .flat()
                    .join(', ')}
                  rows={
                    Object.entries(selectedCarriers).filter(
                      ([_, isSelected]) => isSelected
                    ).length || 1
                  }
                  className='min-h-[20px] read-only:bg-grayscale-bg-secondary/80'
                  disabled
                />
              </TooltipTrigger>
              <TooltipContent className='ml-2 max-w-60 whitespace-pre-wrap'>
                Select cards to add recipients
              </TooltipContent>
            </Tooltip>
          </div>

          <div className='flex flex-col gap-1'>
            <Label name='subject'>Subject</Label>
            <Controller
              name='subject'
              control={control}
              rules={{
                required: { value: true, message: 'Required' },
                minLength: {
                  value: 5,
                  message: 'Minimum length is 5 characters',
                },
              }}
              render={({ field: { ref, onChange, value } }) => (
                <Textarea
                  className='min-h-[20px]'
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  rows={Math.max(2, value.split('\n').length || 1)}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name='subject'
              render={({ message }: { message: string }) => (
                <p className='text-red-500 text-xs'>{message}</p>
              )}
            />
          </div>

          <div className='flex flex-col gap-1'>
            <Label name='cc'>CC:</Label>
            <Controller
              name='cc'
              control={control}
              render={({ field }) => (
                <Input {...field} type='text' placeholder='CC' />
              )}
            />
          </div>

          <Label name='emailBody'>Body</Label>
          <Controller
            name='emailBody'
            control={control}
            rules={{
              required: { value: true, message: 'Required' },
              minLength: {
                value: 20,
                message: 'Minimum length is 20 characters',
              },
            }}
            render={({ field: { ref, onChange, value } }) => (
              <Textarea
                name='emailBody'
                className='p-2 h-96'
                value={value}
                onChange={onChange}
                ref={ref}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name='emailBody'
            render={({ message }: { message: string }) => (
              <p className='text-red-500 text-xs'>{message}</p>
            )}
          />
        </div>
      </div>

      <Button
        buttonNamePosthog={ButtonNamePosthog.EmailCarriers}
        type='submit'
        className='w-full'
        disabled={loading}
      >
        {loading ? <ButtonLoader /> : ButtonText.EmailCarriers}
      </Button>
    </div>
  );
}
