import { ServiceFeaturesListType } from 'contexts/serviceContext';
import { Email } from 'types/Email';
import { Maybe } from 'types/UtilityTypes';
import { EmailLabels } from 'types/enums/EmailLabels';
import SidebarView from 'types/enums/SidebarView';

// Based on the email the user is reading and features enabled for the service, sets the Drumkit view using a logic tree.
export function getViewBasedOnEmail(
  email: Maybe<Email>,
  features: ServiceFeaturesListType
): SidebarView {
  const {
    isLoadBuildingEnabled,
    isQuickQuoteEnabled,
    isTruckListEnabled,
    isCarrierNetworkQuotingEnabled,
    isLoadViewEnabled,
    isQuoteViewEnabled,
  } = features;

  const hasAssociatedLoads =
    email?.freightTrackingIDs && email.freightTrackingIDs.length > 0;
  const emailHasLabel = (label: EmailLabels) => email?.labels.includes(label);
  const isQuotingEnabled =
    isQuickQuoteEnabled || isCarrierNetworkQuotingEnabled;

  if (isLoadViewEnabled && hasAssociatedLoads) {
    return SidebarView.Loads;
  }

  const shouldSetQuoteView =
    // If load view not enabled, then show quote view
    !isLoadViewEnabled ||
    // Or if no loads associated with email and it has certain labels, then show quote view
    (!hasAssociatedLoads &&
      ((emailHasLabel(EmailLabels.QuoteRequest) && isQuotingEnabled) ||
        (emailHasLabel(EmailLabels.LoadBuilding) && isLoadBuildingEnabled) ||
        isTruckListEnabled));

  if (shouldSetQuoteView) {
    return SidebarView.Quote;
  }

  // If there's not enough info to determine default view, then default to Quote View
  if (isQuoteViewEnabled) {
    return SidebarView.Quote;
  }

  // One of the two views must be enabled.
  // If Quote View is not enabled, then default to Load View
  return SidebarView.Loads;
}
