import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '@utils/axios';
import captureException from '@utils/captureException';
import isProd from '@utils/isProd';

import Intro from '../components/DrumkitIntro';
import AuthService from './AuthService';

// Mock function for Office.auth.getAccessToken
const mockGetAccessToken = async () => {
  return 'mockAccessToken';
};

export default function Login(): React.JSX.Element {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const login = () => {
    setLoading(true);
    // Conditionally call getAccessToken based on environment
    const accessTokenPromise = isProd()
      ? Office.auth.getAccessToken({
          allowSignInPrompt: true,
          allowConsentPrompt: true,
        })
      : mockGetAccessToken();

    accessTokenPromise
      .then((value) => {
        return axios.post('/user/login/microsoft', {
          // Microsoft requires that the API URL for Vesta (app ID: dfe5d4cc-66cd-4299-afa7-ed8ab8fb9fb8)
          // matches the URL listed in Azure (vesta.axleapi.com), or Office.auth.getAccessToken() fails with code 13006.
          // So we use `devEmail`to support dev logins.
          ...(isProd()
            ? {}
            : { devEmail: Office.context.mailbox.userProfile.emailAddress }),
          accessToken: value,
        });
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          AuthService.setCurrentUser(res.data);
          navigate('/home');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 404) {
          navigate('/signup');
        } else {
          captureException(err, { functionName: 'login' });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className='h-screen grid place-items-center place-content-start'>
      <Intro />
      <div className='flex justify-center mb-4 mt-4'>
        <button
          type='button'
          onClick={login}
          className='bg-orange-main rounded-full shadow-md cursor-pointer py-2 px-8 text-grayscale-content hover:bg-orange-hover'
        >
          {loading ? 'Loading...' : 'Sign in to Drumkit'}
        </button>
      </div>
    </div>
  );
}
