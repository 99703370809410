enum Pageview {
  AppointmentScheduling = 'appointment_scheduling',
  CarrierQuote = 'carrier_quote',
  CheckCallNotes = 'check_call_notes',
  CheckCalls = 'check_calls',
  LoadBuilding = 'load_building',
  LoadInformation = 'load_information',
  OutboxEmails = 'outbox_emails',
  QuickQuote = 'quick_quote',
  TrackAndTraceExceptions = 'track_and_trace_exceptions',
  TruckList = 'truck_list',
}

export default Pageview;
