import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { TransportType } from 'lib/api/getQuickQuote';
import { LaneTier } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { Maybe } from 'types/UtilityTypes';
import { ApiError } from 'types/api/ApiError';
import { Quoting, TMS } from 'types/enums/Integrations';
import captureException from 'utils/captureException';

type LaneHistoryRequest = {
  quoteRequestId: number;
  destinationCity: string;
  destinationState: string;
  destinationZip: string;
  originCity: string;
  originState: string;
  originZip: string;
  transportType: string;
};

export type WeekLaneData = {
  week: string;
  averageRate: number;
  lowestRate: number;
  maxRate: number;
  quotes: number;
  lowestCarrierName: string;
  maxRateCarrierName: string;
};

export interface CalculatedQuote {
  avgDistance: number;
  countQuotes: number;

  avgCost: number;
  minCost: number;
  maxCost: number;

  avgRatePerMile: number;
  minRatePerMile: number;
  maxRatePerMile: number;
}

export type LaneHistoryResponse = {
  countUniqueSources: number;
  uniqueSources: (TMS | Quoting)[];
  resultsBySource: Record<TMS | Quoting, SourceHistory[]>;
};

export type SourceHistory = {
  source: TMS | Quoting;
  timeframe: string; // e.g. 28-day
  calculatedQuote: Maybe<CalculatedQuote>;
  laneTier: Maybe<LaneTier>;
  inputtedTransportType: TransportType;
  proxiedTransportType: TransportType;
  weeks: WeekLaneData[];
  isPercentile: boolean;
};

export async function getLaneHistory(
  request: LaneHistoryRequest
): Promise<Result<LaneHistoryResponse, ApiError>> {
  try {
    // TODO: Implement a retry on timeout
    const response = await axios.post<LaneHistoryResponse>(
      `quote/private/laneHistory`,
      request
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getLaneHistory' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get lane history' });
    }

    return err({
      message: error.response?.data.message || 'Failed to get lane history',
    });
  }
}
