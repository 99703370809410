import {
  FieldPath,
  UseFormGetValues,
  UseFormReturn,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

import { CarrierCardType } from 'components/CarrierCard';
import { RHFTextInput } from 'components/input/RHFTextInput';
import { TMSCore } from 'contexts/serviceContext';
import { SidebarState } from 'contexts/sidebarStateContext';
import { LaneHistoryResponse } from 'lib/api/getLaneHistory';
import {
  QuickQuoteInputs,
  QuickQuoteResponse,
  getQuickQuote,
} from 'lib/api/getQuickQuote';
import { Email } from 'types/Email';
import { TMSCustomer } from 'types/Load';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import { GenericSuggestion } from 'types/suggestions/CoreSuggestions';
import { GenericCompanySearchableFields } from 'utils/loadInfoAndBuilding';

export enum MarginType {
  // EX: If CarrierCostType = PerMile & MarginType = Amount, then margin is in $ per mile
  // EX: If CarrierCostType = Flat & MarginType = Amount, then margin is total $ value
  Amount = 'Amount',
  Percentage = 'Percentage',
}

export enum CarrierCostType {
  Flat = 'Flat',
  PerMile = 'PerMile',
}

export enum DATQuoteTimeframe {
  '3_DAYS' = '3 day',
  '7_DAYS' = '7 day',
  '15_DAYS' = '15 day',
  '30_DAYS' = '30 day',
  '60_DAYS' = '60 day',
  '90_DAYS' = '90 day',
  '180_DAYS' = '180 day',
  '365_DAYS' = '365 day',
  '1_MONTH' = '1 month',
}

export enum DATQuoteLocationType {
  '3_DIGIT_ZIP',
  'MARKET_AREA',
  'EXTENDED_MARKET_AREA',
  'STATE',
  'REGION',
  'COUNTRY',
}

// Generic Drumkit enums
export enum LaneTier {
  _5DigitZipLaneTier = '5-Digit-Zip',
  _3DigitZipLaneTier = '3-Digit-Zip',
  CityStateLaneTier = 'City-to-City',
  MarketLaneTier = 'Market-to-Market', // Not supported by Mcleod rn
  StateLaneTier = 'State-to-State',
}

export const laneTierMap: Record<LaneTier, string> = {
  [LaneTier._5DigitZipLaneTier]: '5-digit zip',
  [LaneTier._3DigitZipLaneTier]: '3-digit zip',
  [LaneTier.CityStateLaneTier]: 'City',
  [LaneTier.MarketLaneTier]: 'Market',
  [LaneTier.StateLaneTier]: 'State',
};

export type QuickQuoteTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<QuickQuoteInputs> };

export type SendGreenscreensQuoteProps = {
  email: Maybe<Email>;
  quote: QuickQuoteResponse;
  setGreenscreensQuoteID: React.Dispatch<React.SetStateAction<string>>;
};

export type SendUserQuoteProps = {
  email: Maybe<Email>;
  quote: Maybe<QuickQuoteResponse>;
  greenscreensQuoteID: string;
  carrierCost: number;
  margin: number;
  marginType: MarginType;
  finalPrice: number;
  draftResponse: string;
};

export type FetchQuoteNumberProps = {
  email: Maybe<Email>;
  setHasThirdPartyQuoteURLs: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<QuickQuoteInputs>;
};

export type DATQuoteMetadata = {
  timeframe: keyof typeof DATQuoteTimeframe;
  originName: string;
  originType: keyof typeof DATQuoteLocationType;
  destinationName: string;
  destinationType: keyof typeof DATQuoteLocationType;
};

export type FetchLaneRateFromServiceProps = {
  emailId: number;
  threadId: string;
  setCarrierCards: React.Dispatch<React.SetStateAction<CarrierCardType[]>>;
  updatedFormValues: QuickQuoteInputs;
};

export type FetchCustomersProps = {
  setInitialCustomers: React.Dispatch<
    React.SetStateAction<Maybe<TMSCustomer[]>>
  >;
  setCustomers: React.Dispatch<React.SetStateAction<Maybe<TMSCustomer[]>>>;
  setTMSTenant: React.Dispatch<React.SetStateAction<MaybeUndef<string>>>;
  tmsIntegrations: TMSCore[];
};

export type HandleRefreshCustomersProps = {
  setInitialCustomers: React.Dispatch<
    React.SetStateAction<Maybe<TMSCustomer[]>>
  >;
  setCustomers: React.Dispatch<React.SetStateAction<Maybe<TMSCustomer[]>>>;
  tmsIntegrations: TMSCore[];
};

export type HandleResetCustomerSearchProps = {
  setCustomers: React.Dispatch<React.SetStateAction<Maybe<TMSCustomer[]>>>;
  initialCustomers: Maybe<TMSCustomer[]>;
};

export type HandleCustomerSearchProps = {
  field: GenericCompanySearchableFields;
  value: string;
  tmsIntegrations: TMSCore[];
  customers: Maybe<TMSCustomer[]>;
  setCustomers: React.Dispatch<React.SetStateAction<Maybe<TMSCustomer[]>>>;
};

export type OnSubmitFormProps = {
  formValues: QuickQuoteInputs;
  getValues: UseFormGetValues<QuickQuoteInputs>;
  setIsSubmitToTMS: React.Dispatch<React.SetStateAction<boolean>>;
  setCreatedQuoteId: React.Dispatch<React.SetStateAction<MaybeUndef<number>>>;
  setQuote: React.Dispatch<React.SetStateAction<Maybe<QuickQuoteResponse>>>;
  setCarrierCards: React.Dispatch<React.SetStateAction<CarrierCardType[]>>;
  isQuoteSubmissionViaURLEnabled: boolean;
  email: Maybe<Email>;
  setHasThirdPartyQuoteURLs: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<QuickQuoteInputs>;
  isGetLaneRateFromServiceEnabled: boolean;
  clickedSuggestion: Maybe<GenericSuggestion>;
  formMethods: UseFormReturn<QuickQuoteInputs>;
  setQuoteNotConfident: React.Dispatch<React.SetStateAction<boolean>>;
  getQuickQuote: typeof getQuickQuote;
  isQuoteLaneHistoryEnabled: boolean;
  setIsLoadingLaneHistory: React.Dispatch<React.SetStateAction<boolean>>;
  setLaneHistory: React.Dispatch<
    React.SetStateAction<Maybe<LaneHistoryResponse>>
  >;
  setCarrierCost: React.Dispatch<React.SetStateAction<number>>;
  setMargin: React.Dispatch<React.SetStateAction<number>>;
  marginType: MarginType;
  setError: UseFormSetError<QuickQuoteInputs>;
};

export type HandleQuoteSubmissionViaURLProps = {
  email: Maybe<Email>;
  quote: Maybe<QuickQuoteResponse>;
  getValues: UseFormGetValues<QuickQuoteInputs>;
  setError: UseFormSetError<QuickQuoteInputs>;
  setLoadingDraftReply: React.Dispatch<React.SetStateAction<boolean>>;
  finalPrice: number;
  isTMSQuoteSubmissionEnabled: boolean;
  isSubmitToTMS: boolean;
  setCreatedQuoteId: React.Dispatch<React.SetStateAction<MaybeUndef<number>>>;
  clickedSuggestion: Maybe<GenericSuggestion>;
  setCurrentState: React.Dispatch<React.SetStateAction<SidebarState>>;
};

export interface HelperFunctions {
  toTitleCase: (str: string) => string;
  parseLocation: (location: string) => Maybe<{
    zip: string;
    city: string;
    state: string;
  }>;
  sendGreenscreensQuote: (props: SendGreenscreensQuoteProps) => Promise<void>;
  sendUserQuote: (props: SendUserQuoteProps) => Promise<void>;
  fetchQuoteNumber: (props: FetchQuoteNumberProps) => Promise<void>;
  fetchLaneRateFromService: (
    props: FetchLaneRateFromServiceProps
  ) => Promise<void>;
  fetchCustomers: (props: FetchCustomersProps) => Promise<void>;
  onSubmitForm: (props: OnSubmitFormProps) => Promise<void>;
  handleQuoteSubmissionViaURL: (
    props: HandleQuoteSubmissionViaURLProps
  ) => Promise<void>;
}
