import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import { AvailableTabs } from 'constants/SidebarTabs';
import { NormalizedLoad } from 'types/Load';
import { PendingCarrierEmails } from 'types/PendingOutboxEmail';

import CarrierEmails from './Outbox/CarrierEmails';

interface EmailsSectionProps {
  normalizedLoad: NormalizedLoad;
  carrierEmails: PendingCarrierEmails;
  setTab: React.Dispatch<React.SetStateAction<AvailableTabs>>;
}

export default function EmailsSection(props: EmailsSectionProps) {
  return (
    <Accordion
      type='single'
      defaultValue='carrierEmails'
      collapsible
      className='w-full'
    >
      <AccordionItem value='carrierEmails'>
        <AccordionTrigger className='px-5'>Carrier Emails</AccordionTrigger>
        <AccordionContent className='grid grid-cols-1 gap-2 w-full mx-0'>
          <CarrierEmails {...props} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
