import { getUser } from 'lib/api/getUser';
import { Maybe } from 'types/UtilityTypes';
import captureException from 'utils/captureException';
import getEmailAddress from 'utils/getEmailAddress';

export default async function getUserName(): Promise<Maybe<string>> {
  const emailAddress = await getEmailAddress();

  if (!emailAddress) {
    captureException(new Error('No email address found'), {
      functionName: 'getUserName',
    });
    return null;
  }

  const userResult = await getUser(emailAddress);

  if (!userResult.isOk()) {
    captureException(new Error('Error getting user'), {
      functionName: 'getUserName',
    });
    return null;
  }

  return userResult.value.name;
}
