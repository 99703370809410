import { Controller, UseFormReturn } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Select as AntdSelect } from 'antd';
import { BaseOptionType } from 'antd/es/select';

import { Label } from 'components/Label';
import { NormalizedLoad, Specifications, Unit } from 'types/Load';

import {
  LoadBuildingTextInput,
  RateType,
  devDisableRequiredFields,
} from '../McleodLoadBuildingForm';

export function SpecificationsForm({
  formMethods,
}: {
  formMethods: UseFormReturn<NormalizedLoad>;
}) {
  const {
    control,
    formState: { errors },
    watch,
  } = formMethods;
  // TODO use tms.GetLoadBuildingAttributes

  const modes = ['LTL', 'Truckoad'];

  const specs: Specifications = {
    totalWeight: { unit: Unit.Pounds },
    totalDistance: { unit: Unit.Miles },
    totalPieces: { unit: null, val: 0 },
  } as Specifications;

  const isWeightSupported = true;
  const isPiecesSupported = true;

  const isDistanceSupported = false;
  const isPalletSupported = false;

  const watchedCustomerRateType = watch('rateData.customerRateType');

  return (
    <div className='grid grid-cols-2 gap-2 mx-0 w-full'>
      {/* Only Truckloads supported rn, not LTL */}
      <div className='col-span-2'>
        <Label hideAIHint={true} name={'mode'}>
          Mode
        </Label>
        {/* <div className='flex flex-row w-full items-center gap-2'> */}
        <Controller
          name='mode'
          control={control}
          rules={{ required: devDisableRequiredFields ? false : 'Required' }}
          render={({ field }) => (
            <div className=' text-grayscale-content-2'>
              <AntdSelect
                showSearch
                disabled={true} // Only Truckloads supported rn, not LTL
                className='h-9 text-grayscale-content-2'
                placeholder={'Choose'}
                optionFilterProp='children'
                filterOption={(
                  input: string,
                  option: BaseOptionType | undefined
                ) =>
                  (option?.label.toLocaleLowerCase() ?? '').includes(
                    input.toLocaleLowerCase()
                  )
                }
                filterSort={(
                  optionA: BaseOptionType,
                  optionB: BaseOptionType
                ) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={field.onChange}
                value={field.value}
                options={modes?.map((mode) => ({
                  value: mode,
                  label: mode,
                }))}
              />
            </div>
          )}
        />
        {/* </div> */}
        <ErrorMessage
          errors={errors}
          name={'mode'}
          render={({ message }: { message: string }) => (
            <p className='text-red-500 text-xs'>{message}</p>
          )}
        />
      </div>

      <div className='col-span-2'>
        <Label name={'specifications.transportType'}>Transport Type</Label>
        <Controller
          name='specifications.transportType'
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <AntdSelect
              showSearch
              className='h-9 text-grayscale-content-2'
              placeholder={'Choose'}
              optionFilterProp='children'
              filterOption={(
                input: string,
                option: BaseOptionType | undefined
              ) =>
                (option?.label.toLocaleLowerCase() ?? '').includes(
                  input.toLocaleLowerCase()
                )
              }
              filterSort={(optionA: BaseOptionType, optionB: BaseOptionType) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={field.onChange}
              value={field.value}
              options={transportTypeOptions?.map((type) => ({
                value: type.label,
                label: type.label,
              }))}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={'specifications.transportType'}
          render={({ message }: { message: string }) => (
            <p className='text-red-500 text-xs'>{message}</p>
          )}
        />
      </div>

      <div className='col-span-2'>
        <LoadBuildingTextInput
          name='specifications.commodities'
          label='Commodities'
        />
      </div>

      {isWeightSupported && (
        <div
          className={
            isWeightSupported && !isDistanceSupported
              ? 'col-span-2'
              : 'col-span-1'
          }
        >
          <LoadBuildingTextInput
            name='specifications.totalWeight.val'
            label={`Weight${specs.totalWeight?.unit ? ' (' + specs.totalWeight?.unit + ')' : ''}`}
            inputType='number'
            options={{
              valueAsNumber: true,
              required:
                watchedCustomerRateType === RateType.CWT ||
                watchedCustomerRateType === RateType.Tons
                  ? 'Required for weight-based rates'
                  : undefined,
            }}
            required={
              watchedCustomerRateType === RateType.CWT ||
              watchedCustomerRateType === RateType.Tons
            }
          />
        </div>
      )}

      {isDistanceSupported && (
        <div
          className={
            isDistanceSupported && !isWeightSupported
              ? 'col-span-2'
              : 'col-span-1'
          }
        >
          <LoadBuildingTextInput
            name='specifications.totalDistance.val'
            label={`Distance${specs.totalDistance?.unit ? ' (' + specs.totalDistance?.unit + ')' : ''}`}
            inputType='number'
            options={{ valueAsNumber: true }}
          />
        </div>
      )}

      {isPalletSupported && (
        <div
          className={
            isPalletSupported && !isPiecesSupported
              ? 'col-span-2'
              : 'col-span-1'
          }
        >
          <LoadBuildingTextInput
            name='specifications.totalOutPalletCount'
            label='Pallets'
            inputType='number'
            options={{ valueAsNumber: true }}
          />
        </div>
      )}

      {isPiecesSupported && (
        <div
          className={
            isPiecesSupported && !isPalletSupported
              ? 'col-span-2'
              : 'col-span-1'
          }
        >
          <LoadBuildingTextInput
            name='specifications.totalPieces.val'
            label={`Pieces${specs.totalPieces?.unit ? ' (' + specs.totalPieces?.unit + ')' : ''}`}
            inputType='number'
            options={{ valueAsNumber: true }}
          />
        </div>
      )}
    </div>
  );
}

export const transportTypeOptions = [
  { value: '26B', label: "26'" },
  { value: '53F', label: '53ft Flatbed' },
  { value: 'AC', label: 'Auto Carrier' },
  { value: 'B', label: 'Beam Trailer' },
  { value: 'CN', label: 'Conestoga (DAT)' },
  { value: 'C', label: 'Container' },
  { value: 'CR', label: 'Container Refrigerated (DAT)' },
  { value: 'CI', label: 'Container, Insulated (DAT)' },
  { value: 'CV', label: 'Conveyor (DAT)' },
  { value: 'DD', label: 'Double Drop (DAT)' },
  { value: 'DDT', label: 'Drop Deck Trailers (DAT)' },
  { value: 'DL', label: 'Drop Deck, Landoll (DAT)' },
  { value: 'DT', label: 'Dump Trailer (DAT)' },
  { value: 'FR', label: 'Flat/Van/Reefer (DAT)' },
  { value: 'F', label: 'Flatbed (DAT)' },
  { value: 'FA', label: 'Flatbed Airride (DAT)' },
  { value: 'BT', label: 'Flatbed B-Train (DAT)' },
  { value: 'FN', label: 'Flatbed Conestoga (DAT)' },
  { value: 'FZ', label: 'Flatbed Hazmat (DAT)' },
  { value: 'FH', label: 'Flatbed Hotshot (DAT)' },
  { value: 'MX', label: 'Flatbed Maxi (DAT)' },
  { value: 'FMO', label: 'Flatbed Moffett (DAT)' },
  { value: 'FD', label: 'Flatbed or Step Deck (DAT)' },
  { value: 'FO', label: 'Flatbed Over-dimension (DAT)' },
  { value: 'FTE', label: 'Flatbed Tanker Endorsed (DAT)' },
  { value: 'FM', label: 'Flatbed w/ Team (DAT)' },
  { value: 'FT', label: 'Flatbed w/Tarps (DAT)' },
  { value: 'FC', label: 'Flatbed with Chains (DAT)' },
  { value: 'LOG', label: 'Forestry Trailers' },
  { value: 'HB', label: 'Hopper Bottom (DAT)' },
  { value: 'IR', label: 'Insulated Van or Reefer (DAT)' },
  { value: 'LB', label: 'Lowboy (DAT)' },
  { value: 'LR', label: 'Lowboy or RGN (DAT)' },
  { value: 'LO', label: 'Lowboy Overdimension (DAT)' },
  { value: 'MV', label: 'Moving Van (DAT)' },
  { value: 'O', label: 'Other' },
  { value: 'NU', label: 'Pneumatic (DAT)' },
  { value: 'PO', label: 'Power Only (DAT)' },
  { value: 'R', label: 'Reefer (DAT)' },
  { value: 'RA', label: 'Reefer Airride (DAT)' },
  { value: 'R2', label: 'Reefer Doubles (DAT)' },
  { value: 'RZ', label: 'Reefer Hazmat (DAT)' },
  { value: 'RI', label: 'Reefer Intermodal (DAT)' },
  { value: 'RN', label: 'Reefer Logistics (DAT)' },
  { value: 'RV', label: 'Reefer or Vented Van (DAT)' },
  { value: 'RP', label: 'Reefer Pallet Exchange (DAT)' },
  { value: 'RTE', label: 'Reefer Tanker Endorsed (DAT)' },
  { value: 'RTEH', label: 'Reefer Tanker Endorsed Hazmat (DAT)' },
  { value: 'RM', label: 'Reefer w/ Team (DAT)' },
  { value: 'RG', label: 'Removable Gooseneck (DAT)' },
  { value: 'SPR', label: 'Sprinter' },
  { value: 'SD', label: 'Step Deck (DAT)' },
  { value: 'SR', label: 'Step Deck or RGN (DAT)' },
  { value: 'SN', label: 'Stepdeck Conestoga (DAT)' },
  { value: 'SB', label: 'Straight Box Truck' },
  { value: 'SBT', label: 'Straight Box Trucks' },
  { value: 'ST', label: 'Stretch Trailer (DAT)' },
  { value: 'TA', label: 'Tanker Aluminum (DAT)' },
  { value: 'TN', label: 'Tanker Intermodal (DAT)' },
  { value: 'TS', label: 'Tanker Steel (DAT)' },
  { value: 'TT', label: 'Truck and Trailer (DAT)' },
  { value: 'V', label: 'Van (DAT)' },
  { value: 'VA', label: 'Van Airride (DAT)' },
  { value: 'VW', label: 'Van Blanket Wrap' },
  { value: 'VS', label: 'Van Conestoga (DAT)' },
  { value: 'VC', label: 'Van Curtain (DAT)' },
  { value: 'V2', label: 'Van Double (DAT)' },
  { value: 'VZ', label: 'Van Hazmat (DAT)' },
  { value: 'VH', label: 'Van Hotshot (DAT)' },
  { value: 'VI', label: 'Van Insulated (DAT)' },
  { value: 'VN', label: 'Van Intermodal (DAT)' },
  { value: 'VG', label: 'Van Lift-Gate (DAT)' },
  { value: 'VL', label: 'Van Logistics (DAT)' },
  { value: 'OT', label: 'Van Opentop (DAT)' },
  { value: 'VF', label: 'Van or Flatbed (DAT)' },
  { value: 'VT', label: 'Van or Flats w/Tarps (DAT)' },
  { value: 'VR', label: 'Van or Reefer (DAT)' },
  { value: 'VP', label: 'Van Pallet Exchange (DAT)' },
  { value: 'VB', label: 'Van Roller Bed (DAT)' },
  { value: 'VTE', label: 'Van Tanker Endorsed (DAT)' },
  { value: 'VTEH', label: 'Van Tanker Endorsed Hazmat (DAT)' },
  { value: 'VV', label: 'Van Vented (DAT)' },
  { value: 'VM', label: 'Van w/ Team (DAT)' },
];
