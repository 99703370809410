import { createContext } from 'react';

import { Quoting, TMS } from 'types/enums/Integrations';

export type Service = {
  serviceID: number;
  tmsIntegrations: IntegrationCore[];
  quotingIntegrations: IntegrationCore[];
  serviceFeaturesEnabled: ServiceFeaturesListType;
};

export type IntegrationCore = {
  id: number;
  name: TMS | Quoting;
};

export type ServiceFeaturesListType = {
  isLoadViewEnabled: boolean; // Load View
  isQuoteViewEnabled: boolean;

  // Load View Tabs (Load Info is on by default)
  isAppointmentSchedulingEnabled: boolean;
  isTrackAndTraceEnabled: boolean;
  isCarrierVerificationEnabled: boolean;

  // Quote View Tabs
  isQuickQuoteEnabled: boolean;
  isCarrierNetworkQuotingEnabled: boolean;
  isLoadBuildingEnabled: boolean;
  isTruckListEnabled: boolean;

  // Load View - Load Info Tab sub-sections
  // Allows user to edit TMS object on Turvo instead of via Drumkit
  isTurvoSectionLinksEnabled: boolean;
  // Allows user to assign operator to load on Drumkit; TMS integration must implement this
  isOperatorEnabled: boolean;

  // Track & Trace sub-sections / sub-features
  isCheckCallNotesEnabled: boolean;
  isExceptionsEnabled: boolean;
  isCheckCallCarrierSOPEnabled: boolean;
  isCheckCallShipperSOPEnabled: boolean;
  isCarrierEmailOutboxEnabled: boolean;

  // isCheckCallSuggestionsEnabled: boolean;
  isCarrierInfoSuggestionsEnabled: boolean;

  // Appointment Scheduling sub-sections / sub-features
  isAppointmentEmailingEnabled: boolean;
  isAppointmentSuggestionsEnabled: boolean;

  // Quick Quote sub-sections / sub-features
  // Allow users to submit quotes to third-party URLs, e.g. bidding websites
  isQuoteSubmissionViaURLEnabled: boolean;
  // Allows submitting quote to service's internal system (not the same as TMS)
  isQuoteSubmissionToServiceEnabled: boolean;
  isGetLaneRateFromServiceEnabled: boolean;
  // Allows access to quoting tools lane history (e.g. Greenscreens)
  isQuoteLaneHistoryEnabled: boolean;
  // Allows access to TMS lane history (e.g. McLeod)
  isTMSLaneHistoryEnabled: boolean;
  // Allow user to submit quote to TMS (TMS integration must implement CreateQuote)
  isTMSQuoteSubmissionEnabled: boolean;
  // Allow user to toggle fuel type on QQ Calculator (e.g. DOE vs DAT rates)
  isFuelTypeToggleEnabled: boolean;

  isAdvancedSearchEnabled: boolean;
};

export const allEnabledServiceFeatures: ServiceFeaturesListType = {
  isAppointmentSchedulingEnabled: true,
  isAppointmentEmailingEnabled: true,
  isTrackAndTraceEnabled: true,
  isQuickQuoteEnabled: true,
  isAppointmentSuggestionsEnabled: true,
  isCarrierInfoSuggestionsEnabled: true,
  isTurvoSectionLinksEnabled: true,
  isOperatorEnabled: true,
  isExceptionsEnabled: true,
  isCheckCallCarrierSOPEnabled: true,
  isCheckCallShipperSOPEnabled: true,
  isCheckCallNotesEnabled: true,
  isCarrierNetworkQuotingEnabled: true,
  isCarrierEmailOutboxEnabled: true,
  isAdvancedSearchEnabled: true,
  isCarrierVerificationEnabled: true,
  isTruckListEnabled: true,
  isTMSQuoteSubmissionEnabled: true,
  isLoadBuildingEnabled: true,
  isQuoteLaneHistoryEnabled: true,
  isTMSLaneHistoryEnabled: true,
  isQuoteSubmissionViaURLEnabled: true,
  isQuoteSubmissionToServiceEnabled: true,
  isGetLaneRateFromServiceEnabled: true,
  isLoadViewEnabled: true,
  isQuoteViewEnabled: true,
  isFuelTypeToggleEnabled: true,
};

export const initialServiceFeatureValues: ServiceFeaturesListType = {
  isAppointmentSchedulingEnabled: false,
  isAppointmentEmailingEnabled: false,
  isTrackAndTraceEnabled: false,
  isQuickQuoteEnabled: false,
  isAppointmentSuggestionsEnabled: false,
  isCarrierInfoSuggestionsEnabled: false,
  isTurvoSectionLinksEnabled: false,
  isOperatorEnabled: false,
  isExceptionsEnabled: false,
  isCheckCallCarrierSOPEnabled: false,
  isCheckCallShipperSOPEnabled: false,
  isCheckCallNotesEnabled: false,
  isCarrierNetworkQuotingEnabled: false,
  isCarrierEmailOutboxEnabled: false,
  isAdvancedSearchEnabled: false,
  isCarrierVerificationEnabled: false,
  isTruckListEnabled: false,
  isTMSQuoteSubmissionEnabled: false,
  isLoadBuildingEnabled: false,
  isQuoteLaneHistoryEnabled: false,
  isTMSLaneHistoryEnabled: false,
  isQuoteSubmissionViaURLEnabled: false,
  isQuoteSubmissionToServiceEnabled: false,
  isGetLaneRateFromServiceEnabled: false,
  isLoadViewEnabled: false,
  isQuoteViewEnabled: false,
  isFuelTypeToggleEnabled: false,
};

export const initialServiceValues: Service = {
  serviceID: 0,
  tmsIntegrations: [],
  quotingIntegrations: [],
  serviceFeaturesEnabled: initialServiceFeatureValues,
};

export type ServiceContextType = Service & {
  setService: React.Dispatch<React.SetStateAction<Service>>;
};

export const ServiceContext = createContext<ServiceContextType>({
  serviceID: 0,
  tmsIntegrations: [],
  quotingIntegrations: [],
  serviceFeaturesEnabled: initialServiceFeatureValues,
  setService: () => false,
});
