// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import APP_VERSION from '@constants/AppVersion';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { getSentry } from '@utils/sentry/getSentry';

import getEmailAddress from 'utils/getEmailAddress';

import hasValidUserAuth from './hasValidUserAuth';

export default async function captureException(
  error: unknown,
  tags?: { [key: string]: any }
) {
  let email = '';
  const isUserAuthValid = await hasValidUserAuth();

  if (isUserAuthValid) {
    email = (await getEmailAddress()) ?? '';
  }

  const sentryScope = getSentry();

  if (email) {
    sentryScope.setUser({ email });
  }

  if (tags) {
    Object.entries(tags).forEach(([key, value]) => {
      sentryScope.setTag(key, value);
    });
  }

  if (!isProd()) {
    console.error(error);
  }
  sentryScope.setTag('version', APP_VERSION);
  sentryScope.captureException(error);
}
