import { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Button } from 'components/Button';
import { ServiceContext } from 'contexts/serviceContext';
import useFetchRecentWarehouses from 'hooks/useFetchRecentWarehouses';
import { useToast } from 'hooks/useToaster';
import PortalBaseForm from 'pages/LoadView/AppointmentScheduling/SchedulingPortalBaseForm';
import { StopTypes } from 'types/Appointment';
import { NormalizedLoad } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';

import EmailAppointmentForm from './EmailAppointment/AppointmentEmailForm';

dayjs.extend(utc);
dayjs.extend(timezone);

type AppointmentEditorProps = {
  normalizedLoad: NormalizedLoad;
  loadPickupWarehouse: Maybe<Warehouse>;
  loadDropoffWarehouse: Maybe<Warehouse>;
};

export function AppointmentEditor({
  normalizedLoad: load,
  loadPickupWarehouse,
  loadDropoffWarehouse,
}: AppointmentEditorProps) {
  const { toast } = useToast();
  const {
    warehouses: recentWarehouses,
    isLoading,
    error,
  } = useFetchRecentWarehouses();

  const {
    serviceFeaturesEnabled: { isAppointmentEmailingEnabled },
  } = useContext(ServiceContext);
  // As of now, having portal enabled is the default.
  // TODO: Cache default form state in local storage
  const [showSchedulingPortalForm, setShowSchedulingPortalForm] = useState(
    !isAppointmentEmailingEnabled
  );
  const [type, setType] = useState<StopTypes>(StopTypes.Pickup);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (error && showSchedulingPortalForm) {
      toast({
        description: 'Error fetching warehouses.',
        variant: 'destructive',
      });
    }
  }, [isLoading, error, showSchedulingPortalForm]);

  useEffect(() => {
    if (!isAppointmentEmailingEnabled) {
      setShowSchedulingPortalForm(true);
      return;
    }

    switch (type) {
      case StopTypes.Pickup:
        setShowSchedulingPortalForm(!!loadPickupWarehouse);
        break;
      case StopTypes.Dropoff:
        setShowSchedulingPortalForm(!!loadDropoffWarehouse);
        break;
    }
  }, [type]);

  const toggleSchedulingPortal = () => {
    // If only portal enabled, don't allow toggling
    if (!isAppointmentEmailingEnabled) {
      return;
    }
    setShowSchedulingPortalForm((prevState) => !prevState);
  };

  return (
    <div className='flex-1 shrink-0 mt-2'>
      <div className='flex justify-between gap-2 items-center mb-6'>
        <Button
          className='w-full h-8 text-[14px]'
          onClick={() => setType(StopTypes.Pickup)}
          variant={StopTypes.Pickup === type ? 'default' : 'outline'}
          type='button'
        >
          Pickup
        </Button>
        <Button
          className='w-full h-8 text-[14px]'
          onClick={() => setType(StopTypes.Dropoff)}
          variant={StopTypes.Dropoff === type ? 'default' : 'outline'}
          type='button'
        >
          Dropoff
        </Button>
      </div>

      {type === StopTypes.Pickup && (
        <>
          {showSchedulingPortalForm ? (
            <PortalBaseForm
              type={StopTypes.Pickup}
              load={load}
              recentWarehouses={recentWarehouses}
              toggleSchedulingPortal={toggleSchedulingPortal}
              suggestedWarehouse={loadPickupWarehouse}
            />
          ) : isAppointmentEmailingEnabled ? (
            <EmailAppointmentForm
              normalizedLoad={load}
              type={StopTypes.Pickup}
              toggleSchedulingPortal={toggleSchedulingPortal}
            />
          ) : null}
        </>
      )}

      {type === StopTypes.Dropoff && (
        <>
          {showSchedulingPortalForm ? (
            <PortalBaseForm
              type={StopTypes.Dropoff}
              load={load}
              recentWarehouses={recentWarehouses}
              toggleSchedulingPortal={toggleSchedulingPortal}
              suggestedWarehouse={loadDropoffWarehouse}
            />
          ) : isAppointmentEmailingEnabled ? (
            <EmailAppointmentForm
              normalizedLoad={load}
              type={StopTypes.Dropoff}
              toggleSchedulingPortal={toggleSchedulingPortal}
            />
          ) : null}
        </>
      )}
    </div>
  );
}
