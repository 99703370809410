import { createQuickQuoteReplyDraft } from '@src/alexandria/lib/api/createQuickQuoteReplyDraft';
import {
  DraftReplyProps,
  MailClientInterface,
} from '@src/alexandria/lib/mailclient/interface';

const handleReplyDraftCreation = async ({
  threadItemId,
  draftBody,
}: DraftReplyProps) => {
  const result = await createQuickQuoteReplyDraft({ threadItemId, draftBody });

  if (result.isErr()) {
    throw result.error;
  }
};

export class Client implements MailClientInterface {
  draftReply({ threadItemId, draftBody }: DraftReplyProps): Promise<void> {
    return new Promise((resolve, reject) => {
      Office.onReady((info: any) => {
        if (info.host !== Office.HostType.Outlook) {
          reject(
            new Error(
              `Office host is ${info.host}, not ${Office.HostType.Outlook} as expected`
            )
          );
          return;
        }
      });

      const item = Office.context.mailbox.item;
      if (!item) {
        reject(new Error(`Compose item is falsy (${item})`));
        return;
      }

      // If we're not in reply view, create reply draft on the backend
      if (!item.body.getTypeAsync) {
        handleReplyDraftCreation({ threadItemId, draftBody })
          .then(() => resolve())
          .catch(reject);
        return;
      }

      item.body.getTypeAsync((asyncResult: any) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          reject(asyncResult.error);
          return;
        }

        // Set the message body in HTML; Outlook client automatically populates signature.
        item.body.prependAsync(
          draftBody,
          { coercionType: Office.CoercionType.Html },
          (setResult: any) => {
            if (setResult.status === Office.AsyncResultStatus.Failed) {
              reject(setResult.error);
              return;
            }
            resolve();
          }
        );
      });
    });
  }
}
