import { useState } from 'react';

import { RefreshCw } from 'lucide-react';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Tooltip';
import ButtonLoader from 'components/loading/ButtonLoader';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { getLocations } from 'lib/api/getLocations';

export default function RefreshCarrierLocationsButton() {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { toast } = useToast();
  const { tmsIntegrations } = useServiceFeatures();

  const handleRefresh = async (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission/validation if used inside form
    setIsRefreshing(true);

    const res = await getLocations(tmsIntegrations[0]?.id ?? 0, true, true);
    if (res.isOk()) {
      toast({
        description: 'Successfully refreshed locations.',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message || 'Failed to refresh locations.',
        variant: 'destructive',
      });
    }

    setIsRefreshing(false);
  };

  return (
    <Tooltip delayDuration={10}>
      <TooltipTrigger asChild>
        <button onClick={handleRefresh} disabled={isRefreshing} type='button'>
          {isRefreshing ? (
            <ButtonLoader />
          ) : (
            <RefreshCw className='h-4 w-4 mr-2 text-orange-main hover:text-orange-hover' />
          )}
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <p className='text-sm text-gray-700'>Refresh carrier locations</p>
      </TooltipContent>
    </Tooltip>
  );
}
