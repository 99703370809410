import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { BaseOptionType } from 'antd/es/select';
import { RefreshCwIcon, XCircleIcon } from 'lucide-react';

import { DebounceSelect, ValueType } from 'components/DebounceSelect';
import { Label } from 'components/Label';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { getOperators } from 'lib/api/getOperators';
import { searchOperators } from 'lib/api/searchOperators';
import { NormalizedLoad } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';

export function OperatorSectionForm({
  formMethods,
}: {
  formMethods: UseFormReturn<NormalizedLoad>;
}) {
  const {
    control,
    formState: { errors },
  } = formMethods;

  const { tmsIntegrations } = useServiceFeatures();
  const { toast } = useToast();
  const [isLoadingOperators, setIsloadingOperators] = useState(true);
  const [operators, setOperators] = useState<Maybe<string[]>>(null);

  const fetchOperators = async () => {
    setIsloadingOperators(true);

    const res = await getOperators(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setOperators(res.value);
    } else {
      toast({
        description: 'Error while fetching operators list.',
        variant: 'destructive',
      });
    }
    setIsloadingOperators(false);
  };

  const handleRefreshOperators = async () => {
    setIsloadingOperators(true);

    const res = await getOperators(tmsIntegrations?.[0]?.id, true);
    if (res.isOk()) {
      setOperators(res.value);
      toast({
        description: 'Successfully refreshed operators list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing operators list.',
        variant: 'destructive',
      });
    }
    setIsloadingOperators(false);
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  const handleOperatorSearch = async (search: string) => {
    if (search.length > 3) {
      const searchRes = await searchOperators(tmsIntegrations?.[0]?.id, search);

      if (searchRes.isOk()) {
        const searchedOperators = searchRes.value;
        setOperators(searchedOperators);

        return searchedOperators && searchedOperators.length
          ? mapOperatorsToOptions(searchedOperators)
          : [];
      }
    }

    /**
     * Searches that are less than 3 characters dont provide enough context to search amongst
     * all operators. In that case, apply that filter to the entries that are already loaded.
     */
    return mapOperatorsToOptions(operators ?? []).filter((operator) =>
      operator.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  };

  const mapOperatorsToOptions = (operators: Maybe<string[]>): ValueType[] => {
    if (!operators) return [];

    return operators.map((option: string) => ({
      value: option,
      name: option,
      label: option,
    }));
  };

  return (
    <div>
      <Label name={'operator'}>Name</Label>
      <Controller
        name='operator'
        control={control}
        rules={{ required: false }} // Not required, hence why X button is shown, unlike locations and customers which are required
        render={({ field }) => (
          <div className='flex flex-row items-center gap-2 text-grayscale-content-input'>
            <div className='w-[90%] text-grayscale-content-input'>
              <DebounceSelect
                showSearch
                disabled={isLoadingOperators}
                className='h-9 text-grayscale-content-input'
                placeholder={isLoadingOperators ? 'Loading...' : 'Search'}
                optionFilterProp='children'
                fetchOptions={handleOperatorSearch}
                filterSort={(
                  optionA: BaseOptionType,
                  optionB: BaseOptionType
                ) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onSelect={(value) => {
                  field.onChange(value.value);
                }}
                value={
                  field.value
                    ? mapOperatorsToOptions([field.value])[0]
                    : undefined
                }
                options={mapOperatorsToOptions(operators)}
                notFoundContent={
                  <p>No results found. Try a search more than 3 characters.</p>
                }
              />
            </div>

            <button
              title='Refresh operators'
              name='Refresh operators'
              onClick={handleRefreshOperators}
              type='button'
            >
              <RefreshCwIcon className='h-4 w-4 cursor-pointer stroke-grayscale-icon-stroke' />
            </button>

            <button
              title='Clear operator'
              onClick={() => field.onChange(null)}
              className='h-9 flex items-center justify-center'
              type='button'
            >
              <XCircleIcon className='w-4 h-4' />
            </button>
          </div>
        )}
      />

      <ErrorMessage
        errors={errors}
        name={'operator'}
        render={({ message }: { message: string }) => (
          <p className='text-red-500 text-xs'>{message}</p>
        )}
      />
    </div>
  );
}
