import { Maybe } from 'types/UtilityTypes';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { LoadBuildingChanges } from 'types/suggestions/LoadBuildingSuggestions';
import {
  CheckCallChanges,
  LoadChanges,
} from 'types/suggestions/LoadSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';
import { TruckListChanges } from 'types/suggestions/TruckListSuggestions';

// Formatting suggested field to its readable name for carousel summary
export const getSuggestionFormattedLabel = (
  pipeline: SuggestionPipelines,
  changeName: string
): Maybe<string> => {
  let label: Maybe<string> = null;

  switch (pipeline) {
    case SuggestionPipelines.CarrierInfo:
    case SuggestionPipelines.ApptConfirmation: {
      label = loadInfoChangeLabelMap[changeName as keyof LoadChanges];
      break;
    }

    case SuggestionPipelines.CheckCall: {
      label = checkCallChangeLabelMap[changeName as keyof CheckCallChanges];
      break;
    }

    case SuggestionPipelines.QuickQuote:
      label = quoteChangeLabelMap[changeName as keyof QuoteChanges];
      break;

    case SuggestionPipelines.TruckList:
      label = truckListChangeLabelMap[changeName as keyof TruckListChanges];
      break;

    case SuggestionPipelines.LoadBuilding: {
      label =
        loadBuildingChangeLabelMap[changeName as keyof LoadBuildingChanges];
      break;
    }

    default:
      return null;
  }

  return label ? `${label}: ` : null;
};

const loadInfoChangeLabelMap: Record<keyof LoadChanges, string> = {
  pickupApptTime: 'Pickup Appt',
  dropoffApptTime: 'Dropoff Appt',
  firstDriverName: 'First Driver Name',
  firstDriverPhone: 'First Driver Phone',
  secondDriverName: 'Second Driver Name',
  secondDriverPhone: 'Second Driver Phone',
  truckNumber: 'Truck #',
  trailerNumber: 'Trailer #',
  dispatchSource: 'Dispatch Source',
  dispatchCity: 'Dispatch City',
  dispatchState: 'Dispatch State',
  dispatchedTime: 'Dispatched Time',
  expectedPickupTime: 'Expected Pickup Time',
};

const checkCallChangeLabelMap: Record<keyof CheckCallChanges, string> = {
  status: 'Status',
  timestamp: 'Time',
  city: 'City',
  state: 'State',
  notes: 'Notes',
};

const quoteChangeLabelMap: Record<keyof QuoteChanges, string> = {
  transportType: 'Transport Type',
  pickupCity: 'Pickup City',
  pickupState: 'Pickup State',
  pickupZip: 'Pickup Zip',
  pickupDate: 'Pickup Date',
  deliveryCity: 'Delivery City',
  deliveryState: 'Delivery State',
  deliveryZip: 'Delivery Zip',
  deliveryDate: 'Delivery Date',
};

const truckListChangeLabelMap: Record<keyof TruckListChanges, string> = {
  trucks: 'Truck Count',
  serviceName: 'Service Name',
  'carrier.name': 'Carrier Name',
  'carrier.contactEmail': 'Contact Email',
  'carrier.contactName': 'Contact Name',
  'carrier.mc': 'Contact MC #',
  'carrier.dot': 'Contact Dot #',
};

// Map of fields displayed in the form and their human-readable labels
const loadBuildingChangeLabelMap: Record<keyof LoadBuildingChanges, string> = {
  mode: 'Mode',
  // notes: 'Notes',
  transportType: 'Transport Type',
  'consignee.addressLine1': 'Consignee Address Line 1',
  'consignee.addressLine2': 'Consignee Address Line 2',
  'consignee.apptStartTime': 'Consignee Appointment Start Time',
  'consignee.apptEndTime': 'Consignee Appointment End Time',
  'consignee.apptNote': 'Consignee Appointment Note',
  'consignee.apptType': 'Consignee Appointment Type',
  'consignee.city': 'Consignee City',
  'consignee.contact': 'Consignee Contact',
  'consignee.country': 'Consignee Country',
  'consignee.email': 'Consignee Email',
  // 'consignee.mustDeliver': 'Must Deliver By',
  'consignee.name': 'Consignee Name',
  'consignee.phone': 'Consignee Phone',
  'consignee.refNumber': 'Consignee Ref #',
  'consignee.state': 'Consignee State',
  'consignee.zipCode': 'Consignee Zip Code',
  'customer.addressLine1': 'Customer Address Line 1',
  'customer.addressLine2': 'Customer Address Line 2',
  'customer.city': 'Customer City',
  'customer.contact': 'Customer Contact',
  'customer.country': 'Customer Country',
  'customer.email': 'Customer Email',
  // 'customer.externalTMSID': 'Customer External TMS ID',
  'customer.name': 'Customer Name',
  'customer.phone': 'Customer Phone',
  'customer.refNumber': 'Customer Ref #',
  'customer.state': 'Customer State',
  'customer.zipCode': 'Customer Zip Code',
  'pickup.addressLine1': 'Pickup Address Line 1',
  'pickup.addressLine2': 'Pickup Address Line 2',
  'pickup.apptEndTime': 'Pickup Appointment End Time',
  'pickup.apptNote': 'Pickup Appointment Note',
  'pickup.apptStartTime': 'Pickup Appointment Start Time',
  'pickup.apptType': 'Pickup Appointment Type',
  // 'pickup.businessHours': 'Pickup Business Hours',
  'pickup.city': 'Pickup City',
  'pickup.contact': 'Pickup Contact',
  'pickup.country': 'Pickup Country',
  'pickup.email': 'Pickup Email',
  'pickup.name': 'Pickup Name',
  'pickup.phone': 'Pickup Phone',
  'pickup.readyTime': 'Pickup Ready Time',
  'pickup.refNumber': 'Pickup Ref #',
  'pickup.state': 'Pickup State',
  'pickup.zipCode': 'Pickup Zip Code',

  'rateData.carrierRateNumUnits': 'Carrier Rate Units',
  'rateData.carrierLineHaulRate': 'Carrier Linehaul Rate',
  'rateData.carrierLineHaulCharge': 'Carrier Linehaul Charge',
  'rateData.carrierRateType': 'Carrier Rate Type',

  'rateData.customerRateNumUnits': 'Customer Rate Units',
  'rateData.customerLineHaulRate': 'Customer Linehaul Rate',
  'rateData.customerRateType': 'Customer Rate Type',
  'rateData.customerLineHaulCharge': 'Customer Linehaul Charge',

  'specifications.commodities': 'Commodities',
  'specifications.totalOutPalletCount': 'Total Out Pallet Count',
  'specifications.totalPieces.value': 'Total Pieces Value',
  'specifications.totalWeight.value': 'Total Weight Value',

  // Not displayed in the form for now
  // 'rateData.fscPerMile': 'Fuel Surcharge Per Mile',
  // 'rateData.fscPercent': 'Fuel Surcharge Percent',
  // 'rateData.netProfitUSD': 'Net Profit USD',
  // 'rateData.profitPercent': 'Profit Percent',
  // 'specifications.customBonded': 'Custom Bonded',
  // 'specifications.escorts': 'Escorts',
  // 'specifications.hazmat': 'Hazmat',
  // 'specifications.insideDelivery': 'Inside Delivery',
  // 'specifications.insidePickup': 'Inside Pickup',
  // 'specifications.isRefrigerated': 'Is Refrigerated',
  // 'specifications.labor': 'Labor',
  // 'specifications.liftgateDelivery': 'Liftgate Delivery',
  // 'specifications.liftgatePickup': 'Liftgate Pickup',
  // 'specifications.maxTempFahrenheit': 'Maximum Temperature (°F)',
  // 'specifications.minTempFahrenheit': 'Minimum Temperature (°F)',
  // 'specifications.numCommodities': 'Number of Commodities',
  // 'specifications.oversized': 'Oversized',
  // 'specifications.permits': 'Permits',
  // 'specifications.seal': 'Seal',
  // 'specifications.straps': 'Straps',
  // 'specifications.tarps': 'Tarps',
  // 'specifications.totalInPalletCount': 'Total In Pallet Count',
};
