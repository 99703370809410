import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { TMSLocationWithDistance } from 'types/Load';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';
import { GenericCompanySearchableFields } from 'utils/loadInfoAndBuilding';

type SearchLocationsResponse = {
  locationList: TMSLocationWithDistance[];
  tmsTenant: string;
  message?: string;
  location?: {
    city: string;
    state: string;
    zip: string;
  };
};

export async function searchLocations(
  tmsID: number,
  key?: GenericCompanySearchableFields,
  value?: string,
  mileRadius?: number,
  city?: string,
  state?: string,
  zip?: string
): Promise<Result<SearchLocationsResponse, ApiError>> {
  const params = [`tmsID=${encodeURIComponent(tmsID)}`];

  if (key && value) {
    params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  }

  if (mileRadius) {
    params.push(`mileRadius=${encodeURIComponent(mileRadius)}`);
  }

  if (city) {
    params.push(`city=${encodeURIComponent(city)}`);
  }

  if (state) {
    params.push(`state=${encodeURIComponent(state)}`);
  }

  if (zip) {
    params.push(`zip=${encodeURIComponent(zip)}`);
  }

  try {
    const response = await axios.get<SearchLocationsResponse>(
      `/locations/search?${params.join('&')}`
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'searchLocations' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to search locations' });
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Failed to search locations',
    });
  }
}
