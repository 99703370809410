import { CircleCheck, Mail } from 'lucide-react';

import { Maybe } from 'types/UtilityTypes';
import { formatCurrency } from 'utils/formatCurrency';
import { cn } from 'utils/shadcn';

import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip';

type CarrierQuoteCardProps = {
  name?: string;
  locationName?: string;
  city?: string;
  state?: string;
  emails: string[];
  price?: number;
  available?: boolean;
  notes?: Maybe<string>;
  responded: boolean;
  bounced: boolean;
  selected?: boolean;
  onViewThread: () => void;
  onSelect?: () => void;
};

const MAX_NOTE_LENGTH = 80;

const trimString = (str: string, maxLength: number) => {
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
};

export const CarrierQuoteCard = ({
  name,
  city,
  state,
  emails,
  price,
  available,
  notes,
  responded,
  bounced,
  selected,
  onViewThread,
  onSelect,
}: CarrierQuoteCardProps) => {
  const getStatusText = () => {
    if (bounced) return 'Email bounced';
    if (!responded) return 'No quote';
    if (available !== undefined && !available) return 'Not available';
    // Carrier may have not responded yet OR they responded without a quote which processor doesn't pickup
    // because regex looks for a quote number (e.g. '320'). So we say "No quote" instead of "No response" to capture both cases
    // TODO: ENG-3146
    if (!price) return 'No quote';

    return formatCurrency(price || 0, 'USD');
  };

  const getStatusColor = () => {
    if (bounced || !responded || !available) return 'text-red-500';
    return 'text-green-600';
  };

  const renderEmails = () => {
    return (
      <span className='text-grayscale-content-2 text-xs text-ellipsis block '>
        {emails.join(', ')}
      </span>
    );
  };

  return (
    <div
      className={cn(
        'relative flex flex-col border rounded-lg p-2.5 transition-all cursor-pointer shadow-sm',
        bounced || !responded || !available
          ? 'bg-red-50 border-red-200'
          : [
              'border-[#FE9659]',
              selected
                ? 'bg-orange-bg/90 border-[#FE9659] ring-2 ring-[#FE9659]/30'
                : 'hover:shadow-md bg-white',
            ]
      )}
      onClick={onSelect}
    >
      <div className='flex items-center justify-between mb-0.5'>
        <h4
          className='text-sm font-semibold text-grayscale-content-label truncate text-wrap'
          title={name || (Array.isArray(emails) ? emails[0] : emails)}
        >
          {name || (Array.isArray(emails) ? emails[0] : emails)}
        </h4>
        <div className='flex justify-end'>
          <Tooltip delayDuration={10}>
            <TooltipTrigger asChild>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onViewThread();
                }}
                className='p-1 text-[#0ea5e9] hover:bg-[#FE9659] hover:text-white rounded-[4px] transition-colors'
                aria-label='View email thread'
              >
                <Mail className='w-4 h-4' />
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p className='text-xs text-gray-700'>View thread</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>

      <div className='flex flex-col gap-1'>
        {city && state && (
          <p className='text-gray-600 text-xs text-ellipsis'>{`${city}, ${state}`}</p>
        )}

        {name && renderEmails()}

        <div className='flex items-start mt-1 border-t pt-1.5 border-gray-300'>
          <div className='flex flex-col w-full'>
            <div className='flex justify-between items-center'>
              <span className='text-xs font-medium text-gray-700'>
                {price ? 'Total cost:' : 'Status:'}
              </span>
              <span className={cn('font-medium text-xs', getStatusColor())}>
                {getStatusText()}
              </span>
            </div>
            {notes && responded && available && (
              <p
                className='text-xs font-medium text-gray-700 mt-1'
                title={notes}
              >
                Note:{' '}
                <span className='text-xs text-gray-600 italic'>
                  {trimString(notes, MAX_NOTE_LENGTH)}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>

      {selected && (
        <div className='absolute top-[-6px] right-[-6px]'>
          <CircleCheck
            className='text-green-500 w-5 h-5 fill-white'
            strokeWidth={2}
          />
        </div>
      )}
    </div>
  );
};
