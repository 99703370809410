import { Address, TransportType } from 'types/QuoteRequest';
import { Maybe } from 'types/UtilityTypes';
import { titleCase } from 'utils/formatStrings';

import { CarrierQuoteInputs } from './RequestCarrierQuotes';

const formatAddress = (address: Address, isSDS: boolean): string => {
  if (!address) {
    return '';
  }

  if (isSDS) {
    return `${titleCase(address.city)}, ${address.state} ${address.zip}`;
  }

  const parts: string[] = [];
  if (address.city) parts.push(titleCase(address.city));
  if (address.state) parts.push(address.state);
  if (address.zip) parts.push(address.zip);
  if (address.addressLine1) parts.unshift(address.addressLine1);
  return parts.join(', ');
};

const formatTransportType = (type: TransportType): string => {
  return type.toLowerCase();
};

export const constructEmailBody = (
  data: CarrierQuoteInputs,
  isSDS: boolean,
  userName: Maybe<string>
): string => {
  if (isSDS) {
    // Use toLocaleTimeString with options to format the time without seconds
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const timeAvailability = data.pickupStartDate
      ? `between ${new Date(data.pickupStartDate).toLocaleTimeString('en-US', timeOptions)} and ${data.pickupEndDate ? new Date(data.pickupEndDate).toLocaleTimeString('en-US', timeOptions) : 'TBD'}`
      : `
Please let me know your availability`;

    return `Please see the info below and let me know your quote.

Pickup in ${formatAddress(data.pickupLocation, isSDS)} ${data.pickupStartDate ? `on ${new Date(data.pickupStartDate).toLocaleDateString('en-US')}` : ''} ${timeAvailability}

Item Description:
${data.itemDescription}

>>> We would need you to recover the loose material listed above to be palletized & securely shrink wrapped. Once the pallet/s are back at your warehouse we will send in a truck to recover the freight from your dock.
 
Thanks, 
${userName ?? ''}`;
  }

  return `Hello, I am scheduling a pickup in ${formatAddress(data.pickupLocation, isSDS)} ${data.deliveryLocation ? `to be dropped off at ${formatAddress(data.deliveryLocation, isSDS)}` : ''}. Please see the info below and let me know if you can help and your quote.

Pickup: ${data.pickupStartDate ? new Date(data.pickupStartDate).toLocaleDateString('en-US') : 'TBD'}

Location:
${data.pickupLocation?.addressLine1 ?? 'Address Line 1 not provided'}

Equipment: ${data.transportType ? formatTransportType(data.transportType) : 'TBD'}

Instructions and Notes: 
- Weight: ${data.weightLbs} ${data.weightUnit}`;
};

export const constructEmailSubject = (
  pickup: Address,
  dropoff: Maybe<Address>,
  isSDS: boolean
): string => {
  if (isSDS) {
    return `[Carrier Name] - Quote Pick-up ${formatAddress(pickup, isSDS)} for SDS Logistics`;
  }
  return `Quote Pick-up ${formatAddress(pickup, isSDS)} ${dropoff ? `to ${formatAddress(dropoff, isSDS)}` : ''}`;
};
