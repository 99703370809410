/**
 * Determines if a string is likely a phone number to prevent false positives for dates with phone numbers without dash separators
 *
 * @param str The string to check
 * @returns True if the string appears to be a phone number, false otherwise
 */
export const isLikelyPhoneNumber = (str: string | any): boolean => {
  if (!str || str === '') return false;
  str = String(str);
  const digitCount = str
    .split('')
    .filter((char: string) => '0123456789'.includes(char)).length;

  /**
  ^\s* - Matches the start of the string, followed by optional whitespace.
  (?:\+?(\d{1,3}))? - Matches an optional country code, which can be 1-3 digits.
  [-. (]*(\d{3})[-. )]* - Matches the area code, which is 3 digits, optionally surrounded by various separators.
  [-. ]*(\d{3})[-. ]*(\d{4}) - Matches the prefix and line number, which are 3 digits and 4 digits respectively, optionally separated by various separators.
  (?: *x(\d+))? - Matches an optional extension, which is "x" followed by one or more digits.
  
   * Examples: +1 (555) 123-4567, 555.123.4567, 5551234567, 555-123-4567 x123
   */
  const phoneRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  const strValue = String(str);

  // If this is a phone number, it will have at least 10 digits, but we should check if it's a timestamp
  if (digitCount >= 10) {
    // Most phone numbers are less than 15 digits worldwide
    if (strValue.length > 15) return false;

    // Try to interpret as a timestamp (both seconds and milliseconds)
    const possibleDate = new Date(
      strValue.length >= 13 ? Number(strValue) : Number(strValue) * 1000
    );

    // If it is a UNIX timestamp, it will be a number between 1970 and 2100, but we should check it for a reasonable year
    const year = possibleDate.getFullYear();
    const currentYear = new Date().getFullYear();
    if (!isNaN(year) && year >= 1970 && year <= currentYear + 5) {
      return false;
    }
  }
  return phoneRegex.test(strValue);
};
