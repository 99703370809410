import { Controller, UseFormReturn } from 'react-hook-form';

import { Select as AntdSelect } from 'antd';
import { BaseOptionType } from 'antd/es/select';

import { Label } from 'components/Label';
import { NormalizedLoad } from 'types/Load';

import { LoadBuildingTextInput } from '../McleodLoadBuildingForm';
import { currencyList } from './constants';

export function RatesForm({
  formMethods,
}: {
  formMethods: UseFormReturn<NormalizedLoad>;
}): JSX.Element {
  //  TODO: Get from tms.GetLoadBuildingAttributes

  const { control, watch } = formMethods;

  const watchedTotalAmount = watch('rateData.customerTotalCharge.val');
  const watchedCustomerLineHaulCharge = watch(
    'rateData.customerLineHaulCharge.val'
  );
  const watchedCustomerFuelSurcharge = watch('rateData.fscFlatRate');

  return (
    <div className='grid grid-cols-2 gap-2 mx-0 w-full'>
      {/* Customer Rates */}

      <div className='col-span-1 text-md text-grayscale-content-label font-semibold mb-1'>
        Customer
      </div>

      <div className='mx-0 w-full col-span-2 grid grid-cols-2 gap-2'>
        <div className='col-span-1'>
          <LoadBuildingTextInput
            name='rateData.customerLineHaulCharge.val'
            label={`Line Haul Charge`}
            inputType='number'
            options={{ valueAsNumber: true }}
            required={false}
          />
        </div>

        <div className='col-span-1'>
          <LoadBuildingTextInput
            name='rateData.fscFlatRate'
            label={`Fuel Surcharge`}
            inputType='number'
            options={{ valueAsNumber: true }}
            required={false}
          />
        </div>
      </div>

      <div className='col-span-2'>
        <LoadBuildingTextInput
          name='rateData.customerTotalCharge.val'
          label={`Total Amount`}
          inputType='number'
          options={{ valueAsNumber: true }}
          required={false}
        />
      </div>

      <div className='flex flex-col col-span-2'>
        <Label
          name={'rateData.customerTotalCharge.unit'}
          required={
            watchedTotalAmount > 0 ||
            watchedCustomerLineHaulCharge > 0 ||
            (watchedCustomerFuelSurcharge ?? 0) > 0
          }
        >
          Currency
        </Label>
        <Controller
          name='rateData.customerTotalCharge.unit'
          control={control}
          rules={{
            required:
              watchedTotalAmount > 0 ||
              watchedCustomerLineHaulCharge > 0 ||
              (watchedCustomerFuelSurcharge ?? 0) > 0,
          }}
          render={({ field }) => (
            <AntdSelect
              showSearch
              className='h-9 text-grayscale-content-input'
              placeholder={'Choose'}
              optionFilterProp='children'
              filterOption={(
                input: string,
                option: BaseOptionType | undefined
              ) =>
                (option?.label.toLocaleLowerCase() ?? '').includes(
                  input.toLocaleLowerCase()
                )
              }
              filterSort={(optionA: BaseOptionType, optionB: BaseOptionType) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={field.onChange}
              value={field.value}
              options={currencyList?.map((currency) => ({
                value: currency,
                label: currency,
              }))}
            />
          )}
        />
      </div>
    </div>
  );
}
