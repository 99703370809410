import { useEffect, useMemo, useRef, useState } from 'react';

import { Select, SelectProps, Spin } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import debounce from 'lodash/debounce';
import { MapPinIcon } from 'lucide-react';

export type ValueType = any;

export interface DebounceSelectProps<ValueType>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  notFoundContent?: React.ReactNode;
}

export function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  fetchOptions,
  options: initOptions,
  debounceTimeout = 300,
  notFoundContent = <p>No results found</p>,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>(
    initOptions as ValueType[]
  );
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    if (initOptions) {
      setOptions(initOptions as ValueType[]);
    }
  }, [initOptions]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size='small' /> : notFoundContent}
      {...props}
      options={options}
    />
  );
}

// Using Inline CSS for this component since it gets appended outside our root element
export const GenericLocationOption = ({
  option,
  optionFieldsToRender = [],
}: {
  option: BaseOptionType;
  optionFieldsToRender?: string[]; // If empty, the default option fields rendered are ${`mainAddress`} - ${`secondaryAddress`}
}) => {
  const isOptionFieldsEmpty =
    optionFieldsToRender.map((op) => option[op]).filter(Boolean).length === 0;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <div style={{ fontSize: '14px', fontWeight: 400 }}>{option.name}</div>
      {!isOptionFieldsEmpty ? (
        <div style={{ display: 'flex', whiteSpace: 'pre-wrap', gap: '4px' }}>
          <MapPinIcon
            style={{
              height: '13px',
              width: '13px',
              flexShrink: '0',
              marginTop: '2px',
            }}
          />
          <div style={{ fontSize: '12px', fontWeight: 400 }}>
            {optionFieldsToRender && optionFieldsToRender.length > 0
              ? `${optionFieldsToRender
                  .map((field) => {
                    if (!option[field]) {
                      return '';
                    }
                    return option[field];
                  })
                  .filter(Boolean)
                  .join(', ')}`
              : `${option.mainAddress} — ${option.secondaryAddress}`}
          </div>
        </div>
      ) : null}
    </div>
  );
};
