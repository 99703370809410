import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import AuthService from '@auth/AuthService';
import isProd from '@utils/isProd';

import { TitlebarButtons } from 'components/Titlebar';
import SidebarLoader from 'components/loading/SidebarLoader';
import { SidebarViewContext } from 'contexts/sidebarViewContext';
import useFetchEmail from 'hooks/useFetchEmail';
import { useServiceFeatures } from 'hooks/useServiceContext';
import LoadsSidebar from 'pages/LoadView/LoadsSidebar';
import QuoteSidebar from 'pages/QuoteView/QuoteSidebar';
import { initialIngestionMessage } from 'types/IngestionMessage';
import { EmailLabels } from 'types/enums/EmailLabels';
import SidebarView from 'types/enums/SidebarView';

import { useOfficeContext } from './OfficeContext';

export default function SidebarOutlookWrapper() {
  const { state } = useOfficeContext();
  const { currentView, setCurrentView } = useContext(SidebarViewContext);
  const ingestionMessage = initialIngestionMessage;

  // To make local dev easier, map last message in thread to ingestion format
  if (!isProd() && state?.mailbox) {
    const item = state.mailbox.item!;
    ingestionMessage.messageID = item.itemId!;
    ingestionMessage.fromName = item.from.displayName;
    ingestionMessage.fromEmail = item.from.emailAddress;
    ingestionMessage.toName = item.to[0]?.displayName;
    ingestionMessage.toEmail = item.to[0]?.emailAddress;
    item.body.getAsync('text', ({ status, value }) => {
      if (status === Office.AsyncResultStatus.Succeeded) {
        ingestionMessage.body = value;
      }
    });
    ingestionMessage.subject = item.subject;
    ingestionMessage.date = dayjs(item.dateTimeCreated?.toString()).format(
      'MMM D, YYYY, h:mm A'
    );
  }

  const { email, isLoading } = useFetchEmail(
    state?.mailbox?.item?.conversationId as string,
    ingestionMessage
  );
  const navigate = useNavigate();

  const {
    serviceFeaturesEnabled: {
      isQuickQuoteEnabled,
      isLoadBuildingEnabled,
      isTruckListEnabled,
    },
  } = useServiceFeatures();

  useEffect(() => {
    const shouldSetQuoteView = (label: EmailLabels, isEnabled: boolean) =>
      email?.labels.includes(label) &&
      currentView !== SidebarView.Quote &&
      isEnabled;

    // Set quote view if labels match *AND* no freight tracking IDs are present
    if (
      (shouldSetQuoteView(EmailLabels.QuoteRequest, isQuickQuoteEnabled) ||
        shouldSetQuoteView(EmailLabels.LoadBuilding, isLoadBuildingEnabled) ||
        shouldSetQuoteView(EmailLabels.TruckList, isTruckListEnabled)) &&
      email?.freightTrackingIDs?.length == 0
    ) {
      setCurrentView(SidebarView.Quote);
    }
  }, [email, isQuickQuoteEnabled, isLoadBuildingEnabled, isTruckListEnabled]);

  if (isLoading) {
    return <SidebarLoader />;
  }

  // If switching extension environments, token signature will be invalidated. Use logout to start fresh.
  const outlookLogout = () => {
    AuthService.logOut();
    navigate('/login');
  };

  return (
    <div
      className='flex flex-col py-2'
      style={{ minHeight: 'calc(100vh - 44px)' }}
    >
      <div className='flex flex-row justify-end gap-x-2 px-3 z-'>
        <TitlebarButtons outlookLogout={outlookLogout} />
      </div>
      {currentView === SidebarView.Loads && (
        <LoadsSidebar
          email={email}
          initialFreightTrackingIDs={
            email && email.freightTrackingIDs ? email.freightTrackingIDs : []
          }
        />
      )}

      {currentView === SidebarView.Quote && <QuoteSidebar email={email} />}
    </div>
  );
}
