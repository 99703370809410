import { Controller, FieldPath, FormProvider, useForm } from 'react-hook-form';

import { PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { Popover } from 'components/Popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import DateTimeInput from 'components/input/DateTimeInput';
import { RHFTextInput } from 'components/input/RHFTextInput';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { StopTypes } from 'types/Appointment';
import {
  NormalizedDropoff,
  NormalizedLoad,
  NormalizedPickup,
} from 'types/Load';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';

import AppointmentPreview from './AppointmentEmailPreview';

dayjs.extend(utc);
dayjs.extend(timezone);

enum Temperature {
  Dry = 'Dry',
  Chilled = 'Chilled',
  Frozen = 'Frozen',
}

enum LoadType {
  LTL = 'LTL',
  TL = 'TL',
}

type RequestInputs = {
  customerPO: string;
  asn: string;
  nfiOrder: string;
  dryChilledOrFrozen: string;
  LTLorTL: string;
  vendor: string;
  dateTimeRequested: Date;
  palletCount: string;
  trailerType?: string;
  loadType?: string;
};

type RequestFormProps = {
  normalizedLoad: NormalizedLoad;
  type: StopTypes;
  toggleSchedulingPortal: () => void;
};

// For when users want to send an email to a specific warehouse to schedule an appointment
export default function EmailAppointmentForm({
  normalizedLoad: load,
  type,
  toggleSchedulingPortal,
}: RequestFormProps) {
  const stop: NormalizedPickup | NormalizedDropoff =
    type === StopTypes.Dropoff ? load.consignee : load.pickup;
  const apptTime = stop && stop.apptStartTime;

  const initialDate = apptTime ?? new Date();

  const formMethods = useForm<RequestInputs>({
    defaultValues: {
      customerPO: load.poNums || '',
      nfiOrder: load.customer.refNumber || '',
      vendor: load.pickup.name || '',
      dateTimeRequested: initialDate as Date,
      palletCount: `${load.specifications.totalOutPalletCount ?? ''}`,
      dryChilledOrFrozen:
        typeof load.specifications.maxTempFahrenheit === 'number'
          ? getMaxTemp(load.specifications.maxTempFahrenheit)
          : '',
    },
  });
  const { control } = formMethods;

  return (
    <>
      <ExtendedFormProvider aiDefaultValues={true}>
        <FormProvider {...formMethods}>
          <div className='col-span-6 grid gap-4 grid-cols-6 mx-0 w-full'>
            <div className='mb-3 col-span-6'>
              <h3 className='font-medium mb-1'>Request via email</h3>
              <Button
                variant={'underline'}
                type='button'
                className='text-xs p-0 h-6 !ml-auto'
                onClick={() => toggleSchedulingPortal()}
              >
                <>{'Switch to Scheduling Portal'}</>
              </Button>
            </div>

            <p className='col-span-6'>
              <RequestAppointmentEditorTextInput
                label='Customer PO'
                name='customerPO'
                placeholder='3001000'
              />
            </p>
            <p className='col-span-6'>
              <RequestAppointmentEditorTextInput
                label='ASN #'
                name='asn'
                placeholder='3001000'
              />
            </p>
            <p className='col-span-6'>
              <RequestAppointmentEditorTextInput
                label='NFI Order #'
                name='nfiOrder'
                placeholder='700100020000'
              />
            </p>
            <p className='col-span-6'>
              <Label name='dryChilledOrFrozen'>Temperature</Label>

              <Controller
                name='dryChilledOrFrozen'
                control={control}
                render={({ field }) => (
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                    name='dryChilledOrFrozen'
                  >
                    <SelectTrigger className='w-full mt-1' name={field.name}>
                      <SelectValue placeholder='Choose' />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.keys(Temperature).map((temp) => (
                        <SelectItem key={temp} value={temp}>
                          {temp}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </p>
            <p className='col-span-6'>
              <Label name='LTLorTL'>Load Type</Label>

              <Controller
                name='LTLorTL'
                control={control}
                render={({ field }) => (
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                    name='LTLorTL'
                  >
                    <SelectTrigger className='w-full mt-1' name={field.name}>
                      <SelectValue placeholder='Choose' />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.keys(LoadType).map((option) => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </p>
            <p className='col-span-6'>
              <RequestAppointmentEditorTextInput
                label='Vendor'
                name='vendor'
                placeholder='Acme Corporation'
              />
            </p>

            <p className='col-span-6'>
              <DateTimeInput
                control={control}
                name='dateTimeRequested'
                label='Date'
                hideAIHint={true}
              />
            </p>
            <p className='col-span-6'>
              <RequestAppointmentEditorTextInput
                label='Pallet Count'
                name='palletCount'
                placeholder='1'
              />
            </p>
            <Popover>
              <PopoverTrigger className='col-span-6'>
                <Button
                  buttonNamePosthog={ButtonNamePosthog.ShowSmartReply}
                  className='w-full col-span-6'
                  logProperties={{
                    loadID: load.ID,
                    freightTrackingID: load.freightTrackingID,
                    serviceID: load.serviceID,
                  }}
                >
                  {ButtonText.ShowSmartReply}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className='w-[632px] -m-4'
                align='end'
                side='left'
                sideOffset={1}
              >
                <AppointmentPreview normalizedLoad={load} />
              </PopoverContent>
            </Popover>
          </div>
        </FormProvider>
      </ExtendedFormProvider>
    </>
  );
}

function getMaxTemp(maxTemp: number) {
  if (maxTemp < 32) return 'Frozen';
  if (maxTemp < 60) return 'Chilled';
  return '';
}

type RequestAppointmentEditorTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: FieldPath<RequestInputs>;
};
const RequestAppointmentEditorTextInput = (
  props: RequestAppointmentEditorTextInputProps
) => <RHFTextInput {...props} />;
