import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type SubmitApptRequest = {
  warehouseId: string;
  source: string;
  poNumbers: string[];
  lumperRequested: boolean;
  note: string;
  requestedDate?: string;
  timePreference?: string;
};

export async function submitAppt(
  request: SubmitApptRequest
): Promise<Result<any, ApiError>> {
  try {
    const response = await axios.post<any>(`appt/submit`, request);
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'submitAppt' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to submit appointment' });
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Failed to submit appointment',
    });
  }
}
