import { Controller, useFormContext } from 'react-hook-form';

import { TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarIcon, XCircleIcon } from 'lucide-react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import { Button } from 'components/Button';
import { Calendar } from 'components/Calendar';
import { Label } from 'components/Label';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover';
import { RHFTextInput } from 'components/input/RHFTextInput';
import { cn } from 'utils/shadcn';
import {
  timepickerChangeHandler,
  timepickerParseTimeWithoutColon,
} from 'utils/timepickerFunctions';

type TruckTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name:
    | `carrierInformation.name`
    | `carrierInformation.mc`
    | `carrierInformation.dot`
    | `carrierInformation.contact.name`
    | `carrierInformation.contact.email`
    | `default.city`
    | `default.state`
    | `default.dropoffDate`
    | `updated.pickupDate`
    | `${string}.pickupLocation.city`
    | `${string}.pickupLocation.state`
    | `${string}.dropoffLocation.city`
    | `${string}.dropoffLocation.state`
    | `${string}.length`
    | `updated.carrier`;
};

export const TruckTextInput = (props: TruckTextInputProps) => (
  <RHFTextInput {...props} />
);

type TruckDateTimeInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: `${string}.pickupDate` | `${string}.dropoffDate`;
  label: string;
  includeTime?: boolean;
};

export const TruckDateTimeInput = ({
  name,
  label,
  includeTime = true,
}: TruckDateTimeInputProps) => {
  const { control } = useFormContext<Record<string, any>>();

  return (
    <div className='mt-2'>
      <Label name={name} className='text-grayscale-content-3 text-sm'>
        {label}
      </Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className='mt-1 flex flex-row gap-1'>
            <div className='flex gap-4 flex-1'>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    size='sm'
                    className={cn(
                      'w-full justify-start text-left text-[14px] font-normal rounded-[4px] border-orange-border py-3 h-9',
                      !field.value && 'text-muted-foreground'
                    )}
                  >
                    <CalendarIcon className='mr-2 h-4 w-4 shrink-0' />
                    <span className='text-sm text-grayscale-content-2'>
                      {field.value
                        ? dayjs.utc(field.value as Date).format('MM/DD/YY')
                        : 'Pick a date'}
                    </span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0'>
                  <Calendar
                    mode='single'
                    selected={field.value ? (field.value as Date) : undefined}
                    onSelect={(newValue: any) => {
                      if (typeof newValue === 'undefined') {
                        field.onChange(null);
                        return;
                      }

                      if (field.value && newValue) {
                        newValue.setHours((field.value as Date).getHours());
                        newValue.setMinutes((field.value as Date).getMinutes());
                      }

                      field.onChange(newValue);
                    }}
                  />
                </PopoverContent>
              </Popover>

              {includeTime && (
                <TimePicker
                  className='!pr-1 pl-3 text-[14px] w-1/2 border rounded-[4px] border-orange-border text-grayscale-content-2 h-9'
                  allowClear={false}
                  disabled={!field.value}
                  placeholder='00:00'
                  format={'HH:mm'}
                  value={
                    field.value
                      ? dayjs.utc(field.value as string)
                      : dayjs.utc().hour(0).minute(0)
                  }
                  onBlur={(e: React.FocusEvent<HTMLElement>) => {
                    const parsedTime = timepickerParseTimeWithoutColon(
                      e.target as HTMLInputElement
                    );
                    if (parsedTime) {
                      timepickerChangeHandler(
                        dayjs
                          .utc(field.value as string)
                          .hour(parsedTime.hours)
                          .minute(parsedTime.minutes),
                        field
                      );
                    }
                  }}
                  onChange={(dayjsTime: Dayjs) =>
                    timepickerChangeHandler(dayjsTime, field)
                  }
                  onKeyDown={(e: React.KeyboardEvent) =>
                    e.key === 'Enter' && e.preventDefault()
                  }
                />
              )}
            </div>
            {field.value && (
              <button
                title='Clear date'
                onClick={() => field.onChange(null)}
                className='h-9 flex items-center justify-center'
              >
                <XCircleIcon className='w-4 h-4' />
              </button>
            )}
          </div>
        )}
      />
    </div>
  );
};

type TruckListSectionAccordionItemProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  contentClassName?: string;
  triggerClassName?: string;
  label: string;
  name: string;
  activeTabs: string[];
  showEditIcon: boolean;
  setDateEditing?: React.Dispatch<React.SetStateAction<string>>;
};

export function TruckListSectionAccordionItem({
  icon,
  children,
  contentClassName = '',
  triggerClassName = '',
  name,
  label,
  activeTabs,
  showEditIcon,
  setDateEditing,
}: TruckListSectionAccordionItemProps) {
  return (
    <AccordionItem value={name}>
      <AccordionTrigger
        className={`${triggerClassName} hover:no-underline`}
        icon={icon}
        showEditIcon={showEditIcon}
        editIconCallback={(e) => {
          e.preventDefault();
          if (setDateEditing) setDateEditing(name);
        }}
      >
        {label}
      </AccordionTrigger>
      <AccordionContent
        forceMount={true}
        hidden={!activeTabs.includes(name)}
        className={contentClassName}
      >
        {children}
      </AccordionContent>
    </AccordionItem>
  );
}
