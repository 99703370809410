import { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Info } from 'lucide-react';

import { Button } from 'components/Button';
import {
  DebounceSelect,
  GenericLocationOption,
} from 'components/DebounceSelect';
import { ServiceContext } from 'contexts/serviceContext';
import { useToast } from 'hooks/useToaster';
import { getWarehouseData } from 'lib/api/getWarehouseData';
import { getWarehouseSearch } from 'lib/api/getWarehouseSearch';
import {
  GetWarehouseResponse,
  SchedulingPortals,
  StopTypes,
} from 'types/Appointment';
import { NormalizedLoad } from 'types/Load';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';

import { OpendockForm } from './SchedulingPortalForms/OpendockForm';
import { RetalixForm } from './SchedulingPortalForms/RetalixForm';

dayjs.extend(utc);
dayjs.extend(timezone);

type PortalBaseFormProps = {
  type: StopTypes;
  load: NormalizedLoad;
  recentWarehouses: Warehouse[];
  suggestedWarehouse: Maybe<Warehouse>;
  toggleSchedulingPortal: () => void;
};

export default function PortalBaseForm({
  type,
  load,
  recentWarehouses,
  suggestedWarehouse,
  toggleSchedulingPortal,
}: PortalBaseFormProps) {
  const { toast } = useToast();
  const {
    serviceFeaturesEnabled: { isAppointmentEmailingEnabled },
  } = useContext(ServiceContext);

  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>();
  const [selectedWarehouseDetails, setSelectedWarehouseDetails] =
    useState<GetWarehouseResponse>();
  const [warehouses, setWarehouses] = useState<Warehouse[]>(
    recentWarehouses && recentWarehouses.length ? recentWarehouses : []
  );
  const [uniqueWarehouseSources, setUniqueWarehouseSources] = useState<
    string[]
  >([]);

  const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);

  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const [hasOverriddenSuggestedWarehouse, setHasOverriddenSuggestedWarehouse] =
    useState(false);

  useEffect(() => {
    if (warehouses) {
      setUniqueWarehouseSources([
        ...new Set(warehouses.map((wh) => wh.warehouseSource)),
      ]);
    }
  }, [warehouses]);

  useEffect(() => {
    if (suggestedWarehouse) {
      setSelectedWarehouse(suggestedWarehouse);
    }
  }, [suggestedWarehouse]);

  // When warehouse selected, fetch its load types
  useEffect(() => {
    if (!selectedWarehouse) {
      return;
    }

    if (selectedWarehouse.warehouseSource === SchedulingPortals.Opendock) {
      handleFetchWarehouse();
    }
  }, [selectedWarehouse]);

  const capitalizeFirst = (str: MaybeUndef<string>) => {
    if (!str) return '';
    const trimmed = str.trim();
    return trimmed.charAt(0).toUpperCase() + trimmed.slice(1);
  };

  const handleFetchWarehouse = async () => {
    if (!selectedWarehouse) {
      return;
    }

    setIsLoadingCustomFields(true);

    const warehouseResponse = await getWarehouseData(
      selectedWarehouse.warehouseID,
      selectedWarehouse.warehouseSource
    );
    if (warehouseResponse.isOk()) {
      setSelectedWarehouseDetails(warehouseResponse.value);
    } else {
      toast({
        description:
          warehouseResponse?.error?.message ??
          "Error fetching warehouse's custom form.",
        variant: 'destructive',
      });
    }

    setIsLoadingCustomFields(false);
  };

  const handleResetWarehouseSearch = () => {
    setIsInitialSearch(true);
    setWarehouses(recentWarehouses);
  };

  const handleWarehouseSearch = async (search: string) => {
    if (search.length > 3) {
      setIsInitialSearch(false);

      const searchRes = await getWarehouseSearch(search);

      if (searchRes.isOk()) {
        const { warehouses: searchedWarehouses } = searchRes.value;

        setWarehouses(searchedWarehouses);

        return searchedWarehouses && searchedWarehouses.length
          ? mapWarehousesToOptions(searchedWarehouses)
          : [];
      }
    }

    /**
     * If we've searched before with the current dropdown and now just want to
     * filter the initial entries, reset state.
     */
    handleResetWarehouseSearch();

    /**
     * Searches that are less than 3 characters dont provide enough context to search amongst
     * all warehouses. In that case, apply that filter to the entries that are already loaded.
     */
    return mapWarehousesToOptions(recentWarehouses).filter((wh) =>
      wh.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  };

  return (
    <div className='col-span-6'>
      <div className='mb-3'>
        <h3 className='font-medium mb-1'>Request via Portal</h3>
        {isAppointmentEmailingEnabled && (
          <Button
            variant={'underline'}
            type='button'
            className='text-xs p-0 h-6 !ml-auto'
            onClick={() => toggleSchedulingPortal()}
          >
            <>{'Switch to email'}</>
          </Button>
        )}
      </div>

      <form className='w-full flex flex-col mb-2'>
        <label>Warehouse</label>
        <DebounceSelect
          showSearch
          placeholder='Choose'
          optionFilterProp='children'
          value={
            selectedWarehouse
              ? mapWarehousesToOptions([selectedWarehouse])
              : null
          }
          fetchOptions={handleWarehouseSearch}
          onFocus={handleResetWarehouseSearch}
          onSelect={({ value }) => {
            if (
              !hasOverriddenSuggestedWarehouse &&
              value !== suggestedWarehouse?.warehouseName
            ) {
              setHasOverriddenSuggestedWarehouse(true);
            }

            setSelectedWarehouse(
              warehouses.find((w) => w.warehouseID === value)
            );
          }}
          options={[
            {
              label: (
                <span>
                  {`${isInitialSearch ? 'Recently used' : 'Searched'} warehouses`}
                </span>
              ),
              title: `${isInitialSearch ? 'Recently used' : 'Searched'} warehouses`,
              options: mapWarehousesToOptions(warehouses),
            },
          ]}
          optionRender={(option) => (
            <GenericLocationOption
              option={option.data}
              optionFieldsToRender={[
                'mainAddress',
                'secondaryAddress',
                ...(uniqueWarehouseSources ? ['source'] : []),
              ]}
            />
          )}
          notFoundContent={
            isInitialSearch ? (
              <p>Start typing to search for a warehouse</p>
            ) : (
              <p>No results found</p>
            )
          }
        />
      </form>

      {selectedWarehouse && (
        <>
          <span className='flex flex-row bg-violet-blue-bg rounded-lg space-x-1 py-1 mt-0'>
            <div>
              <Info className='h-4 w-4 pl-1' color='#969696' strokeWidth={3} />
            </div>
            <span>
              <p className='text-sm text-grayscale-content-description font-bold'>
                Warehouse:
              </p>
              <p className='text-xs text-grayscale-content-description font-medium mt-1'>
                {selectedWarehouse.warehouseName.trim()}
              </p>
              <p className='text-xs text-grayscale-content-description font-medium mt-1'>
                {selectedWarehouse.warehouseAddressLine1.trim()}
              </p>
              <p className='text-xs text-grayscale-content-description font-medium mt-1'>
                {selectedWarehouse.warehouseAddressLine2.trim()}
              </p>
              <p className='text-xs text-grayscale-content-description font-medium mt-1'>
                {capitalizeFirst(selectedWarehouse.warehouseSource)}
              </p>
            </span>
          </span>

          {selectedWarehouse.warehouseSource === SchedulingPortals.Opendock ? (
            <div className='w-full'>
              <OpendockForm
                type={type}
                load={load}
                selectedWarehouse={selectedWarehouse}
                selectedWarehouseDetails={selectedWarehouseDetails}
                hasOverriddenSuggestedWarehouse={
                  hasOverriddenSuggestedWarehouse
                }
                isSuggested={
                  !hasOverriddenSuggestedWarehouse &&
                  suggestedWarehouse === selectedWarehouse
                }
                isLoadingCustomFields={isLoadingCustomFields}
              />
            </div>
          ) : selectedWarehouse.warehouseSource ===
            SchedulingPortals.Retalix ? (
            <div className='w-full'>
              <RetalixForm
                load={load}
                selectedWarehouse={selectedWarehouse}
                type={type}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export function convertInputToWarehouseTimezone(
  date: Date,
  warehouseTimezone: string
): dayjs.Dayjs {
  return dayjs()
    .tz(warehouseTimezone)
    .date(date.getDate())
    .month(date.getMonth())
    .year(date.getFullYear())
    .hour(date.getHours())
    .minute(date.getMinutes())
    .second(date.getSeconds())
    .millisecond(date.getMilliseconds());
}
export const mapWarehousesToOptions = (warehouses: Warehouse[]) =>
  warehouses?.map((option: Warehouse) => ({
    ...option,
    value: option.warehouseID,
    name: option.warehouseName,
    mainAddress: option.warehouseAddressLine1,
    secondaryAddress: option.warehouseAddressLine2,
    source: option.warehouseSource,
    label: option.warehouseName,
  }));
