type ErrorMessages = {
  [key: string]: { fields: string[]; message: string };
};

export const pickupLocationErrorMessages: ErrorMessages = {
  'pickup-could-not-be-found': {
    fields: ['pickupLocation.city'],
    message: 'Location not found',
  },
};

export const dropoffLocationErrorMessages: ErrorMessages = {
  'carrier-domicile-not-found': {
    fields: ['dropoffLocation.city'],
    message: 'Carrier domicile not found',
  },
  'destination-needs-more-than-city': {
    fields: ['dropoffLocation.state'],
    message: 'Required',
  },
  'destination-city-state-not-found': {
    fields: ['dropoffLocation.city'],
    message: 'Location not found',
  },
  'destination-state-not-found': {
    fields: ['dropoffLocation.state'],
    message: 'Invalid',
  },
  // 'destination-zip-not-found': { fields: ['dropoffLocation.city'], message: 'Location not found' },
  // 'destination-city-zip-not-found': { fields: ['dropoffLocation.state'], message: 'Invalid' },
  // 'destination-state-zip-not-found': { fields: ['dropoffLocation.state'], message: 'Invalid' },
};

export const pickupDateErrorMessages: ErrorMessages = {
  'availabledate-is-required': { fields: [''], message: 'Date is required' },
  'availabledate-mustbe-noworfuture': {
    fields: [''],
    message: "Date can't be in the past",
  },
  'availabledate-mustbe-withinthirtydays': {
    fields: [''],
    message: 'Date must be within 30 days',
  },
};

export const postingErrorMessages: ErrorMessages = {
  'invalid-or-unknown-truck-type': {
    fields: ['type'],
    message: 'Invalid or unknown Truck Type',
  },
  'no-matching-equipment-found': {
    fields: ['type', 'length'],
    message: 'No matching equipment found',
  },
  'no-matching-trailer-type-found-in-carrier-file': {
    fields: ['length'],
    message: 'No matching Trailer Type for carrier',
  },
};

export const carrierContactErrorMessages: ErrorMessages = {
  'unexpected-error-occurred': {
    fields: ['carrierInformation.contact.email'],
    message: 'Unexpected carrier contact error',
  },
  'no-matching-equipment-found': {
    fields: ['type', 'length'],
    message: 'No matching equipment found',
  },
  'no-matching-trailer-type-found-in-carrier-file': {
    fields: ['length'],
    message: 'No matching Trailer Type for carrier',
  },
};
