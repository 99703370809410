import { MemoryRouter, Navigate, Route, Routes } from 'react-router-dom';

import { PostHogProvider } from 'posthog-js/react';
import { SWRConfig } from 'swr';

import Progress from '@alexandria/components/Progress';
import Login from '@auth/Login';
import Signup from '@auth/Signup';
import POSTHOG_API_KEY from '@constants/PosthogApiKey';
import '@css/global/Global.css';

import AuthProvider from 'components/AuthProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import RequireAuth from 'components/RequireAuth';
import ServiceProvider from 'components/ServiceProvider';
import SidebarStateProvider from 'components/SidebarStateContext';
import SidebarViewProvider from 'components/SidebarViewContext';
import { DrumkitPlatform } from 'contexts/sidebarStateContext';
import { fetcher } from 'utils/fetcher';

import { OfficeContextConsumer, OfficeContextProvider } from './OfficeContext';
import SidebarOutlookWrapper from './SidebarWrapper';

/* global require */

export interface AppProps {
  title: string;
}

function App({ title }: AppProps) {
  return (
    <OfficeContextProvider>
      <OfficeContextConsumer>
        {({
          isInitialized: isOfficeInitialized,
          inboxEmailAddress,
          threadId,
          threadItemId,
          isOutlookReply,
        }) => (
          <>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600&display=swap');",
              }}
            />
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                fetcher,
              }}
            >
              <>
                {!isOfficeInitialized && (
                  <Progress
                    title={title}
                    // eslint-disable-next-line @typescript-eslint/no-require-imports
                    logo={require('../../assets/drumkit-logo.png')}
                    message='Please sideload your add-in to see app body.'
                  />
                )}
                {isOfficeInitialized && (
                  <MemoryRouter>
                    <Routes>
                      <Route
                        path='/'
                        element={
                          <ErrorBoundary>
                            <PostHogProvider
                              apiKey={POSTHOG_API_KEY}
                              options={{
                                api_host: 'https://us.i.posthog.com',
                                person_profiles: 'always',
                              }}
                            >
                              <ServiceProvider>
                                <SidebarStateProvider
                                  inboxEmailAddress={inboxEmailAddress}
                                  drumkitPlatform={DrumkitPlatform.Outlook}
                                  threadId={threadId}
                                  threadItemId={threadItemId}
                                  isOutlookReply={isOutlookReply}
                                >
                                  <AuthProvider>
                                    <RequireAuth>
                                      <SidebarViewProvider>
                                        <SidebarOutlookWrapper />
                                      </SidebarViewProvider>
                                    </RequireAuth>
                                  </AuthProvider>
                                </SidebarStateProvider>
                              </ServiceProvider>
                            </PostHogProvider>
                          </ErrorBoundary>
                        }
                      />
                      <Route path='/signup' element={<Signup />} />
                      <Route path='/login' element={<Login />} />
                      <Route path='/home' element={<Navigate to={'/'} />} />
                    </Routes>
                  </MemoryRouter>
                )}
              </>
            </SWRConfig>
          </>
        )}
      </OfficeContextConsumer>
    </OfficeContextProvider>
  );
}

export default App;
