import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';

import dayjs from 'dayjs';
import {
  BadgeInfoIcon,
  CalendarIcon,
  CalendarXIcon,
  MapPinIcon,
  PlusIcon,
  TriangleAlert,
} from 'lucide-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/Tooltip';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { useToast } from 'hooks/useToaster';
import addTruckToTruckList from 'lib/api/addTruckToTruckList';
import deleteTruckFromTruckList from 'lib/api/deleteTruckFromTruckList';
import { TruckListResponse } from 'lib/api/getTruckList';
import { submitTruckList } from 'lib/api/submitTruckList';
import validateTruckListCarrier from 'lib/api/validateTruckListCarrier';
import { Email } from 'types/Email';
import {
  CarrierInformation,
  FormTruck,
  Truck,
  TruckListErrors,
} from 'types/Truck';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';
import { EmailLabels } from 'types/enums/EmailLabels';

import { TruckListSectionAccordionItem } from '../TruckListFormFields';
import { RedwoodCarrierFieldset } from './Components/RedwoodCarrierFieldset';
import { RedwoodNewGroupFieldset } from './Components/RedwoodNewGroupFieldset';
import { RedwoodOverrideDropoffFieldset } from './Components/RedwoodOverrideDropoffFieldset';
import RedwoodTruckFieldset from './Components/RedwoodTruckFieldset';
import { postedByErrorMessages } from './RedwoodTruckListErrors';
import {
  getCarrierForSubmit,
  getParsedErrors,
  getToastForCarrierErrors,
  getToastForFailedSubmission,
  getToastForGenericError,
  getToastForInvalidFields,
  getToastForSuccessSubmission,
  getToastForTruckErrors,
  getTrucksForSubmit,
  parseCarrierContactErrors,
  parseCarrierErrors,
  parseTruckErrors,
} from './RedwoodUtils';

type RedwoodTruckListFormProps = {
  email: Maybe<Email>;
  truckList: TruckListResponse;
  updateTruckListForm: ({
    trucks,
    carrier,
    errors,
  }: Omit<TruckListResponse, 'serviceName'>) => void;
};

export default function RedwoodTruckListForm({
  email,
  truckList,
  updateTruckListForm,
}: RedwoodTruckListFormProps) {
  const { toast } = useToast();
  const posthog = usePostHog();
  const [loading, setLoading] = useState(false);

  const [carriers, setCarriers] = useState<CarrierInformation[]>([]);
  const [showCarrierSelector, setShowCarrierSelector] =
    useState<boolean>(false);

  const [activeTabs, setActiveTabs] = useState<string[]>([]);

  const memoizedDefaultValues: any = useMemo(() => {
    const { trucks } = truckList || {};

    /**
     * Truck Length is typed as a number in the BE but as a string in the FE in order
     * to allow for an empty value without confusing the user with a 0 length.
     *
     * Here we map the length from number to string, and then map them back when submitting.
     */
    const formTrucks: FormTruck[] = trucks?.map((t) => {
      const truckErrors = truckList.errors?.truckErrors?.[t.id];
      return {
        ...t,
        length: t.length ? String(t.length) : '',
        errors: truckErrors,
        isEditing: Boolean(truckErrors),
      };
    });

    return {
      carrier: {
        name: truckList.carrier?.name,
        contact: {
          name: truckList.carrier?.contactName,
          email: truckList.carrier?.contactEmail,
        },
        mc: truckList.carrier?.mc,
        dot: truckList.carrier?.dot,
      },
      trucks: formTrucks,
    };
  }, [truckList]);

  // Set default values based on the grouped trucks
  const formMethods = useForm<RedwoodTruckListInputs>({
    defaultValues: memoizedDefaultValues,
  });

  useEffect(() => {
    if (memoizedDefaultValues) {
      formMethods.reset(memoizedDefaultValues);
    }

    // Active tabs should be set when form values are reset, even if sidebar hasn't rerendered
    if (!trucksArrayByPickupDate) return;

    setActiveTabs(['carrier-details']);
  }, [memoizedDefaultValues]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    setValue,
    setError,
  } = formMethods;

  const {
    fields: trucksArray,
    append: appendTruck,
    remove: removeTruck,
    update: updateTruck,
  } = useFieldArray({
    control,
    name: 'trucks',
    keyName: 'rhfId',
  });

  const parseAllErrors = ({
    carrierErrors = truckList.errors?.carrierErrors,
    carrierContactErrors = truckList.errors?.carrierContactErrors,
    truckErrors = truckList.errors?.truckErrors,
    postedByErrors = truckList.errors?.postedByErrors,
  }: Partial<TruckListErrors> = {}) => {
    parseCarrierErrors({
      carrierErrors: carrierErrors,
      setError,
      setShowCarrierSelector,
      setCarriers,
    });

    parseTruckErrors({
      truckErrors: truckErrors,
      truckArrayWatcher,
      trucksArrayByPickupDate,
      setError,
    });

    parseCarrierContactErrors({
      carrierContactErrors: carrierContactErrors,
      setError,
    });

    postedByErrors?.forEach((err) => {
      const error = postedByErrorMessages[err];
      if (error) {
        toast({
          title: 'Posting Error',
          description: error.message,
          variant: 'destructive',
        });
      }
    });
  };

  // We map the errors with known error messages and update the form state accordingly.
  useEffect(() => {
    parseAllErrors();
  }, [truckList, setError]);

  const truckArrayWatcher = useWatch({
    control,
    name: 'trucks',
  });

  const trucksArrayByPickupDate: TruckGroupByPickupDate = useMemo(
    () =>
      truckArrayWatcher.reduce((acc, t) => {
        if (!t.pickupDate || !dayjs(t.pickupDate).isValid()) return acc;

        const date = dayjs(t.pickupDate).utc().format('YYYY-MM-DD');
        if (!acc[date]) {
          acc[date] = [];
        }

        const truckErrors = truckList.errors?.truckErrors?.[t.id];
        acc[date].push({
          ...t,
          length: String(t.length),
          errors: truckErrors,
        });
        return acc;
      }, {} as TruckGroupByPickupDate),
    [truckArrayWatcher]
  );

  const updateTruckPickupDate = (truckId: number, newDate: string) => {
    const rhfFieldArrayIdx = truckArrayWatcher.findIndex(
      (t) => t.id === truckId
    );
    const truckFields = truckArrayWatcher[rhfFieldArrayIdx];

    const pickupDateErrors =
      truckList?.errors?.truckErrors?.[truckId]?.pickupDateErrors;
    if (pickupDateErrors) {
      while (pickupDateErrors.length > 0) {
        truckList.errors?.truckErrors?.[truckId]?.pickupDateErrors.pop();
      }
    }

    updateTruck(rhfFieldArrayIdx, { ...truckFields, pickupDate: newDate });
  };

  const updatedCarrierName = useWatch({
    control,
    name: 'update.carrier',
  });

  useEffect(() => {
    if (updatedCarrierName) {
      const selectedCarrier = carriers.find(
        (carrier) => carrier.name === updatedCarrierName
      );

      if (selectedCarrier) {
        setValue('carrier.name', selectedCarrier.name);
        setValue('carrier.contact.name', selectedCarrier.contactName);
        setValue('carrier.contact.email', selectedCarrier.contactEmail);
        setValue('carrier.mc', selectedCarrier.mc);
        setValue('carrier.dot', selectedCarrier.dot);
      }
    }
  }, [updatedCarrierName, carriers, setValue]);

  const onSubmit: SubmitHandler<Record<string, any>> = async (data, e) => {
    e?.preventDefault();
    e?.stopPropagation();

    setLoading(true);

    if (email?.id == undefined || email?.threadID == undefined) {
      toast(getToastForFailedSubmission());
      setLoading(false);
      return;
    }

    const trucks: Truck[] = getTrucksForSubmit(truckArrayWatcher);
    const carrierInformation = getCarrierForSubmit(data);

    // Clear form errors before submitting
    updateTruckListForm({
      trucks: trucks,
      carrier: carrierInformation,
      errors: undefined,
    });

    const res = await submitTruckList(
      email.id,
      email.threadID,
      truckList.serviceName,
      carrierInformation,
      trucks
    );

    if (res.isOk()) {
      toast(getToastForSuccessSubmission());
    } else {
      if (!res.error.truckListErrors) {
        toast(getToastForFailedSubmission());
        setLoading(false);
        return;
      }

      setActiveTabs([]);

      // Updating form with errors from new submission
      updateTruckListForm({
        trucks,
        carrier: carrierInformation,
        errors: res.error.truckListErrors,
      });

      const {
        carrierErrorsCount,
        carrierContactErrorsCount,
        postedByErrorsCount,
        truckErrorsCount,
      } = getParsedErrors(res.error.truckListErrors);

      if (carrierErrorsCount || carrierContactErrorsCount) {
        toast(getToastForCarrierErrors());
      } else {
        toast(getToastForTruckErrors(truckErrorsCount, postedByErrorsCount));
      }
    }

    setLoading(false);
  };

  const handleValidateCarrier = async () => {
    if (!email) return;

    setLoading(true);

    const carrier = {
      name: getValues('carrier.name') ?? '',
      contactName: getValues('carrier.contact.name') ?? '',
      contactEmail: getValues('carrier.contact.email') ?? '',
      mc: getValues('carrier.mc') ?? '',
      dot: getValues('carrier.dot') ?? '',
    };

    const res = await validateTruckListCarrier(
      email.id,
      email.threadID,
      carrier
    );
    if (res.isOk()) {
      toast({
        title: 'Carrier is valid!',
        variant: 'success',
      });

      setShowCarrierSelector(false);
    } else {
      toast({
        title: 'Invalid carrier, please review before submitting truck list.',
        variant: 'destructive',
      });

      const { carrierErrors, carrierContactErrors } = res.error;

      parseCarrierErrors({
        carrierErrors,
        setError,
        setShowCarrierSelector,
        setCarriers,
      });

      parseCarrierContactErrors({
        carrierContactErrors,
        setError,
      });
    }

    setLoading(false);
  };

  const onInvalid: SubmitErrorHandler<Record<string, any>> = async (errors) => {
    setActiveTabs([]);

    if (errors?.carrier) {
      setActiveTabs(['carrier-details']);
    }

    toast(getToastForInvalidFields());
  };

  const handleAddTruckToGroup = async (date: string) => {
    if (!email) {
      toast({
        title: 'Error',
        description: 'Something went wrong',
        variant: 'destructive',
      });
      return;
    }

    const res = await addTruckToTruckList(
      email.id,
      email.threadID,
      dayjs(date).utc().toISOString()
    );
    if (res.isOk()) {
      toast({ title: 'Truck added to Truck List', variant: 'info' });
      appendTruck({ ...res.value.createdTruck, length: '', isEditing: false });
    } else {
      toast({ title: 'Error adding new Truck', variant: 'destructive' });
    }
  };

  const handleRemoveTruck = async (idx: number) => {
    // Manually logging event since it's not triggered through a Button
    posthog?.capture(ButtonNamePosthog.DeleteTruckFromTruckList);

    const { id } = truckArrayWatcher[idx];
    if (!email || !id) {
      toast(getToastForGenericError());
      return;
    }

    const res = await deleteTruckFromTruckList(email.id, email.threadID, id);
    if (res.isOk()) {
      toast({ title: 'Truck deleted from Truck List', variant: 'info' });
      removeTruck(idx);
    } else {
      toast({ title: 'Error deleting Truck', variant: 'destructive' });
    }
  };

  const handleOverrideDropoff = () => {
    trucksArray.forEach((_, idx) => {
      setValue(
        `trucks.${idx}.dropoffLocation.city`,
        getValues(`override.city`) ?? ''
      );
      setValue(
        `trucks.${idx}.dropoffLocation.state`,
        getValues(`override.state`) ?? ''
      );
      setValue(
        `trucks.${idx}.dropoffDate`,
        dayjs(getValues('override.dropoffDate')).toISOString()
      );
    });

    resetField('override.city');
    resetField('override.state');
    resetField('override.dropoffDate');
  };

  const handleAddNewGroup = () => {
    const createdDate = dayjs(getValues('create.pickupDate')).toISOString();

    handleAddTruckToGroup(createdDate);
    resetField('create.pickupDate');
  };

  const {
    carrierErrors,
    carrierContactErrors,
    truckErrors: truckErrorsObject,
  } = truckList.errors || {};
  const truckErrors = Object.entries(truckErrorsObject ?? {});

  return (
    <TooltipProvider>
      <ExtendedFormProvider
        aiDefaultValues={email?.labels.includes(EmailLabels.TruckList) ?? false}
        aiIconOnly={true}
      >
        {(carrierErrors || carrierContactErrors || truckErrors.length > 0) && (
          <div className='bg-red-500 text-white p-4 rounded-[4px] mt-8'>
            <h3>Error Summary</h3>
            <ul className='text-[12px] list-disc ml-6 mt-2'>
              {carrierErrors && <li>Submitted carrier has errors</li>}
              {carrierContactErrors && (
                <li>Submitted carrier contact has errors</li>
              )}
              {truckErrors.length > 0 && (
                <li>{truckErrors.length} submitted trucks have errors</li>
              )}
            </ul>
          </div>
        )}

        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            {showCarrierSelector ? (
              <div className='rounded-md bg-yellow-50 mt-8 mb-8 p-4'>
                <div className='flex items-start'>
                  <div className='flex-shrink-0 pt-1.5'>
                    <TriangleAlert
                      aria-hidden='true'
                      className='h-4 w-4 text-yellow-500'
                    />
                  </div>
                  <div className='ml-3'>
                    <h3 className='leading-8 text-sm font-medium text-yellow-600'>
                      Multiple carriers were found, please select one below:
                    </h3>
                    <div>
                      <Controller
                        name={`update.carrier`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value as string}
                          >
                            <SelectTrigger className='w-full mt-1'>
                              <SelectValue placeholder={'Choose'} />
                            </SelectTrigger>
                            <SelectContent>
                              {Object.values(carriers).map((carrier) => (
                                <SelectItem
                                  key={carrier.name}
                                  value={carrier.name}
                                >
                                  {carrier.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <Accordion
              type='multiple'
              value={activeTabs}
              onValueChange={setActiveTabs}
              className='w-full'
            >
              <TruckListSectionAccordionItem
                key={'carrier-details'}
                icon={<BadgeInfoIcon />}
                name={`carrier-details`}
                label={`Carrier Details`}
                contentClassName='px-2'
              >
                <RedwoodCarrierFieldset
                  truckListServiceName={truckList.serviceName}
                  handleValidateCarrier={handleValidateCarrier}
                  loading={loading}
                />
              </TruckListSectionAccordionItem>

              <TruckListSectionAccordionItem
                key={'override-dropoff'}
                icon={<MapPinIcon />}
                name={`override-dropoff`}
                label={`Override Dropoff`}
                contentClassName='px-2'
              >
                <RedwoodOverrideDropoffFieldset
                  handleOverrideDropoff={handleOverrideDropoff}
                />
              </TruckListSectionAccordionItem>

              {trucksArrayByPickupDate
                ? Object.entries(trucksArrayByPickupDate)
                    .sort((a, b) => dayjs(a[0]).unix() - dayjs(b[0]).unix())
                    .map(([pickupDate, trucksInGroup]) => {
                      if (!trucksInGroup.length) return;

                      const showPickupDateError = trucksInGroup.some(
                        (t) =>
                          truckList.errors?.truckErrors?.[t.id]
                            ?.pickupDateErrors?.length
                      );

                      return (
                        <div className='mb-2 mt-6'>
                          <div className='flex items-center gap-2'>
                            <h2
                              key={pickupDate}
                              className={`truck-group-${pickupDate} flex items-center gap-2 text-[16px] font-bold`}
                            >
                              {showPickupDateError ? (
                                <TooltipProvider>
                                  <Tooltip delayDuration={10}>
                                    <TooltipTrigger asChild>
                                      <CalendarXIcon className='h-6 w-6 text-red-500 cursor-pointer stroke-red-main' />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      Pickup date is invalid
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              ) : (
                                <CalendarIcon className='h-5 w-5' />
                              )}
                              {dayjs(pickupDate).utc().format('MMM D - dddd')}
                            </h2>
                          </div>

                          <div className='pl-3 ml-2 border-l border-gray-600'>
                            <RedwoodTruckFieldset
                              control={control}
                              errors={errors}
                              trucksInGroup={trucksInGroup}
                              truckArrayWatcher={truckArrayWatcher}
                              trucksArrayByPickupDate={trucksArrayByPickupDate}
                              handleRemoveTruck={handleRemoveTruck}
                              updateTruckPickupDate={updateTruckPickupDate}
                              updateTruck={updateTruck}
                            />

                            <Button
                              className='w-40 mx-auto h-8 text-[14px] text-grayscale-content-2 flex gap-2 hover:border-gray-600 hover:bg-gray-200'
                              buttonNamePosthog={
                                ButtonNamePosthog.CreateTruckForTruckList
                              }
                              onClick={() => handleAddTruckToGroup(pickupDate)}
                              type='button'
                              variant='ghost'
                            >
                              <PlusIcon className='h-4 w-4' />
                              Add new Truck
                            </Button>
                          </div>
                        </div>
                      );
                    })
                : null}
            </Accordion>

            <RedwoodNewGroupFieldset
              handleAddNewGroup={handleAddNewGroup}
              getValues={getValues}
            />

            <Button
              buttonNamePosthog={ButtonNamePosthog.SubmitTruckList}
              type='submit'
              disabled={loading}
              className='w-full mt-6'
            >
              {loading ? (
                <div className='flex gap-2 items-center'>
                  {`Contacting ${truckList.serviceName ?? 'Service'}`}
                  <ButtonLoader />
                </div>
              ) : truckList.serviceName != '' ? (
                `${ButtonText.SubmitTruckListWithServiceName} ${truckList.serviceName}`
              ) : (
                ButtonText.Submit
              )}
            </Button>
          </form>
        </FormProvider>
      </ExtendedFormProvider>
    </TooltipProvider>
  );
}

export type TruckGroupByPickupDate = { [key: string]: FormTruck[] };
export interface RedwoodTruckListInputs {
  carrier: {
    name: MaybeUndef<string>;
    dot: MaybeUndef<string>;
    mc: MaybeUndef<string>;
    contact: {
      name: MaybeUndef<string>;
      email: MaybeUndef<string>;
    };
  };
  override: {
    city: MaybeUndef<string>;
    state: MaybeUndef<string>;
    dropoffDate: MaybeUndef<string>;
  };
  update: {
    pickupDate: MaybeUndef<string>;
    carrier: MaybeUndef<string>;
  };
  create: {
    pickupDate: MaybeUndef<string>;
  };
  trucks: FormTruck[];
}
