enum ButtonName {
  ApptConfirmationSuggestionClick = 'Appointment Confirmation Suggestion Click',
  CarrierInfoSuggestionClick = 'Carrier Info Suggestion Click',
  LoadBuildingSuggestionClick = 'Load Building Suggestion Click',
  CheckCallSuggestionClick = 'Check Call Suggestion Click',
  QuickQuoteSuggestionClick = 'Quick Quote Suggestion Click',
  TruckListSuggestionClick = 'Truck List Suggestion Click',
  SubmitCheckCall = 'Submit check call',
  SubmitException = 'Submit exception',
  SubmitNote = 'Submit note',
  ConfirmSlotApptSchedulingLoadAssociated = 'Confirm slot for appointment scheduling load associated',
  ConfirmSlotApptSchedulingLoadIndependent = 'Confirm slot for appointment scheduling load independent',
  DeleteCarrierEmail = 'Delete carrier email',
  FindOpenApptSlotsLoadAssociated = 'Find open appointment slots load associated',
  FindOpenApptSlotsLoadIndependent = 'Find open appointment slots load independent',
  GoToOutboxTab = 'Go to Outbox',
  UpdateCarrierEmail = 'Update carrier email',
  UpdateTMS = 'Update TMS',
  ScheduleCarrierEmails = 'Schedule emails',
  ScheduleShipperEmails = 'Schedule shipper emails',
  ShowCarrierSuggestion = 'Show Carrier Suggestion',
  ShowSmartReply = 'Show Smart Reply',
  ShowLoadIndependentApptSchedulingForm = 'Show Load Independent Appt Scheduling Form',
  SkipCarrierInfoSuggestion = 'Skip Carrier Info Suggestion',
  SkipApptSchedulingSuggestion = 'Skip Appointment Scheduling Suggestion',
  SkipCheckCallSuggestion = 'Skip Check Call Suggestion',
  SubmitTruckList = 'Submit',
  UndoDeleteCarrierEmail = 'Undo delete carrier email',
  BuildLoad = 'Build Load',
  RefreshCustomers = 'Refresh customers',
  RefreshLocations = 'Refresh locations',
  RefreshOperators = 'Refresh operators',
  ClearForm = 'Clear form',
}

export default ButtonName;
