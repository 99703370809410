import { LoadBuildingSuggestions } from './LoadBuildingSuggestions';
import {
  CarrierChanges,
  CheckCallChanges,
  SuggestedLoadChange,
} from './LoadSuggestions';
import { SuggestedQuoteChange } from './QuoteSuggestions';
import {
  SuggestedTruckListChange,
  TruckListCarrier,
} from './TruckListSuggestions';

export enum SuggestionPipelines {
  CarrierInfo = 'carrier_info_pipeline',
  ApptConfirmation = 'appointment_confirmation',
  CheckCall = 'check_call_pipeline',
  QuickQuote = 'quick_quote_pipeline',
  TruckList = 'truck_list_pipeline',
  LoadBuilding = 'load_building_pipeline',
}

export enum SuggestionCategories {
  CarrierInfo = 'carrier_info',
  PickupInfo = 'pickup_info',
  CheckCall = 'check_call',
  QuickQuote = 'quick_quote',
  TruckList = 'truck_list',
  LoadBuilding = 'load_building',
}

export type GenericSuggestion =
  | SuggestedLoadChange
  | SuggestedQuoteChange
  | SuggestedTruckListChange
  | LoadBuildingSuggestions;

export type SuggestionChangeRecord = Record<
  string,
  | CarrierChanges[keyof CarrierChanges]
  | CheckCallChanges[keyof CheckCallChanges]
  | TruckListCarrier[keyof TruckListCarrier]
>;

export type SuggestionAppliedPair<T> = {
  suggestion: T;
  applied: T | string;
};
