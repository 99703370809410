// These enums must match those in drumkit/common/models/integration.
export enum TMS {
  Aljex = 'aljex',
  Turvo = 'turvo',
  Relay = 'relay',
  Tai = 'tai',
  McleodEnterprise = 'mcleodenterprise',
  Mcleod = 'mcleod',
}

export enum Quoting {
  Greenscreens = 'greenscreens',
  GlobalTranz = 'globaltranz',
}

export enum CarrierVerification {
  MyCarrierPortal = 'mycarrierportal',
  Highway = 'highway',
  FreightValidate = 'freightvalidate',
  Truckstop = 'truckstop',
}

// Map enums to their human-readable names
export const integrationNameMap: Record<TMS | Quoting, string> = {
  [Quoting.Greenscreens]: 'Greenscreens',
  [Quoting.GlobalTranz]: 'GlobalTranz',
  [TMS.Aljex]: 'Aljex',
  [TMS.Turvo]: 'Turvo',
  [TMS.Relay]: 'Relay',
  [TMS.Tai]: 'Tai',
  // Typically customers have Mcleod Enterprise or Mcleod Innovation so we can use "Mcleod" for both keep user-facing name concise
  [TMS.McleodEnterprise]: 'McLeod',
  [TMS.Mcleod]: 'McLeod',
};
