import { isAxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import {
  GetSlotsResponse,
  GroupedSlot,
  OrderedSlots,
  Slot,
} from 'types/Appointment';
import { SchedulingPortals } from 'types/Appointment';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

dayjs.extend(timezone);

// for retalix
interface WarehouseDetails {
  warehouseID: string;
  warehouseName: string;
}

interface BaseGetOpenSlotsProps {
  // TODO: move this to GetOpenSlotsOpendock after updating the backend
  freightTrackingID?: string;
  loadTypeID: string;
  // TODO: move this to GetOpenSlotsOpendock after updating the backend
  warehouseID: string;
  warehouseTimezoneStartDate: dayjs.Dayjs;
  warehouseTimezoneEndDate: dayjs.Dayjs;
}

interface GetOpenSlotsRetalix {
  source: SchedulingPortals.Retalix;
  warehouse: WarehouseDetails;
  poNumbers: string[];
}

interface GetOpenSlotsOpendock {
  source: SchedulingPortals.Opendock;
  trailerType?: string;
  dockId?: string;
}

type GetOpenSlotsProps = BaseGetOpenSlotsProps &
  (GetOpenSlotsRetalix | GetOpenSlotsOpendock);

export async function getOpenApptSlots(
  props: GetOpenSlotsProps
): Promise<Result<OrderedSlots, ApiError>> {
  try {
    const base = '/appt/slots';
    const params: string[] = [];

    // Common parameters
    params.push(`loadTypeID=${encodeURIComponent(props.loadTypeID)}`);
    params.push(
      `start=${encodeURIComponent(props.warehouseTimezoneStartDate.format())}`
    );
    params.push(
      `end=${encodeURIComponent(props.warehouseTimezoneEndDate.format())}`
    );
    params.push(`source=${encodeURIComponent(props.source)}`);

    if (props.source === SchedulingPortals.Retalix) {
      if (!props.warehouse || !props.poNumbers?.length) {
        throw new Error(
          'Warehouse details and PO numbers are required for Retalix'
        );
      }

      params.push(
        `warehouse.warehouseID=${encodeURIComponent(props.warehouse.warehouseID)}`
      );
      params.push(
        `warehouse.warehouseName=${encodeURIComponent(props.warehouse.warehouseName)}`
      );
      params.push(
        `poNumbers=${encodeURIComponent(JSON.stringify(props.poNumbers))}`
      );
    } else {
      // Opendock specific parameters
      params.push(`warehouseID=${encodeURIComponent(props.warehouseID)}`);

      if (props.freightTrackingID) {
        params.push(
          `freightTrackingID=${encodeURIComponent(props.freightTrackingID)}`
        );
      }

      if (props.trailerType) {
        params.push(`trailerType=${encodeURIComponent(props.trailerType)}`);
      }

      if (props.dockId) {
        params.push(`dockID=${encodeURIComponent(props.dockId)}`);
      }
    }

    const url = `${base}?${params.join('&')}`;

    const response = await axios.get(url);

    if (props.source === SchedulingPortals.Retalix) {
      if (!response.data || !response.data.slots) {
        return err({ message: 'Invalid response format' });
      }

      // Transform response for Retalix
      const transformedResponse: GetSlotsResponse = {
        trailerType: response.data.trailerType || '',
        start: response.data.start,
        end: response.data.end,
        loadType: {
          id: response.data.loadType.id || '',
          name: response.data.loadType.name || '',
          direction: response.data.loadType.direction || '',
        },
        warehouse: {
          warehouseID: response.data.warehouse.warehouseID,
          company_name: response.data.warehouse.warehouseName,
          location_name: response.data.warehouse.warehouseFullAddress,
          warehouseAddressLine1: response.data.warehouse.warehouseAddressLine1,
          warehouseAddressLine2: response.data.warehouse.warehouseAddressLine2,
          city: '',
          state: '',
          zip: '',
          contact_email: response.data.warehouse.defaultSubscribedEmail || '',
          contact_phone: '',
        },
        slots: response.data.slots.map((slot: any) => ({
          dock: {
            id: slot.dock.id || '',
            name: slot.dock.name || '',
            warehouseId: slot.dock.warehouseId || '',
            orgId: '', // Required by interface
            loadTypeIds: slot.dock.loadTypeIds || [],
          },
          availability: [], // Required by interface
          startTimes: slot.startTimes,
          warehouse: {
            id: slot.warehouse.warehouseID,
            name: slot.warehouse.warehouseName,
            orgId: '', // Required by interface
            warehouseTimezone: slot.warehouse.warehouseTimezone || 'UTC',
            email: slot.warehouse.defaultSubscribedEmail || '',
          },
        })),
      };

      const newGroupedSlots = groupSlotsByDate(transformedResponse);
      return ok(newGroupedSlots);
    } else {
      // For Opendock, use response directly
      const newGroupedSlots = groupSlotsByDate(response.data);
      return ok(newGroupedSlots);
    }
  } catch (error) {
    captureException(error, { functionName: 'getOpenApptSlots' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
    });
  }
}

function groupSlotsByDate(resp: GetSlotsResponse): OrderedSlots {
  const grouped: Record<string, GroupedSlot[]> = {};
  let warehouseTimezone = '';

  resp.slots.forEach((slot: Slot) => {
    if (warehouseTimezone === '') {
      warehouseTimezone = slot.warehouse.warehouseTimezone;
    }

    slot.startTimes.forEach((startTime: string) => {
      const startTimeWithTimezone = dayjs(startTime).tz(
        slot.warehouse.warehouseTimezone
      );

      const date = formatDateToMMDD(startTimeWithTimezone);

      if (!grouped[date]) {
        grouped[date] = [];
      }

      const hasSlotOnDateTime = grouped[date].some(
        (s) =>
          startTimeWithTimezone.toDate().toISOString() ===
          s.startTime.toISOString()
      );

      if (hasSlotOnDateTime) {
        return;
      }

      grouped[date].push({
        dock: slot.dock,
        startTime: startTimeWithTimezone.toDate(),
        timezone: slot.warehouse.warehouseTimezone,
      });
    });
  });

  for (const date in grouped) {
    grouped[date].sort((a: GroupedSlot, b: GroupedSlot) => {
      return dayjs(a.startTime).diff(dayjs(b.startTime));
    });
  }

  return {
    start: resp.start,
    end: resp.end,
    warehouse: resp.warehouse,
    warehouseTimezone: warehouseTimezone,
    loadType: resp.loadType,
    trailerType: resp.trailerType,
    slots: grouped,
  };
}

function formatDateToMMDD(date: Dayjs): string {
  const formattedDate = date.format('M/D/YY');
  const dayOfWeek = date.format('ddd');

  return `${dayOfWeek}, ${formattedDate}`;
}
