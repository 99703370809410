import { isAxiosError } from 'axios';
import 'dayjs/locale/en';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { EmailCarriersBody } from 'types/QuoteRequest';
import {
  EmailCarriersError,
  EmailCarriersResponse,
} from 'types/api/EmailCarriersResult';
import captureException from 'utils/captureException';

export async function emailCarrierNetwork(
  actualRequest: EmailCarriersBody,
  carrierEmails: string[],
  ccEmails: string[],
  subject: string,
  emailBody: string
): Promise<Result<EmailCarriersResponse, EmailCarriersError>> {
  const requestBody = {
    actualRequest: actualRequest,
    carrierEmails: carrierEmails,
    ccEmails: ccEmails,
    subject: subject,
    emailBody: emailBody,
  };
  try {
    const resp = await axios.post(`/quote/request/carrierNetwork`, requestBody);

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'emailCarrierNetwork' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to email carriers', countSuccess: 0 });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      results: error.response?.data.results || [],
      countSuccess: error.response?.data.countSuccess ?? 0,
      message:
        error.response?.data.message ||
        error.response?.data ||
        'Failed to email carriers',
    });
  }
}
