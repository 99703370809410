import { useRef, useState } from 'react';

import { AlertTriangle, CheckIcon, XIcon } from 'lucide-react';

import { getValidChangeElements } from 'components/AISuggestions/SuggestionsCarousel';
import { Separator } from 'components/Separator';
import { TransportType } from 'lib/api/getQuickQuote';
import { Maybe } from 'types/UtilityTypes';
import {
  GenericSuggestion,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import { flattenSuggestionChanges } from 'utils/flattenSuggestionChanges';
import { getSuggestionTitleByPipeline } from 'utils/suggestions/getSuggestionTitleByPipeline';
import { formatFileName } from 'utils/suggestions/suggestionFormat';

import EmailAIIcon from '../../assets/email-ai-icon-64.png';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../Tooltip';

type SuggestionCardProps = {
  suggestion: GenericSuggestion;
  clickedSuggestion: Maybe<GenericSuggestion>;
  handleClearSuggestion: (
    e: React.MouseEvent<SVGSVGElement>,
    suggestion: GenericSuggestion
  ) => Promise<void>;
  handleApplySuggestion: (suggestion: GenericSuggestion) => void;
  pipelineGroupIndex: Maybe<number>;
  pipelineGroupTotal: Maybe<number>;
};

export const MaxElementsInCollapsedView = 3;

export const SuggestionCard = ({
  suggestion,
  clickedSuggestion,
  handleClearSuggestion,
  handleApplySuggestion,
  pipelineGroupIndex,
  pipelineGroupTotal,
}: SuggestionCardProps) => {
  const [showAllChanges, setShowAllChanges] = useState(false);
  const hoverTimerRef = useRef<Maybe<NodeJS.Timeout>>(null);
  const [isCheckTooltipHovered, setIsCheckTooltipHovered] = useState(false);
  const showPipelineGroupIndex =
    pipelineGroupIndex &&
    pipelineGroupTotal &&
    pipelineGroupTotal > 1 &&
    (suggestion.pipeline === SuggestionPipelines.LoadBuilding ||
      suggestion.pipeline === SuggestionPipelines.QuickQuote);

  // extracted logic to flatten and get change elements
  const changes =
    suggestion.pipeline === SuggestionPipelines.CheckCall
      ? suggestion.suggested.checkCallChanges
      : suggestion.suggested;

  const flattenedChanges = flattenSuggestionChanges(changes);
  const { elements, validChangesCount } = getValidChangeElements(
    flattenedChanges,
    suggestion
  );

  const hasSpecialTransport =
    suggestion.pipeline === SuggestionPipelines.QuickQuote &&
    suggestion.suggested.transportType === TransportType.SPECIAL;
  const hasHiddenChanges = validChangesCount > MaxElementsInCollapsedView;
  const initialChangeElements = elements.slice(0, MaxElementsInCollapsedView);
  const hiddenChangeElements = elements.slice(MaxElementsInCollapsedView);

  if (!initialChangeElements || !initialChangeElements.length) return null;

  const handleMouseEnterOtherChanges = () => {
    // timer to expand the card after 300ms
    hoverTimerRef.current = setTimeout(() => {
      setShowAllChanges(true);
      hoverTimerRef.current = null;
    }, 300);
  };

  const handleMouseLeaveOtherChanges = () => {
    // clear timer if it exists
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
      hoverTimerRef.current = null;
    }
  };

  const handleMouseLeaveCard = () => {
    // collapse the card when the mouse leaves the card
    setShowAllChanges(false);
  };

  return (
    <TooltipProvider>
      <div
        onClick={() => {
          if (!hasSpecialTransport) {
            handleApplySuggestion(suggestion);
          }
        }}
      >
        <div
          className={`carousel-card ${
            clickedSuggestion?.id === suggestion.id
              ? 'bg-blue-lightbg drop-shadow-none'
              : `bg-grayscale-bg-secondary ${!hasSpecialTransport && 'drop-shadow-lg carousel-card-hover hover:border-[1.5px]'}`
          }   ${hasSpecialTransport && 'bg-red-lightbg border-red-border cursor-not-allowed'}
           px-3 py-3 my-1 mx-2 border border-blue-main rounded-[4px] cursor-pointer`}
          onMouseLeave={handleMouseLeaveCard}
        >
          <div>
            <div className='flex justify-between items-baseline'>
              <div className='flex flex-col flex-wrap mb-1'>
                <div className='flex flex-row items-center gap-2'>
                  <img
                    src={EmailAIIcon}
                    loading='lazy'
                    alt='Email AI Icon'
                    width={26}
                  />
                  <h3 className='text-md font-bold'>
                    {`${getSuggestionTitleByPipeline(suggestion.pipeline)} 
                    ${showPipelineGroupIndex ? `(${pipelineGroupIndex} of ${pipelineGroupTotal})` : ''}`}
                  </h3>
                </div>
                {suggestion.attachment?.originalFileName && (
                  <div className='text-xs text-grayscale-content-2 italic'>
                    <p className='mb-1 text-xs'>
                      From:{' '}
                      <span
                        className='cursor-help text-xs'
                        title={suggestion.attachment.originalFileName}
                      >
                        {formatFileName(suggestion.attachment.originalFileName)}
                      </span>
                    </p>
                  </div>
                )}
              </div>

              <div className='flex flex-shrink-0 items-center space-x-2'>
                {hasSpecialTransport && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <AlertTriangle className='w-4 h-4 mt-1 text-[#e90e0e]' />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side='left'>
                      Special Transport
                    </TooltipContent>
                  </Tooltip>
                )}
                {clickedSuggestion?.id === suggestion.id ? (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div
                        onMouseEnter={() => setIsCheckTooltipHovered(true)}
                        onMouseLeave={() => setIsCheckTooltipHovered(false)}
                      >
                        {isCheckTooltipHovered ? (
                          <XIcon
                            className='w-4 h-4 mt-1 cursor-pointer'
                            onClick={(e) =>
                              handleClearSuggestion(e, suggestion)
                            }
                          />
                        ) : (
                          <CheckIcon className='w-4 h-4 mt-1' />
                        )}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side='left'>
                      {isCheckTooltipHovered
                        ? 'Clear Suggestion'
                        : 'Suggestion Applied'}
                    </TooltipContent>
                  </Tooltip>
                ) : (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <XIcon
                        className='w-4 h-4 mt-1 cursor-pointer'
                        onClick={(e) => handleClearSuggestion(e, suggestion)}
                      />
                    </TooltipTrigger>
                    <TooltipContent side='left'>
                      Clear Suggestion
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className='w-full'>{initialChangeElements}</div>
            <div
              style={{
                maxHeight: showAllChanges
                  ? `${hiddenChangeElements.length * 18}px`
                  : '0px',
              }}
              className='overflow-hidden transition-all duration-300 ease'
            >
              {hiddenChangeElements}
            </div>
          </div>

          <div>
            <Separator className='my-2 bg-gray-200' />
            <div className='text-grayscale-content-description text-xs text-center mt-2'>
              <div
                className='font-semibold'
                onMouseEnter={handleMouseEnterOtherChanges}
                onMouseLeave={handleMouseLeaveOtherChanges}
              >
                {hasSpecialTransport
                  ? 'For special transport types, consult your team to ensure proper handling.'
                  : hasHiddenChanges
                    ? showAllChanges
                      ? 'Click to review'
                      : `Expand`
                    : 'Click to review'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

export const KeyValueElement = ({
  name,
  changeLabel,
  changeValue,
}: {
  name: string;
  changeLabel: string;
  changeValue: string;
}): JSX.Element => {
  return (
    <div
      key={name}
      className='text-[12px] whitespace-nowrap overflow-hidden text-ellipsis'
    >
      <span className='font-medium'>{`${changeLabel}: `}</span>
      {`${changeValue}`}
    </div>
  );
};
