import { useEffect, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  useForm,
} from 'react-hook-form';

import dayjs from 'dayjs';

import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { DatePicker } from 'components/DatePicker';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { Textarea } from 'components/Textarea';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { useToast } from 'hooks/useToaster';
import { confirmSlotAppt } from 'lib/api/confirmSlotAppt';
import { getOpenApptSlots } from 'lib/api/openApptSlots';
import { submitAppt } from 'lib/api/submitAppt';
import { validateAppt } from 'lib/api/validateAppt';
import {
  GroupedSlot,
  OrderedSlots,
  SchedulingPortals,
  StopTypes,
} from 'types/Appointment';
import { NormalizedLoad } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';
import { cn } from 'utils/shadcn';

import { RetalixPOList } from './RetalixPOList';

// Calling object PurchaseOrders instead of PONumbers to avoid stuttering
export type PurchaseOrder = {
  number: string;
  isValid: boolean;
  error: string;
};

// Add time preference options
const timePreferences = [
  { value: 'Anytime', label: 'Anytime' },
  { value: 'Before Noon', label: 'Before Noon' },
  { value: 'Noon - 6pm', label: 'Noon - 6pm' },
  { value: 'After 6pm', label: 'After 6pm' },
];

interface RetalixInputsWithoutLoad {
  shouldRequestLumper: boolean;
  apptNote: string;
  poNumbers: string;
  requestedDate?: Date;
  timePreference?: string;
}

type RetalixFormProps = {
  load: NormalizedLoad;
  selectedWarehouse: Warehouse;
  type: StopTypes;
};

export function RetalixForm({
  load,
  selectedWarehouse,
  type,
}: RetalixFormProps) {
  const { toast } = useToast();
  const formMethods = useForm<RetalixInputsWithoutLoad>();
  const scrollResultsIntoViewRef = useRef<HTMLDivElement>(null);

  const [isValidatingPOs, setIsValidatingPOs] = useState(false);
  const [isLoadingSlots, setIsLoadingSlots] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [orderedSlots, setOrderedSlots] = useState<Maybe<OrderedSlots>>(null);
  const [selectedSlot, setSelectedSlot] = useState<Maybe<GroupedSlot>>(null);
  const [apptConfirmationNumber, setApptConfirmationNumber] = useState('');
  const [liveAppointmentsAvailable, setLiveAppointmentsAvailable] =
    useState(true);

  const { control, handleSubmit, getValues } = formMethods;

  useEffect(() => {
    setOrderedSlots(null);
  }, [selectedWarehouse]);

  useEffect(() => {
    if (load.poNums && load.poNums !== '') {
      setPurchaseOrders(
        load.poNums
          .split(load.poNums?.includes(',') ? ',' : '/')
          .map((poNum) => ({ number: poNum, isValid: false, error: '' }))
      );
    }
  }, [load]);

  const validatePONumbers = async (poNumbers: string[]) => {
    setIsValidatingPOs(true);

    try {
      const validateRes = await validateAppt(
        selectedWarehouse.warehouseID,
        selectedWarehouse.warehouseSource,
        poNumbers
      );

      if (validateRes.isOk()) {
        const { validatedPONumbers } = validateRes.value;

        if (validatedPONumbers.length === 0) {
          toast({
            description: 'No PO numbers to validate.',
            variant: 'default',
          });
          return false;
        }

        setPurchaseOrders(
          validatedPONumbers.map((poNum) => ({
            number: poNum.poNumber,
            isValid: poNum.isValid,
            error: poNum.error,
          }))
        );

        const allPOsValid = validatedPONumbers.every(
          (po) => po.isValid && !po.error
        );

        if (!allPOsValid) {
          toast({
            description: 'Some PO numbers are invalid.',
            variant: 'destructive',
          });
        }

        return allPOsValid;
      } else {
        toast({
          description: 'Failed to validate PO numbers.',
          variant: 'destructive',
        });
        return false;
      }
    } catch {
      toast({
        description: 'An error occurred during validation.',
        variant: 'destructive',
      });
      return false;
    } finally {
      setIsValidatingPOs(false);
    }
  };

  const loadAvailableSlots = async (validatedPONumbers: string[]) => {
    setIsLoadingSlots(true);

    try {
      const res = await getOpenApptSlots({
        loadTypeID: 'placeholder-value',
        warehouseID: selectedWarehouse?.warehouseID ?? '',
        warehouseTimezoneStartDate: dayjs(),
        warehouseTimezoneEndDate: dayjs(),
        freightTrackingID: '',
        source: SchedulingPortals.Retalix,
        warehouse: {
          warehouseID: selectedWarehouse?.warehouseID ?? '',
          warehouseName: selectedWarehouse?.warehouseName ?? '',
        },
        poNumbers: validatedPONumbers,
      });

      if (res.isOk()) {
        setOrderedSlots(res.value);
        setLiveAppointmentsAvailable(true);

        if (!res.value?.slots || Object.keys(res.value.slots).length === 0) {
          toast({
            description: 'No available appointment times found.',
            variant: 'default',
          });
          return false;
        }
        return true;
      } else {
        if (
          res.error.message ===
          'Live appointments are not available at this time'
        ) {
          setLiveAppointmentsAvailable(false);
          toast({
            description:
              'Option 2 (Live Appointments) is not available for ' +
              'this warehouse. Please use Option 1 to submit an ' +
              'appointment request.',
            variant: 'default',
          });
        } else {
          toast({
            description:
              res.error.message || 'Failed to load available appointments.',
            variant: 'destructive',
          });
        }
        return false;
      }
    } catch {
      toast({
        description: 'Failed to load available appointments.',
        variant: 'destructive',
      });
      return false;
    } finally {
      setIsLoadingSlots(false);
    }
  };

  const handleValidateAppt = async () => {
    const poNumbers = purchaseOrders.map((po) => po.number);

    const isValid = await validatePONumbers(poNumbers);
    if (!isValid) return;

    await loadAvailableSlots(poNumbers);
  };

  const handleConfirmAppointment = async () => {
    if (!selectedSlot || !orderedSlots || !selectedWarehouse) {
      toast({
        description: 'Please select an available time slot',
        variant: 'default',
      });
      return;
    }

    // Get validated PO numbers
    const validPoNumbers = purchaseOrders
      .filter((po) => po.isValid && !po.error)
      .map((po) => po.number);

    if (!validPoNumbers.length) {
      toast({
        description: 'No valid PO numbers found',
        variant: 'destructive',
      });
      return;
    }

    setIsLoadingConfirm(true);

    const res = await confirmSlotAppt({
      // TODO: update Relay with confirmation number
      source: SchedulingPortals.Retalix,
      isTMSLoad: false,
      isV2: true,
      stopType: type,
      start: selectedSlot.startTime,
      loadTypeId: orderedSlots.loadType.id || 'placeholder',
      warehouseID: orderedSlots.warehouse.warehouseID,
      warehouseTimezone: selectedWarehouse.warehouseTimezone || 'UTC',
      dockId: selectedSlot.dock.id || 'placeholder',
      loadID: load.ID!,
      freightTrackingId: load.freightTrackingID || 'placeholder', // PRO Number
      trailerType: orderedSlots.trailerType,
      subscribedEmail: '',
      notes: 'Booking appointment',
      poNums: validPoNumbers.join(','),
    });

    if (res.isOk()) {
      setApptConfirmationNumber(res.value.ConfirmationNo);
    } else {
      if (res.error.message === 'Conflicting Appointments') {
        toast({
          title: 'Conflicting Appointments',
          description:
            "Make sure you don't have an existing appointment for this load.",
          variant: 'destructive',
        });
      } else {
        toast({
          description: res.error.message,
          variant: 'destructive',
        });
      }
    }

    setIsLoadingConfirm(false);
  };

  const handleSubmitAppt = async () => {
    setIsLoadingSubmit(true);

    const formValues = getValues();

    const request = {
      warehouseId: selectedWarehouse.warehouseID,
      source: selectedWarehouse.warehouseSource,
      poNumbers: purchaseOrders.map((po) => po.number),
      lumperRequested: formValues.shouldRequestLumper,
      note: formValues.apptNote,
      requestedDate: formValues.requestedDate
        ? dayjs(formValues.requestedDate).format()
        : undefined,
      timePreference: formValues.timePreference || undefined,
    };

    const submitRes = await submitAppt(request);

    if (submitRes.isOk()) {
      toast({
        description: 'Your appointment has been submitted.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'An error occurred while submitting your appointment.',
        variant: 'destructive',
      });
    }

    setIsLoadingSubmit(false);
  };

  const onInvalid: SubmitErrorHandler<any> = async () => {
    toast({
      description: 'Some fields are invalid.',
      variant: 'destructive',
    });
  };

  const canSubmit =
    purchaseOrders.length > 0 &&
    purchaseOrders.every((po) => po.isValid && po.error === '');

  useEffect(() => {
    if (canSubmit) {
      scrollResultsIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [canSubmit]);

  return (
    <ExtendedFormProvider aiDefaultValues={true}>
      <FormProvider {...formMethods}>
        <div className='col-span-6'>
          <div className='mt-2'>
            <form
              onSubmit={handleSubmit(handleSubmitAppt, onInvalid)}
              className='flex flex-col gap-4 mt-4 mx-0 w-full'
            >
              <div>
                <Label name='poNumbers'>Customer PO #</Label>
                <RetalixPOList
                  purchaseOrders={purchaseOrders}
                  setPurchaseOrders={setPurchaseOrders}
                />
              </div>

              <Button
                buttonNamePosthog={ButtonNamePosthog.ValidateRetalixPONumbers}
                className='w-full'
                type='button'
                disabled={!purchaseOrders.length || isValidatingPOs}
                onClick={handleValidateAppt}
              >
                {isValidatingPOs ? (
                  <ButtonLoader />
                ) : (
                  ButtonText.ValidatePONumbers
                )}
              </Button>

              {canSubmit && (
                <div
                  className='flex flex-col gap-8 mt-4'
                  ref={scrollResultsIntoViewRef}
                >
                  {/* Submit appointment request section - always visible */}
                  <div className='rounded-lg border border-gray-200 p-4 bg-white shadow-sm'>
                    <div className='mb-6'>
                      <span className='text-sm font-medium text-gray-500 block mb-2'>
                        Option 1
                      </span>
                      <h2 className='text-lg font-semibold text-gray-900 mb-2'>
                        Submit Appointment Request
                      </h2>
                      <p className='text-sm text-gray-500 mt-1'>
                        Submit a request for review. You will be notified once
                        approved.
                      </p>
                    </div>

                    <Controller
                      name='shouldRequestLumper'
                      control={control}
                      render={({ field }) => (
                        <div className='flex items-center space-x-2'>
                          <Checkbox
                            onCheckedChange={field.onChange}
                            checked={field.value}
                          />
                          <label
                            htmlFor='shouldRequestLumper'
                            className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                          >
                            Request Lumper?
                          </label>
                        </div>
                      )}
                    />

                    <div className='space-y-6 py-4'>
                      <div className='space-y-2'>
                        <Label name='requestedDate'>
                          Requested Delivery Date
                        </Label>
                        <Controller
                          name='requestedDate'
                          control={control}
                          render={({ field }) => <DatePicker field={field} />}
                        />
                      </div>

                      <div className='space-y-2'>
                        <Label name='timePreference'>Preferred Time</Label>
                        <Controller
                          name='timePreference'
                          control={control}
                          render={({ field }) => (
                            <Select
                              onValueChange={field.onChange}
                              value={field.value}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder='Select preferred time' />
                              </SelectTrigger>
                              <SelectContent>
                                {timePreferences.map(({ value, label }) => (
                                  <SelectItem key={value} value={value}>
                                    {label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </div>
                    </div>

                    <div className='flex flex-col gap-2 mt-4'>
                      <Label name='apptNote' className='text-base'>
                        Appointment Note
                      </Label>
                      <Controller
                        control={control}
                        name='apptNote'
                        render={({ field: { onChange, value } }) => (
                          <Textarea
                            className='p-2 h-16 whitespace-pre-wrap focus-visible:ring-transparent'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </div>

                    <Button
                      buttonNamePosthog={ButtonNamePosthog.SubmitRetalixAppt}
                      type='submit'
                      className='mt-4 w-full'
                      disabled={!canSubmit || isLoadingSubmit}
                    >
                      {isLoadingSubmit ? <ButtonLoader /> : 'Submit'}
                    </Button>
                  </div>

                  {/* Live Appointment Section */}
                  <div
                    className={cn(
                      'rounded-lg border border-gray-200 p-4 bg-white shadow-sm',
                      !liveAppointmentsAvailable &&
                        'opacity-50 pointer-events-none'
                    )}
                  >
                    <div className='mb-6'>
                      <span className='text-sm font-medium text-gray-500 block mb-2'>
                        Option 2
                      </span>
                      <h2 className='text-lg font-semibold text-gray-900 mb-2'>
                        Book Live Appointment
                      </h2>
                      <p className='text-sm text-gray-500'>
                        {liveAppointmentsAvailable
                          ? 'Book an appointment immediately from available time slots.'
                          : 'Live appointments are not available for this warehouse. ' +
                            'Please use Option 1 to submit an appointment request.'}
                      </p>
                    </div>

                    {isValidatingPOs ? (
                      <div className='text-center'>
                        <div className='flex items-center justify-start gap-2 mt-4'>
                          <ButtonLoader />
                          <p>Validating PO numbers...</p>
                        </div>
                      </div>
                    ) : isLoadingSlots ? (
                      <div className='text-center'>
                        <div className='flex items-center justify-start gap-2 mt-4'>
                          <ButtonLoader />
                          <p>Loading open slots...</p>
                        </div>
                      </div>
                    ) : orderedSlots ? (
                      <div>
                        {Object.entries(orderedSlots.slots).map(
                          ([date, slots]) => (
                            <div key={date}>
                              <h3 className='text-grayscale-content-description font-bold uppercase text-sm mt-4'>
                                {date}
                              </h3>
                              <div className='grid grid-cols-3 gap-1 mt-2 mx-0 w-full'>
                                {slots.map((slot, idx) => (
                                  <Button
                                    key={idx}
                                    type='button'
                                    onClick={() =>
                                      setSelectedSlot(
                                        selectedSlot === slot ? null : slot
                                      )
                                    }
                                    className={cn(
                                      'bg-white border border-grayscale-border-outline p-1 py-2 rounded cursor-pointer text-sm hover:bg-orange-hover hover:text-white text-black',
                                      selectedSlot === slot &&
                                        'bg-orange-pressed border-orange-pressed text-white'
                                    )}
                                  >
                                    {dayjs.utc(slot.startTime).format('HH:mm')}
                                  </Button>
                                ))}
                              </div>
                            </div>
                          )
                        )}

                        {selectedSlot && (
                          <div className='mt-4 text-grayscale-content-description text-left text-sm'>
                            <p className='my-1 font-bold'>Selected Slot:</p>
                            <p className='mb-2'>
                              {dayjs
                                .utc(selectedSlot.startTime)
                                .format('MMM D, YYYY, HH:mm')}
                            </p>
                            {apptConfirmationNumber ? (
                              <div className='whitespace-pre-wrap my-3 rounded py-3 text-grayscale-content-1 px-4 bg-green-bg'>
                                <p className='mb-2'>Appointment confirmed 🎉</p>
                                <p className='mb-4 text-[14px]'>
                                  <b className='text-[14px]'>
                                    Retalix Confirmation #:{' '}
                                  </b>
                                  {apptConfirmationNumber}
                                </p>
                              </div>
                            ) : (
                              <Button
                                onClick={handleConfirmAppointment}
                                className='mt-4 w-full'
                                disabled={isLoadingConfirm}
                                buttonNamePosthog={
                                  ButtonNamePosthog.ConfirmSlotApptScheduling
                                }
                              >
                                {isLoadingConfirm ? (
                                  <ButtonLoader />
                                ) : (
                                  'Confirm Appointment'
                                )}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Button
                        onClick={handleValidateAppt}
                        disabled={
                          !purchaseOrders.length || !liveAppointmentsAvailable
                        }
                        className='mt-4 w-full'
                      >
                        Load available times
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </FormProvider>
    </ExtendedFormProvider>
  );
}
