import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import AuthService from '@auth/AuthService';
import DRUMKIT_AUTH_URL from '@constants/DrumkitAuthUrl';
import isProd from '@utils/isProd';

import { TitlebarButtons } from 'components/Titlebar';
import SidebarLoader from 'components/loading/SidebarLoader';
import { SidebarViewContext } from 'contexts/sidebarViewContext';
import useFetchEmail from 'hooks/useFetchEmail';
import { useServiceFeatures } from 'hooks/useServiceContext';
import LoadsSidebar from 'pages/LoadView/LoadsSidebar';
import QuoteSidebar from 'pages/QuoteView/QuoteSidebar';
import { initialIngestionMessage } from 'types/IngestionMessage';
import SidebarView from 'types/enums/SidebarView';
import { getViewBasedOnEmail } from 'utils/getViewBasedOnEmail';

import { LoadSearchProvider } from '../alexandria/contexts/loadSearchContext';
import { useOfficeContext } from './OfficeContext';
import { usePortalContext } from './PortalContext';

export default function SidebarOutlookWrapper() {
  const { state } = useOfficeContext();
  const { setPortalUrl } = usePortalContext();
  const { currentView, setCurrentView } = useContext(SidebarViewContext);
  const ingestionMessage = initialIngestionMessage;

  // To make local dev easier, map last message in thread to ingestion
  // format
  if (!isProd() && state?.mailbox) {
    const item = state.mailbox.item!;
    ingestionMessage.messageID = item.itemId!;
    ingestionMessage.fromName = item.from.displayName;
    ingestionMessage.fromEmail = item.from.emailAddress;
    ingestionMessage.toName = item.to[0]?.displayName;
    ingestionMessage.toEmail = item.to[0]?.emailAddress;
    item.body.getAsync('text', ({ status, value }) => {
      if (status === Office.AsyncResultStatus.Succeeded) {
        ingestionMessage.body = value;
      }
    });
    ingestionMessage.subject = item.subject;
    ingestionMessage.date = dayjs(item.dateTimeCreated?.toString()).format(
      'MMM D, YYYY, h:mm A'
    );
  }

  const { email, isLoading } = useFetchEmail(
    state?.mailbox?.item?.conversationId as string,
    ingestionMessage
  );
  const navigate = useNavigate();

  const { serviceFeaturesEnabled } = useServiceFeatures();

  useEffect(() => {
    const handleAuth = async () => {
      if (email?.needsReauthorization) {
        await AuthService.logOut();

        const customPortalDomain = email?.customPortalDomain;
        const portalHost = customPortalDomain ?? DRUMKIT_AUTH_URL;
        const portalUrl = portalHost + '/login';

        setPortalUrl(portalUrl);
        navigate('/portal/login');
      }
    };

    handleAuth();

    const defaultView = getViewBasedOnEmail(email, serviceFeaturesEnabled);

    if (currentView !== defaultView) {
      setCurrentView(defaultView);
    }
  }, [email, serviceFeaturesEnabled]);

  if (isLoading) {
    return <SidebarLoader />;
  }

  // If switching extension environments, token signature will be
  // invalidated. Use logout to start fresh.
  const outlookLogout = () => {
    AuthService.logOut();
    navigate('/login');
  };

  return (
    <LoadSearchProvider
      initialFreightTrackingIDs={email?.freightTrackingIDs || []}
    >
      <div
        className='flex flex-col py-2'
        style={{ minHeight: 'calc(100vh - 44px)' }}
      >
        <div className='flex flex-row justify-end gap-x-2 px-3 z-'>
          <TitlebarButtons outlookLogout={outlookLogout} />
        </div>

        {currentView === SidebarView.Quote ? (
          <QuoteSidebar email={email} />
        ) : (
          currentView === SidebarView.Loads && <LoadsSidebar email={email} />
        )}
      </div>
    </LoadSearchProvider>
  );
}
