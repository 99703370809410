import { useEffect, useState } from 'react';
import {
  Controller,
  FieldErrors,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { RefreshCwIcon, XCircleIcon } from 'lucide-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

import {
  DebounceSelect,
  GenericLocationOption,
  ValueType,
} from 'components/DebounceSelect';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { hasRequiredValidation } from 'components/input/RHFTextInput';
import { useFieldAttributes } from 'hooks/useLoadContext';
import {
  FieldAttributes,
  getFieldAttribute,
  initFieldAttributes,
} from 'types/LoadAttributes';
import { Maybe, Undef } from 'types/UtilityTypes';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import {
  CarrierSearchableFields,
  GenericCompanySearchableFields,
} from 'utils/loadInfoAndBuilding';
import { cn } from 'utils/shadcn';

type RHFDebounceSelectProps<TFieldValues extends FieldValues> = Pick<
  UseControllerProps<TFieldValues>,
  'name' | 'rules' | 'control' | 'disabled'
> & {
  label: string;
  errors: FieldErrors;
  data: Maybe<any[]>; // List of objects (locations/customers) which will then be mapped to options
  fetchOptions: (
    field: GenericCompanySearchableFields,
    searchTerm: string
  ) => Promise<ValueType[]>;
  mapOptions: (data: Maybe<any[]>) => ValueType[];
  refreshHandler: () => void;
  resetOptionsHandler?: () => void;
  isLoading: boolean;

  required?: boolean; // Default = true
  requiredIcon?: boolean;
  placeholder?: string; // Default = 'Search'
  showSearchParamDropdown?: boolean; // Default = true
  searchParamOptions?: {
    key: GenericCompanySearchableFields | CarrierSearchableFields;
    label: string;
  }[];
  // Optional fields for parent component to control the value/display of its child DebounceSelect input.
  // Example: The debounce select is used to search a location by street address, and when selected, we want to update the parent
  // `${stop}.externalTMSID` field with the selected location's externalTMSID,
  //  and `${stop}.addressLine1` with the selected location's addressLine1. See example usage in
  //   [McleodSectionForms/Stop.tsx](https://github.com/drumkitai/alexandria/pages/QuoteView/LoadBuilding/McleodSectionForms/Stop.tsx#L190-L210)
  controllingParentValue?: ValueType;
  valueRenderer?: (selectedOption: ValueType) => ValueType;
  parentOnChange?: (value: ValueType) => void;
};

// TODO: Use more type safety https://shorturl.at/SxZ7T
export function RHFDebounceSelect<TFieldValues extends FieldValues>({
  name,
  label,
  disabled,
  control,
  errors,
  rules,
  fetchOptions,
  mapOptions,
  data: initData,
  isLoading: initIsLoading,
  refreshHandler,
  resetOptionsHandler,
  controllingParentValue: initParentValue,
  valueRenderer,
  parentOnChange,
  placeholder = 'Search',
  required = true,
  showSearchParamDropdown = true,
  searchParamOptions = [
    { key: 'name', label: 'Name' },
    { key: 'addressLine1', label: 'Street' },
  ],
}: RHFDebounceSelectProps<TFieldValues>) {
  const allFieldAttrs = useFieldAttributes();

  const thisFieldAttr: FieldAttributes = (() => {
    // Handle fields of type `ValueUnit`
    const normalizedName = name.replace('.val', '');

    const res = getFieldAttribute(allFieldAttrs, normalizedName);
    return res ?? initFieldAttributes;
  })();

  required = required || hasRequiredValidation(rules as RegisterOptions);
  const [curParentValue, setCurParentValue] =
    useState<Undef<ValueType>>(initParentValue);
  const [curDataList, setCurDataList] = useState<Maybe<ValueType[]>>(initData);
  const [isLoadingDebounceSelect, setIsLoadingDebounceSelect] = useState(false);
  const [selectedObj, setSelectedObj] = useState<Maybe<ValueType>>(null);
  const [searchField, setSearchField] = useState<string>('name');

  const posthog = usePostHog();

  useEffect(() => {
    if (initData) {
      setCurDataList(initData);
    }
  }, [initData]);

  useEffect(() => {
    setIsLoadingDebounceSelect(initIsLoading);
  }, [initIsLoading]);

  useEffect(() => {
    setCurParentValue(initParentValue);
  }, [initParentValue]);

  return (
    <div className='flex flex-row items-start w-full gap-2 whitespace-nowrap'>
      {showSearchParamDropdown && !thisFieldAttr.isReadOnly && (
        <div className='w-[85px] flex-shrink-0'>
          <Label name='searchParam' className='text-[8px]'>
            Search by
          </Label>
          <Select
            value={searchField}
            disabled={disabled || thisFieldAttr.isReadOnly}
            onValueChange={(value) => {
              setSearchField(value);
              posthog?.capture(
                ButtonNamePosthog.LoadBuildingLocationNameVsStreet,
                {
                  searchField,
                }
              );
            }}
          >
            <SelectTrigger className='mt-1 h-8'>
              <SelectValue placeholder='Search by...' />
            </SelectTrigger>
            <SelectContent className=''>
              {searchParamOptions.map((option) => (
                <SelectItem
                  key={option.key}
                  value={option.key}
                  className='text-xs'
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
      <div className={cn('flex flex-col w-full min-w-0')}>
        <Label name={name} required={required}>
          {label}
        </Label>

        <div className='flex flex-row w-full items-center gap-2'>
          <div className='flex-grow min-w-0 overflow-hidden'>
            <Controller
              name={name}
              control={control}
              disabled={disabled || thisFieldAttr.isReadOnly}
              rules={{ required: required ? 'Required' : undefined, ...rules }}
              render={({ field }) => {
                let selectedOption: ValueType = mapOptions(curDataList).find(
                  (option) => option.value === (curParentValue ?? field.value)
                );
                // This ensures that if user's searched list does not include the currently selected object,
                // we can still show the selected object onBlur
                if (!selectedOption && selectedObj && field.value) {
                  selectedOption = mapOptions([selectedObj])[0];
                }

                return (
                  <div className='flex flex-row items-center gap-1 text-grayscale-content-input w-full overflow-hidden'>
                    <div className='relative flex items-center w-full'>
                      <DebounceSelect
                        showSearch
                        disabled={
                          isLoadingDebounceSelect ||
                          disabled ||
                          thisFieldAttr.isReadOnly
                        }
                        className='h-9 text-grayscale-content-input w-full'
                        placeholder={
                          isLoadingDebounceSelect
                            ? 'Loading...'
                            : placeholder
                              ? placeholder
                              : showSearchParamDropdown
                                ? `Search by ${searchField}`
                                : 'Search'
                        }
                        optionFilterProp='children'
                        fetchOptions={(search) =>
                          fetchOptions(
                            searchField as GenericCompanySearchableFields,
                            search
                          )
                        }
                        onChange={(value) => {
                          parentOnChange
                            ? parentOnChange(value)
                            : field.onChange(value.value);
                          const selectedObj = curDataList?.find(
                            (c) => c.externalTMSID === value.value
                          );
                          setSelectedObj(selectedObj);
                        }}
                        value={
                          selectedOption
                            ? valueRenderer
                              ? valueRenderer(selectedOption)
                              : {
                                  label: selectedOption.label,
                                  value: selectedOption.value,
                                }
                            : null
                        }
                        options={mapOptions(curDataList)}
                        optionRender={(option) => (
                          <GenericLocationOption
                            option={option.data}
                            optionFieldsToRender={[
                              'addressLine1',
                              'addressLine2',
                              'city',
                              'state',
                              'zipCode',
                            ]}
                          />
                        )}
                        notFoundContent={
                          <p>
                            {name.includes('customer')
                              ? 'No results found. Try a search with 2 or more characters.'
                              : 'No results found. Try a search with 3 or more characters.'}
                          </p>
                        }
                        onDropdownVisibleChange={(visible) =>
                          !visible && resetOptionsHandler
                            ? resetOptionsHandler()
                            : null
                        }
                        dropdownStyle={{ maxWidth: '100%' }}
                        style={{
                          maxWidth:
                            field.value && !required
                              ? 'calc(100% - 28px)'
                              : 'calc(100% - 8px)',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      />

                      {field.value && !required && (
                        <button
                          title='Clear'
                          onClick={() => field.onChange(null)}
                          className='h-9 flex items-center justify-center absolute right-0'
                        >
                          <XCircleIcon className='w-4 h-4' />
                        </button>
                      )}
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <button
            title='Refresh'
            onClick={refreshHandler}
            type='button'
            className='flex-shrink-0'
          >
            <RefreshCwIcon className='h-4 w-4 cursor-pointer stroke-grayscale-content-input' />
          </button>
        </div>

        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className='text-red-500 text-xs'>{message}</p>
          )}
        />
      </div>
    </div>
  );
}
