import { useContext } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Select as AntdSelect } from 'antd';
import { BaseOptionType } from 'antd/es/select';

import { Label } from 'components/Label';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { NormalizedLoad } from 'types/Load';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';

import {
  LoadBuildingTextInput,
  devDisableRequiredFields,
} from '../McleodLoadBuildingForm';
import {
  equipmentList,
  equipmentSizeList,
  serviceTypes,
  unitsList,
  weightUnitsList,
} from './constants';

export function SpecificationsForm({
  formMethods,
}: {
  formMethods: UseFormReturn<NormalizedLoad>;
}) {
  const {
    control,
    formState: { errors },
    watch,
  } = formMethods;
  // TODO use tms.GetLoadBuildingAttributes

  const modes = ['Truckoad'];

  const {
    currentState: { clickedSuggestion },
  } = useContext(SidebarStateContext);

  const isCommoditiesSupported = true;
  const isWeightSupported = true;

  const watchedCommodities = watch('specifications.commodities');
  const watchedGrossWeight = watch('specifications.totalWeight.val');
  const watchedNetWeight = watch('specifications.netWeight.val');

  return (
    <div className='grid grid-cols-4 gap-2 mx-0 w-full'>
      {/* Only Truckloads supported rn, not LTL */}
      <div className='col-span-4'>
        <Label hideAIHint={true} name={'mode'} required={true}>
          Mode
        </Label>
        {/* <div className='flex flex-row w-full items-center gap-2'> */}
        <Controller
          name='mode'
          control={control}
          rules={{ required: devDisableRequiredFields ? false : 'Required' }}
          render={({ field }) => (
            <div className='text-grayscale-content-input'>
              <AntdSelect
                showSearch
                disabled={false} // Only Truckloads supported rn, not LTL
                className='h-9 text-grayscale-content-input'
                placeholder={'Choose'}
                optionFilterProp='children'
                filterOption={(
                  input: string,
                  option: BaseOptionType | undefined
                ) =>
                  (option?.label.toLocaleLowerCase() ?? '').includes(
                    input.toLocaleLowerCase()
                  )
                }
                filterSort={(
                  optionA: BaseOptionType,
                  optionB: BaseOptionType
                ) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={field.onChange}
                value={field.value}
                options={modes?.map((mode) => ({
                  value: mode,
                  label: mode,
                }))}
              />
            </div>
          )}
        />
        {/* </div> */}
        <ErrorMessage
          errors={errors}
          name={'mode'}
          render={({ message }: { message: string }) => (
            <p className='text-red-500 text-xs'>{message}</p>
          )}
        />
      </div>
      <div className='col-span-4'>
        <Label
          name={'serviceType'}
          // We always default this value so don't show AI hint unless there's a suggestion
          hideAIHint={
            !clickedSuggestion ||
            clickedSuggestion.pipeline !== SuggestionPipelines.LoadBuilding
          }
          required={true}
        >
          Service Type
        </Label>
        <Controller
          name='specifications.serviceType'
          control={control}
          rules={{ required: devDisableRequiredFields ? false : 'Required' }}
          render={({ field }) => (
            <div className='text-grayscale-content-input'>
              <AntdSelect
                showSearch
                disabled={false}
                className='h-9 text-grayscale-content-input'
                placeholder={'Choose'}
                optionFilterProp='children'
                filterOption={(
                  input: string,
                  option: BaseOptionType | undefined
                ) =>
                  (option?.label.toLocaleLowerCase() ?? '').includes(
                    input.toLocaleLowerCase()
                  )
                }
                filterSort={(
                  optionA: BaseOptionType,
                  optionB: BaseOptionType
                ) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={field.onChange}
                value={field.value}
                options={serviceTypes?.map((serviceType) => ({
                  value: serviceType,
                  label: serviceType,
                }))}
              />
            </div>
          )}
        />
        <ErrorMessage
          errors={errors}
          name={'specifications.serviceType'}
          render={({ message }: { message: string }) => (
            <p className='text-red-500 text-xs'>{message}</p>
          )}
        />
      </div>

      {isCommoditiesSupported && (
        <div className='mx-0 w-full col-span-4 grid grid-cols-4 gap-2'>
          <div className='col-span-2'>
            <LoadBuildingTextInput
              name='specifications.commodities'
              label='Item'
              required={true}
            />
          </div>

          <div className='col-span-1'>
            <LoadBuildingTextInput
              name='specifications.totalPieces.val'
              label={`Quantity`}
              inputType='number'
              options={{
                valueAsNumber: true,
                required: watchedCommodities != '' ? 'Required' : undefined,
              }}
              required={watchedCommodities != ''}
            />
          </div>

          <div className='col-span-1'>
            <Label
              name={'specifications.totalPieces.unit'}
              required={watchedCommodities != ''}
            >
              Units
            </Label>
            <Controller
              name='specifications.totalPieces.unit'
              control={control}
              rules={{ required: watchedCommodities != '' }}
              render={({ field }) => (
                <AntdSelect
                  showSearch
                  className='h-9 text-grayscale-content-input'
                  placeholder={'Choose'}
                  optionFilterProp='children'
                  filterOption={(
                    input: string,
                    option: BaseOptionType | undefined
                  ) =>
                    (option?.label.toLocaleLowerCase() ?? '').includes(
                      input.toLocaleLowerCase()
                    )
                  }
                  filterSort={(
                    optionA: BaseOptionType,
                    optionB: BaseOptionType
                  ) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={field.onChange}
                  value={field.value}
                  options={unitsList?.map((unit) => ({
                    value: unit,
                    label: unit,
                  }))}
                />
              )}
            />
          </div>
        </div>
      )}

      <div className='col-span-2'>
        <Label
          name={'specifications.transportType'}
          // We always default this value so don't show AI hint unless there's a suggestion
          hideAIHint={
            !clickedSuggestion ||
            clickedSuggestion.pipeline !== SuggestionPipelines.LoadBuilding
          }
          required={true}
        >
          Equipment
        </Label>
        <Controller
          name='specifications.transportType'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <AntdSelect
              showSearch
              className='h-9 text-grayscale-content-input'
              placeholder={'Choose'}
              optionFilterProp='children'
              filterOption={(
                input: string,
                option: BaseOptionType | undefined
              ) =>
                (option?.label.toLocaleLowerCase() ?? '').includes(
                  input.toLocaleLowerCase()
                )
              }
              filterSort={(optionA: BaseOptionType, optionB: BaseOptionType) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={field.onChange}
              value={field.value}
              options={equipmentList?.map((type) => ({
                value: type,
                label: type,
              }))}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={'specifications.transportType'}
          render={({ message }: { message: string }) => (
            <p className='text-red-500 text-xs'>{message}</p>
          )}
        />
      </div>
      <div className='col-span-2'>
        <Label name={'specifications.transportLength'} required={false}>
          Size
        </Label>
        <Controller
          name='specifications.transportLength'
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <AntdSelect
              showSearch
              className='h-9 text-grayscale-content-input'
              placeholder={'Choose'}
              optionFilterProp='children'
              filterOption={(
                input: string,
                option: BaseOptionType | undefined
              ) =>
                (option?.label.toLocaleLowerCase() ?? '').includes(
                  input.toLocaleLowerCase()
                )
              }
              filterSort={(optionA: BaseOptionType, optionB: BaseOptionType) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={field.onChange}
              value={field.value}
              options={equipmentSizeList?.map((size) => ({
                value: size,
                label: size,
              }))}
            />
          )}
        />
      </div>

      {isWeightSupported && (
        <div className='mx-0 w-full col-span-4 grid grid-cols-2 gap-2'>
          <div className='mx-0 w-full col-span-2 grid grid-cols-2 gap-2'>
            <div className='col-span-1'>
              <LoadBuildingTextInput
                name='specifications.totalWeight.val'
                label={`Gross Weight`}
                inputType='number'
                options={{ valueAsNumber: true }}
                required={false}
              />
            </div>

            <div className='flex flex-col col-span-1'>
              <Label
                name={'specifications.totalWeight.unit'}
                required={watchedGrossWeight > 0}
              >
                Units
              </Label>
              <Controller
                name='specifications.totalWeight.unit'
                control={control}
                rules={{ required: watchedGrossWeight > 0 }}
                render={({ field }) => (
                  <AntdSelect
                    showSearch
                    className='h-9 text-grayscale-content-input'
                    placeholder={'Choose'}
                    optionFilterProp='children'
                    filterOption={(
                      input: string,
                      option: BaseOptionType | undefined
                    ) =>
                      (option?.label.toLocaleLowerCase() ?? '').includes(
                        input.toLocaleLowerCase()
                      )
                    }
                    filterSort={(
                      optionA: BaseOptionType,
                      optionB: BaseOptionType
                    ) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={field.onChange}
                    value={field.value}
                    options={weightUnitsList?.map((unit) => ({
                      value: unit,
                      label: unit,
                    }))}
                  />
                )}
              />
            </div>
          </div>
          <div className='mx-0 w-full col-span-2 grid grid-cols-2 gap-2'>
            <div className='col-span-1'>
              <LoadBuildingTextInput
                name='specifications.netWeight.val'
                label={`Net Weight`}
                inputType='number'
                options={{ valueAsNumber: true }}
                required={false}
              />
            </div>

            <div className='flex flex-col col-span-1'>
              <Label
                name={'specifications.netWeight.unit'}
                required={watchedNetWeight > 0}
              >
                Units
              </Label>
              <Controller
                name='specifications.netWeight.unit'
                control={control}
                rules={{ required: watchedNetWeight > 0 }}
                render={({ field }) => (
                  <AntdSelect
                    showSearch
                    className='h-9 text-grayscale-content-input'
                    placeholder={'Choose'}
                    optionFilterProp='children'
                    filterOption={(
                      input: string,
                      option: BaseOptionType | undefined
                    ) =>
                      (option?.label.toLocaleLowerCase() ?? '').includes(
                        input.toLocaleLowerCase()
                      )
                    }
                    filterSort={(
                      optionA: BaseOptionType,
                      optionB: BaseOptionType
                    ) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={field.onChange}
                    value={field.value}
                    options={weightUnitsList?.map((unit) => ({
                      value: unit,
                      label: unit,
                    }))}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
