// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { UseFormReturn } from 'react-hook-form/dist/types/form';

import { isAxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { toast } from 'hooks/useToaster';
import { updateQuoteRequestSuggestion } from 'lib/api/updateQuoteRequestSuggestion';
import { MarginType } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { Email } from 'types/Email';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import {
  GenericSuggestion,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';

export enum TransportType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  FLATBED = 'FLATBED',
  // Not supported natively by GS; there's special logic in BE to handle these
  SPECIAL = 'SPECIAL',
  FLATBED_HOTSHOT = 'FLATBED HOTSHOT',
  BOXTRUCK = 'BOX TRUCK',
}

export enum SelectedCarrierType {
  NETWORK = 'NETWORK',
  BUYPOWER = 'BUYPOWER',
  DAT = 'DAT',
  TRUCKSTOP_POSTED = 'TRUCKSTOP_POSTED',
  TRUCKSTOP_BOOKED = 'TRUCKSTOP_BOOKED',
  LANE_HISTORY = 'LANE_HISTORY',
}

type Stop = {
  zip: string;
  city: string;
  state: string;
  location?: string;
};

export interface QuickQuoteInputs {
  transportType: TransportType;
  pickupDate: Date;
  deliveryDate: Date;
  stops: Array<Stop>;
  isSubmitToTMS: boolean;
  customerName: string;
  quoteNumber: string;
  quoteExpirationDate: Date;
  quoteEta: Date;
}

interface QuickQuoteBody extends QuickQuoteInputs {
  emailID?: number;
  threadID?: string;
}

export interface QuickQuoteResponse {
  id: number;
  stops: {
    order: number;
    city: string;
    state: string;
    zip: string;
    country: string;
  }[];
  selectedRateName: SelectedCarrierType;
  // HotShot and Box Truck are approximated with Flatbed and Van respectively
  // so we need to indicate the transport type for this data to the user
  inputtedTransportType: TransportType;
  submittedTransportType: TransportType;
  defaultPercentMargin: number;
  defaultFlatMargin: number;
  networkLaneRateDistance: any;
  networkLaneRateTargetBuy: any;
  networkLaneRateConfidenceLevel: any;
  laneRateDistance: any;
  laneRateTargetBuy: any;
  laneRateConfidenceLevel: any;
  lowConfidenceThreshold: any;
  mediumConfidenceThreshold: any;
  belowThresholdMessage: any;
  truckStopPostedLowerRate: any;
  truckStopPostedPredictedRate: any;
  truckStopPostedUpperRate: any;
  truckStopPostedAverageScore: any;
  truckStopPostedRatePerMile: any;
  truckStopBookedLowerRate: any;
  truckStopBookedPredictedRate: any;
  truckStopBookedUpperRate: any;
  truckStopBookedAverageScore: any;
  truckStopBookedRatePerMile: any;
}

type ErrorData = {
  error: string;
  threshold: Maybe<number>;
  defaultPercentMargin: number;
  defaultFlatMargin: number;
};

// TODO: Refactor to match other API funcs; form updates should be handled in the form
export async function getQuickQuote(
  email: Maybe<Email>,
  clickedSuggestion: Maybe<GenericSuggestion>,
  formValues: QuickQuoteInputs,
  formMethods: UseFormReturn<QuickQuoteInputs>,
  setQuoteNotConfidentHandler: React.Dispatch<React.SetStateAction<boolean>>,
  setMargin: React.Dispatch<React.SetStateAction<number>>,
  marginType: MarginType
): Promise<MaybeUndef<QuickQuoteResponse>> {
  setQuoteNotConfidentHandler(false);

  let body: QuickQuoteBody = { ...formValues };

  if (email !== null) {
    body = { ...body, emailID: email.id, threadID: email.threadID };
  }

  try {
    const { data } = await axios.post<QuickQuoteResponse>(
      `/quote/private/quickquote`,
      body
    );

    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.QuickQuote
    ) {
      const inFlightQuoteRequest: QuoteChanges = {
        transportType: formValues.transportType,
        pickupCity: formValues.stops[0].city,
        pickupState: formValues.stops[0].state,
        pickupZip: formValues.stops[0].zip,
        pickupDate: formValues.pickupDate,
        deliveryCity: formValues.stops[1].city,
        deliveryState: formValues.stops[1].state,
        deliveryZip: formValues.stops[1].zip,
        deliveryDate: formValues.deliveryDate,
      };

      await updateQuoteRequestSuggestion(
        clickedSuggestion.id,
        SuggestionStatus.InFlight,
        inFlightQuoteRequest,
        data.id || 0
      );
    }

    return data;
  } catch (error) {
    const defaultErrorMessage =
      'Please try again in a few moments or contact our team if the issue persists.';

    if (isAxiosError(error)) {
      const status = error.response?.status;
      const data = error.response?.data;

      switch (status) {
        case 422: {
          const typedData: ErrorData = data;

          // Handle unprocessable entity - possibly low confidence quote
          if (
            typedData.error.includes('unable') &&
            typedData.error.includes('confident')
          ) {
            setQuoteNotConfidentHandler(true);
            setMargin(
              marginType === MarginType.Amount
                ? typedData.defaultFlatMargin || 100
                : typedData.defaultPercentMargin || 10
            );
            toast({
              description: `Greenscreens quote confidence is below
                      ${
                        typedData.threshold
                          ? typedData.threshold + '%.'
                          : 'your minimum threshold.'
                      }
                      Try another quoting approach, then return to complete the form.`,
              variant: 'destructive',
            });
            return;
          }
          formMethods.setError('root', {
            message: data || defaultErrorMessage,
          });
          return;
        }
        case 503: {
          const data: ErrorData = error.response?.data;

          if (data.error.includes('stops.0')) {
            formMethods.setError('root', {
              message:
                "The pickup location doesn't exist. Please enter a valid city and state pair.",
            });
            formMethods.setError('stops.0.city', {});
            formMethods.setError('stops.0.state', {});
          } else if (data.error.includes('stops.1')) {
            formMethods.setError('root', {
              message:
                "The dropoff location doesn't exist. Please enter a valid city and state pair.",
            });
            formMethods.setError('stops.1.city', {});
            formMethods.setError('stops.1.state', {});
          } else if (data.error.includes('Invalid Zip Code.')) {
            if (data.error.includes('stop 0')) {
              formMethods.setError('root', {
                message:
                  "The pickup location doesn't exist. Please enter a valid ZIP code.",
              });
              formMethods.setError('stops.0.zip', {});
            } else if (data.error.includes('stop 1')) {
              formMethods.setError('root', {
                message:
                  "The dropoff location doesn't exist. Please enter a valid ZIP code.",
              });
              formMethods.setError('stops.1.zip', {});
            }
          } else if (
            data.error.includes('ZIPCode must be 5 characters') ||
            data.error.includes('is not 5 digits if USA')
          ) {
            if (
              data.error.includes('stop 0') ||
              data.error.includes('stops.0')
            ) {
              formMethods.setError('stops.0.zip', {});
            } else if (
              data.error.includes('stop 1') ||
              data.error.includes('stops.1')
            ) {
              formMethods.setError('stops.1.zip', {});
            }
            formMethods.setError('root', {
              message: 'ZIP code must be 5 characters.',
            });
          } else {
            toast({
              description: `Unable to retrieve Greenscreens quote. Please retry or contact our team if the issue persists.`,
              variant: 'destructive',
            });
            return;
          }
          return;
        }
        default: {
          formMethods.setError('root', {
            message: data || defaultErrorMessage,
          });
          return;
        }
      }
    }

    // Non-Axios error fallback
    formMethods.setError('root', {
      message: defaultErrorMessage,
    });
  }
  return null;
}
