import _ from 'lodash';

import {
  CarrierCardType,
  DATTooltipConstructor,
  LaneHistoryTooltipConstructor,
  PriceRangeType,
} from 'components/CarrierCard';
import { toast } from 'hooks/useToaster';
import DATLogo from 'icons/DAT';
import GlobalTranzLogo from 'icons/GlobalTranz';
import GreenscreensLogo from 'icons/Greenscreens';
import McleodLogo from 'icons/McleodEnterprise';
import TruckstopLogo from 'icons/TruckstopLogo';
import { getCustomers } from 'lib/api/getCustomers';
import { getLaneHistory } from 'lib/api/getLaneHistory';
import { getLaneRateFromService } from 'lib/api/getLaneRateFromService';
import { SelectedCarrierType } from 'lib/api/getQuickQuote';
import { getQuoteNumber } from 'lib/api/getQuoteNumber';
import {
  GreenscreensQuote,
  sendGreenscreensQuoteToService,
} from 'lib/api/postGreenscreensQuoteToService';
import {
  UserQuote,
  sendUserQuoteToService,
} from 'lib/api/postUserQuoteToService';
import { submitQuoteToTMS } from 'lib/api/submitQuoteToTMS';
import { submitQuoteViaURL } from 'lib/api/submitQuoteViaURL';
import { updateQuoteRequestSuggestion } from 'lib/api/updateQuoteRequestSuggestion';
import { Quoting, TMS } from 'types/enums/Integrations';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';

import {
  DATQuoteLocationType,
  DATQuoteTimeframe,
  FetchCustomersProps,
  FetchLaneRateFromServiceProps,
  FetchQuoteNumberProps,
  HandleQuoteSubmissionViaURLProps,
  HelperFunctions,
  MarginType,
  OnSubmitFormProps,
  SendGreenscreensQuoteProps,
  SendUserQuoteProps,
} from './types';

export const useHelperFunctions: HelperFunctions = {
  toTitleCase: (str: string): string => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  },

  sendGreenscreensQuote: async ({
    email,
    quote,
    setGreenscreensQuoteID,
  }: SendGreenscreensQuoteProps) => {
    if (!email) return;

    const greenscreensQuoteObject: GreenscreensQuote = {
      stops: quote.stops,
      selectedRateName: quote.selectedRateName,
      networkLaneRateDistance: quote.networkLaneRateDistance,
      networkLaneRateTargetBuy: quote.networkLaneRateTargetBuy,
      networkLaneRateConfidenceLevel: quote.networkLaneRateConfidenceLevel,
      laneRateDistance: quote.laneRateDistance,
      laneRateTargetBuy: quote.laneRateTargetBuy,
      laneRateConfidenceLevel: quote.laneRateConfidenceLevel,
    };

    const res = await sendGreenscreensQuoteToService(
      email.id,
      greenscreensQuoteObject
    );
    if (res.isOk()) {
      setGreenscreensQuoteID(res.value);
    }
  },

  sendUserQuote: async ({
    email,
    quote,
    greenscreensQuoteID,
    carrierCost,
    margin,
    marginType,
    finalPrice,
    draftResponse,
  }: SendUserQuoteProps) => {
    if (!greenscreensQuoteID || !email || !quote) return;

    const userQuoteObject: UserQuote = {
      gsQuoteID: greenscreensQuoteID,
      draftResponse: draftResponse,
      carrierCost: carrierCost,
      margin: margin,
      marginType: marginType,
      targetSell: _.round(finalPrice),
      stops: quote.stops,
    };

    await sendUserQuoteToService(email.id, userQuoteObject);
  },

  fetchQuoteNumber: async ({
    email,
    setHasThirdPartyQuoteURLs,
    setValue,
  }: FetchQuoteNumberProps) => {
    if (!email) return;
    const res = await getQuoteNumber(email.id);
    if (res.isOk()) {
      setHasThirdPartyQuoteURLs(res.value.hasThirdPartyQuoteURLs);
      // can't use resetField here because the form input hasn't been initialized yet
      setValue('quoteNumber', res.value.quoteNumber);
      return;
    }
    setHasThirdPartyQuoteURLs(false);
  },

  fetchLaneRateFromService: async ({
    emailId,
    threadId,
    setCarrierCards,
    updatedFormValues,
  }: FetchLaneRateFromServiceProps) => {
    const res = await getLaneRateFromService({
      emailId: emailId,
      threadId: threadId,
      transportType: updatedFormValues.transportType,
      originDate: new Date(updatedFormValues.pickupDate).toISOString(),
      originZip: updatedFormValues.stops[0].zip,
      originCity: updatedFormValues.stops[0].city,
      originState: updatedFormValues.stops[0].state,
      destinationDate: new Date(updatedFormValues.deliveryDate).toISOString(),
      destinationZip: updatedFormValues.stops[1].zip,
      destinationCity: updatedFormValues.stops[1].city,
      destinationState: updatedFormValues.stops[1].state,
    });
    if (res.isOk()) {
      const {
        lowPerTrip,
        highPerTrip,
        lowPerMile,
        highPerMile,
        timeframe,
        originName,
        originType,
        destinationName,
        destinationType,
      } = res.value;

      setCarrierCards((prev) => [
        ...prev,
        {
          type: SelectedCarrierType.DAT,
          title: `RateView Median`,
          icon: <DATLogo className='inline-block w-auto h-3' />,
          cost: Number(res.value.ratePerTrip),
          costPerMile: Number(res.value.ratePerMile),
          confidence: null,
          priceRange:
            lowPerTrip && highPerTrip
              ? ({
                  lowEstimate: _.round(lowPerTrip),
                  highEstimate: _.round(highPerTrip),
                } as PriceRangeType)
              : null,
          priceRangePerMile:
            lowPerMile && highPerMile
              ? ({
                  lowEstimate: _.round(lowPerMile),
                  highEstimate: _.round(highPerMile),
                } as PriceRangeType)
              : null,
          tooltipContent: {
            timeframe: DATQuoteTimeframe[timeframe],
            originName: originName,
            originType: DATQuoteLocationType[originType],
            destinationName: destinationName,
            destinationType: DATQuoteLocationType[destinationType],
          },
          tooltipConstructor: DATTooltipConstructor,
          inputtedTransportType: updatedFormValues.transportType,
          actualTransportType: updatedFormValues.transportType,
        },
      ]);
    } else {
      let serviceLaneRateError =
        'Oops, something went wrong while fetching tailored lane rates. Showing standard lane rates instead.';

      if (res.error.message.includes("Trident's DAT integration")) {
        serviceLaneRateError =
          'Trident was unable to retrieve lane rate from DAT. Showing standard lane rates instead.';
        toast({
          description: serviceLaneRateError,
          variant: 'info',
        });
      } else if (res.error.message.includes('Unrecognized Service')) {
        serviceLaneRateError =
          'Tailored rates unavailable. Showing standard lane rates instead.';
        toast({
          description: serviceLaneRateError,
          variant: 'info',
        });
      } else {
        toast({
          description: serviceLaneRateError,
          variant: 'info',
        });
      }
    }
  },

  fetchCustomers: async ({
    setInitialCustomers,
    setCustomers,
    setTMSTenant,
    tmsIntegrations,
  }: FetchCustomersProps) => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setInitialCustomers(res.value.customerList);
      setCustomers(res.value.customerList);
      setTMSTenant(res.value.tmsTenant);
    } else {
      toast({
        description: 'Error while fetching customer list.',
        variant: 'destructive',
      });
    }
  },

  onSubmitForm: async ({
    formValues,
    setIsSubmitToTMS,
    setCreatedQuoteId,
    setQuote,
    setCarrierCards,
    isQuoteSubmissionViaURLEnabled,
    email,
    setHasThirdPartyQuoteURLs,
    setValue,
    isGetLaneRateFromServiceEnabled,
    clickedSuggestion,
    formMethods,
    setQuoteNotConfident,
    getQuickQuote,
    isQuoteLaneHistoryEnabled,
    setIsLoadingLaneHistory,
    setLaneHistory,
    setCarrierCost,
    setMargin,
    marginType,
    setError,
  }: OnSubmitFormProps) => {
    // these state variables should be reset when the form is submitted
    setIsSubmitToTMS(false);
    setCreatedQuoteId(undefined);
    setQuote(null);
    setCarrierCards([]);
    setCarrierCost(0);
    setLaneHistory(null);

    const pickup = useHelperFunctions.parseLocation(
      formValues.stops[0].location || ''
    );
    const delivery = useHelperFunctions.parseLocation(
      formValues.stops[1].location || ''
    );

    const validationErrors = [
      !pickup && [
        'stops.0.location',
        'Please enter valid ZIP code or City, State',
      ],
      !delivery && [
        'stops.1.location',
        'Please enter valid ZIP code or City, State',
      ],
    ].filter(Boolean);
    if (validationErrors.length) {
      (validationErrors as [`stops.${number}.location`, string][]).forEach(
        ([field, message]) => setError(field, { message })
      );
      return;
    }

    const updatedFormValues = {
      ...formValues,
      // validated that pickup/delivery isn't null, we can safely assert it's non-null
      stops: [
        {
          zip: pickup!.zip,
          city: pickup!.city,
          state: pickup!.state,
        },
        {
          zip: delivery!.zip,
          city: delivery!.city,
          state: delivery!.state,
        },
      ],
    };

    // Only need to fetch quote number when submitting quotes via URL is supported
    if (isQuoteSubmissionViaURLEnabled) {
      useHelperFunctions.fetchQuoteNumber({
        email,
        setHasThirdPartyQuoteURLs,
        setValue,
      });
    }

    if (isGetLaneRateFromServiceEnabled) {
      await useHelperFunctions.fetchLaneRateFromService({
        emailId: email?.id ?? 0,
        threadId: email?.threadID ?? '',
        setCarrierCards,
        updatedFormValues,
      });
    }

    const newQuote = await getQuickQuote(
      email,
      clickedSuggestion,
      updatedFormValues,
      formMethods,
      setQuoteNotConfident,
      setMargin,
      marginType
    );

    if (!newQuote) {
      return;
    }

    const newCarrierCards: CarrierCardType[] = [];

    if (newQuote.networkLaneRateTargetBuy) {
      newCarrierCards.push({
        type: SelectedCarrierType.NETWORK,
        title: 'Network Quote',
        icon: (
          <GreenscreensLogo height={12} width={75} className='inline-block' />
        ),
        cost:
          newQuote.networkLaneRateTargetBuy * newQuote.networkLaneRateDistance,
        costPerMile: _.round(newQuote.networkLaneRateTargetBuy, 2),
        confidence: newQuote.networkLaneRateConfidenceLevel,
        priceRange: null,
        inputtedTransportType: updatedFormValues.transportType,
        actualTransportType: newQuote.submittedTransportType,
      });
    }

    if (newQuote.laneRateTargetBuy) {
      newCarrierCards.push({
        type: SelectedCarrierType.BUYPOWER,
        title: 'Buy Power Quote',
        icon: (
          <GreenscreensLogo height={12} width={75} className='inline-block' />
        ),
        cost: newQuote.laneRateTargetBuy * newQuote.laneRateDistance,
        costPerMile: _.round(newQuote.laneRateTargetBuy, 2),
        confidence: newQuote.laneRateConfidenceLevel,
        priceRange: null,
        inputtedTransportType: updatedFormValues.transportType,
        actualTransportType: newQuote.submittedTransportType,
      });
    }

    if (newQuote.truckStopBookedPredictedRate) {
      newCarrierCards.push({
        type: SelectedCarrierType.TRUCKSTOP_BOOKED,
        title: 'Booked Quote',
        icon: <TruckstopLogo height={12} width={12} className='inline-block' />,
        cost: newQuote.truckStopBookedPredictedRate,
        confidence: newQuote.truckStopBookedAverageScore,
        priceRange: null,
        inputtedTransportType: updatedFormValues.transportType,
        actualTransportType: newQuote.submittedTransportType,
      });
    }

    if (newQuote.truckStopPostedPredictedRate) {
      newCarrierCards.push({
        type: SelectedCarrierType.TRUCKSTOP_POSTED,
        title: 'Posted Quote',
        icon: <TruckstopLogo height={12} width={12} className='inline-block' />,
        cost: newQuote.truckStopPostedPredictedRate,
        confidence: newQuote.truckStopPostedAverageScore,
        priceRange: null,
        inputtedTransportType: updatedFormValues.transportType,
        actualTransportType: newQuote.submittedTransportType,
      });
    }

    // set state variables
    setMargin(
      marginType === MarginType.Amount
        ? newQuote.defaultFlatMargin || 100
        : newQuote.defaultPercentMargin || 10
    );
    setCarrierCards((prev) => [...prev, ...newCarrierCards]);
    setQuote(newQuote);

    if (isQuoteLaneHistoryEnabled) {
      setIsLoadingLaneHistory(true);

      const res = await getLaneHistory({
        destinationCity: updatedFormValues.stops[1].city,
        destinationState: updatedFormValues.stops[1].state,
        destinationZip: updatedFormValues.stops[1].zip,
        originCity: updatedFormValues.stops[0].city,
        originState: updatedFormValues.stops[0].state,
        originZip: updatedFormValues.stops[0].zip,
        transportType: updatedFormValues.transportType,
      });
      if (res.isOk()) {
        setLaneHistory(res.value);

        // Find the first CalculatedQuote and add it to the CarrierCards
        Object.values(res.value.resultsBySource).forEach((source) => {
          for (const history of source) {
            if (history.calculatedQuote) {
              const quote = history.calculatedQuote;

              let carrierIcon = <img />;
              let costPerMile = undefined;
              let priceRangePerMile = null;
              switch (history.source) {
                case TMS.Mcleod:
                  carrierIcon = (
                    <McleodLogo width={45} className='inline-block' />
                  );
                  costPerMile = _.round(quote.avgRatePerMile, 2);
                  priceRangePerMile =
                    quote.minCost && quote.maxCost
                      ? ({
                          lowEstimate: _.round(quote.minCost),
                          highEstimate: _.round(quote.maxCost),
                        } as PriceRangeType)
                      : null;
                  break;
                case Quoting.GlobalTranz:
                  carrierIcon = <GlobalTranzLogo height={12} width={75} />;
                  break;
              }

              // TODO: Add lane tier toggle to card
              const newCard: CarrierCardType = {
                type: SelectedCarrierType.LANE_HISTORY,
                title: `Lane History`,
                icon: carrierIcon,
                cost: _.round(quote.avgCost),
                costPerMile: costPerMile,
                confidence: null,
                priceRange:
                  quote.minCost && quote.maxCost
                    ? ({
                        lowEstimate: _.round(quote.minCost),
                        highEstimate: _.round(quote.maxCost),
                      } as PriceRangeType)
                    : null,
                priceRangePerMile: priceRangePerMile,
                tooltipContent: {
                  timeframe: history.timeframe + ' average',
                  originName: 'Origin',
                  originType: history.laneTier!,
                  destinationName: 'Destination',
                  destinationType: history.laneTier!,
                },
                tooltipConstructor: LaneHistoryTooltipConstructor,
                inputtedTransportType: history.inputtedTransportType,
                actualTransportType: history.proxiedTransportType,
              };

              // This takes longer to fetch than other cards, so we're adding it to the end of the list
              // to avoid flickering/pushing user's view
              setCarrierCards((prev) => [...prev, newCard]);

              break;
            }
          }
        });
      }

      setIsLoadingLaneHistory(false);
    }
  },

  parseLocation: (location: string) => {
    // Check if input is a 5-digit ZIP code
    if (/^\d{5}$/.test(location.trim())) {
      return {
        zip: location.trim(),
        city: '',
        state: '',
      };
    }

    // Parse city, state format (e.g. "Boston, MA")
    const match = location.match(/^([^,]+),\s*([A-Z]{2})$/i);
    if (match) {
      return {
        zip: '',
        city: match[1].trim(),
        state: match[2].toUpperCase(),
      };
    }

    return null;
  },

  handleQuoteSubmissionViaURL: async ({
    email,
    quote,
    getValues,
    setError,
    setLoadingDraftReply,
    finalPrice,
    isTMSQuoteSubmissionEnabled,
    isSubmitToTMS,
    setCreatedQuoteId,
    clickedSuggestion,
    setCurrentState,
  }: HandleQuoteSubmissionViaURLProps) => {
    if (!email) return;

    if (!getValues('quoteNumber')) {
      setError(
        'quoteNumber',
        {
          message: 'Quote number is required',
        },
        { shouldFocus: true }
      );
      return;
    }

    if (!finalPrice || finalPrice <= 0) {
      return;
    }

    setLoadingDraftReply(true);

    const res = await submitQuoteViaURL(email.id, {
      quoteAmount: _.round(finalPrice),
      quoteNumber: getValues('quoteNumber'),
      expirationDate: new Date(getValues('quoteExpirationDate')),
      eta: new Date(getValues('quoteEta')),
    });

    if (res.isOk()) {
      toast({
        description: 'Successfully submitted quote via hyperlink.',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
      setLoadingDraftReply(false);
      return; // don't proceed with TMS submission if quote submission via URL fails
    }

    if (isTMSQuoteSubmissionEnabled && isSubmitToTMS) {
      const customerId = getValues('customerName');
      if (!customerId) {
        setLoadingDraftReply(false);
        return;
      }

      const res = await submitQuoteToTMS({
        customerId: customerId.toString(),
        quotePrice: _.round(finalPrice),
        quoteNumber: getValues('quoteNumber'),
        transportType: getValues('transportType'),
        pickupLocationZip: getValues('stops.0.zip'),
        pickupLocationCity: getValues('stops.0.city'),
        pickupLocationState: getValues('stops.0.state'),
        pickupDate: new Date(getValues('pickupDate')).toISOString(),
        deliveryLocationZip: getValues('stops.1.zip'),
        deliveryLocationCity: getValues('stops.1.city'),
        deliveryLocationState: getValues('stops.1.state'),
        deliveryDate: new Date(getValues('deliveryDate')).toISOString(),
      });

      if (res.isOk()) {
        setCreatedQuoteId(res.value.quoteId);
      } else {
        toast({
          description: 'Error creating Quote in TMS.',
          variant: 'destructive',
        });
        setLoadingDraftReply(false);
        return; // don't proceed with suggestion update if TMS submission fails
      }
    }

    // only remove suggestion from list at the very end of a successful quote submission
    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.QuickQuote
    ) {
      const quoteInfo: QuoteChanges = {
        transportType: getValues('transportType'),
        pickupZip: getValues('stops.0.zip'),
        pickupCity: getValues('stops.0.city'),
        pickupState: getValues('stops.0.state'),
        pickupDate: new Date(getValues('pickupDate')),
        deliveryZip: getValues('stops.1.zip'),
        deliveryCity: getValues('stops.1.city'),
        deliveryState: getValues('stops.1.state'),
        deliveryDate: new Date(getValues('deliveryDate')),
      };
      await updateQuoteRequestSuggestion(
        clickedSuggestion.id,
        SuggestionStatus.Accepted,
        quoteInfo,
        quote?.id || 0
      );

      setCurrentState((prevState) => ({
        ...prevState,
        clickedSuggestion: null,
        curSuggestionList: prevState.curSuggestionList.filter(
          (s) => s.id !== clickedSuggestion.id
        ),
      }));
    }

    setLoadingDraftReply(false);
  },
};
