import { CarrierCard } from 'components/CarrierCard';
import { TMSLocationWithDistance } from 'types/Load';

type EligibleCarrierListProps = {
  selectedCarriers: Record<string, boolean>;
  toggleSelection: (email: string | string[]) => void;
  locations: TMSLocationWithDistance[];
};

const EligibleCarrierList = ({
  selectedCarriers,
  toggleSelection,
  locations,
}: EligibleCarrierListProps) => {
  return (
    <div className='grid grid-cols-1 gap-2 mx-0 w-full'>
      {locations.map((location) => {
        const locationEmail = location.email || location.carrier?.email || null;
        const allEmails =
          location.emails || (locationEmail ? [locationEmail] : []);
        const isAnyEmailSelected = allEmails.some(
          (email) => selectedCarriers[email]
        );

        return (
          <CarrierCard
            key={location.externalTMSID}
            name={location.carrier?.name || location.name}
            locationName={location.name}
            city={location.city}
            state={location.state}
            email={locationEmail}
            emails={location.emails}
            distance={location.milesDistance}
            isSelected={isAnyEmailSelected}
            onClick={() => {
              if (allEmails.length > 0) {
                toggleSelection(
                  allEmails.length === 1 ? allEmails[0] : allEmails
                );
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default EligibleCarrierList;
