import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type SubmitQuoteToTMSBody = {
  customerId: string;
  quotePrice: number;
  transportType: string;
  pickupLocation: string;
  pickupDate: string;
  deliveryLocation: string;
  deliveryDate: string;
};

type SubmitQuoteToTMSResponse = {
  quoteId: number;
};

export async function submitQuoteToTMS(
  quoteBody: SubmitQuoteToTMSBody
): Promise<Result<SubmitQuoteToTMSResponse, ApiError>> {
  try {
    const resp = await axios.post<SubmitQuoteToTMSResponse>(
      `/quote/private/tms/create`,
      quoteBody
    );
    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'submitQuoteToTMS' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to submit quote to TMS' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data?.message || 'Failed to submit quote to TMS',
    });
  }
}
