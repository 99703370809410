// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import React from 'react';

import _ from 'lodash';
import { Check, ChevronDown } from 'lucide-react';
import { CartesianGrid, Line, LineChart, YAxis } from 'recharts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { DataKey } from 'recharts/types/util/types';

import { Button } from 'components/Button';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover';
import { cn } from 'utils/shadcn';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './Card';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from './Chart';

interface GenericLineChartProps<
  DataItem extends Record<string, any> = any,
  SeriesName extends keyof DataItem = keyof DataItem,
  SubseriesName = any,
> {
  data: DataItem[];
  title: string;
  subtitle?: string | JSX.Element;
  description?: string;
  selectedData?: SubseriesName; // Currently selected data set
  toggleDataHandler?: (tier: SubseriesName) => void; // Toggle between sets of data
  toggleOptions?: SubseriesName[]; // List of options to toggle between
  chartConfig: ChartConfig;
  yAxisDomainMax: number;
  yAxisDomainMin?: number;
  thirdTooltipLabel?: string;
  yAxisWidth?: number;
  dataKeys: SeriesName[];
  isPercentile?: boolean;
}

// Only non-generic param here is data
export function GenericLineChart({
  data,
  title,
  subtitle,
  description,
  selectedData,
  toggleDataHandler,
  toggleOptions,
  chartConfig,
  yAxisDomainMax,
  yAxisDomainMin,
  thirdTooltipLabel,
  yAxisWidth,
  dataKeys,
  isPercentile = false,
}: GenericLineChartProps) {
  const roundedMaxYValue = _.round(yAxisDomainMax);

  return (
    <Card className='bg-transparent border-none shadow-none'>
      <CardHeader className='p-0 pb-3'>
        <CardTitle className='flex flex-row flex-wrap gap-x-2 items-center text-md text-grayscale-content-label font-semibold'>
          {title}
          {typeof subtitle === 'string' ? (
            <span className='text-sm font-normal'>({subtitle})</span>
          ) : (
            subtitle // Render JSX element directly if subtitle is not a string
          )}
          {toggleDataHandler && toggleOptions && toggleOptions.length > 0 && (
            <div className=''>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant='underline'
                    type='button'
                    className='text-xs p-0 h-6 flex items-center gap-1 !mr-auto'
                  >
                    {selectedData || 'Select a tier'}
                    <ChevronDown className='w-4 h-4' />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-48 p-3 mx-1'>
                  <ul className='text-sm'>
                    {toggleOptions.map((option, index) => (
                      <li
                        key={index}
                        className={cn(
                          'flex flex-row justify-between text-xs py-0.5 hover:bg-gray-100 cursor-pointer rounded-[4px]'
                        )}
                        onClick={() => toggleDataHandler(option)}
                      >
                        {option}
                        {option === selectedData && (
                          <Check className='w-4 h-4 text-green-500' />
                        )}
                      </li>
                    ))}
                  </ul>
                </PopoverContent>
              </Popover>
            </div>
          )}
        </CardTitle>

        {description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
      <CardContent className='p-0'>
        <ChartContainer config={chartConfig}>
          <LineChart accessibilityLayer data={data}>
            <CartesianGrid vertical={false} />
            <YAxis
              domain={[yAxisDomainMin || 0, roundedMaxYValue]}
              tickFormatter={(value) => `$${_.round(value)}`}
              tickLine={false}
              width={yAxisWidth}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  hideLabel
                  className='w-[140px]'
                  cursor={false}
                  defaultIndex={1}
                  formatter={(
                    value: any,
                    name: any,
                    item: Payload<any, any>,
                    // index refers to the position of the dataKey in the dataKeys array during tooltip rendering
                    // used to customize how the tooltip displays information for each dataKey,
                    // like adding extra details for the first key (index = 0) or handling edge cases (quotes === 0).
                    index: number
                  ) => {
                    if (item.payload.quotes === 0) {
                      return (
                        <>
                          {index === 0 && (
                            <div className='flex flex-col items-center w-full gap-2'>
                              <div className='w-full'>{item.payload.week}</div>
                              <div className='italic text-[11px] text-grayscale-content-description'>
                                No Data Available
                              </div>
                            </div>
                          )}
                          {index !== 0 && null}
                        </>
                      );
                    }

                    return (
                      <>
                        {/* Display Week Date Range */}
                        {index === 0 && (
                          <div className='w-full'>{item.payload.week}</div>
                        )}

                        {/* Main data row */}
                        <div className='flex items-center w-full gap-2'>
                          <div
                            className='h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]'
                            style={
                              {
                                '--color-bg': `var(--color-${name})`,
                              } as React.CSSProperties
                            }
                          />
                          <span className='flex-grow'>
                            {chartConfig[name as keyof typeof chartConfig]
                              ?.label || name}
                          </span>
                          <div className='flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground'>
                            ${formatDecimalValue(value)}
                          </div>
                        </div>

                        {/* Show carrier name after all data points */}
                        {index === 2 && (
                          <>
                            {/* Carrier name of lowest rate*/}
                            <div className='w-full ml-[17px] -mb-1 -mt-1 italic text-[11px] text-grayscale-content-description leading-none'>
                              {_.startCase(
                                _.toLower(
                                  String(item.payload.lowestCarrierName)
                                )
                              )}
                            </div>

                            {/* Quotes information */}
                            <div className='flex w-full items-center border-t pt-1.5 text-xs font-medium text-foreground'>
                              {thirdTooltipLabel}
                              <div className='ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground'>
                                {item.payload.quotes}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    );
                  }}
                />
              }
            />
            {/* Display the data series lines */}
            {dataKeys.map((key) => (
              <Line
                dataKey={key as DataKey<typeof key>}
                type='monotone'
                stroke={chartConfig[key as keyof typeof chartConfig]?.color}
                strokeWidth={2}
                dot={true}
                connectNulls
                key={key as React.Key}
              />
            ))}
            <ChartLegend
              content={<ChartLegendContent isPercentile={isPercentile} />}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}

const formatDecimalValue = (
  value: number,
  minPrecision = 0,
  maxPrecision = 0
) => {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });
};
