import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import captureException from 'utils/captureException';

import { parseTimeInput } from './timepickerFunctions';

// Ensure dayjs has the needed plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const processTimeAndUpdateDate = (
  timeInputValue: string,
  currentDateValue: Date | null | undefined,
  tzString?: string
): Date | null => {
  const parsed = parseTimeInput(timeInputValue);
  if (!parsed) return null;

  // If no timezone specified, use standard Date object
  if (!tzString) {
    const baseDate =
      currentDateValue instanceof Date
        ? new Date(currentDateValue)
        : typeof currentDateValue === 'string' ||
            typeof currentDateValue === 'number'
          ? new Date(currentDateValue)
          : new Date();

    baseDate.setHours(parsed.hours, parsed.minutes);
    return baseDate;
  }

  // If timezone specified, use dayjs to handle it properly
  try {
    const baseDate = currentDateValue ? dayjs(currentDateValue) : dayjs();
    return baseDate
      .tz(tzString)
      .hour(parsed.hours)
      .minute(parsed.minutes)
      .toDate();
  } catch (e) {
    captureException(e);
    const baseDate =
      currentDateValue instanceof Date
        ? new Date(currentDateValue)
        : new Date();
    baseDate.setHours(parsed.hours, parsed.minutes);
    return baseDate;
  }
};
