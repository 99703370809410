import { Controller, useFormContext } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import DateTimeInput from 'components/input/DateTimeInput';
import { Input } from 'components/input/Input';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { IntegrationCore } from 'contexts/serviceContext';
import { CompanyCoreInfo } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { GenericCompanySearchableFields } from 'utils/loadInfoAndBuilding';

import LoadInformationForm from './LoadInformationForm';
import RefreshCarrierLocationsButton from './RefreshCarrierLocationsButton';
import { FormConditionProps } from './RequestCarrierQuotes';

export interface FindCarrierFormProps {
  loading: boolean;
  formConditions: FormConditionProps;
  customers: Maybe<CompanyCoreInfo[]>;
  handleRefreshCustomers: () => Promise<void>;
  handleResetCustomerSearch: () => void;
  handleCustomerSearch: (
    field: GenericCompanySearchableFields,
    value: string
  ) => Promise<any[]>;
  tmsIntegrations: IntegrationCore[];
}

// Universal location input component with proper types
interface LocationInputProps {
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
}

const LocationInput = ({
  name,
  label,
  placeholder,
  required = false,
}: LocationInputProps) => {
  return (
    <div className='flex flex-col gap-1'>
      <RHFTextInput
        name={name}
        label={label}
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};

export default function FindCarrierForm({
  loading,
  formConditions,
  customers,
  handleRefreshCustomers,
  handleResetCustomerSearch,
  handleCustomerSearch,
  tmsIntegrations,
}: FindCarrierFormProps) {
  const {
    control,
    register,
    formState: { errors, isValid },
  } = useFormContext();

  return (
    <div className='grid gap-3 grid-cols-1 w-full mx-0'>
      {/* Load Information Form */}
      <LoadInformationForm
        formConditions={formConditions}
        customers={customers}
        handleRefreshCustomers={handleRefreshCustomers}
        handleResetCustomerSearch={handleResetCustomerSearch}
        handleCustomerSearch={handleCustomerSearch}
        tmsIntegrations={tmsIntegrations}
      />

      {/* Pickup */}
      <div className='flex-1 flex flex-col gap-2'>
        <div className='flex items-center justify-between'>
          <h2 className='text-md whitespace-nowrap text-grayscale-content-label font-semibold'>
            Pickup
          </h2>
          <RefreshCarrierLocationsButton />
        </div>

        {formConditions.showPickupAddressLine1 && (
          <div className='flex flex-col'>
            <Label name='addressLine1' required={true}>
              Address Line 1
            </Label>
            <Input
              {...register('pickupLocation.addressLine1', {
                required: 'Address Line 1 is required',
              })}
              placeholder='e.g. 123 Main St'
              className='mt-1'
            />
            <ErrorMessage
              errors={errors}
              name='pickupLocation.addressLine1'
              render={({ message }: { message: string }) => (
                <p className='text-red-500 text-xs'>{message}</p>
              )}
            />
          </div>
        )}

        {/* Universal Location Input for Pickup */}
        <LocationInput
          name='pickupLocation.location'
          label='Location'
          placeholder='e.g. 02116 or Boston, MA'
          required={true}
        />

        <div className='flex flex-col gap-1'>
          <Label name='mileRadius' required={true}>
            Search Radius (miles)
          </Label>
          <Controller
            name='mileRadius'
            control={control}
            rules={{
              required: 'Search radius is required',
              min: { value: 1, message: 'Radius must be at least 1 mile' },
            }}
            render={({ field }) => (
              <Input {...field} placeholder='Enter radius (miles)' />
            )}
          />
          <ErrorMessage
            errors={errors}
            name='mileRadius'
            render={({ message }: { message: string }) => (
              <p className='text-red-500 text-xs'>{message}</p>
            )}
          />
        </div>

        <Accordion type='single' collapsible defaultValue=''>
          <AccordionItem value='pickup-dates'>
            <AccordionTrigger className='text-sm py-3'>
              Pickup Time Window
            </AccordionTrigger>
            <AccordionContent>
              <div className='flex flex-col gap-2'>
                <Controller
                  name='pickupStartDate'
                  control={control}
                  render={({ field }) => (
                    <DateTimeInput
                      {...field}
                      control={control}
                      name='pickupStartDate'
                      label='Start Date and Time'
                      preventNormalizedLabelTZ={true}
                    />
                  )}
                />
                <Controller
                  name='pickupEndDate'
                  control={control}
                  render={({ field }) => (
                    <DateTimeInput
                      {...field}
                      control={control}
                      name='pickupEndDate'
                      label='End Date and Time'
                      preventNormalizedLabelTZ={true}
                    />
                  )}
                />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      {/* Dropoff */}
      {formConditions.showDeliverySection && (
        <div className='flex-1 flex flex-col gap-2 mt-2'>
          <h2 className='text-md whitespace-nowrap text-grayscale-content-label font-semibold'>
            Dropoff
          </h2>

          {/* Universal Location Input for Delivery */}
          <LocationInput
            name='deliveryLocation.location'
            label='Location'
            placeholder='e.g. 02116 or Boston, MA'
            required={formConditions.requireDeliveryLocation}
          />

          <Accordion type='single' collapsible defaultValue=''>
            <AccordionItem value='delivery-dates'>
              <AccordionTrigger className='text-sm py-3'>
                Delivery Time Window
              </AccordionTrigger>
              <AccordionContent>
                <div className='flex flex-col gap-2'>
                  <Controller
                    name='deliveryStartDate'
                    control={control}
                    render={({ field }) => (
                      <DateTimeInput
                        {...field}
                        control={control}
                        name='deliveryStartDate'
                        label='Start Date and Time'
                        preventNormalizedLabelTZ={true}
                      />
                    )}
                  />
                  <Controller
                    name='deliveryEndDate'
                    control={control}
                    render={({ field }) => (
                      <DateTimeInput
                        {...field}
                        control={control}
                        name='deliveryEndDate'
                        label='End Date and Time'
                        preventNormalizedLabelTZ={true}
                      />
                    )}
                  />
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      )}

      {/* Find Carriers Button */}
      <Button
        buttonNamePosthog={ButtonNamePosthog.FindCarriers}
        type='submit'
        className='w-full'
        disabled={
          loading ||
          !isValid ||
          !!(errors.pickupLocation as any)?.location ||
          !!errors.mileRadius
        }
      >
        {loading ? <ButtonLoader /> : 'Find carriers near pickup'}
      </Button>
    </div>
  );
}
