import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldPathByValue,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { XCircleIcon } from 'lucide-react';

import { AIHintProps } from 'components/AIHint';
import { DatePicker } from 'components/DatePicker';
import { Label } from 'components/Label';
import { Input } from 'components/input';
import { RHFTextInput } from 'components/input/RHFTextInput';
import {
  ExtendedFormContextType,
  useExtendedFormContext,
} from 'contexts/extendedFormContext';
import { useFieldAttributes } from 'hooks/useLoadContext';
import useTMSContext from 'hooks/useTMSContext';
import {
  FieldAttributes,
  getFieldAttribute,
  initFieldAttributes,
} from 'types/LoadAttributes';
import { Maybe } from 'types/UtilityTypes';
import { TMS } from 'types/enums/Integrations';
import { processTimeAndUpdateDate } from 'utils/processTimeAndUpdateDate';
import { cn } from 'utils/shadcn';

export type DateTimeInputProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, Date>,
> = React.ComponentPropsWithoutRef<typeof RHFTextInput> &
  Omit<AIHintProps, 'name'> & {
    control: Control<TFieldValues>;
    name: TPath;
    label: string;
    required?: boolean;
    hideAIHint?: boolean;
    preventNormalizedLabelTZ?: boolean;
    options?: Omit<
      RegisterOptions<TFieldValues>,
      'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
    >;
    disabled?: boolean;
  };

export default function DateTimeInput<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, Date>,
>({
  control,
  name,
  label,
  required,
  hideAIHint,
  preventNormalizedLabelTZ,
  options,
  disabled,
}: DateTimeInputProps<TFieldValues, TPath>) {
  const { tmsName } = useTMSContext();
  const isTZAgnosticTMS = tmsName === TMS.Aljex;
  const shortTZ = isTZAgnosticTMS ? '' : `(${dayjs().tz().format('z')})`;
  const normalizedLabel =
    preventNormalizedLabelTZ || isTZAgnosticTMS ? label : label + ' ' + shortTZ;
  const [timeInput, setTimeInput] = useState('');
  const [highlightDirtyField, setHighlightDirtyField] = useState(false);

  const formContext = useFormContext();
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = formContext;

  let extendedFormContext: Maybe<ExtendedFormContextType> = null;
  try {
    extendedFormContext = useExtendedFormContext();
  } catch {
    setHighlightDirtyField(false);
  }

  useEffect(() => {
    if (formContext && extendedFormContext && name) {
      const {
        getFieldState,
        formState: { dirtyFields },
      } = formContext;
      const highlightDirtyFields =
        extendedFormContext.highlightDirtyFields ?? false;
      const fieldState = getFieldState(name);
      const isDirty = fieldState.isDirty || get(dirtyFields, name);

      setHighlightDirtyField(isDirty && highlightDirtyFields);
    } else {
      setHighlightDirtyField(false);
    }
  }, [extendedFormContext, formContext, name]);

  const allFieldAttrs = useFieldAttributes();
  const thisFieldAttr: FieldAttributes =
    getFieldAttribute(allFieldAttrs, name) ?? initFieldAttributes;

  const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeInput(e.target.value);
  };

  const handleTimeInputBlur = (field: any) => {
    const newDate = processTimeAndUpdateDate(timeInput, field.value);
    if (newDate) {
      clearErrors(name);
      field.onChange(newDate);
      setTimeInput('');
    } else {
      setError(name, {
        type: 'manual',
        message: 'Invalid time format. Please use hh:mm or hh:mm',
      });
    }
  };

  return thisFieldAttr.isNotSupported ? null : (
    <p>
      <Label name={name} hideAIHint={hideAIHint} required={required}>
        {normalizedLabel}
      </Label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? 'Required' : undefined,
          ...options,
        }}
        render={({ field }) => {
          const displayTime = field.value
            ? dayjs(field.value).format('HH:mm')
            : '';

          return (
            <div className='mt-1 flex flex-row gap-1' data-name={name}>
              <div className='flex gap-4 flex-1'>
                <DatePicker
                  field={field}
                  thisFieldAttr={thisFieldAttr}
                  highlightDirtyField={highlightDirtyField}
                />
                <Input
                  type='text'
                  className={cn(
                    'w-1/2 h-8 rounded-[4px] border border-grayscale-border-input text-grayscale-content-input !pr-1 pl-3',
                    highlightDirtyField && 'bg-yellow-50'
                  )}
                  placeholder='HH:mm'
                  value={timeInput || displayTime}
                  name={name}
                  disabled={thisFieldAttr.isReadOnly || disabled}
                  readOnly={thisFieldAttr.isReadOnly || disabled}
                  onChange={handleTimeInputChange}
                  onBlur={() => handleTimeInputBlur(field)}
                />
              </div>
              {field.value && (
                <button
                  title='Clear date'
                  onClick={() => field.onChange(null)}
                  className='h-9 flex items-center justify-center'
                >
                  <XCircleIcon className='w-4 h-4' />
                </button>
              )}
            </div>
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }: { message: string }) => (
          <p className='text-red-500 text-xs'>{message}</p>
        )}
      />
    </p>
  );
}
