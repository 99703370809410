import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type GetUserDATInfoResponse = {
  hasGrantedDATPermissions: boolean;
  datEmailAddress: string;
};

export async function getUserDATInfo(): Promise<
  Result<GetUserDATInfoResponse, ApiError>
> {
  try {
    const url = `/user/dat`;
    const { data } = await axios.get<GetUserDATInfoResponse>(url);

    return ok(data);
  } catch (error) {
    captureException(error, { functionName: 'getUserDATInfo' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get user DAT info' });
    }

    return err({
      message: error.response?.data.message || 'Failed to get user DAT info',
    });
  }
}
