enum ButtonNamePosthog {
  ApptConfirmationSuggestionClick = 'appointment_confirmation_suggestion_click',
  CarrierInfoSuggestionClick = 'carrier_info_suggestion_click',
  LoadBuildingSuggestionClick = 'load_building_suggestion_click',
  CheckCallSuggestionClick = 'check_call_suggestion_click',
  QuickQuoteSuggestionClick = 'quick_quote_suggestion_click',
  TruckListSuggestionClick = 'truck_list_suggestion_click',
  SubmitCheckCall = 'submit_check_call',
  SubmitException = 'submit_exception',
  SubmitNote = 'submit_note',
  ConfirmSlotApptSchedulingLoadAssociated = 'confirm_slot_appt_scheduling_load_associated',
  ConfirmSlotApptSchedulingLoadIndependent = 'confirm_slot_appt_scheduling_load_independent',
  DeleteCarrierEmail = 'delete_carrier_email',
  FindOpenApptSlotsLoadAssociated = 'find_open_appt_slots_load_associated',
  FindOpenApptSlotsLoadIndependent = 'find_open_appt_slots_load_independent',
  GoToOutboxTab = 'go_to_outbox',
  UpdateCarrierEmail = 'update_carrier_email',
  UpdateTMS = 'update_tms',
  ScheduleCarrierEmails = 'schedule_carrier_emails',
  ScheduleShipperEmails = 'schedule_shipper_emails',
  ShowCarrierSuggestion = 'show_carrier_suggestion',
  ShowSmartReply = 'show_smart_reply',
  ShowLoadIndependentApptSchedulingForm = 'show_load_independent_appt_scheduling_form',
  SkipCarrierInfoSuggestion = 'skip_carrier_info_suggestion',
  SkipApptSchedulingSuggestion = 'skip_appt_scheduling_suggestion',
  SkipCheckCallSuggestion = 'skip_check_call_suggestion',
  SubmitTruckList = 'submit_truck_list',
  UndoDeleteCarrierEmail = 'undo_delete_carrier_email',
  BuildLoad = 'build_load',
  RefreshCustomers = 'refresh_customers',
  RefreshLocations = 'refresh_locations',
  RefreshOperators = 'refresh_operators',
  ClearForm = 'clear_form',
}

export default ButtonNamePosthog;
