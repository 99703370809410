import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Select as AntdSelect } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  BoxIcon,
  Building2,
  CircleDollarSignIcon,
  CircleUserRound,
  ExternalLinkIcon,
  Info,
  ReceiptIcon,
  TruckIcon,
  WarehouseIcon,
  Weight,
  XCircleIcon,
} from 'lucide-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { ComboboxDropdownMenu } from 'components/Combobox';
import { DatePicker } from 'components/DatePicker';
import InputPreviousValue from 'components/InputPreviousValue';
import { Label } from 'components/Label';
import { ScrollUntilComponent } from 'components/ScrollUntilComponent';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { Input } from 'components/input';
import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import {
  FormatPhoneNumber,
  InputValue,
  RHFTextInput,
} from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import {
  ExtendedFormProvider,
  useExtendedFormContext,
} from 'contexts/extendedFormContext';
import { isUnsupportedSection } from 'contexts/load';
import {
  SidebarState,
  SidebarStateContext,
} from 'contexts/sidebarStateContext';
import { useFieldAttributes, useLoadContext } from 'hooks/useLoadContext';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import { useServiceFeatures } from 'hooks/useServiceContext';
import useTMSContext from 'hooks/useTMSContext';
import { useToast } from 'hooks/useToaster';
import { applyLoadSuggestion } from 'lib/api/applyLoadSuggestion';
import { getCarrierQualification } from 'lib/api/getCarrierQualification';
import { getCarriers } from 'lib/api/getCarriers';
import { getCustomers } from 'lib/api/getCustomers';
import { getLocations } from 'lib/api/getLocations';
import { getOperators } from 'lib/api/getOperators';
import { updateTMS } from 'lib/api/updateTMS';
import { relaySourceEnums } from 'pages/LoadView/TrackAndTrace/CheckCalls';
import { CustomerSectionFormProps } from 'pages/QuoteView/LoadBuilding/McleodSectionForms/Customer';
import { RatesForm } from 'pages/QuoteView/LoadBuilding/McleodSectionForms/Rates';
import { transportTypeOptions } from 'pages/QuoteView/LoadBuilding/McleodSectionForms/Specifications';
import { Email } from 'types/Email';
import { ExternalLinks } from 'types/ExternalLinks';
import {
  Load,
  NormalizedLoad,
  TMSCarrier,
  TMSCustomer,
  TMSLocation,
  createInitCompanyCoreInfo,
  normalizeLoad,
} from 'types/Load';
import {
  FieldAttributes,
  getFieldAttribute,
  initFieldAttributes,
} from 'types/LoadAttributes';
import { Maybe, MaybeUndef, Undef } from 'types/UtilityTypes';
import { TMSOperation } from 'types/api/UpdateTMS';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';
import { TMS } from 'types/enums/Integrations';
import Pageview from 'types/enums/Pageview';
import {
  GenericSuggestion,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import {
  ApptConfirmationChanges,
  CarrierChanges,
  LoadChanges,
  SuggestedLoadChange as suggestion,
} from 'types/suggestions/LoadSuggestions';
import captureException from 'utils/captureException';
import { datetimeFieldOptions, emailFieldOptions } from 'utils/formValidators';
import { titleCase } from 'utils/formatStrings';
import { isElementInView } from 'utils/isElementInView';
import joinClasses from 'utils/joinClasses';
import {
  GenericCompanySearchableFields,
  carrierSearchHandler,
  customerSearchHandler,
  injectSelectedObject,
  locationSearchHandler,
  mapCarriersToAntdOptions,
  mapCustomerToAntdOptions,
  mapLocationsToAntdOptions,
} from 'utils/loadInfoAndBuilding';
import { denormalizeDatesForTMSForm } from 'utils/parseDatesForTMSForm';
import { processTimeAndUpdateDate } from 'utils/processTimeAndUpdateDate';
import { cn } from 'utils/shadcn';
import { handleClickedSuggestionAndGetElements } from 'utils/suggestionScroll';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type LoadInformationSectionProps = {
  load: Load;
  suggestion?: suggestion;
  showSuggestion?: boolean;
  externalLinks: MaybeUndef<ExternalLinks>;
};

export default function LoadInformationSection({
  load,
  externalLinks,
}: LoadInformationSectionProps) {
  useLogPostHogPageView(Pageview.LoadInformation, {
    service_id: load.serviceID,
    load_id: load.ID,
    freightTrackingID: load.freightTrackingID,
  });

  const { tmsName, tmsID } = useTMSContext();
  const { toast } = useToast();
  const { fieldAttributes, invalidateLoad } = useLoadContext();
  const allFieldAttrs = useFieldAttributes();

  const [suggestedFieldsEdited, setSuggestedFieldsEdited] = useState<
    Record<string, boolean>
  >({});
  const [operators, setOperators] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>([]);

  const [suggestionScrollTo, setSuggestionScrollTo] =
    useState<Maybe<HTMLElement>>();
  const [suggestionMustScrollUntil, setSuggestionMustScrollUntil] =
    useState<Maybe<HTMLElement>>();
  const [showScrollUntilComponent, setShowScrollUntilComponent] =
    useState<boolean>(false);

  const [suggestedFieldsPrevValues, setSuggestedFieldsPrevValues] =
    useState<LoadChanges>();

  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [locations, setLocations] = useState<Maybe<TMSLocation[]>>(null);

  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
  const [customers, setCustomers] = useState<Maybe<TMSCustomer[]>>(null);

  const [isLoadingCarriers, setIsLoadingCarriers] = useState(true);
  const [carriers, setCarriers] = useState<Maybe<TMSCarrier[]>>(null);

  const [carrierIsQualified, setCarrierIsQualified] = useState(true);

  const posthog = usePostHog();

  const {
    serviceFeaturesEnabled: { isTurvoSectionLinksEnabled, isOperatorEnabled },
  } = useServiceFeatures();

  const {
    currentState: { drumkitAppContainer, clickedSuggestion },
    setCurrentState,
  } = useContext(SidebarStateContext);

  // Collapsing sections and updating fields after Email AI card is clicked
  useEffect(() => {
    if (!clickedSuggestion) return;

    switch (clickedSuggestion.pipeline) {
      case SuggestionPipelines.CarrierInfo:
        if (!activeTabs.includes('carrier')) {
          setActiveTabs((oldTabs) => [...oldTabs, 'carrier']);
        }
        break;
      case SuggestionPipelines.ApptConfirmation:
        if (
          clickedSuggestion.suggested.pickupApptTime &&
          !activeTabs.includes('pickup')
        ) {
          setActiveTabs((oldTabs) => [...oldTabs, 'pickup']);
        }
        if (
          clickedSuggestion.suggested.dropoffApptTime &&
          !activeTabs.includes('consignee')
        ) {
          setActiveTabs((oldTabs) => [...oldTabs, 'consignee']);
        }
        break;
    }

    handleClickedSuggestionAndGetElements(clickedSuggestion).then((value) => {
      value.scrollTo && setSuggestionScrollTo(value.scrollTo);
      value.mustScrollUntil &&
        setSuggestionMustScrollUntil(value.mustScrollUntil);
    });

    setSuggestedFieldsPrevValues(
      Object.keys(clickedSuggestion.suggested).reduce(
        (res, field) => ({
          ...res,
          [field]: memoizedDefaultValues.carrier[field as keyof CarrierChanges],
        }),
        {} as LoadChanges
      )
    );

    const autoFilledFields = Object.keys(clickedSuggestion.suggested);
    setSuggestedFieldsEdited(
      autoFilledFields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: false,
        }),
        {}
      )
    );
  }, [clickedSuggestion]);

  const handleFieldChange = (
    fieldName: string,
    oldValue: any,
    newValue: any
  ) => {
    if (
      clickedSuggestion &&
      Object.hasOwnProperty.call(suggestedFieldsEdited, fieldName) &&
      !suggestedFieldsEdited[fieldName]
    ) {
      setSuggestedFieldsEdited((prev) => ({
        ...prev,
        [fieldName]: true,
      }));

      posthog.capture('load_information_suggestion_field_edited', {
        field_name: fieldName,
        original_value: oldValue,
        new_value: newValue,
        suggestion_id: clickedSuggestion.id,
        suggestion_type: clickedSuggestion.pipeline,
        service_id: load.serviceID,
        load_id: load.ID,
      });
    }
  };

  useEffect(() => {
    if (suggestionScrollTo && suggestionMustScrollUntil) {
      suggestionScrollTo.scrollIntoView({ behavior: 'smooth' });

      setTimeout(() => {
        if (isElementInView(drumkitAppContainer, suggestionMustScrollUntil)) {
          drumkitAppContainer?.removeEventListener('scroll', onScroll);
        } else {
          setShowScrollUntilComponent(true);
        }
      }, 1000);
    }
  }, [suggestionScrollTo]);

  // Fetch TMS objects
  const fetchOperators = async () => {
    if (isOperatorEnabled) {
      const res = await getOperators(tmsID);
      if (res.isOk()) {
        setOperators(res.value);
      }
    }
  };

  useEffect(() => {
    if (isOperatorEnabled) {
      fetchOperators();
    }
  }, []);

  const fetchCustomers = async () => {
    if (tmsName !== TMS.McleodEnterprise) {
      return;
    }

    setIsLoadingCustomers(true);

    const res = await getCustomers(tmsID);
    if (res.isOk()) {
      setCustomers(res.value.customerList);
    } else {
      toast({
        description: 'Error while fetching customer list.',
        variant: 'destructive',
      });
    }
    setIsLoadingCustomers(false);
  };

  const fetchCarriers = async () => {
    if (tmsName !== TMS.McleodEnterprise) {
      return;
    }

    setIsLoadingCarriers(true);

    const res = await getCarriers(tmsID);
    if (res.isOk()) {
      setCarriers(res.value.carrierList);
    } else {
      toast({
        description: 'Error while fetching carrier list.',
        variant: 'destructive',
      });
    }
    setIsLoadingCarriers(false);
  };

  // Optimization: Defined here instead of in the component to avoid multiple API lookups
  const fetchLocations = async () => {
    // NOTE: Only Mcleod GET objects supported on Load Info rn
    if (tmsName !== TMS.McleodEnterprise) {
      return;
    }
    setIsLoadingLocations(true);

    const res = await getLocations(tmsID);
    if (res.isOk()) {
      setLocations(res.value.locationList);
    } else {
      toast({
        description: 'Error while fetching location list.',
        variant: 'destructive',
      });
    }
    setIsLoadingLocations(false);
  };

  const handleRefreshLocations = async () => {
    setIsLoadingLocations(true);

    const res = await getLocations(tmsID, true);
    if (res.isOk()) {
      setLocations(res.value.locationList);
      toast({
        description: 'Successfully refreshed location list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing location list.',
        variant: 'destructive',
      });
    }
    setIsLoadingLocations(false);
  };

  useEffect(() => {
    fetchCustomers();
    fetchLocations();
    fetchCarriers();
  }, []);

  const onScroll = () => {
    if (!clickedSuggestion) return;

    const isInView = isElementInView(
      drumkitAppContainer,
      suggestionMustScrollUntil
    );

    // we don't want to keep toggling the visibility on this component. once it's hidden, it won't show up anymore
    if (isInView) {
      setShowScrollUntilComponent(false);
      drumkitAppContainer?.removeEventListener('scroll', onScroll);
    }
  };

  useEffect(() => {
    if (drumkitAppContainer) {
      drumkitAppContainer.addEventListener('scroll', onScroll, {
        passive: true,
      });
    }
  }, [suggestionMustScrollUntil]);

  const memoizedDefaultValues: NormalizedLoad = useMemo(() => {
    const normalizedLoad = normalizeLoad(tmsName, load);
    const suggestedFields = clickedSuggestion?.suggested as LoadChanges;

    return getLoadInfoWithSuggestion(normalizedLoad, suggestedFields);
  }, [tmsName, load, suggestedFieldsPrevValues]);

  // Update form values when suggestion changes
  useEffect(() => {
    if (memoizedDefaultValues) {
      formMethods.reset(memoizedDefaultValues);
    }
  }, [memoizedDefaultValues]);

  useEffect(() => {
    const addtlLocs: TMSLocation[] = [];
    if (memoizedDefaultValues.pickup.externalTMSID) {
      addtlLocs.push(memoizedDefaultValues.pickup);
    }

    if (memoizedDefaultValues.consignee.externalTMSID) {
      addtlLocs.push(memoizedDefaultValues.consignee);
    }

    setLocations((prevLocations) => {
      let updatedLocations = prevLocations ?? [];

      addtlLocs.forEach((loc) => {
        updatedLocations = injectSelectedObject(loc, updatedLocations);
      });

      return updatedLocations;
    });
  }, [
    // TODO: We should be able to store the initial selected value
    // without constantly injecting into the list like in Load Building
    // page. It's causing glitchy loading right now.
    locations,
    memoizedDefaultValues?.pickup.externalTMSID,
    memoizedDefaultValues?.consignee.externalTMSID,
  ]);

  useEffect(() => {
    const customerID = memoizedDefaultValues.customer?.externalTMSID;
    if (customerID) {
      setCustomers((prevCustomers) =>
        injectSelectedObject(
          memoizedDefaultValues.customer,
          prevCustomers ?? []
        )
      );
    }
  }, [customers, memoizedDefaultValues?.customer?.externalTMSID]);

  useEffect(() => {
    const carrierID = memoizedDefaultValues.carrier?.externalTMSID;
    const selectedObj = carriers?.find((c) => c.externalTMSID === carrierID);

    if (carrierID && carriers) {
      setCarriers((prevCarriers) =>
        injectSelectedObject(
          selectedObj ??
            ({
              name: memoizedDefaultValues.carrier?.name,
              externalTMSID: memoizedDefaultValues.carrier?.externalTMSID,
            } as TMSCarrier),
          prevCarriers ?? []
        )
      );
    }
  }, [carriers, memoizedDefaultValues?.carrier?.externalTMSID]);

  const formMethods = useForm<NormalizedLoad>({
    defaultValues: memoizedDefaultValues,
  });
  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<NormalizedLoad> = async (data) => {
    if (clickedSuggestion) {
      const editedFields = Object.entries(suggestedFieldsEdited)
        .filter(([_, wasEdited]) => wasEdited)
        .map(([field]) => field);

      posthog.capture('load_information_suggestion_summary', {
        suggestion_id: clickedSuggestion.id,
        suggestion_type: clickedSuggestion.pipeline,
        total_suggested_fields: Object.keys(suggestedFieldsEdited).length,
        edited_fields_count: editedFields.length,
        edited_fields: editedFields,
        service_id: load.serviceID,
        load_id: load.ID,
      });
    }

    if (!carrierIsQualified) {
      // Prevent form submission if there are validation errors
      toast({
        description: data.carrier.name + ' is not qualified for this load.',
        variant: 'destructive',
      });
      return;
    }

    setLoading(true);

    const reqData = {
      operation: TMSOperation.UpdateLoad,
      load: {
        // Ensure we're sending a full load
        ...load,
        freightTrackingID: data.freightTrackingID,
        externalTMSID: data.externalTMSID,
        operator: data.operator,
        customer: denormalizeDatesForTMSForm(tmsName, data.customer),
        billTo: denormalizeDatesForTMSForm(tmsName, data.billTo),
        specifications: denormalizeDatesForTMSForm(
          tmsName,
          data.specifications
        ),
        pickup: {
          ...denormalizeDatesForTMSForm(tmsName, data.pickup),
          externalTMSID: data.pickup.externalTMSID ?? data.pickup.name,
        },
        consignee: {
          ...denormalizeDatesForTMSForm(tmsName, data.consignee),
          externalTMSID: data.consignee.externalTMSID ?? data.consignee.name,
        },
        carrier: {
          ...denormalizeDatesForTMSForm(tmsName, data.carrier),
          rateConfirmationSent: data.carrier.rateConfirmationSent
            ? true
            : false,
        },
        rateData: denormalizeDatesForTMSForm(tmsName, data.rateData),
      } as Load,
    };

    const res = await updateTMS(load.ID!, reqData);

    if (res.isOk()) {
      toast({
        description: res.value.message,
        variant: 'success',
      });

      if (res.value.load && res.value.loadAttributes) {
        await invalidateLoad(res.value.load, res.value.loadAttributes);
        formMethods.reset(normalizeLoad(tmsName, res.value.load));
      }
      // If updated load not in response for some reason, re-fetch from API
      else await invalidateLoad();
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });

      if (res.error.load && res.error.loadAttributes) {
        await invalidateLoad(res.error.load, res.error.loadAttributes);
        formMethods.reset(normalizeLoad(tmsName, res.error.load));
      } else {
        await invalidateLoad();
      }
    }

    submitAcceptedSuggestion(
      reqData,
      clickedSuggestion,
      setCurrentState,
      setSuggestedFieldsPrevValues
    );

    setLoading(false);
  };

  const onInvalid: SubmitErrorHandler<Email> = async () => {
    toast({
      description: 'Some fields are invalid.',
      variant: 'destructive',
    });
  };

  return (
    <div className='mb-5'>
      {showScrollUntilComponent &&
        clickedSuggestion?.pipeline === SuggestionPipelines.CarrierInfo && (
          <ScrollUntilComponent />
        )}

      {/* Mcleod Load Info leverages Antd Select components to enable optimized fuzzy search, but they don't support highlighting dirty fields.
So for the sake of making all the input fields consistent, we're disabling highlighting for Mcleod */}
      <ExtendedFormProvider
        aiDefaultValues={false}
        highlightDirtyFields={tmsName !== TMS.McleodEnterprise}
        onFieldChange={handleFieldChange}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <Accordion
              type='multiple'
              value={activeTabs}
              onValueChange={setActiveTabs}
            >
              {!isUnsupportedSection(allFieldAttrs, 'customer') && (
                <LoadSectionAccordionItem
                  label='Customer'
                  icon={<Building2 className='h-6 w-6' strokeWidth={1} />}
                  name='customer'
                  activeTabs={activeTabs}
                >
                  <CustomerSectionForm
                    customers={customers}
                    setCustomers={setCustomers}
                    isLoadingCustomers={isLoadingCustomers}
                    setIsLoadingCustomers={setIsLoadingCustomers}
                    formMethods={formMethods}
                  />
                </LoadSectionAccordionItem>
              )}

              {!isUnsupportedSection(allFieldAttrs, 'billTo') && (
                <LoadSectionAccordionItem
                  label='Bill To'
                  icon={<ReceiptIcon className='h-6 w-6' strokeWidth={1} />}
                  name='billTo'
                  activeTabs={activeTabs}
                >
                  <BillToSectionForm />
                </LoadSectionAccordionItem>
              )}

              {!isUnsupportedSection(allFieldAttrs, 'specifications') && (
                <LoadSectionAccordionItem
                  label='Specs'
                  icon={<Weight className='h-6 w-6' strokeWidth={1} />}
                  name='specifications'
                  activeTabs={activeTabs}
                >
                  <SpecificationsForm load={load} formMethods={formMethods} />
                </LoadSectionAccordionItem>
              )}

              {!isUnsupportedSection(allFieldAttrs, 'rateData') && (
                <LoadSectionAccordionItem
                  label='Rates'
                  icon={
                    <CircleDollarSignIcon className='h-6 w-6' strokeWidth={1} />
                  }
                  name='rateData'
                  activeTabs={activeTabs}
                >
                  <RatesForm
                    formMethods={formMethods}
                    showCarrierFields={true}
                  />
                </LoadSectionAccordionItem>
              )}

              <LoadSectionAccordionItem
                label='Pickup'
                icon={<BoxIcon className='h-6 w-6' strokeWidth={1} />}
                name='pickup'
                activeTabs={activeTabs}
              >
                <PickupSectionForm
                  formMethods={formMethods}
                  isLoadingLocations={isLoadingLocations}
                  locations={locations}
                  handleRefreshLocations={handleRefreshLocations}
                  setLocations={setLocations}
                  suggestedFieldsPrevValues={suggestedFieldsPrevValues}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Consignee'
                icon={<WarehouseIcon className='h-6 w-6' strokeWidth={1} />}
                name='consignee'
                activeTabs={activeTabs}
              >
                <ConsigneeSectionForm
                  formMethods={formMethods}
                  isLoadingLocations={isLoadingLocations}
                  locations={locations}
                  handleRefreshLocations={handleRefreshLocations}
                  setLocations={setLocations}
                  suggestedFieldsPrevValues={suggestedFieldsPrevValues}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Carrier'
                icon={<TruckIcon className='h-6 w-6' strokeWidth={1} />}
                name='carrier'
                activeTabs={activeTabs}
              >
                {isTurvoSectionLinksEnabled &&
                  externalLinks &&
                  externalLinks.viewCarrier && (
                    <div className='p-4 bg-grayscale-border rounded'>
                      <a
                        href={externalLinks.viewCarrier}
                        target='_blank'
                        rel='noreferrer'
                        className='underline text-violet-blue-main flex flex-row items-center'
                      >
                        Edit carrier details on Turvo.{' '}
                        <ExternalLinkIcon className='w-3 h-3 ml-1' />
                      </a>
                    </div>
                  )}
                <CarrierSectionForm
                  load={load}
                  suggestedFieldsPrevValues={suggestedFieldsPrevValues}
                  formMethods={formMethods}
                  isLoadingCarriers={isLoadingCarriers}
                  carriers={carriers}
                  setIsLoadingCarriers={setIsLoadingCarriers}
                  setCarriers={setCarriers}
                  setIsCarrierQualified={setCarrierIsQualified}
                />
              </LoadSectionAccordionItem>

              {isOperatorEnabled && (
                <LoadSectionAccordionItem
                  label='Operator'
                  icon={<CircleUserRound className='h-6 w-6' strokeWidth={1} />}
                  name='operator'
                  activeTabs={activeTabs}
                >
                  <OperatorSectionForm
                    formMethods={formMethods}
                    operators={operators}
                    canEdit={
                      !fieldAttributes.find((obj) => 'operator' in obj)
                        ?.isReadOnly
                    }
                  />
                </LoadSectionAccordionItem>
              )}
            </Accordion>

            {load.moreThanTwoStops && (
              <span className='flex flex-row bg-violet-blue-bg rounded-lg space-x-1 py-1 mt-3'>
                <div>
                  <Info
                    className='h-4 w-4 pl-1'
                    color='#969696'
                    strokeWidth={3}
                  />
                </div>
                <span className='text-xs text-grayscale-content-description font-medium'>
                  {`Only the first pickup and last dropoff are shown for multi-stop loads.
                More support for LTL loads coming soon!`}
                </span>
              </span>
            )}
            <section className='w-full mt-8'>
              <Button
                buttonNamePosthog={ButtonNamePosthog.UpdateTMS}
                type='submit'
                className='w-full'
                disabled={loading}
                logProperties={{
                  loadID: load.ID,
                  freightTrackingID: load.freightTrackingID,
                  serviceID: load.serviceID,
                }}
              >
                {loading ? <ButtonLoader /> : ButtonText.UpdateTMS}
              </Button>
            </section>
          </form>
        </FormProvider>
      </ExtendedFormProvider>
    </div>
  );
}

function CustomerSectionForm({
  formMethods,
  customers,
  setCustomers,
  isLoadingCustomers,
  setIsLoadingCustomers,
}: CustomerSectionFormProps) {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const { toast } = useToast();
  const { tmsName, tmsID } = useTMSContext();
  const [isMcleodTMS, setIsMcleodTMS] = useState<boolean>(
    tmsName === TMS.McleodEnterprise
  );

  const watchedCustomerID = watch('customer.externalTMSID');

  useEffect(() => {
    if (isMcleodTMS) {
      setIsMcleodTMS(true);
    }
  }, [tmsName]);

  const handleRefreshCustomers = async () => {
    setIsLoadingCustomers(true);

    const res = await getCustomers(tmsID, true);
    if (res.isOk()) {
      setCustomers(res.value.customerList);
      toast({
        description: 'Successfully refreshed customer list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing customer list.',
        variant: 'destructive',
      });
    }
    setIsLoadingCustomers(false);
  };

  const handleCustomerSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return customerSearchHandler({
      tmsID: tmsID,
      customers,
      setCustomers,
      field,
      value,
    });
  };

  useEffect(() => {
    if (watchedCustomerID) {
      const selectedCustomer = customers?.find(
        (c) => c.externalTMSID === watchedCustomerID
      );
      if (!selectedCustomer) {
        return;
      }

      // Autofill customer object
      Object.entries(selectedCustomer).forEach(([key, value]) => {
        if (key in createInitCompanyCoreInfo()) {
          setValue(`customer.${key}` as FieldPath<NormalizedLoad>, value, {
            shouldDirty: true,
          });
        }
      });
    }
  }, [watchedCustomerID]);

  return (
    <>
      {!isMcleodTMS ? (
        <LoadTextInput name='customer.name' label='Name' />
      ) : (
        <RHFDebounceSelect
          required={true}
          name={`customer.externalTMSID`}
          label='Name'
          control={control}
          errors={errors}
          data={customers}
          isLoading={isLoadingCustomers}
          refreshHandler={handleRefreshCustomers}
          fetchOptions={handleCustomerSearch}
          mapOptions={mapCustomerToAntdOptions}
        />
      )}

      <LoadTextInput name='customer.addressLine1' label='Address Line 1' />

      <LoadTextInput name='customer.addressLine2' label='Address Line 2' />
      <LoadTextInput name='customer.city' label='City' />
      <LoadTextInput name='customer.state' label='State' />
      <LoadTextInput
        name='customer.zipCode'
        label='Zip Code'
        placeholder='12345'
        // Zipcode validation disabled to prevent false positives due to Canada postal and read-only values from TMS,
        // options={zipCodeFieldOptions}
      />
      <LoadTextInput name='customer.country' label='Country' />
      <LoadTextInput name='customer.contact' label='Contact' />
      <LoadTextInput
        name='customer.phone'
        label='Phone'
        placeholder='(555) 555-5555'
        // options={phoneFieldOptions}
        inputValue={InputValue.PHONE_NUMBER}
      />
      <LoadTextInput
        name='customer.email'
        label='Email'
        placeholder='example@email.com'
        options={emailFieldOptions}
      />
      <LoadTextInput name='customer.refNumber' label='Ref # / BOL' />

      <LoadTextInput
        name={'poNums'}
        label='PO #'
        placeholder='Comma-separated list (e.g. 123,456,789)'
      />
    </>
  );
}

function BillToSectionForm() {
  return (
    <>
      <LoadTextInput name='billTo.name' label='Name' />
      <LoadTextInput name='billTo.addressLine1' label='Address Line 1' />
      <LoadTextInput name='billTo.addressLine2' label='Address Line 2' />
      <LoadTextInput name='billTo.city' label='City' />
      <LoadTextInput name='billTo.state' label='State' />
      <LoadTextInput
        name='billTo.zipCode'
        label='Zip Code'
        placeholder='12345'
        // options={zipCodeFieldOptions}
      />
      <LoadTextInput name='billTo.contact' label='Contact' />
      <LoadTextInput
        name='billTo.phone'
        label='Phone'
        placeholder='(555) 555-5555'
        // options={phoneFieldOptions}
        inputValue={InputValue.PHONE_NUMBER}
      />
      <LoadTextInput
        name='billTo.email'
        label='Email'
        placeholder='example@email.com'
        options={emailFieldOptions}
      />
    </>
  );
}

function SpecificationsForm({
  load,
  formMethods,
}: {
  load: Load | NormalizedLoad;
  formMethods: UseFormReturn<NormalizedLoad>;
}) {
  const {
    control,
    formState: { errors },
  } = formMethods;
  const { loadAttrsObj: attrs } = useLoadContext();
  const specs = load.specifications;
  const { tmsName } = useTMSContext();
  const [isMcleodTMS, setIsMcleodTMS] = useState<boolean>(
    tmsName === TMS.McleodEnterprise
  );

  useEffect(() => {
    setIsMcleodTMS(tmsName === TMS.McleodEnterprise);
  }, [tmsName]);

  const isWeightSupported = !attrs.specifications.totalWeight.isNotSupported;
  const isDistanceSupported =
    !attrs.specifications.totalDistance.isNotSupported;

  const isPalletSupported =
    !attrs.specifications.totalOutPalletCount.isNotSupported;
  const isPiecesSupported = !attrs.specifications.totalPieces.isNotSupported;

  return (
    <div className='grid grid-cols-2 gap-2 mx-0 w-full'>
      {!isMcleodTMS ? (
        <div className='col-span-2'>
          <LoadTextInput
            name='specifications.transportType'
            label='Transport Type'
          />
        </div>
      ) : (
        <div className='col-span-2'>
          <Label name={'specifications.transportType'}>Transport Type</Label>
          <Controller
            name='specifications.transportType'
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <AntdSelect
                showSearch
                className='h-9 text-grayscale-content-input'
                placeholder={'Choose'}
                optionFilterProp='children'
                filterOption={(
                  input: string,
                  option: BaseOptionType | undefined
                ) =>
                  (option?.label.toLocaleLowerCase() ?? '').includes(
                    input.toLocaleLowerCase()
                  )
                }
                filterSort={(
                  optionA: BaseOptionType,
                  optionB: BaseOptionType
                ) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={field.onChange}
                value={field.value}
                options={transportTypeOptions?.map((type) => ({
                  value: type.label,
                  label: type.label,
                }))}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={'specifications.transportType'}
            render={({ message }: { message: string }) => (
              <p className='text-red-500 text-xs'>{message}</p>
            )}
          />
        </div>
      )}

      <div className='col-span-2'>
        <LoadTextInput name='specifications.commodities' label='Commodities' />
      </div>

      <div
        className={
          isWeightSupported && !isDistanceSupported
            ? 'col-span-2'
            : 'col-span-1'
        }
      >
        <LoadTextInput
          name='specifications.totalWeight.val'
          label={`Weight${specs.totalWeight?.unit ? ' (' + specs.totalWeight?.unit + ')' : ''}`}
          inputType='number'
          options={{ valueAsNumber: true }}
        />
      </div>

      <div
        className={
          isDistanceSupported && !isWeightSupported
            ? 'col-span-2'
            : 'col-span-1'
        }
      >
        <LoadTextInput
          name='specifications.totalDistance.val'
          label={`Distance${specs.totalDistance?.unit ? ' (' + specs.totalDistance?.unit + ')' : ''}`}
          inputType='number'
          options={{ valueAsNumber: true }}
        />
      </div>

      <div
        className={
          isPalletSupported && !isPiecesSupported ? 'col-span-2' : 'col-span-1'
        }
      >
        <LoadTextInput
          name='specifications.totalOutPalletCount'
          label='Pallets'
          inputType='number'
          options={{ valueAsNumber: true }}
        />
      </div>

      <div
        className={
          isPiecesSupported && !isPalletSupported ? 'col-span-2' : 'col-span-1'
        }
      >
        <LoadTextInput
          name='specifications.totalPieces.val'
          label={`Pieces${specs.totalPieces?.unit ? ' (' + specs.totalPieces?.unit + ')' : ''}`}
          inputType='number'
          options={{ valueAsNumber: true }}
        />
      </div>
    </div>
  );
}

function PickupSectionForm({
  suggestedFieldsPrevValues,
  formMethods,
  isLoadingLocations,
  locations,
  handleRefreshLocations,
  setLocations,
}: {
  suggestedFieldsPrevValues: any;
  formMethods: UseFormReturn<NormalizedLoad>;
  isLoadingLocations: boolean;
  locations: Maybe<TMSLocation[]>;
  handleRefreshLocations: () => void;
  setLocations: React.Dispatch<React.SetStateAction<Maybe<TMSLocation[]>>>;
}) {
  const stop = 'pickup';

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const fieldAttrs = useFieldAttributes();
  const apptRequiredAttr = getFieldAttribute(fieldAttrs, 'pickup.apptRequired');

  const watchedLocationID = watch(`${stop}.externalTMSID`);
  const watchedLocationObj = watch(stop);

  const { tmsName, tmsID } = useTMSContext();
  const [isMcleodTMS, setIsMcleodTMS] = useState<boolean>(
    tmsName === TMS.McleodEnterprise
  );

  useEffect(() => {
    if (isMcleodTMS) {
      setIsMcleodTMS(true);
    }
  }, [tmsName]);

  useEffect(() => {
    if (watchedLocationID) {
      const selectedLoc = locations?.find(
        (loc) => loc.externalTMSID === watchedLocationID
      );
      if (!selectedLoc) {
        return;
      }
      // setLocations(injectSelectedObject(selectedLoc, locations ?? []));

      Object.entries(selectedLoc).forEach(([key, value]) => {
        setValue(`${stop}.${key}` as FieldPath<NormalizedLoad>, value, {
          shouldDirty: true,
        });
      });
    }
  }, [watchedLocationID]);

  const handleLocationSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return locationSearchHandler({
      tmsID,
      locations,
      setLocations,
      field,
      value,
    });
  };

  const currentLoad = formMethods.watch(); // Get current form values for all fields

  return (
    <>
      {!isMcleodTMS ? (
        <LoadTextInput name='pickup.name' label='Name' />
      ) : (
        <>
          <RHFDebounceSelect
            required={true}
            name={`${stop}.externalTMSID`}
            label='Name'
            control={control}
            errors={errors}
            data={locations}
            isLoading={isLoadingLocations}
            refreshHandler={handleRefreshLocations}
            fetchOptions={handleLocationSearch}
            mapOptions={mapLocationsToAntdOptions}
          />
        </>
      )}

      <LoadTextInput name='pickup.addressLine1' label='Address Line 1' />
      <LoadTextInput name='pickup.addressLine2' label='Address Line 2' />
      <LoadTextInput name='pickup.city' label='City' />
      <LoadTextInput name='pickup.state' label='State' />
      <LoadTextInput
        name='pickup.zipCode'
        label='Zip Code'
        placeholder='12345'
        // options={zipCodeFieldOptions}
      />
      <LoadTextInput name='pickup.country' label='Country' />
      <LoadTextInput name='pickup.contact' label='Contact' />
      <LoadTextInput
        name='pickup.phone'
        label='Phone'
        placeholder='(555) 555-5555'
        // options={phoneFieldOptions}
        inputValue={InputValue.PHONE_NUMBER}
      />
      <LoadTextInput
        name='pickup.email'
        label='Email'
        placeholder='example@email.com'
      />
      <LoadTextInput name='pickup.businessHours' label='Business Hours' />
      <LoadTextInput name='pickup.refNumber' label='Ref #' />
      <LoadDateTimeInput
        name='pickup.readyTime'
        label='Ready Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      {apptRequiredAttr &&
        !apptRequiredAttr?.isNotSupported &&
        watchedLocationObj?.apptRequired && (
          <Controller
            name={`${stop}.apptRequired`}
            control={control}
            render={({ field }) => (
              <div className='flex items-center space-x-2'>
                <Checkbox
                  onCheckedChange={(checked) => {
                    field.onChange(checked);
                  }}
                  checked={field.value || undefined}
                  disabled={apptRequiredAttr?.isReadOnly}
                />
                <label
                  htmlFor={`${stop}.apptRequired`}
                  className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                >
                  {'Appointment Required?'}
                </label>
              </div>
            )}
          />
        )}
      <LoadDateTimeInput
        name='pickup.apptStartTime'
        label='Appointment Start Time'
        options={datetimeFieldOptions}
        prevValue={suggestedFieldsPrevValues?.pickupApptTime}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='pickup.apptEndTime'
        label='Appointment End Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadTextInput name='pickup.apptNote' label='Appointment Note' />
    </>
  );
}

function ConsigneeSectionForm({
  suggestedFieldsPrevValues,
  formMethods,
  isLoadingLocations,
  locations,
  handleRefreshLocations,
  setLocations,
}: {
  suggestedFieldsPrevValues: any;
  formMethods: UseFormReturn<NormalizedLoad>;
  isLoadingLocations: boolean;
  locations: Maybe<TMSLocation[]>;
  handleRefreshLocations: () => void;
  setLocations: React.Dispatch<React.SetStateAction<Maybe<TMSLocation[]>>>;
}) {
  const stop = 'consignee';
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const { tmsName, tmsID } = useTMSContext();
  const fieldAttrs = useFieldAttributes();
  const apptRequiredAttr = getFieldAttribute(
    fieldAttrs,
    'consignee.apptRequired'
  );
  const [isMcleodTMS, setIsMcleodTMS] = useState<boolean>(
    tmsName === TMS.McleodEnterprise
  );

  useEffect(() => {
    if (isMcleodTMS) {
      setIsMcleodTMS(true);
    }
  }, [tmsName]);

  const watchedLocationID = watch(`${stop}.externalTMSID`);
  const watchedLocationObj = watch(stop);

  useEffect(() => {
    if (watchedLocationID) {
      const selectedLoc = locations?.find(
        (loc) => loc.externalTMSID === watchedLocationID
      );
      if (!selectedLoc) {
        return;
      }

      Object.entries(selectedLoc).forEach(([key, value]) => {
        setValue(`${stop}.${key}` as FieldPath<NormalizedLoad>, value, {
          shouldDirty: true,
        });
      });
    }
  }, [watchedLocationID]);

  const handleLocationSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return locationSearchHandler({
      tmsID,
      locations,
      setLocations,
      field,
      value,
    });
  };

  const currentLoad = formMethods.watch(); // Get current form values

  return (
    <>
      {!isMcleodTMS ? (
        <LoadTextInput name='consignee.name' label='Name' />
      ) : (
        <RHFDebounceSelect
          required={true}
          name={`${stop}.externalTMSID`}
          label='Name'
          control={control}
          errors={errors}
          data={locations}
          isLoading={isLoadingLocations}
          refreshHandler={handleRefreshLocations}
          fetchOptions={handleLocationSearch}
          mapOptions={mapLocationsToAntdOptions}
        />
      )}

      <LoadTextInput name='consignee.addressLine1' label='Address Line 1' />
      <LoadTextInput name='consignee.addressLine2' label='Address Line 2' />
      <LoadTextInput name='consignee.city' label='City' />
      <LoadTextInput name='consignee.state' label='State' />
      <LoadTextInput
        name='consignee.zipCode'
        label='Zip Code'
        placeholder='12345'
        // options={zipCodeFieldOptions}
      />
      <LoadTextInput name='consignee.country' label='Country' />
      <LoadTextInput name='consignee.contact' label='Contact' />
      <LoadTextInput
        name='consignee.phone'
        label='Phone'
        placeholder='(555) 555-5555'
        // FIXME handling of read-only phone fields not working as expected
        // options={phoneFieldOptions}
        inputValue={InputValue.PHONE_NUMBER}
      />
      <LoadTextInput
        name='consignee.email'
        label='Email'
        placeholder='example@email.com'
      />
      <LoadTextInput name='consignee.businessHours' label='Business Hours' />
      <LoadTextInput name='consignee.refNumber' label='Ref #' />
      <LoadTextInput name='consignee.mustDeliver' label='Must Deliver By' />
      {apptRequiredAttr &&
        !apptRequiredAttr.isNotSupported &&
        watchedLocationObj?.apptRequired && (
          <Controller
            name={`${stop}.apptRequired`}
            control={control}
            render={({ field }) => (
              <div className='flex items-center space-x-2'>
                <Checkbox
                  onCheckedChange={(checked) => {
                    field.onChange(checked);
                  }}
                  checked={field.value || undefined}
                  disabled={apptRequiredAttr?.isReadOnly}
                />
                <label
                  htmlFor={`${stop}.apptRequired`}
                  className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                >
                  {'Appointment Required?'}
                </label>
              </div>
            )}
          />
        )}
      <LoadDateTimeInput
        name='consignee.apptStartTime'
        label='Appointment Start Time'
        options={datetimeFieldOptions}
        prevValue={suggestedFieldsPrevValues?.dropoffApptTime}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='consignee.apptEndTime'
        label='Appointment End Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadTextInput name='consignee.apptNote' label='Appointment Note' />
    </>
  );
}

function CarrierSectionForm({
  suggestedFieldsPrevValues,
  load,
  formMethods,
  setIsLoadingCarriers,
  isLoadingCarriers,
  carriers,
  setCarriers,
  setIsCarrierQualified,
}: {
  suggestedFieldsPrevValues: any;
  load: Load;
  formMethods: UseFormReturn<NormalizedLoad>;
  isLoadingCarriers: boolean;
  carriers: Maybe<TMSCarrier[]>;
  setIsLoadingCarriers: React.Dispatch<React.SetStateAction<boolean>>;
  setCarriers: React.Dispatch<React.SetStateAction<Maybe<TMSCarrier[]>>>;
  setIsCarrierQualified: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    resetField,
    setError,
    clearErrors,
  } = formMethods;
  const { tmsName, tmsID } = useTMSContext();
  const { toast } = useToast();
  const isMcleodTMS = tmsName === TMS.McleodEnterprise;

  const handleRefreshCarriers = async () => {
    setIsLoadingCarriers(true);

    const res = await getCarriers(tmsID, true);
    if (res.isOk()) {
      setCarriers(res.value.carrierList);
      toast({
        description: 'Successfully refreshed customer list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing customer list.',
        variant: 'destructive',
      });
    }
    setIsLoadingCarriers(false);
  };

  const handleCarrierSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return carrierSearchHandler({
      tmsID: tmsID,
      carriers,
      setCarriers,
      field,
      value,
    });
  };

  const handleGetCarrierQualification = async (
    carrierName: string,
    carrierExternalTMSID: string
  ) => {
    const res = await getCarrierQualification(
      tmsID,
      load.freightTrackingID,
      carrierExternalTMSID
    );

    if (res.isOk()) {
      if (!res.value.isQualified) {
        setIsCarrierQualified(false);
        toast({
          description: carrierName + ' is not qualified for this load.',
          variant: 'destructive',
        });
        setError('carrier.externalTMSID', {
          type: 'manual',
          message: 'Not qualified for this load.',
        });
      } else {
        // Clear because it's qualified
        setIsCarrierQualified(true);
        clearErrors('carrier.externalTMSID');
      }
    } else {
      // Clear because it's undefined
      setIsCarrierQualified(true);
      clearErrors('carrier.externalTMSID');
      toast({
        description: 'Unable to get carrier qualification from TMS.',
        variant: 'destructive',
      });
    }
  };
  const watchedCarrierID = watch('carrier.externalTMSID');

  useEffect(() => {
    if (watchedCarrierID) {
      const selectedCarrier = carriers?.find(
        (c) => c.externalTMSID === watchedCarrierID
      );
      if (!selectedCarrier) {
        return;
      }

      setValue('carrier.dotNumber', selectedCarrier.dotNumber, {
        shouldDirty: true,
      });
      setValue('carrier.name', selectedCarrier.name, {
        shouldDirty: true,
      });

      if (watchedCarrierID != load.carrier.externalTMSID) {
        // If user switches carriers, reset dependent to blank
        // For Mcleod, driver object is attached to carrier object
        setValue('carrier.dispatcher', '', { shouldDirty: true });
        setValue('carrier.phone', '', { shouldDirty: true });
        setValue('carrier.email', '', { shouldDirty: true });
        setValue('carrier.mcNumber', '', { shouldDirty: true });
        setValue('carrier.firstDriverName', '', { shouldDirty: true });
        setValue('carrier.firstDriverPhone', '', { shouldDirty: true });
        setValue('carrier.secondDriverName', '', { shouldDirty: true });
        setValue('carrier.secondDriverPhone', '', { shouldDirty: true });
      } else {
        // If user switches back to original carrier, reset fields to original values
        resetField('carrier.dispatcher', {
          defaultValue: load.carrier.dispatcher,
        });
        resetField('carrier.phone', { defaultValue: load.carrier.phone });
        resetField('carrier.email', { defaultValue: load.carrier.email });
        resetField('carrier.mcNumber', { defaultValue: load.carrier.mcNumber });
        resetField('carrier.dotNumber', {
          defaultValue: load.carrier.dotNumber,
        });

        resetField('carrier.firstDriverName', {
          defaultValue: load.carrier.firstDriverName,
        });
        resetField('carrier.firstDriverPhone', {
          defaultValue: load.carrier.firstDriverPhone,
        });
        resetField('carrier.secondDriverName', {
          defaultValue: load.carrier.secondDriverName,
        });
        resetField('carrier.secondDriverPhone', {
          defaultValue: load.carrier.secondDriverPhone,
        });
      }

      handleGetCarrierQualification(
        selectedCarrier.name,
        selectedCarrier.externalTMSID
      );
    }
  }, [watchedCarrierID]);

  const currentLoad = formMethods.watch(); // Get current form values

  return (
    <>
      {!isMcleodTMS ? (
        <LoadTextInput name='carrier.name' label='Name' />
      ) : (
        <RHFDebounceSelect
          required={true}
          name={`carrier.externalTMSID`}
          label='Name'
          control={control}
          errors={errors}
          data={carriers}
          isLoading={isLoadingCarriers}
          refreshHandler={handleRefreshCarriers}
          fetchOptions={handleCarrierSearch}
          mapOptions={mapCarriersToAntdOptions}
          searchParamOptions={[
            { key: 'name', label: 'Name' },
            { key: 'addressLine1', label: 'Street' },
            { key: 'dotNumber', label: 'DOT #' },
          ]}
        />
      )}

      <LoadTextInput name='carrier.dotNumber' label='DOT #' />
      <LoadTextInput name='carrier.mcNumber' label='MC #' />
      <LoadTextInput name='carrier.sealNumber' label='Seal #' />
      <LoadTextInput name='carrier.scac' label='SCAC' />
      <LoadTextInput
        name='carrier.phone'
        label='Phone'
        placeholder='(555) 555-5555'
        // options={phoneFieldOptions}
        inputValue={InputValue.PHONE_NUMBER}
      />
      <LoadTextInput
        name='carrier.email'
        label='Email'
        placeholder='example@email.com'
        options={emailFieldOptions}
      />
      <LoadTextInput name='carrier.dispatcher' label='Dispatcher' />

      <LoadTextInput
        name='carrier.rateConfirmationSent'
        label='Rate Confirmation Sent'
      />
      <LoadTextInput
        name='carrier.firstDriverName'
        label='First Driver Name'
        prevValue={suggestedFieldsPrevValues?.firstDriverName}
      />
      <LoadTextInput
        name='carrier.firstDriverPhone'
        label='First Driver Phone'
        // options={phoneFieldOptions}
        inputValue={InputValue.PHONE_NUMBER}
        prevValue={suggestedFieldsPrevValues?.firstDriverPhone}
      />
      <LoadTextInput
        name='carrier.secondDriverName'
        label='Second Driver Name'
        prevValue={suggestedFieldsPrevValues?.secondDriverName}
      />
      <LoadTextInput
        name='carrier.secondDriverPhone'
        label='Second Driver Phone'
        // options={phoneFieldOptions}
        inputValue={InputValue.PHONE_NUMBER}
        prevValue={suggestedFieldsPrevValues?.secondDriverPhone}
      />

      {tmsName === TMS.Relay && (
        <div>
          <Label name='carrier.dispatchSource'>Dispatch Source</Label>
          <Controller
            name='carrier.dispatchSource'
            control={control}
            render={({ field }) => {
              return (
                <Select
                  onValueChange={field.onChange}
                  value={(field.value as string) ?? ''}
                >
                  <SelectTrigger className='w-full mt-2'>
                    <SelectValue placeholder='Choose' />
                  </SelectTrigger>
                  <SelectContent>
                    {relaySourceEnums.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {titleCase(option.value)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              );
            }}
          />
          {suggestedFieldsPrevValues?.dispatchSource && (
            <InputPreviousValue
              prevValue={suggestedFieldsPrevValues?.dispatchSource}
            />
          )}
          <ErrorMessage
            errors={errors}
            name={`carrier.dispatchSource`}
            render={({ message }: { message: string }) => (
              <p className='text-red-500 text-xs'>{message}</p>
            )}
          />
        </div>
      )}

      <LoadTextInput
        name='carrier.dispatchCity'
        label='Dispatch City'
        prevValue={suggestedFieldsPrevValues?.dispatchCity}
      />
      <LoadTextInput
        name='carrier.dispatchState'
        label='Dispatch State'
        prevValue={suggestedFieldsPrevValues?.dispatchState}
      />
      <LoadTextInput
        name='carrier.truckNumber'
        label='Truck #'
        prevValue={suggestedFieldsPrevValues?.truckNumber}
      />
      <LoadTextInput
        name='carrier.trailerNumber'
        label='Trailer #'
        prevValue={suggestedFieldsPrevValues?.trailerNumber}
      />

      <LoadTextInput name='carrier.notes' label='Notes' />

      <LoadDateTimeInput
        name='carrier.confirmationSentTime'
        label='Confirmation Sent Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='carrier.confirmationReceivedTime'
        label='Confirmation Received Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='carrier.dispatchedTime'
        label='Dispatched Time'
        options={datetimeFieldOptions}
        prevValue={suggestedFieldsPrevValues?.dispatchedTime}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='carrier.expectedPickupTime'
        label='Expected Pickup Time'
        options={datetimeFieldOptions}
        prevValue={suggestedFieldsPrevValues?.expectedPickupTime}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='carrier.pickupStart'
        label='Pickup Start Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='carrier.pickupEnd'
        label='Pickup End Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='carrier.expectedDeliveryTime'
        label='Expected Delivery Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='carrier.deliveryStart'
        label='Delivery Start Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadDateTimeInput
        name='carrier.deliveryEnd'
        label='Delivery End Time'
        options={datetimeFieldOptions}
        load={currentLoad}
      />
      <LoadTextInput name='carrier.signedBy' label='Signed By' />
    </>
  );
}

function OperatorSectionForm({
  formMethods,
  operators,
  canEdit,
}: {
  formMethods: UseFormReturn<NormalizedLoad>;
  operators: Array<string>;
  canEdit: boolean;
}) {
  const inputName = 'operator';
  const { tmsName } = useTMSContext();
  const [highlightDirtyField, setHighlightDirtyField] = useState(false);

  const {
    control,
    watch,
    getFieldState,
    formState: { dirtyFields },
  } = formMethods;
  const { highlightDirtyFields } = useExtendedFormContext();
  const watchedOperator = watch('operator');

  useEffect(() => {
    const highlightDirtyField = highlightDirtyFields ?? false;
    const fieldState = getFieldState(inputName);
    const isDirty = fieldState.isDirty || (dirtyFields[inputName] ?? false);

    setHighlightDirtyField(isDirty && highlightDirtyField);
  }, [watchedOperator, highlightDirtyFields]);

  return (
    <div className={joinClasses('flex flex-col gap-2.5')}>
      <Label name='operator'>Assigned Operator:</Label>
      <Controller
        name='operator'
        control={control}
        render={({ field }) => (
          <ComboboxDropdownMenu
            canEdit={canEdit}
            selectedOperator={field.value}
            operators={operators}
            setSelectedOperator={field.onChange}
            highlightDirtyField={highlightDirtyField}
          />
        )}
      />

      {!canEdit && tmsName === TMS.Aljex && (
        <span className='flex flex-row bg-violet-blue-bg rounded-lg space-x-1 py-1 mt-0'>
          <div>
            <Info className='h-4 w-4 pl-1' color='#969696' strokeWidth={3} />
          </div>
          <span className='text-xs text-grayscale-content-description font-medium'>
            {`In order to assign an operator, the load's status must be OPEN and pickup and dropoff appointment times must first be set.`}
          </span>
        </span>
      )}
    </div>
  );
}

type LoadTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: FieldPath<NormalizedLoad>;
};
const LoadTextInput = (props: LoadTextInputProps) => (
  <RHFTextInput {...props} />
);

type LoadDateTimeInputProps = Omit<
  React.ComponentPropsWithoutRef<typeof RHFTextInput>,
  'prevValue'
> & {
  name: FieldPath<NormalizedLoad>;
  showAIHint?: boolean;
  prevValue?: Undef<Date>;
  load?: NormalizedLoad;
};

export const LoadDateTimeInput = ({
  name,
  label,
  prevValue,
  required,
  load,
}: LoadDateTimeInputProps) => {
  const { tmsName } = useTMSContext();

  // Get timezone from the appropriate location based on field name
  const getTimezoneForField = (): MaybeUndef<string> => {
    if (!load) return undefined;

    // Extract the section name from the field path (e.g., 'pickup' from 'pickup.apptStartTime')
    const section = name.split('.')[0];

    if (section === 'pickup' && load.pickup?.timezone) {
      return load.pickup.timezone;
    } else if (section === 'consignee' && load.consignee?.timezone) {
      return load.consignee.timezone;
    } else if (section === 'carrier') {
      // For carrier fields, use pickup timezone as default
      return load.pickup?.timezone;
    }

    return undefined;
  };

  // Get the timezone string (e.g. "America/Chicago")
  const timezone = getTimezoneForField();

  // Get short timezone display (e.g. "CST")
  const getShortTimezone = (timezone?: MaybeUndef<string>): string => {
    if (tmsName === TMS.Aljex || !timezone) {
      return '';
    }

    try {
      // Get current date to account for daylight saving time
      const date = new Date();
      const shortTZ =
        new Intl.DateTimeFormat('en-US', {
          timeZone: timezone,
          timeZoneName: 'short',
        })
          .formatToParts(date)
          .find((part) => part.type === 'timeZoneName')?.value || '';
      return shortTZ;
    } catch (error) {
      captureException(error);
      return '';
    }
  };

  const shortTZ = getShortTimezone(timezone);
  const normalizedLabel = label + (shortTZ ? ` (${shortTZ})` : '');

  const [highlightDirtyField, setHighlightDirtyField] = useState(false);
  const [timeInput, setTimeInput] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const timeInputRef = useRef<HTMLInputElement>(null);

  const extendedFormContext = useExtendedFormContext();
  const formContext = useFormContext<NormalizedLoad>();
  const {
    control: formControl,
    getFieldState,
    clearErrors,
    setError,
    formState: { errors },
  } = formContext;
  const posthog = usePostHog();

  // Format a date using the appropriate timezone
  const formatDateWithTimezone = (dateValue: any): string => {
    if (!dateValue || !(dateValue instanceof Date)) return '';

    if (timezone) {
      return dayjs(dateValue).tz(timezone).format('HH:mm');
    }

    return dayjs(dateValue).format('HH:mm');
  };

  // When selecting a date, ensure it's in the correct timezone
  const handleDateSelect = (date: Date | null, field: any) => {
    if (typeof field.onChange === 'function') {
      field.onChange(date);

      // Focus on time input after date selection
      if (date && timeInputRef.current) {
        setTimeout(() => timeInputRef.current?.focus());
      }
    }
  };

  const handleTimeInputBlur = (field: any) => {
    // Only process if the user has entered something
    if (timeInput.trim()) {
      // Use the section's timezone when processing the time
      const newDate = processTimeAndUpdateDate(
        timeInput,
        field.value,
        timezone ? String(timezone) : undefined
      );

      if (newDate) {
        clearErrors(name);
        if (typeof field.onChange === 'function') {
          field.onChange(newDate);
        }
      } else {
        setError(name, {
          type: 'manual',
          message: 'Invalid time format. Please use HH:mm',
        });
      }
    } else {
      // If they didn't enter anything, don't show an error
      clearErrors(name);
    }

    setTimeInput('');
  };

  useEffect(() => {
    if (formContext && extendedFormContext && name) {
      const highlightDirtyFields =
        extendedFormContext.highlightDirtyFields ?? false;
      const fieldState = getFieldState(name);
      setHighlightDirtyField(fieldState.isDirty && highlightDirtyFields);
    } else {
      setHighlightDirtyField(false);
    }
  }, [extendedFormContext, formContext, name, getFieldState]);

  const allFieldAttrs = useFieldAttributes();
  const thisFieldAttr: FieldAttributes =
    getFieldAttribute(allFieldAttrs, name) ?? initFieldAttributes;

  const prevValueStr = prevValue
    ? dayjs(prevValue).format('M/DD/YYYY HH:mm')
    : '';

  return thisFieldAttr.isNotSupported ? null : (
    <div>
      <Label name={name} required={required}>
        {normalizedLabel}
      </Label>

      <Controller
        name={name}
        control={formControl}
        rules={required ? { required: 'Required' } : {}}
        render={({ field }) => {
          // Format display time with the appropriate timezone
          const displayTime = field.value
            ? formatDateWithTimezone(field.value)
            : '';

          return (
            <div className='mt-1 flex flex-row gap-1' data-name={name}>
              <div className='flex gap-4 flex-1'>
                <DatePicker
                  buttonNamePosthog={ButtonNamePosthog.LoadBuildingDateInput}
                  logProperties={{ dateLabel: label }}
                  field={{
                    ...field,
                    onChange: (date: Date | null) =>
                      handleDateSelect(date, field),
                  }}
                  thisFieldAttr={thisFieldAttr}
                  highlightDirtyField={highlightDirtyField}
                />

                <Input
                  ref={timeInputRef}
                  type='text'
                  className={cn(
                    '!pr-1 pl-3 w-1/2 h-8 rounded-[4px] border border-grayscale-border-input text-grayscale-content-input',
                    highlightDirtyField && 'bg-yellow-50'
                  )}
                  placeholder='HH:mm'
                  value={isEditing ? timeInput : timeInput || displayTime}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTimeInput(e.target.value);
                    setIsEditing(true);
                    posthog.capture('load_building_time_input_change', {
                      timeInput: e.target.value,
                      field: name,
                    });
                  }}
                  onFocus={() => setIsEditing(true)}
                  onBlur={() => {
                    handleTimeInputBlur(field);
                    setIsEditing(false);
                  }}
                />
              </div>
              {field.value && (
                <button
                  title='Clear date'
                  onClick={() => field.onChange(null)}
                  className='h-9 flex items-center justify-center'
                >
                  <XCircleIcon className='w-4 h-4' />
                </button>
              )}
            </div>
          );
        }}
      />

      {prevValue ? <InputPreviousValue prevValue={prevValueStr} /> : null}

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }: { message: string }) => (
          <p className='text-red-500 text-xs'>{message}</p>
        )}
      />
    </div>
  );
};

type LoadSectionAccordionItemProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  label: string;
  name: string;
  activeTabs: string[];
};

export function LoadSectionAccordionItem({
  icon,
  children,
  name,
  label,
  activeTabs,
}: LoadSectionAccordionItemProps) {
  return (
    <AccordionItem value={name}>
      <AccordionTrigger icon={icon}>{label}</AccordionTrigger>
      <AccordionContent
        forceMount={true}
        hidden={!activeTabs.includes(name)}
        className='grid grid-cols-1 gap-3 w-full m-0'
      >
        {children}
      </AccordionContent>
    </AccordionItem>
  );
}

const submitAcceptedSuggestion = async (
  reqData: { operation: TMSOperation; load: Load },
  clickedSuggestion: Maybe<GenericSuggestion>,
  setCurrentState: React.Dispatch<React.SetStateAction<SidebarState>>,
  setSuggestedFieldsPrevValues: React.Dispatch<
    React.SetStateAction<CarrierChanges | undefined>
  >
): Promise<void> => {
  if (!clickedSuggestion) return;

  const isCarrierSuggestion =
    clickedSuggestion?.pipeline === SuggestionPipelines.CarrierInfo;
  const isApptSuggestion =
    clickedSuggestion?.pipeline === SuggestionPipelines.ApptConfirmation;

  if (isCarrierSuggestion || isApptSuggestion) {
    const suggestedFields = clickedSuggestion?.suggested as CarrierChanges;
    const clickedSuggestionChanges = Object.entries(suggestedFields)
      .map((sug) => {
        const sugField = isCarrierSuggestion
          ? (sug[0] as keyof CarrierChanges)
          : (sug[0] as keyof ApptConfirmationChanges);

        let sugFieldValue;
        if (isApptSuggestion) {
          switch (sugField) {
            case 'pickupApptTime':
              sugFieldValue = reqData.load.pickup.apptStartTime;
              break;
            case 'dropoffApptTime':
              sugFieldValue = reqData.load.consignee.apptStartTime;
              break;
          }
        }
        return {
          [sugField]: isCarrierSuggestion
            ? reqData.load.carrier[sugField as keyof CarrierChanges]
            : sugFieldValue,
        };
      })
      .reduce((r, c) => Object.assign(r, c), {}) as CarrierChanges;

    await applyLoadSuggestion(clickedSuggestion?.id, {
      existingLoadSuggestion: clickedSuggestionChanges,
    });

    // Remove the applied suggestion from the list of suggestions
    setCurrentState((prevState) => ({
      ...prevState,
      clickedSuggestion: null,
      curSuggestionList: prevState.curSuggestionList.filter(
        (s) => s.id !== clickedSuggestion?.id
      ),
    }));

    setSuggestedFieldsPrevValues(undefined);
  }
};

const getLoadInfoWithSuggestion = (
  normalizedLoad: NormalizedLoad,
  suggestedFields: LoadChanges
): NormalizedLoad => ({
  ...normalizedLoad,
  carrier: {
    ...normalizedLoad.carrier,

    // If there's a clicked suggestion in state, we check the if fields below exists on it.
    firstDriverName:
      suggestedFields?.firstDriverName ??
      normalizedLoad.carrier.firstDriverName,
    firstDriverPhone: suggestedFields?.firstDriverPhone
      ? FormatPhoneNumber(suggestedFields?.firstDriverPhone)
      : normalizedLoad.carrier.firstDriverPhone,
    secondDriverName:
      suggestedFields?.secondDriverName ??
      normalizedLoad.carrier.secondDriverName,
    secondDriverPhone: suggestedFields?.secondDriverPhone
      ? FormatPhoneNumber(suggestedFields?.secondDriverPhone)
      : normalizedLoad.carrier.secondDriverPhone,
    truckNumber:
      suggestedFields?.truckNumber ?? normalizedLoad.carrier.truckNumber,
    trailerNumber:
      suggestedFields?.trailerNumber ?? normalizedLoad.carrier.trailerNumber,
    dispatchedTime:
      suggestedFields?.dispatchedTime ?? normalizedLoad.carrier.dispatchedTime,
    dispatchSource:
      suggestedFields?.dispatchSource ?? normalizedLoad.carrier.dispatchSource,
    expectedPickupTime:
      suggestedFields?.expectedPickupTime ??
      normalizedLoad.carrier.expectedPickupTime,
  },
  pickup: {
    ...normalizedLoad.pickup,
    // If there's a clicked suggestion in state, we check the if fields below exists on it.
    apptStartTime:
      // FIXME: Fix TZ
      suggestedFields?.pickupApptTime ?? normalizedLoad.pickup.apptStartTime,
  },
  consignee: {
    ...normalizedLoad.consignee,
    // If there's a clicked suggestion in state, we check the if fields below exists on it.
    apptStartTime:
      suggestedFields?.dropoffApptTime ??
      normalizedLoad.consignee.apptStartTime,
  },
});
