// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { UseFormReturn } from 'react-hook-form/dist/types/form';

import { isAxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { toast } from 'hooks/useToaster';
import {
  DATQuoteLocationType,
  DATQuoteTimeframe,
  MarginType,
  QuoteTypeInSource,
} from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { Email } from 'types/Email';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import { Quoting } from 'types/enums/Integrations';
import { GenericSuggestion } from 'types/suggestions/CoreSuggestions';

export enum TransportType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  FLATBED = 'FLATBED',
  // Not supported natively by GS; there's special logic in BE to handle these
  SPECIAL = 'SPECIAL',
  HOTSHOT = 'HOTSHOT',
  BOXTRUCK = 'BOX TRUCK',
}

export enum SelectedCarrierType {
  NETWORK = 'NETWORK',
  BUYPOWER = 'BUYPOWER',
  DAT = 'DAT',
  TRUCKSTOP_POSTED = 'TRUCKSTOP_POSTED',
  TRUCKSTOP_BOOKED = 'TRUCKSTOP_BOOKED',
  LANE_HISTORY = 'LANE_HISTORY',
}

type Stop = {
  zip: string;
  city: string;
  state: string;
  location?: string;
};

export interface QuickQuoteInputs {
  transportType: TransportType;
  pickupDate: Date;
  deliveryDate: Date;
  stops: Array<Stop>;
  isSubmitToTMS: boolean;
  customerName: string;
  quoteNumber: string;
  quoteExpirationDate: Date;
  quoteEta: Date;
}

interface QuickQuoteBody extends QuickQuoteInputs {
  quoteRequestId?: number;
  emailID?: number;
  threadID?: string;
}

type respStops = {
  order: number;
  city: string;
  state: string;
  zip: string;
  country: string;
};

type respQuotes = {
  id: number;
  source: Quoting;
  type: QuoteTypeInSource;
  rates: {
    target: number;
    low: number;
    high: number;
    targetPerMile: number;
    lowPerMile: number;
    highPerMile: number;
  };
  distance: number;
  metadata?: {
    error?: string;

    // GS Metadata
    confidenceLevel?: number;

    // DAT Metadata
    companies?: number;
    reports?: number;
    timeframe?: DATQuoteTimeframe;
    originName?: string;
    originType?: DATQuoteLocationType;
    destinationName?: string;
    destinationType?: DATQuoteLocationType;
    fuelSurchargePerMile?: number;
  };
};

// TODO: This will be refactored in ENG-2961
export interface QuickQuoteResponse {
  quoteRequestId: number;
  stops: respStops[];
  selectedRateName: SelectedCarrierType;
  // HotShot and Box Truck are approximated with Flatbed and Van respectively
  // so we need to indicate the transport type for this data to the user
  inputtedTransportType: TransportType;
  submittedTransportType: TransportType;
  configuration?: {
    lowConfidenceThreshold: number;
    mediumConfidenceThreshold: number;
    belowThresholdMessage: string;
    defaultPercentMargin: number;
    defaultFlatMargin: number;
  };

  quotes: respQuotes[];
}

type ErrorData = {
  error: string;
  threshold: Maybe<number>;
  defaultPercentMargin: number;
  defaultFlatMargin: number;
};

// TODO: Refactor to match other API funcs; form updates should be handled in the form
export async function getQuickQuote(
  email: Maybe<Email>,
  clickedSuggestion: Maybe<GenericSuggestion>,
  formValues: QuickQuoteInputs,
  formMethods: UseFormReturn<QuickQuoteInputs>,
  setQuoteNotConfidentHandler: React.Dispatch<React.SetStateAction<boolean>>,
  setMargin: React.Dispatch<React.SetStateAction<number>>,
  marginType: MarginType
): Promise<MaybeUndef<QuickQuoteResponse>> {
  setQuoteNotConfidentHandler(false);

  const body: QuickQuoteBody = {
    ...formValues,
    ...(email && { emailID: email.id, threadID: email.threadID }),
    ...(clickedSuggestion && { quoteRequestId: clickedSuggestion.id }),
  };

  try {
    const { data } = await axios.post<QuickQuoteResponse>(
      `/quote/quickquote/v2`,
      body
    );

    // Check for non-omitted errors from below-threshold confidence GS quotes
    const lowConfGSQuotes = data.quotes.filter(
      (quote: respQuotes) =>
        quote.metadata?.error &&
        quote.metadata?.error.includes('unable') &&
        quote.metadata?.error.includes('confident')
    );

    if (lowConfGSQuotes.length) {
      setMargin(
        marginType === MarginType.Amount
          ? data.configuration?.defaultFlatMargin || 100
          : data.configuration?.defaultPercentMargin || 10
      );
      toast({
        description: `Greenscreens quote confidence is below
                ${
                  data.configuration?.lowConfidenceThreshold
                    ? data.configuration?.lowConfidenceThreshold + '%.'
                    : 'your minimum threshold.'
                }
                Try another quoting approach, then return to complete the form.`,
        variant: 'destructive',
      });

      data.quotes = data.quotes.filter(
        (quote: respQuotes) => !lowConfGSQuotes.includes(quote)
      );
      data.quotes.length === 0 && setQuoteNotConfidentHandler(true);
    }

    return data;
  } catch (error) {
    const defaultErrorMessage =
      'Please try again in a few moments or contact our team if the issue persists.';

    if (isAxiosError(error)) {
      const status = error.response?.status;
      const data = error.response?.data;

      switch (status) {
        case 503: {
          const data: ErrorData = error.response?.data;

          if (data.error.includes('stops.0')) {
            formMethods.setError('root', {
              message:
                "The pickup location doesn't exist. Please enter a valid city and state pair.",
            });
            formMethods.setError('stops.0.city', {});
            formMethods.setError('stops.0.state', {});
          } else if (data.error.includes('stops.1')) {
            formMethods.setError('root', {
              message:
                "The dropoff location doesn't exist. Please enter a valid city and state pair.",
            });
            formMethods.setError('stops.1.city', {});
            formMethods.setError('stops.1.state', {});
          } else if (data.error.includes('Invalid Zip Code.')) {
            if (data.error.includes('stop 0')) {
              formMethods.setError('root', {
                message:
                  "The pickup location doesn't exist. Please enter a valid ZIP code.",
              });
              formMethods.setError('stops.0.zip', {});
            } else if (data.error.includes('stop 1')) {
              formMethods.setError('root', {
                message:
                  "The dropoff location doesn't exist. Please enter a valid ZIP code.",
              });
              formMethods.setError('stops.1.zip', {});
            }
          } else if (
            data.error.includes('ZIPCode must be 5 characters') ||
            data.error.includes('is not 5 digits if USA')
          ) {
            if (
              data.error.includes('stop 0') ||
              data.error.includes('stops.0')
            ) {
              formMethods.setError('stops.0.zip', {});
            } else if (
              data.error.includes('stop 1') ||
              data.error.includes('stops.1')
            ) {
              formMethods.setError('stops.1.zip', {});
            }
            formMethods.setError('root', {
              message: 'ZIP code must be 5 characters.',
            });
          } else {
            toast({
              description: `Unable to retrieve quote. Please retry or contact our team if the issue persists.`,
              variant: 'destructive',
            });
            return;
          }
          return;
        }
        default: {
          formMethods.setError('root', {
            message: data || defaultErrorMessage,
          });
          return;
        }
      }
    }

    // Non-Axios error fallback
    formMethods.setError('root', {
      message: defaultErrorMessage,
    });
  }
  return null;
}
