import { ReactNode, createContext, useContext } from 'react';

export interface ExtendedFormContextType {
  aiDefaultValues: boolean; // Indicates if default values are to be treated as AI-filled
  aiIconOnly?: boolean;
  // Indicates if dirty fields should be highlighted. Particularly help for Load Information form which
  // 1) has many inputs and 2) defaults inputs to load's existing values.
  // So highlighting dirty fields allows user to easily see they changed.
  //
  // NOTE: For this to work for shadcn Select components, you MUST define SelectTrigger.Name prop.
  // e.g. `<SelectTrigger className='w-full mt-1' name={field.name}>`
  //
  // TODO: Add support for Antd Select component
  highlightDirtyFields?: boolean;
}

export const ExtendedFormContext = createContext<ExtendedFormContextType>({
  aiDefaultValues: false,
  aiIconOnly: false,
  highlightDirtyFields: false,
});

export const ExtendedFormProvider = ({
  children,
  ...props
}: ExtendedFormContextType & {
  children: ReactNode;
}) => {
  return (
    <ExtendedFormContext.Provider value={{ ...props }}>
      {children}
    </ExtendedFormContext.Provider>
  );
};

export const useExtendedFormContext = () => useContext(ExtendedFormContext);
