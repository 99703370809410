import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { useServiceFeatures } from 'hooks/useServiceContext';
import McleodEnterpriseLoadBuildingForm from 'pages/QuoteView/LoadBuilding/McleodLoadBuildingForm';
import TaiLoadBuildingForm from 'pages/QuoteView/LoadBuilding/TaiLoadBuildingForm';
import { Maybe } from 'types/UtilityTypes';
import TMS from 'types/enums/Integrations';
import captureException from 'utils/captureException';

export default function LoadBuildingSection() {
  const { serviceID, tmsIntegrations } = useServiceFeatures();
  const [isMcleodEnterpriseTMS, setIsMcleodEnterpriseTMS] =
    useState<Maybe<boolean>>(null);

  useEffect(() => {
    if (tmsIntegrations.length > 0) {
      // TODO: add support for multiple TMS integrations
      if (tmsIntegrations.length > 1 && isProd()) {
        captureException(
          new Error(
            'Service with multiple TMS integrations trying to build load'
          ),
          { serviceID: serviceID, tmsIntegrations: tmsIntegrations }
        );
      }
      setIsMcleodEnterpriseTMS(
        tmsIntegrations[0].name === TMS.McleodEnterprise
      );
    }
  }, [tmsIntegrations]);

  return isMcleodEnterpriseTMS === null ? null : isMcleodEnterpriseTMS ? (
    <McleodEnterpriseLoadBuildingForm />
  ) : (
    <TaiLoadBuildingForm />
  );
}
