import { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  useForm,
} from 'react-hook-form';

import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Label } from 'components/Label';
import { Textarea } from 'components/Textarea';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { useToast } from 'hooks/useToaster';
import { submitAppt } from 'lib/api/submitAppt';
import { validateAppt } from 'lib/api/validateAppt';
import { NormalizedLoad } from 'types/Load';
import { Warehouse } from 'types/Warehouse';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';

import { RetalixPOList } from './RetalixPOList';

// Calling object PurchaseOrders instead of PONumbers to avoid stuttering
export type PurchaseOrder = {
  number: string;
  isValid: boolean;
  error: string;
};

type RetalixFormProps = {
  load: NormalizedLoad;
  selectedWarehouse: Warehouse;
};

export function RetalixForm({ load, selectedWarehouse }: RetalixFormProps) {
  const { toast } = useToast();
  const formMethods = useForm<RetalixInputsWithoutLoad>();

  const [isLoadingValidate, setIsLoadingValidate] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);

  const { control, handleSubmit, getValues } = formMethods;

  useEffect(() => {
    if (load.poNums && load.poNums !== '') {
      setPurchaseOrders(
        load.poNums
          .split(load.poNums?.includes(',') ? ',' : '/')
          .map((poNum) => ({ number: poNum, isValid: false, error: '' }))
      );
    }
  }, [load]);

  const handleValidateAppt = async () => {
    setIsLoadingValidate(true);

    const validateRes = await validateAppt(
      selectedWarehouse.warehouseID,
      selectedWarehouse.warehouseSource,
      purchaseOrders.map((po) => po.number)
    );

    if (validateRes.isOk()) {
      const { validatedPONumbers } = validateRes.value;

      if (validatedPONumbers.length === 0) return;

      setPurchaseOrders(
        validatedPONumbers.map((poNum) => ({
          number: poNum.poNumber,
          isValid: poNum.isValid,
          error: poNum.error,
        }))
      );
    } else {
      toast({
        description: 'An error occurred while validating your PO numbers.',
        variant: 'destructive',
      });
    }

    setIsLoadingValidate(false);
  };

  const handleSubmitAppt = async () => {
    setIsLoadingSubmit(true);

    const submitRes = await submitAppt(
      selectedWarehouse.warehouseID,
      selectedWarehouse.warehouseSource,
      purchaseOrders.map((po) => po.number),
      getValues('shouldRequestLumper'),
      getValues('apptNote')
    );

    if (submitRes.isOk()) {
      toast({
        description: 'Your appointment has been submitted.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'An error occurred while submitting your appointment.',
        variant: 'destructive',
      });
    }

    setIsLoadingSubmit(false);
  };

  const onInvalid: SubmitErrorHandler<any> = async () => {
    toast({
      description: 'Some fields are invalid.',
      variant: 'destructive',
    });
  };

  const canSubmit =
    purchaseOrders.map((po) => po.isValid || po.error !== '').length === 0;

  return (
    <ExtendedFormProvider aiDefaultValues={true}>
      <FormProvider {...formMethods}>
        <div className='col-span-6'>
          <div className='mt-2'>
            <form
              onSubmit={handleSubmit(handleSubmitAppt, onInvalid)}
              className='flex flex-col gap-4 mt-4 mx-0 w-full'
            >
              <div>
                <Label name='poNumbers'>Customer PO #</Label>
                <RetalixPOList
                  purchaseOrders={purchaseOrders}
                  setPurchaseOrders={setPurchaseOrders}
                />
              </div>

              <Button
                buttonNamePosthog={ButtonNamePosthog.ValidateRetalixPONumbers}
                className='w-full'
                type='button'
                disabled={!purchaseOrders.length || isLoadingValidate}
                onClick={handleValidateAppt}
              >
                {isLoadingValidate ? (
                  <ButtonLoader />
                ) : (
                  ButtonText.ValidatePONumbers
                )}
              </Button>

              {canSubmit && (
                <div className='flex flex-col gap-6 mt-4'>
                  <Controller
                    name='shouldRequestLumper'
                    control={control}
                    render={({ field }) => (
                      <div className='flex items-center space-x-2'>
                        <Checkbox
                          onCheckedChange={field.onChange}
                          checked={field.value}
                        />
                        <label
                          htmlFor='shouldRequestLumper'
                          className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                        >
                          Request Lumper?
                        </label>
                      </div>
                    )}
                  />

                  <div className='flex flex-col gap-2'>
                    <Label name='apptNote' className='text-base'>
                      Appointment Note
                    </Label>
                    <Controller
                      control={control}
                      name='apptNote'
                      render={({ field: { onChange, value } }) => (
                        <Textarea
                          className='p-2 h-16 whitespace-pre-wrap focus-visible:ring-transparent'
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>

                  <Button
                    buttonNamePosthog={ButtonNamePosthog.SubmitRetalixAppt}
                    type='submit'
                    className='w-full'
                    disabled={!canSubmit || isLoadingSubmit}
                  >
                    {isLoadingSubmit ? <ButtonLoader /> : ButtonText.Submit}
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
      </FormProvider>
    </ExtendedFormProvider>
  );
}

interface RetalixInputsWithoutLoad {
  shouldRequestLumper: boolean;
  apptNote: string;
  poNumbers: string;
}
