import dayjs from 'dayjs';

export type TimepickerParsedTime =
  | { hours: number; minutes: number }
  | undefined;

/**
 * Parses a time string supporting formats like:
 * - 0900, 900, 09:00, 9:00 (24-hour)
 * - 09:00pm, 9:00pm, etc. (12-hour with am/pm)
 */
export const parseTimeInput = (input: string): TimepickerParsedTime => {
  if (!input) return undefined;
  input = input.trim().toLowerCase().replace(/\s+/g, '');

  // Try matching 12-hour format with am/pm (with or without colon)
  let match = input.match(/^(\d{1,2})[:]?(\d{2})(am|pm)$/);
  if (match) {
    let hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const period = match[3];
    if (hours < 1 || hours > 12 || minutes < 0 || minutes > 59)
      return undefined;
    if (period === 'pm' && hours !== 12) {
      hours += 12;
    }
    if (period === 'am' && hours === 12) {
      hours = 0;
    }
    return { hours, minutes };
  }
  // Try matching 24-hour format (with or without colon)
  match = input.match(/^(\d{1,2})[:]?(\d{2})$/);
  if (match) {
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59)
      return undefined;
    return { hours, minutes };
  }
  return undefined;
};

export const timepickerChangeHandler = (
  dayjsTime: dayjs.Dayjs,
  field: { value: Date; onChange: (date: Date) => void }
) => {
  const hours = dayjsTime.hour();
  const minutes = dayjsTime.minute();

  const currentDate = dayjs.isDayjs(field.value)
    ? field.value.toDate()
    : new Date(field.value);
  const newDate = new Date(currentDate.getTime());

  newDate.setHours(hours);
  newDate.setMinutes(minutes);

  field.onChange(newDate);
};
