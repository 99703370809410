import { useContext, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { SidebarStateContext } from 'contexts/sidebarStateContext';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import { useServiceFeatures } from 'hooks/useServiceContext';
import McleodEnterpriseLoadBuildingForm from 'pages/QuoteView/LoadBuilding/McleodLoadBuildingForm';
import { Maybe } from 'types/UtilityTypes';
import { TMS } from 'types/enums/Integrations';
import Pageview from 'types/enums/Pageview';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import captureException from 'utils/captureException';

import TurvoLoadBuildingForm from './TurvoLoadBuildingForm';

export default function LoadBuildingTab() {
  const { serviceID, tmsIntegrations } = useServiceFeatures();
  const [isMcleodEnterpriseTMS, setIsMcleodEnterpriseTMS] =
    useState<Maybe<boolean>>(null);
  const [isTurvoTMS, setIsTurvoTMS] = useState<Maybe<boolean>>(null);

  useLogPostHogPageView(Pageview.LoadBuilding, {
    form_type: isMcleodEnterpriseTMS
      ? TMS.Mcleod
      : isTurvoTMS
        ? TMS.Turvo
        : TMS.Tai,
    tms_integrations_count: tmsIntegrations.length,
    service_id: serviceID,
  });

  const {
    currentState: {
      clickedSuggestion,
      curSuggestionList,
      goToSuggestionInCarousel,
    },
  } = useContext(SidebarStateContext);

  useEffect(() => {
    // Go to first load building suggestion in carousel
    if (!clickedSuggestion) {
      goToSuggestionInCarousel({
        suggestionPipeline: SuggestionPipelines.LoadBuilding,
      });
    }
  }, [curSuggestionList, clickedSuggestion, goToSuggestionInCarousel]);

  useEffect(() => {
    if (tmsIntegrations.length > 0) {
      // TODO: add support for multiple TMS integrations
      if (tmsIntegrations.length > 1 && isProd()) {
        captureException(
          new Error(
            'Service with multiple TMS integrations trying to build load'
          ),
          { serviceID: serviceID, tmsIntegrations: tmsIntegrations }
        );
      }

      switch (tmsIntegrations[0].name) {
        case TMS.McleodEnterprise || TMS.Mcleod:
          setIsMcleodEnterpriseTMS(true);
          break;
        case TMS.Turvo:
          setIsTurvoTMS(true);
          break;
        default:
          break;
      }
    }
  }, [tmsIntegrations]);

  if (isMcleodEnterpriseTMS) {
    return <McleodEnterpriseLoadBuildingForm />;
  }

  if (isTurvoTMS) {
    return <TurvoLoadBuildingForm />;
  }

  return null;
}
