import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { DATQuoteMetadata } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export type LaneRateResponse = {
  rateType: string;
  confidence: string;
  // Per Trip data
  ratePerTrip: number;
  highPerTrip: number;
  lowPerTrip: number;
  // Per Mile data
  ratePerMile: string;
  highPerMile: number;
  lowPerMile: number;
} & DATQuoteMetadata;

export type LaneRateRequest = {
  emailId: number;
  threadId: string;
  transportType: string;
  originDate: string;
  originZip: string;
  originCity: string;
  originState: string;
  destinationDate: string;
  destinationZip: string;
  destinationCity: string;
  destinationState: string;
};

export async function getLaneRateFromService(
  request: LaneRateRequest
): Promise<Result<LaneRateResponse, ApiError>> {
  try {
    const response = await axios.post<LaneRateResponse>(
      `/quote/private/get-from-service/lane-rate`,
      request
    );
    return ok(response.data);
  } catch (error: unknown) {
    captureException(error, { functionName: 'getLaneRateFromService' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get lane rate from service' });
    }

    return err({
      message: error.response?.data || 'Failed to get lane rate from service',
    });
  }
}
