import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { captureException } from '@sentry/browser';
import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import _ from 'lodash';
import {
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
} from 'lucide-react';
import { CheckIcon } from 'lucide-react';

import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { DatePicker } from 'components/DatePicker';
import { GenericLineChart } from 'components/GenericLineChart';
import { Label } from 'components/Label';
import { QuoteCard, QuoteCardType } from 'components/QuoteCard';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { Textarea } from 'components/Textarea';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/Tooltip';
import { Input } from 'components/input';
import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import CarrierPriceCalculator from 'components/pricing/CarrierPriceCalculator';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import {
  DrumkitPlatform,
  SidebarStateContext,
} from 'contexts/sidebarStateContext';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import DATLogo from 'icons/DAT';
import InfoCircleIcon from 'icons/InfoCircle';
import { getCustomers } from 'lib/api/getCustomers';
import {
  LaneHistoryResponse,
  SourceHistory,
  WeekLaneData,
} from 'lib/api/getLaneHistory';
import {
  QuickQuoteInputs,
  QuickQuoteResponse,
  SelectedCarrierType,
  TransportType,
  getQuickQuote,
} from 'lib/api/getQuickQuote';
import { getUserDATInfo } from 'lib/api/getUserDATInfo';
import { updateQuoteRequestSuggestion } from 'lib/api/updateQuoteRequestSuggestion';
import { createMailClientInstance } from 'lib/mailclient/interface';
import { Email } from 'types/Email';
import { TMSCustomer } from 'types/Load';
import { Maybe, Undef } from 'types/UtilityTypes';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import ButtonText from 'types/enums/ButtonText';
import { Quoting, TMS, integrationNameMap } from 'types/enums/Integrations';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';
import { copyToClipboard } from 'utils/copyToClipboard';
import { formatCurrency } from 'utils/formatCurrency';
import { titleCase } from 'utils/formatStrings';
import {
  chartConfig,
  chartConfigPercentile,
} from 'utils/laneHistoryChartConfig';
import {
  GenericCompanySearchableFields,
  customerSearchHandler,
  mapCustomerToAntdOptions,
} from 'utils/loadInfoAndBuilding';
import { calculatePricing } from 'utils/priceCalculations';
import { cn } from 'utils/shadcn';

import { useHelperFunctions } from './helperFunctions';
import {
  CarrierCostType,
  LaneTier,
  MarginType,
  QuickQuoteTextInputProps,
} from './types';

export const QuickQuoteTextInput = (props: QuickQuoteTextInputProps) => (
  <RHFTextInput {...props} />
);

export enum FuelType {
  DOE = 'DOE',
  DAT = 'DAT',
  None = 'None', // Handles initialization logic
}

export default function QuickQuoteForm({ email }: { email: Maybe<Email> }) {
  const { toast } = useToast();
  const [customers, setCustomers] = useState<Maybe<TMSCustomer[]>>(null);
  const [initialCustomers, setInitialCustomers] =
    useState<Maybe<TMSCustomer[]>>(null);
  const [quote, setQuote] = useState<Maybe<QuickQuoteResponse>>(null);
  const [hasThirdPartyQuoteURLs, setHasThirdPartyQuoteURLs] =
    useState<boolean>(false);

  const [laneHistory, setLaneHistory] =
    useState<Maybe<LaneHistoryResponse>>(null);
  const [isLoadingLaneHistory, setIsLoadingLaneHistory] = useState(false);
  const [tmsLaneHistory, setTMSLaneHistory] =
    useState<Maybe<SourceHistory[]>>(null);
  const [tmsLaneHistorySelectedTierIndex, setTmsLaneHistorySelectedTierIndex] =
    useState<Maybe<number>>(null);

  const [quoteNotConfident, setQuoteNotConfident] = useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] = useState<SelectedCarrierType>(
    SelectedCarrierType.NETWORK
  );
  const [quoteCards, setQuoteCards] = useState<QuoteCardType[]>([]);
  const [carrierCost, setCarrierCost] = useState(0);
  const [carrierCostType, setCarrierCostType] = useState<CarrierCostType>(
    CarrierCostType.Flat
  );
  const [maxDistance, setMaxDistance] = useState(0);

  const [marginType, setMarginType] = useState<MarginType>(
    MarginType.Percentage
  );
  const [margin, setMargin] = useState(
    marginType === MarginType.Amount ? 100 : 10
  );

  const [finalPrice, setFinalPrice] = useState(
    // Flat or per mile
    carrierCost * (1 + margin / 100)
  );

  // If carrier cost is per mile, finalFlatPrice is calculated as (cost + margin) * distance
  const [finalFlatPrice, setFinalFlatPrice] = useState(finalPrice);
  const [finalPriceFormat, setFinalPriceFormat] = useState<
    CarrierCostType | 'Both'
  >('Both');

  // Final flat price minus fuel surcharge
  const [fuelEstimate, setFuelEstimate] = useState(0);
  const [datFuelSurcharge, setDATFuelSurcharge] = useState<Maybe<number>>(null);

  const [draftResponse, setDraftResponse] = useState('');
  const [userEditedDraft, setUserEditedDraft] = useState(false);
  const [hasCopiedDraftResponse, setHasCopiedDraftResponse] = useState(false);
  // If user edits message but wants to toggle the format of the price,
  // we need to keep track of the current price string to replace it with the new one
  const [currentPriceString, setCurrentPriceString] = useState('');

  const [loadingDraftReply, setLoadingDraftReply] = useState(false);
  const [isSubmitToTMS, setIsSubmitToTMS] = useState<boolean>(true);
  const [createdQuoteId, setCreatedQuoteId] = useState<Maybe<number>>();

  // This is the ID of the parent quote request that we reference after calling GetQuickQuote. It is used to
  // update the quote request to accepted at the end of the quick quote flow.
  const [parentQuoteRequestId, setParentQuoteRequestId] = useState<number>(0);

  const [tmsTenant, setTMSTenant] = useState<Maybe<string>>();

  const [greenscreensQuoteID, setGreenscreensQuoteID] = useState<string>('');

  const [serviceUsesDAT, setServiceUsesDAT] = useState<boolean>(false);
  const [isLoadingDAT, setIsLoadingDAT] = useState<boolean>(false);
  const [datEmailAddress, setDATEmailAddress] = useState<string>('');
  const [hasGrantedDATPermissions, setHasGrantedDATPermissions] =
    useState<Maybe<boolean>>(null);

  const [isDateDetailsOpen, setIsDateDetailsOpen] = useState<boolean>(false);
  const scrollResultsIntoViewRef = useRef<HTMLDivElement>(null);

  const [frontToTMSLoading, setFrontToTMSLoading] = useState<boolean>(false);

  const {
    currentState: {
      drumkitPlatform,
      threadItemId,
      isOutlookReply,
      clickedSuggestion,
      goToSuggestionInCarousel,
      getDisplayedSuggestion,
      curSuggestionList,
    },
    setCurrentState,
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: {
      isTMSQuoteSubmissionEnabled,
      isQuoteLaneHistoryEnabled,
      isTMSLaneHistoryEnabled,
      isQuoteSubmissionViaURLEnabled,
      isQuoteSubmissionToServiceEnabled,
      isGetLaneRateFromServiceEnabled,
    },
    tmsIntegrations,
    quotingIntegrations,
  } = useServiceFeatures();

  const fetchUserDATInfo = async () => {
    const res = await getUserDATInfo();
    if (res.isOk()) {
      setHasGrantedDATPermissions(res.value.hasGrantedDATPermissions);
    }
  };

  useEffect(() => {
    const serviceHasDATIntegration = quotingIntegrations.some(
      (qIntegration) => qIntegration.name === Quoting.DAT
    );

    if (serviceHasDATIntegration) {
      setServiceUsesDAT(true);
    }
  }, [quotingIntegrations]);

  useEffect(() => {
    if (serviceUsesDAT) {
      fetchUserDATInfo();
    }
  }, [serviceUsesDAT]);

  const memoizedDefaultValues = useMemo<QuickQuoteInputs>(() => {
    const suggestedFields = clickedSuggestion?.suggested as QuoteChanges;

    // Process suggested pickup location
    const pickupLocation = useHelperFunctions.parseLocation(
      suggestedFields?.pickupZip
        ? suggestedFields.pickupZip
        : `${suggestedFields?.pickupCity}, ${suggestedFields?.pickupState}`
    );

    // Process suggested delivery location
    const deliveryLocation = useHelperFunctions.parseLocation(
      suggestedFields?.deliveryZip
        ? suggestedFields.deliveryZip
        : `${suggestedFields?.deliveryCity}, ${suggestedFields?.deliveryState}`
    );

    const parsedValues = {
      transportType: suggestedFields?.transportType ?? '',
      customerName: suggestedFields?.customerExternalTMSID ?? '',
      pickupDate: suggestedFields?.pickupDate,
      deliveryDate: suggestedFields?.deliveryDate,
      stops: [
        {
          location: pickupLocation
            ? pickupLocation.zip
              ? pickupLocation.zip
              : `${pickupLocation.city}, ${pickupLocation.state}`
            : '',
          city: pickupLocation?.city ?? '',
          state: pickupLocation?.state ?? '',
          zip: pickupLocation?.zip ?? '',
        },
        {
          location: deliveryLocation
            ? deliveryLocation.zip
              ? deliveryLocation.zip
              : `${deliveryLocation.city}, ${deliveryLocation.state}`
            : '',
          city: deliveryLocation?.city ?? '',
          state: deliveryLocation?.state ?? '',
          zip: deliveryLocation?.zip ?? '',
        },
      ],
      isSubmitToTMS: true,
    };

    if (tmsIntegrations && tmsIntegrations.length > 0) {
      useHelperFunctions.fetchCustomers({
        setInitialCustomers,
        setCustomers,
        setTMSTenant,
        tmsIntegrations,
      });
    }

    return parsedValues as QuickQuoteInputs;
  }, [clickedSuggestion]);

  const formMethods = useForm<QuickQuoteInputs>({
    defaultValues: memoizedDefaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    resetField,
    formState: { errors, isSubmitting },
  } = formMethods;

  const handleRefreshCustomers = async () => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id, true);
    if (res.isOk()) {
      setInitialCustomers(res.value.customerList);
      setCustomers(res.value.customerList);
      toast({
        description: 'Successfully refreshed customer list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing customer list.',
        variant: 'destructive',
      });
    }
  };

  const handleResetCustomerSearch = () => {
    setCustomers(initialCustomers);
  };

  const handleCustomerSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return customerSearchHandler({
      tmsID: tmsIntegrations?.[0]?.id,
      customers,
      setCustomers,
      field,
      value,
    });
  };

  const formatFinalPriceInDraft = (
    flatPrice: number,
    format: CarrierCostType | 'Both',
    fuelEstimate?: number
  ): string => {
    if (format === CarrierCostType.Linehaul && fuelEstimate) {
      return `${formatCurrency(flatPrice - fuelEstimate, 'USD')}`;
    }

    if (!maxDistance) return formatCurrency(flatPrice, 'USD');

    if (format === 'Both') {
      return `${formatCurrency(flatPrice, 'USD')} (${formatCurrency(
        flatPrice / maxDistance,
        'USD',
        2
      )}/mile)`;
    } else if (format === CarrierCostType.PerMile) {
      return `${formatCurrency(flatPrice / maxDistance, 'USD', 2)}/mile`;
    } else {
      return formatCurrency(flatPrice, 'USD');
    }
  };

  // Setting default carrier
  useEffect(() => {
    if (!quoteCards?.length) return;

    // If TMS lane history is enabled, always select that box
    if (
      isTMSLaneHistoryEnabled &&
      laneHistory &&
      Object.values(laneHistory?.resultsBySource)?.length > 0
    ) {
      const laneHistoryCard = quoteCards.find(
        (c) => c.type === SelectedCarrierType.LANE_HISTORY
      );
      if (laneHistoryCard) {
        setSelectedCarrier(SelectedCarrierType.LANE_HISTORY);
        setCarrierCost(_.round(laneHistoryCard.cost, 2));
        return;
      }
    }

    const hasDATCarrier = quoteCards.find(
      (c) => c.type === SelectedCarrierType.DAT
    );
    const defaultCarrier =
      hasDATCarrier ??
      quoteCards.reduce((prev, curr) => (prev.cost < curr.cost ? prev : curr));

    setSelectedCarrier(defaultCarrier.type);
    setCarrierCost(_.round(defaultCarrier.cost));
  }, [quoteCards]);

  const handleEnableDATAccess = async () => {
    setIsLoadingDAT(true);

    await useHelperFunctions.enableDATIndividualAccess({
      datEmailAddress,
      setHasGrantedDATPermissions,
    });

    setIsLoadingDAT(false);
  };

  const onSubmitForm: SubmitHandler<QuickQuoteInputs> = async (formValues) => {
    setCarrierCostType(CarrierCostType.Flat);
    setMarginType(MarginType.Percentage);
    setLaneHistory(null);

    await useHelperFunctions.onSubmitForm({
      getValues,
      formValues,
      setIsSubmitToTMS,
      setCreatedQuoteId,
      setQuote,
      setQuoteCards,
      isQuoteSubmissionViaURLEnabled,
      email,
      setHasThirdPartyQuoteURLs,
      setValue,
      isGetLaneRateFromServiceEnabled,
      clickedSuggestion,
      formMethods,
      setQuoteNotConfident,
      getQuickQuote,
      isQuoteLaneHistoryEnabled,
      setIsLoadingLaneHistory,
      setLaneHistory,
      setCarrierCost,
      setMargin,
      marginType,
      setError,
      setParentQuoteRequestId,
      setGreenscreensQuoteID,
      isQuoteSubmissionToServiceEnabled,
      setDATFuelSurcharge,
    });
  };

  useEffect(() => {
    // If TMS lane history is enabled, set the selected lane tier to the first tier in the list
    const tmsHistoriesKey = Object.keys(
      laneHistory?.resultsBySource || {}
    ).find((source) => Object.values(TMS).includes(source as TMS));

    if (tmsHistoriesKey) {
      setTMSLaneHistory(
        laneHistory?.resultsBySource[tmsHistoriesKey as TMS] || null
      );
      setTmsLaneHistorySelectedTierIndex(0);
    }
  }, [laneHistory]);

  const toggleLaneHistoryGraph = (option: string) => {
    if (!tmsLaneHistory) return;

    const tierIndex = tmsLaneHistory?.findIndex(
      (history) => history.laneTier === option
    );
    if (tierIndex === -1) return;

    setTmsLaneHistorySelectedTierIndex(tierIndex);
  };

  // If no suggestions have been applied and there are QQ ones, apply the first one
  useEffect(() => {
    const firstQuickQuoteSuggestion = curSuggestionList.find(
      (s) => s.pipeline === SuggestionPipelines.QuickQuote
    );

    if (!clickedSuggestion && firstQuickQuoteSuggestion) {
      setCurrentState((prevState) => ({
        ...prevState,
        clickedSuggestion: firstQuickQuoteSuggestion,
      }));
    }
  }, [curSuggestionList]);

  // If applied suggestion is QQ, highlight it in carousel
  useEffect(() => {
    const displayedSuggestion = getDisplayedSuggestion();
    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.QuickQuote &&
      displayedSuggestion?.id !== clickedSuggestion?.id
    ) {
      goToSuggestionInCarousel({
        suggestionID: clickedSuggestion?.id,
      });
    }
  }, [clickedSuggestion, goToSuggestionInCarousel]);

  const handleCopyToClipboard = async () => {
    try {
      const success = await copyToClipboard(draftResponse);
      if (success) {
        setHasCopiedDraftResponse(true);
        // Use the utility function for final price and margin calculations
        const { flatCarrierCost: finalFlatCarrierCost, finalMargin } =
          calculatePricing(
            carrierCost,
            carrierCostType,
            margin,
            marginType,
            maxDistance
          );

        await updateQuoteRequestSuggestion(
          parentQuoteRequestId,
          SuggestionStatus.Accepted,
          {
            finalQuotePrice: _.round(finalPrice),
            finalMargin: _.round(finalMargin),
            marginType: marginType,
            finalCarrierCost: _.round(finalFlatCarrierCost),
            carrierCostType: carrierCostType,
          }
        );
        // Reset copied state after a delay
        setTimeout(() => setHasCopiedDraftResponse(false), 2000);
      }
    } catch (error) {
      captureException(error);

      toast({
        description: 'Failed to copy to clipboard.',
        variant: 'destructive',
      });
    }
  };

  // Update draft response when quote, finalPrice or finalPriceFormat changes.
  // If user edited the draft, then replace just the price substring, not all of their changes.
  useEffect(() => {
    let pickupZip,
      pickupCity,
      pickupState,
      deliveryZip,
      deliveryCity,
      deliveryState;
    const pickupDate = new Date(getValues('pickupDate'));
    const transportType = getValues('transportType');

    if (!quote) {
      pickupZip = getValues('stops.0.zip');
      pickupCity = getValues('stops.0.city');
      pickupState = getValues('stops.0.state');
      deliveryZip = getValues('stops.1.zip');
      deliveryCity = getValues('stops.1.city');
      deliveryState = getValues('stops.1.state');
    } else {
      pickupZip = quote.stops[0].zip;
      pickupCity = quote.stops[0].city;
      pickupState = quote.stops[0].state;
      deliveryCity = quote.stops[1].city;
      deliveryState = quote.stops[1].state;
      deliveryZip = quote.stops[1].zip;
    }

    const formattedPriceString = formatFinalPriceInDraft(
      finalFlatPrice,
      finalPriceFormat,
      fuelEstimate
    );

    const draftBase =
      `Thank you for your ${transportType?.toLowerCase()} request from ` +
      `${
        pickupCity
          ? `${useHelperFunctions.toTitleCase(pickupCity)}, ${pickupState}`
          : pickupZip
      }` +
      ` to ` +
      `${
        deliveryCity
          ? `${useHelperFunctions.toTitleCase(deliveryCity)}, ${deliveryState}`
          : deliveryZip
      }` +
      ` on ${pickupDate.toDateString()}. The rate would be `;

    if (!userEditedDraft) {
      setDraftResponse(draftBase + formattedPriceString + '.');
      setCurrentPriceString(formattedPriceString);
      return;
    }

    // If user edited the draft, replace the old price substring with the new one
    // If the price string is missing, reset the draft to the default message
    const newPrice = formatFinalPriceInDraft(
      finalFlatPrice,
      finalPriceFormat,
      fuelEstimate
    );

    setFinalPriceFormat(finalPriceFormat);

    if (draftResponse.includes(currentPriceString)) {
      // Only replace the old price with the new one
      setDraftResponse(draftResponse.replace(currentPriceString, newPrice));
    } else {
      // Reset message if the price string is missing (user edited too much)
      setDraftResponse(draftBase + newPrice);
    }

    setCurrentPriceString(newPrice);
  }, [quote, finalFlatPrice, finalPriceFormat]);

  // Update form values when suggestion changes
  useEffect(() => {
    if (memoizedDefaultValues) {
      reset(memoizedDefaultValues);
    }
    // Use setValue so as to not show AI-filled helper text
    if (!getValues('pickupDate')) {
      setValue('pickupDate', dayjs().add(1, 'day').toDate());
    }
    if (!getValues('deliveryDate')) {
      setValue('deliveryDate', dayjs().add(2, 'day').toDate());
    }
    if (!getValues('transportType')) {
      setValue('transportType', TransportType.VAN);
    }
  }, [memoizedDefaultValues]);

  useEffect(() => {
    if (quote || quoteNotConfident) {
      quote && setMaxDistance(Math.max(...quote.quotes.map((q) => q.distance)));
      scrollResultsIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });

      // in order to get the ai label to show up, we need to reset the quoteNumber field with the value as a default value
      resetField('quoteNumber', { defaultValue: getValues('quoteNumber') });
    }
  }, [quote, quoteNotConfident]);

  useEffect(() => {
    if (carrierCostType === CarrierCostType.Flat) {
      setFinalFlatPrice(finalPrice);
    } else {
      setFinalFlatPrice(finalPrice * maxDistance);
    }
  }, [finalPrice, carrierCostType, maxDistance]);

  const handleSubmitQuoteToTMS = async () => {
    if (isTMSQuoteSubmissionEnabled && isSubmitToTMS) {
      const customerId = getValues('customerName');
      if (!customerId) return;

      const createdQuote = await useHelperFunctions.processQuoteTMSSubmission({
        customerId: customerId,
        finalPrice: finalFlatPrice,
        getValues,
      });

      createdQuote?.quoteId && setCreatedQuoteId(createdQuote.quoteId);
    }
  };

  let handleDraftResponse: () => void;
  if (drumkitPlatform === DrumkitPlatform.Outlook) {
    // TODO: Warn user to configure signature in Portal if none found
    const mailClient = createMailClientInstance(drumkitPlatform);
    handleDraftResponse = async () => {
      if (!finalFlatPrice || finalFlatPrice <= 0) {
        return;
      }

      setLoadingDraftReply(true);

      // Using separate API calls for draft and TMS Quote submission since the draft call is conditional
      // e.g. we create the draft directly on the client-side in Outlook's Compose View.
      await handleSubmitQuoteToTMS();

      try {
        // Convert newlines to HTML <br> tags since the mail client works with HTML.
        const formattedDraftBody = draftResponse.trim().replace(/\n/g, '<br>');

        await mailClient.draftReply({
          threadItemId,
          draftBody: formattedDraftBody,
        });

        // Use the utility function for final price and margin calculations
        const { flatCarrierCost: finalFlatCarrierCost, finalMargin } =
          calculatePricing(
            carrierCost,
            carrierCostType,
            margin,
            marginType,
            maxDistance
          );

        if (isQuoteSubmissionToServiceEnabled) {
          useHelperFunctions.sendUserQuote({
            email,
            quote,
            parentQuoteRequestId,
            greenscreensQuoteID,
            carrierCost: _.round(finalFlatCarrierCost),
            margin: _.round(finalMargin),
            finalPrice: finalFlatPrice,
            draftResponse,
            marginType,
          });
        }

        await updateQuoteRequestSuggestion(
          parentQuoteRequestId,
          SuggestionStatus.Accepted,
          {
            finalQuotePrice: _.round(finalFlatPrice),
            finalMargin: _.round(finalMargin),
            marginType: marginType,
            finalCarrierCost: _.round(finalFlatCarrierCost),
            carrierCostType: carrierCostType,
            customerExternalTMSId: getValues('customerName'),
          }
        );

        // only remove suggestion from list at the very end of a successful draft creation
        if (clickedSuggestion) {
          setCurrentState((prevState) => ({
            ...prevState,
            clickedSuggestion: null,
            curSuggestionList: prevState.curSuggestionList.filter(
              (s) => s.id !== clickedSuggestion.id
            ),
          }));
        }

        // When in Read View, there's a lag between when Outlook created the draft in the backend
        // and showing it in the client so wait for a moment before showing toaster.
        setTimeout(
          () => {
            toast({
              description: 'Successfully created draft reply.',
              variant: 'success',
            });

            setLoadingDraftReply(false);
          },
          isOutlookReply ? 1 : 3500
        );
      } catch (error: unknown) {
        // narrow type to safely access the message
        const errMsg = error instanceof Error ? error.message : String(error);

        toast({
          description: errMsg || 'Something went wrong creating draft reply',
          variant: 'destructive',
        });

        setLoadingDraftReply(false);
      }
    };
  }

  return (
    <div className='mb-4'>
      <ExtendedFormProvider aiDefaultValues={true}>
        <FormProvider {...formMethods}>
          <TooltipProvider>
            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className='grid gap-6 grid-cols-1 mx-0 w-full'
            >
              <div className='grid gap-3 grid-cols-1 w-full mx-0 mt-4'>
                <div className='text-md whitespace-nowrap text-grayscale-content-label font-semibold'>
                  Load Information
                </div>

                {tmsIntegrations && tmsIntegrations.length > 0 && (
                  <div>
                    <RHFDebounceSelect
                      required={false}
                      name='customerName'
                      label='Customer'
                      control={control}
                      errors={errors}
                      data={customers}
                      isLoading={false}
                      showSearchParamDropdown={false}
                      refreshHandler={handleRefreshCustomers}
                      resetOptionsHandler={handleResetCustomerSearch}
                      fetchOptions={handleCustomerSearch}
                      mapOptions={mapCustomerToAntdOptions}
                    />
                  </div>
                )}
                <div>
                  <Label name={'transportType'} required={true}>
                    Transport Type
                  </Label>
                  <Controller
                    name='transportType'
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger className='w-full mt-1'>
                          <SelectValue placeholder='Choose' />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.values(TransportType)
                            .filter(
                              (value) =>
                                value !== TransportType.SPECIAL && // Always filter out SPECIAL
                                (isTMSLaneHistoryEnabled ||
                                  (value !== TransportType.BOXTRUCK &&
                                    value !== TransportType.HOTSHOT)) // Only include these when feature flag is enabled
                            )
                            .map((option) => (
                              <SelectItem key={option} value={option}>
                                {option}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
              </div>

              <div className='flex justify-between items-start gap-2 w-full mx-0'>
                {/* Pickup */}
                <div className='w-full'>
                  <QuickQuoteTextInput
                    name='stops.0.location'
                    label='Pickup'
                    placeholder='ZIP or City, State'
                    inputClassName='placeholder:text-[12px]'
                    required
                  />
                </div>

                <ArrowRightIcon className='w-10 text-grayscale-icon-stroke mt-7' />

                {/* Dropoff */}
                <div className='w-full'>
                  <QuickQuoteTextInput
                    name='stops.1.location'
                    label='Dropoff'
                    placeholder='ZIP or City, State'
                    inputClassName='placeholder:text-[12px]'
                    required
                  />
                </div>
              </div>

              {isDateDetailsOpen && (
                <div className='flex justify-between items-end gap-4 w-full mx-0'>
                  <div className='w-full'>
                    <Label name='pickupDate' className='text-base'>
                      Pickup Date
                    </Label>
                    <Controller
                      name={`pickupDate`}
                      control={control}
                      rules={{ required: 'Required' }}
                      render={({ field }) => (
                        <div className='mt-1 flex flex-row gap-1'>
                          <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                            <div className='col-span-2'>
                              <DatePicker field={field} />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>

                  <div className='w-full'>
                    <Label name='deliveryDate' className='text-base'>
                      Dropoff Date
                    </Label>
                    <Controller
                      name={`deliveryDate`}
                      control={control}
                      render={({ field }) => (
                        <div className='mt-1 flex flex-row gap-1'>
                          <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                            <div className='col-span-2'>
                              <DatePicker field={field} />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              )}

              <Button
                className='w-40 mx-auto h-8 text-[14px] text-grayscale-content-2 flex gap-2 hover:border-gray-600 hover:bg-gray-200'
                buttonNamePosthog={ButtonNamePosthog.ToggleQuoteFuelDetails}
                onClick={() => setIsDateDetailsOpen(!isDateDetailsOpen)}
                type='button'
                variant='ghost'
              >
                {!isDateDetailsOpen ? (
                  <ChevronDownIcon className='h-4 w-4' />
                ) : (
                  <ChevronUpIcon className='h-4 w-4' />
                )}
                Add date details
              </Button>

              {errors.root && (
                <div className='flex justify-center'>
                  <div className='w-auto text-sm border-red-500 bg-red-500 text-neutral-50 dark:border-red-900 dark:bg-red-900 dark:text-neutral-50 rounded-lg p-2 text-center'>
                    {errors.root.message}
                  </div>
                </div>
              )}

              {serviceUsesDAT &&
                hasGrantedDATPermissions !== null &&
                !hasGrantedDATPermissions && (
                  <div className='grid gap-3 w-full mx-0 bg-blue-bg border border-blue-main rounded-[4px] py-4 px-3'>
                    <div className='flex items-center justify-between'>
                      <DATLogo height={12} />
                      <Tooltip delayDuration={10}>
                        <TooltipTrigger>
                          <InfoCircleIcon className='w-5 h-5 text-blue-main cursor-pointer' />
                        </TooltipTrigger>
                        <TooltipContent>
                          Enable DAT using your email address
                        </TooltipContent>
                      </Tooltip>
                    </div>

                    <div className='flex justify-between items-end gap-3 w-full'>
                      <div className='w-full flex flex-col grow-1'>
                        <label className='text-sm text-grayscale-content-label mb-1'>
                          Email Address
                        </label>
                        <Input
                          name='datEmailAddress'
                          placeholder='dat_user@example.com'
                          className='!outline-none'
                          onChange={(e) => setDATEmailAddress(e.target.value)}
                        />
                      </div>

                      <Button
                        buttonNamePosthog={ButtonNamePosthog.GetQuickQuote}
                        className='w-20 h-8 text-sm mb-[1px] bg-orange-main/80'
                        onClick={handleEnableDATAccess}
                        disabled={isLoadingDAT || !datEmailAddress}
                        type='button'
                      >
                        {isLoadingDAT ? (
                          <ButtonLoader />
                        ) : (
                          ButtonText.EnableDATForUser
                        )}
                      </Button>
                    </div>
                  </div>
                )}

              <Button
                buttonNamePosthog={ButtonNamePosthog.GetQuickQuote}
                type='submit'
                className='w-full'
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoader /> : ButtonText.GetQuickQuote}
              </Button>

              {quote && !quoteNotConfident && (
                <div className='flex flex-col items-center py-2'>
                  <div className=' w-full text-left text-md text-grayscale-content-label font-semibold'>
                    Quote for
                  </div>
                  <h3 className='text-sm w-full text-left'>{`
                      ${useHelperFunctions.toTitleCase(quote.stops[0].city)}, ${quote.stops[0].state} to
                      ${useHelperFunctions.toTitleCase(quote.stops[1].city)}, ${quote.stops[1].state}
                    `}</h3>
                  {maxDistance > 0 && (
                    <h3 className='text-sm w-full text-left'>
                      {`Distance: ${_.round(maxDistance)} miles`}
                    </h3>
                  )}

                  <div className='w-full h-0.5 bg-[#305645] mt-2' />

                  <div className='flex flex-col gap-4 mt-4 w-full'>
                    {quoteCards.map((card) => (
                      <QuoteCard
                        key={card.type}
                        carrier={card}
                        isSelected={selectedCarrier === card.type}
                        onClick={() => {
                          setSelectedCarrier(card.type);
                          if (carrierCostType === CarrierCostType.Flat) {
                            setCarrierCost(_.round(card.cost));
                          } else {
                            // Use costPerMile if available
                            if (card?.costPerMile) {
                              setCarrierCost(card?.costPerMile);
                            } else {
                              // Otherwise, reset carrier cost to flat
                              setCarrierCostType(CarrierCostType.Flat);
                              setCarrierCost(_.round(card.cost));
                            }
                          }
                        }}
                        lowConfidenceThreshold={
                          quote.configuration?.lowConfidenceThreshold || 70
                        }
                        mediumConfidenceThreshold={
                          quote.configuration?.mediumConfidenceThreshold || 80
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
              {/* Lane History Charts */}
              {(quoteNotConfident || quote) && (
                <>
                  {isQuoteLaneHistoryEnabled ? (
                    isLoadingLaneHistory ? (
                      <div className='flex flex-row justify-center gap-1'>
                        <p className='text-sm text-grayscale-content-input'>
                          Fetching Lane History...
                        </p>
                        <ButtonLoader />
                      </div>
                    ) : laneHistory &&
                      Object.values(laneHistory.resultsBySource).length ? (
                      // Generate a chart for each source
                      Object.entries(laneHistory.resultsBySource).map(
                        ([source, history], index) => {
                          let laneTierHistory = history[0];

                          const isTMSChart =
                            tmsLaneHistory &&
                            tmsLaneHistory.length > 0 &&
                            source === tmsLaneHistory[0].source;

                          // If there's TMS lane history and multiple tiers, graph the selected tier
                          if (
                            tmsLaneHistory &&
                            isTMSChart &&
                            tmsLaneHistorySelectedTierIndex !== null
                          ) {
                            laneTierHistory =
                              tmsLaneHistory[tmsLaneHistorySelectedTierIndex];
                          }

                          let laneTierOptions: Undef<LaneTier[]> = undefined;
                          if (tmsLaneHistory && isTMSChart) {
                            laneTierOptions = tmsLaneHistory
                              .filter((history) => Boolean(history.laneTier))
                              .map((history) => history.laneTier as LaneTier);
                          }

                          const yAxisMax = Math.max(
                            ...history[0].weeks
                              .filter((item) => Boolean(item.maxRate))
                              .map((item) => item.maxRate)
                          );

                          const tooltipElt = (
                            inputtedTransportType: TransportType,
                            proxiedTransportType: TransportType
                          ) => (
                            <div className='flex justify-between'>
                              <div className='flex items-baseline gap-x-2'>
                                <Tooltip delayDuration={10}>
                                  <TooltipTrigger className='border-b border-dashed border-black text-sm font-normal'>
                                    {`(${titleCase(proxiedTransportType)})`}
                                  </TooltipTrigger>
                                  <TooltipContent className='ml-2 font-normal max-w-60 whitespace-pre-wrap'>
                                    <p>{`Greenscreens does not yet support ${titleCase(inputtedTransportType)}, so it was proxied with ${titleCase(proxiedTransportType)} data.`}</p>
                                  </TooltipContent>
                                </Tooltip>
                              </div>
                            </div>
                          );

                          return (
                            <>
                              <GenericLineChart
                                data={laneTierHistory.weeks}
                                title={`${integrationNameMap[laneTierHistory.source]} Lane History`}
                                subtitle={
                                  laneTierHistory.inputtedTransportType !==
                                  laneTierHistory.proxiedTransportType
                                    ? tooltipElt(
                                        laneTierHistory.inputtedTransportType,
                                        laneTierHistory.proxiedTransportType
                                      )
                                    : titleCase(
                                        laneTierHistory.proxiedTransportType
                                      )
                                }
                                // Don't repeat description for each chart
                                description={
                                  index === 0
                                    ? laneTierHistory.isPercentile
                                      ? `${quote?.stops[0].state} to ${quote?.stops[1].state} rates from the last 4 weeks`
                                      : 'Rates from the last four weeks'
                                    : ''
                                }
                                toggleOptions={laneTierOptions}
                                selectedData={
                                  laneTierHistory.laneTier as string
                                }
                                toggleDataHandler={toggleLaneHistoryGraph}
                                chartConfig={
                                  laneTierHistory.isPercentile
                                    ? chartConfigPercentile
                                    : chartConfig
                                }
                                yAxisDomainMax={yAxisMax}
                                yAxisDomainMin={0}
                                yAxisWidth={yAxisMax > 999 ? 45 : 35}
                                thirdTooltipLabel='Loads'
                                dataKeys={
                                  [
                                    'maxRate',
                                    'averageRate',
                                    'lowestRate',
                                  ] as (keyof WeekLaneData)[]
                                }
                              />
                            </>
                          );
                        }
                      )
                    ) : (
                      <div className='flex justify-center'>
                        <p className='text-sm text-grayscale-content-input'>
                          No Lane History Available
                        </p>
                      </div>
                    )
                  ) : null}
                  <CarrierPriceCalculator
                    parentQuoteRequestId={parentQuoteRequestId}
                    showTitle={false}
                    mileage={maxDistance}
                    finalPrice={finalPrice}
                    fuelEstimate={fuelEstimate}
                    datFuelSurcharge={datFuelSurcharge}
                    margin={margin}
                    marginType={marginType}
                    maxDistance={maxDistance}
                    setMarginTypeHandler={setMarginType}
                    carrierCost={carrierCost}
                    carrierCostType={carrierCostType}
                    setCarrierCostTypeHandler={setCarrierCostType}
                    setCarrierCostHandler={setCarrierCost}
                    setMarkupHandler={setMargin}
                    setFuelEstimateHandler={setFuelEstimate}
                    setFinalPriceHandler={setFinalPrice}
                  />
                </>
              )}
            </form>

            {quote && quote.configuration?.belowThresholdMessage && (
              <div className='w-auto text-sm border-red-500 bg-red-500 text-neutral-50 dark:border-red-900 dark:bg-red-900 dark:text-neutral-50 rounded-lg p-2 mb-4 text-center'>
                {quote.configuration?.belowThresholdMessage}
              </div>
            )}

            {(quote || quoteNotConfident) && (
              <div
                className='flex-col justify-center'
                ref={scrollResultsIntoViewRef}
              >
                {isQuoteSubmissionViaURLEnabled && hasThirdPartyQuoteURLs ? (
                  <>
                    <h3>Submission Hyperlink Detected</h3>
                    <div className='rounded bg-[#eaeaea] text-sm py-3 px-3 mt-1'>
                      <div className='w-full'>
                        <QuickQuoteTextInput
                          name='quoteNumber'
                          placeholder='Q000001'
                          label='Quote #'
                          required
                        />
                      </div>
                      <div className='w-full flex flex-row justify-between gap-2 mt-2'>
                        <div className='flex flex-col flex-1'>
                          <Label
                            name='quoteExpirationDate'
                            className='text-base'
                          >
                            Expiration
                          </Label>
                          <Controller
                            name={`quoteExpirationDate`}
                            control={control}
                            render={({ field }) => (
                              <div className='mt-1 flex flex-row gap-1'>
                                <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                                  <div className='col-span-2'>
                                    <DatePicker field={field} />
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                          {errors.quoteExpirationDate && (
                            <p className='text-red-500 text-xs'>
                              {errors.quoteExpirationDate.message}
                            </p>
                          )}
                        </div>
                        <div className='flex flex-col flex-1'>
                          <Label name='Eta' className='text-base'>
                            ETA
                          </Label>
                          <Controller
                            name={`quoteEta`}
                            control={control}
                            render={({ field }) => (
                              <div className='mt-1 flex flex-row gap-1'>
                                <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                                  <div className='col-span-2'>
                                    <DatePicker field={field} />
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                          {errors.quoteEta && (
                            <p className='text-red-500 text-xs'>
                              {errors.quoteEta.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='mt-4 '>
                    <h3 className='mb-1'>Draft Response</h3>

                    <div className='relative'>
                      <Button
                        className={cn(
                          'absolute h-6 w-6 p-0 -top-6 right-0 border-none',
                          hasCopiedDraftResponse
                            ? 'cursor-default'
                            : 'cursor-pointer'
                        )}
                        variant='ghost'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          !hasCopiedDraftResponse && handleCopyToClipboard();
                        }}
                      >
                        {hasCopiedDraftResponse ? (
                          <Tooltip open={true}>
                            <TooltipTrigger asChild>
                              <CheckIcon className='h-4 w-4' />
                            </TooltipTrigger>
                            <TooltipContent>Copied!</TooltipContent>
                          </Tooltip>
                        ) : (
                          <CopyIcon className='h-4 w-4' />
                        )}
                      </Button>

                      {/* Draft Response Textarea */}
                      <Textarea
                        name='emailBody'
                        className='p-2 h-36 rounded-[4px]'
                        value={draftResponse}
                        onChange={(e) => {
                          setDraftResponse(e.target.value);
                          !userEditedDraft && setUserEditedDraft(true);
                        }}
                      />
                    </div>

                    {/* If distance exists, allow user to toggle format of final price in response */}
                    {Boolean(maxDistance) && (
                      <div className='flex flex-row items-center whitespace-nowrap min-w-0 mt-1 overflow-hidden'>
                        <span className='text-[13px] xxs:text-xs text-grayscale-content-description mr-2'>
                          Show:
                        </span>
                        <div className='inline-flex rounded-[4px] max-h-5 border border-grayscale-border-input text-xs'>
                          <button
                            type='button'
                            title={'Flat Rate'}
                            onClick={() =>
                              setFinalPriceFormat(CarrierCostType.Flat)
                            }
                            className={`px-1.5 transition-colors ${
                              finalPriceFormat === CarrierCostType.Flat
                                ? 'text-[#FE9659] font-medium bg-orange-bg'
                                : 'text-grayscale-content-description hover:text-[#FE9659]'
                            }`}
                          >
                            Flat Rate
                          </button>
                          <button
                            title={'Per Mile Rate'}
                            type='button'
                            onClick={() =>
                              setFinalPriceFormat(CarrierCostType.PerMile)
                            }
                            className={`px-1.5 transition-colors ${
                              finalPriceFormat === CarrierCostType.PerMile
                                ? 'text-[#FE9659] font-medium bg-orange-bg'
                                : 'text-grayscale-content-description hover:text-[#FE9659]'
                            }`}
                          >
                            Per Mile
                          </button>
                          <button
                            title={'Both Rates'}
                            type='button'
                            onClick={() => setFinalPriceFormat('Both')}
                            className={`px-1.5 transition-colors ${
                              finalPriceFormat === 'Both'
                                ? 'text-[#FE9659] font-medium bg-orange-bg'
                                : 'text-grayscale-content-description hover:text-[#FE9659]'
                            }`}
                          >
                            Both
                          </button>
                          <button
                            title={'Customer Linehaul'}
                            type='button'
                            onClick={() =>
                              setFinalPriceFormat(CarrierCostType.Linehaul)
                            }
                            className={`px-1.5 transition-colors ${
                              finalPriceFormat === 'Linehaul'
                                ? 'text-[#FE9659] font-medium bg-orange-bg'
                                : 'text-grayscale-content-description hover:text-[#FE9659]'
                            }`}
                          >
                            Linehaul
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {isTMSQuoteSubmissionEnabled ? (
                  <>
                    <div className='flex items-center space-x-2 mt-6'>
                      <Checkbox
                        onCheckedChange={() => setIsSubmitToTMS(!isSubmitToTMS)}
                        checked={isSubmitToTMS}
                      />
                      <label
                        htmlFor='isSubmitToTMS'
                        className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                      >
                        Submit Quote to TMS
                      </label>
                    </div>

                    {isSubmitToTMS ? (
                      <div className='mt-4'>
                        <RHFDebounceSelect
                          required={true}
                          name='customerName'
                          label='Customer'
                          control={control}
                          errors={errors}
                          data={customers}
                          isLoading={false}
                          showSearchParamDropdown={false}
                          refreshHandler={handleRefreshCustomers}
                          resetOptionsHandler={handleResetCustomerSearch}
                          fetchOptions={handleCustomerSearch}
                          mapOptions={mapCustomerToAntdOptions}
                        />
                        <a
                          className='underline text-[12px] text-orange-main'
                          target='_blank'
                          rel='noreferrer'
                          href={`https://app.turvo.com/#/${tmsTenant}/accounts/cards?type=shipper`}
                        >
                          or create a new customer.
                        </a>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {createdQuoteId ? (
                  <>
                    <div className='whitespace-pre-wrap my-3 rounded py-3 text-grayscale-content-label px-4 bg-green-bg'>
                      <p className='mb-2'>Quote Created 🎉</p>
                      <p className='mb-2 text-[14px]'>
                        <b className='text-[14px]'>Quote ID #: </b>
                        {createdQuoteId}
                      </p>
                      <p className='mb-1 text-[14px]'>
                        {/** Feature is only supported on Turvo for now */}
                        <a
                          className='underline'
                          target='_blank'
                          rel='noreferrer'
                          href={`https://app.turvo.com/#/${tmsTenant}/shipments/${createdQuoteId}/details`}
                        >
                          Access the created quote for more details
                        </a>
                      </p>
                    </div>
                  </>
                ) : null}

                {/** Temporary button for Front, allowing load submission but not reply drafts */}
                {isTMSQuoteSubmissionEnabled &&
                isSubmitToTMS &&
                drumkitPlatform === DrumkitPlatform.Front ? (
                  <Button
                    className='w-full mt-4'
                    type='button'
                    disabled={frontToTMSLoading}
                    onClick={async () => {
                      const customerId = getValues('customerName');
                      if (!customerId) {
                        toast({
                          description: 'Please enter a customer name.',
                          variant: 'destructive',
                        });
                        return;
                      }

                      setFrontToTMSLoading(true);
                      await handleSubmitQuoteToTMS();
                      setFrontToTMSLoading(false);
                    }}
                  >
                    {frontToTMSLoading ? (
                      <ButtonLoader />
                    ) : (
                      'Submit quote to TMS'
                    )}
                  </Button>
                ) : null}

                {/** Only supported on Outlook for now */}
                {drumkitPlatform === DrumkitPlatform.Outlook ? (
                  isQuoteSubmissionViaURLEnabled && hasThirdPartyQuoteURLs ? (
                    <Button
                      className='w-full mt-4'
                      type='button'
                      disabled={loadingDraftReply}
                      onClick={() =>
                        useHelperFunctions.handleQuoteSubmissionViaURL({
                          email,
                          quote,
                          getValues,
                          setError,
                          setLoadingDraftReply,
                          finalPrice,
                          isTMSQuoteSubmissionEnabled,
                          isSubmitToTMS,
                          setCreatedQuoteId,
                          clickedSuggestion,
                          setCurrentState,
                          parentQuoteRequestId,
                          margin,
                          marginType,
                          carrierCost,
                          carrierCostType,
                          maxDistance,
                        })
                      }
                    >
                      {loadingDraftReply ? (
                        <ButtonLoader />
                      ) : (
                        'Submit quote via URL'
                      )}
                    </Button>
                  ) : (
                    <Button
                      className='w-full mt-4'
                      type='button'
                      disabled={loadingDraftReply}
                      onClick={() => handleDraftResponse()}
                    >
                      {isOutlookReply ? (
                        'Add reply to current draft'
                      ) : loadingDraftReply ? (
                        <ButtonLoader />
                      ) : (
                        'Create reply draft'
                      )}
                    </Button>
                  )
                ) : null}
              </div>
            )}
          </TooltipProvider>
        </FormProvider>
      </ExtendedFormProvider>
    </div>
  );
}
