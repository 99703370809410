import React, { useState } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  RegisterOptions,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  AlarmClockMinus,
  AlarmClockPlus,
  ChevronDown,
  PackageMinus,
  PackagePlus,
  Route,
  SquareCheckBig,
} from 'lucide-react';

import { Button } from 'components/Button';
import { Textarea } from 'components/Textarea';
import DateTimeInput from 'components/input/DateTimeInput';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { AvailableTabs } from 'constants/SidebarTabs';
import { useToast } from 'hooks/useToaster';
import { deleteCarrierEmail } from 'lib/api/deleteCarrierEmail';
import { undoDeleteCarrierEmail } from 'lib/api/undoDeleteCarrierEmail';
import { updateCarrierEmail } from 'lib/api/updateCarrierEmail';
import { NormalizedLoad } from 'types/Load';
import { PendingCarrierEmails, PendingEmail } from 'types/PendingOutboxEmail';
import { Maybe } from 'types/UtilityTypes';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { formatTimestamp } from 'utils/time';

dayjs.extend(utc);
dayjs.extend(timezone);

const getHeaderText = (key: string) => {
  switch (key) {
    case 'dispatch':
      return 'Confirm dispatch';
    case 'pickup':
      return 'Confirm at pickup appt';
    case 'loaded':
      return 'Confirm loaded';
    case 'inTransit':
      return 'Confirm In-Transit ETA';
    case 'dropoff':
      return 'Confirm at dropoff appt';
    case 'unloaded':
      return 'Confirm unloaded';
    default:
      return key;
  }
};

const getMilestoneIcon = (key: string, pendingEmails: PendingEmail[]) => {
  const iconColor = pendingEmails.length === 0 ? '#9CA3AF' : '#E92C2C';

  switch (key) {
    case 'dispatch':
      return <SquareCheckBig color={iconColor} size={'sm'} strokeWidth={1} />;
    case 'pickup':
      return <AlarmClockPlus color={iconColor} size={'sm'} strokeWidth={1} />;
    case 'loaded':
      return <PackagePlus color={iconColor} size={'sm'} strokeWidth={1} />;
    case 'inTransit':
      return <Route color={iconColor} size={'sm'} strokeWidth={1} />;
    case 'dropoff':
      return <AlarmClockMinus color={iconColor} size={'sm'} strokeWidth={1} />;
    case 'unloaded':
      return <PackageMinus color={iconColor} size={'sm'} strokeWidth={1} />;
    default:
      return null;
  }
};

const scheduleSendFieldOptions: RegisterOptions<
  CarrierEmailInputs,
  'scheduleSend'
> = {
  validate: (value: Maybe<Date>) => {
    if (value && value < new Date()) {
      return "Drumkit isn't a time machine. Please select a future date.";
    }

    return true;
  },
};

interface CarrierEmailInputs {
  recipient: string;
  subject: string;
  body: string;
  scheduleSend: Date;
}

type CarrierEmailsTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<CarrierEmailInputs> };
const CarrierEmailTextInput = (props: CarrierEmailsTextInputProps) => (
  <RHFTextInput {...props} />
);

type CarrierEmailsProps = {
  normalizedLoad: NormalizedLoad;
  carrierEmails: PendingCarrierEmails;
  setTab: React.Dispatch<React.SetStateAction<AvailableTabs>>;
};

export default function CarrierEmails({
  carrierEmails,
  setTab,
}: CarrierEmailsProps) {
  const [expandedEmails, setExpandedEmails] = useState<{
    [key: number]: boolean;
  }>({});
  const [deletedEmails, setDeletedEmails] = useState<number[]>([]);
  const [undoableEmails, setUndoableEmails] = useState<number[]>([]);
  const [deleteTimeouts, setDeleteTimeouts] = useState<{
    [key: number]: NodeJS.Timeout;
  }>({});
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const noCarrierEmails: boolean = Object.values(carrierEmails).every(
    (emailArray) => emailArray.length === 0
  );

  const onSubmit: SubmitHandler<PendingEmail> = async (data) => {
    setLoading(true);

    const reqData = {
      recipient: data.recipient,
      subject: data.subject,
      body: data.body,
      scheduleSend: data.scheduleSend,
    };

    const res = await updateCarrierEmail(data.id, reqData);

    if (res.isOk()) {
      toast({
        description: res.value.message,
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setLoading(false);
  };

  const sortedEmails = Object.entries(carrierEmails).map(
    ([milestone, emailArray]) => {
      const sortedArray = emailArray.sort(
        (a: PendingEmail, b: PendingEmail) => {
          const dateA = dayjs(a.timestamp);
          const dateB = dayjs(b.timestamp);
          return dateA.diff(dateB);
        }
      );

      const groupedEmails: { [date: string]: PendingEmail[] } = {};
      sortedArray.forEach((email: PendingEmail) => {
        const date = dayjs(email.timestamp).format('MM/DD');
        if (!groupedEmails[date]) {
          groupedEmails[date] = [];
        }
        groupedEmails[date].push(email);
      });

      return [milestone, groupedEmails] as [
        string,
        { [date: string]: PendingEmail[] },
      ];
    }
  );

  const handleEmailClick = (emailId: number) => {
    setExpandedEmails((prevState) => ({
      ...prevState,
      [emailId]: !prevState[emailId],
    }));
  };

  const setDeletedEmailWithDelay = (emailId: number, delay: number) => {
    setUndoableEmails((prev) => [...prev, emailId]);

    const timeoutId = setTimeout(() => {
      setUndoableEmails((prev) => prev.filter((id) => id !== emailId));
      setDeletedEmails((prevDeletedEmails) => [...prevDeletedEmails, emailId]);
      setDeleteTimeouts((prev) => {
        const newTimeouts = { ...prev };
        delete newTimeouts[emailId];
        return newTimeouts;
      });
    }, delay);

    setDeleteTimeouts((prev) => ({ ...prev, [emailId]: timeoutId }));
  };

  const handleDeleteClick = async (emailId: number) => {
    const res = await deleteCarrierEmail(emailId);

    if (res.isOk()) {
      let countdown = 5;
      const countdownInterval = setInterval(() => {
        toast({
          title: res.value.message,
          description: (
            <>
              Removing in {countdown} second{countdown !== 1 ? 's' : ''}
            </>
          ),
          variant: 'default',
        });
        countdown--;
        if (countdown < 0) {
          clearInterval(countdownInterval);
        }
      }, 1000);
      setDeletedEmailWithDelay(emailId, 5000);
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }
  };

  const handleUndoDeleteClick = async (emailId: number) => {
    setUndoableEmails((prev) => prev.filter((id) => id !== emailId));

    if (deleteTimeouts[emailId]) {
      clearTimeout(deleteTimeouts[emailId]);
      setDeleteTimeouts((prev) => {
        const newTimeouts = { ...prev };
        delete newTimeouts[emailId];
        return newTimeouts;
      });
    }

    const res = await undoDeleteCarrierEmail(emailId);

    if (res.isOk()) {
      toast({
        description: res.value.message,
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }
  };

  return (
    <>
      {noCarrierEmails && (
        <div className='px-2 mx-3 flex flex-col items-center'>
          <span className='text-grayscale-content-2 mb-4'>
            {
              'No emails scheduled for this load yet. You can configure them in the Track & Trace tab under Contact Carrier section.'
            }
          </span>
          <Button
            className='text-sm h-8'
            onClick={() => setTab(AvailableTabs.TrackAndTrace)}
          >
            {'Go to Track & Trace'}
          </Button>
        </div>
      )}

      {!noCarrierEmails && (
        <ol className='relative border-s ms-5 border-black dark:border-black-400 px-2'>
          <p className='text-xs italic text-grayscale-content-3 mb-3'>{`Note: All timestamps are in your local timezone.`}</p>
          {sortedEmails.map(([milestone, groupedEmails]) => {
            const allEmailsForMilestone = Object.values(groupedEmails).flat();
            const activeEmails = allEmailsForMilestone.filter(
              (email) =>
                !deletedEmails.includes(email.id) ||
                undoableEmails.includes(email.id)
            );

            return (
              <li key={milestone} className='mt-2 mb-8'>
                <span className='absolute flex items-center w-6 h-6 mr-2 bg-white rounded-full -start-3 ring-1 ring-offset-4 ring-black dark:ring-gray-900 dark:bg-black-900'>
                  {getMilestoneIcon(milestone, activeEmails)}
                </span>
                <h3 className='flex ml-4 space-x-2 text-gray-600 mb-2'>
                  <span>{getHeaderText(milestone)}</span>
                </h3>
                <hr className='mb-4 w-full' />

                {Object.entries(groupedEmails).map(([date, emailGroup]) => (
                  <React.Fragment key={date}>
                    {emailGroup.map((email) => {
                      const formMethods = useForm<PendingEmail>({
                        defaultValues: email,
                      });
                      const {
                        handleSubmit,
                        control,
                        formState: { isDirty },
                      } = formMethods;

                      const onInvalid: SubmitErrorHandler<
                        PendingEmail
                      > = async () => {
                        toast({
                          description: 'Some fields are invalid.',
                          variant: 'destructive',
                        });
                      };

                      if (deletedEmails.includes(email.id)) {
                        return <></>;
                      }

                      const isUndoable = undoableEmails.includes(email.id);

                      return (
                        <>
                          <h4 className='mb-2 text-gray-600'>
                            {dayjs(emailGroup[0].timestamp).format(
                              'dddd, MMMM D'
                            )}
                          </h4>
                          <div
                            className='grid grid-cols-1 rounded bg-white p-2 mb-4 mx-0 w-full'
                            key={email.id}
                          >
                            <div
                              onClick={() => handleEmailClick(email.id)}
                              className='cursor-pointer flex items-center justify-between'
                            >
                              <p className='text-base leading-7 text-gray-900'>
                                <span className='text-gray-400 pr-1'>
                                  Scheduled for
                                </span>{' '}
                                {formatTimestamp(
                                  dayjs(email.timestamp),
                                  'HH:mm z'
                                )}
                              </p>
                              <span
                                className={`${
                                  expandedEmails[email.id] ? 'rotate-180' : ''
                                } transition-all duration-300 ease-in-out`}
                              >
                                <ChevronDown className='h-4 w-4 shrink-0 transition-transform duration-200' />
                              </span>
                            </div>
                            <div
                              className={`${
                                expandedEmails[email.id]
                                  ? 'max-h-screen'
                                  : 'max-h-0'
                              } overflow-hidden transition-all duration-300 ease-in-out`}
                            >
                              <hr className='bg-transparent border-t-[3px] border-dotted mt-4' />
                              <FormProvider {...formMethods}>
                                <form
                                  onSubmit={handleSubmit(onSubmit, onInvalid)}
                                >
                                  <div>
                                    <div className='w-full col-span-6 mt-4 mb-4'>
                                      <DateTimeInput
                                        control={control}
                                        name='scheduleSend'
                                        label='Reschedule email'
                                        options={scheduleSendFieldOptions}
                                      />
                                    </div>

                                    <div className='w-full col-span-6 mt-4 mb-4'>
                                      <CarrierEmailTextInput
                                        name='recipient'
                                        label='Recipient'
                                        inputType='email'
                                      />
                                    </div>
                                    <div className='w-full col-span-6 mt-4 mb-4'>
                                      <CarrierEmailTextInput
                                        name='subject'
                                        label='Subject'
                                      />
                                    </div>
                                    <Controller
                                      control={control}
                                      name='body'
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Textarea
                                          className='p-2 h-96 whitespace-pre-wrap'
                                          onChange={onChange}
                                          value={value}
                                        />
                                      )}
                                    />
                                    <div className='grid gap-2 grid-cols-2 mt-4 relative mx-0 w-full'>
                                      <div className='relative overflow-hidden col-span-2 px-2'>
                                        <div className='relative'>
                                          <Button
                                            buttonName={
                                              isUndoable
                                                ? ButtonName.UndoDeleteCarrierEmail
                                                : ButtonName.DeleteCarrierEmail
                                            }
                                            buttonNamePosthog={
                                              isUndoable
                                                ? ButtonNamePosthog.UndoDeleteCarrierEmail
                                                : ButtonNamePosthog.DeleteCarrierEmail
                                            }
                                            type='button'
                                            onClick={(e) => {
                                              e.preventDefault();
                                              isUndoable
                                                ? handleUndoDeleteClick(
                                                    email.id
                                                  )
                                                : handleDeleteClick(email.id);
                                            }}
                                            variant={`${
                                              isUndoable
                                                ? 'secondary'
                                                : 'destructive'
                                            }`}
                                            className={`transition-all duration-500 w-full ${
                                              isUndoable ? 'w-full' : 'w-1/2'
                                            }`}
                                          >
                                            {isUndoable
                                              ? 'Undo Delete'
                                              : 'Delete'}
                                          </Button>
                                        </div>
                                      </div>
                                      <div className='absolute top-0 right-0 w-1/2 px-1'>
                                        <Button
                                          buttonName={
                                            ButtonName.UpdateCarrierEmail
                                          }
                                          buttonNamePosthog={
                                            ButtonNamePosthog.UpdateCarrierEmail
                                          }
                                          type='submit'
                                          disabled={!isDirty || loading}
                                          className={`transition-all duration-500 w-full ${
                                            isUndoable
                                              ? 'bg-transparent border-transparent text-transparent'
                                              : ''
                                          }`}
                                        >
                                          {loading ? (
                                            <ButtonLoader />
                                          ) : (
                                            'Save edits'
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </FormProvider>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </React.Fragment>
                ))}
              </li>
            );
          })}
        </ol>
      )}
    </>
  );
}
