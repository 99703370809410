enum ButtonText {
  // General
  ClearForm = 'Clear form',
  CopyToClipboard = 'Copy to clipboard',
  Submit = 'Submit',

  // Load Building
  BuildLoad = 'Build Load',

  // Carrier Network Quotes
  AddCarrierNetworkQuoteDraft = 'Add Email',
  DeleteCarrierEmail = 'Delete',
  EmailCarriers = 'Email Carriers',

  // Outbox
  UndoDeleteCarrierEmail = 'Undo Delete',
  UpdateCarrierEmail = 'Save edits',
  GoToOutboxTab = 'Go to Outbox',

  // Quick Quote
  GetQuickQuote = 'Get Quick Quote',

  // Appointment Scheduling
  ConfirmSlotApptScheduling = 'Confirm appointment',
  GetOpenApptSlots = 'Get open slots',
  ShowSmartReply = 'Show Smart Reply',
  ValidatePONumbers = 'Validate PO numbers',

  // Truck Lists
  CreatePickupGroupForTruckList = 'Add new pickup date',
  IngestEmail = 'Process Email',
  SubmitTruckListWithServiceName = 'Submit to',
  ValidateTruckListCarrier = 'Validate Carrier',

  // Track & Trace
  ScheduleCarrierEmails = 'Schedule emails',
  ScheduleShipperEmails = 'Schedule shipper emails',
  SubmitCheckCall = 'Submit check call',
  SubmitException = 'Submit exception',
  SubmitNote = 'Submit note',

  // Load Info
  SearchLoad = 'Search',
  UpdateTMS = 'Save',

  // AI Suggestions
  SkipSuggestion = 'Skip Suggestion',
}

export default ButtonText;
