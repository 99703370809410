import { CircleCheck } from 'lucide-react';

import { Maybe } from 'types/UtilityTypes';
import { cn } from 'utils/shadcn';

import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip';

type CarrierCardProps = {
  name: string;
  locationName: string;
  city: string;
  state: string;
  email: Maybe<string>;
  emails?: Maybe<string[]>;
  distance?: number;
  isSelected: boolean;
  onClick: () => void;
};

const MAX_LOCATION_NAME_LENGTH = 40;
const MAX_EMAIL_LENGTH = 36;

const trimString = (str: string, maxLength: number) => {
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
};

export const CarrierCard = ({
  name,
  locationName,
  city,
  state,
  email,
  emails,
  distance,
  isSelected,
  onClick,
}: CarrierCardProps) => {
  const isDisabled = !email && !emails?.length;
  const allEmails = emails || (email ? [email] : []);
  const showLocationName = locationName !== name;

  return (
    <div
      className={cn(
        'relative flex flex-col border rounded-lg p-2.5 transition-all cursor-pointer shadow-sm border-[#FE9659]',
        isDisabled &&
          'opacity-60 cursor-not-allowed bg-gray-50 border-gray-300',
        !isDisabled && [
          'border-[#FE9659]',
          isSelected
            ? 'bg-orange-bg/90 border-[#FE9659] ring-2 ring-[#FE9659]/30'
            : 'hover:shadow-md  bg-white',
        ]
      )}
      onClick={isDisabled ? undefined : onClick}
    >
      <div className='flex items-center justify-between mb-1'>
        <h4
          className='text-sm font-semibold text-gray-800 truncate max-w-[75%] text-wrap'
          title={name}
        >
          {name}
        </h4>
        {distance && (
          <Tooltip delayDuration={10}>
            <TooltipTrigger>
              <div className='flex items-center  flex-shrink-0'>
                <span className='text-xs text-gray-600 font-medium'>
                  {distance.toFixed(1)} mi
                </span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p className='text-xs text-gray-700'>Distance from pickup</p>
            </TooltipContent>
          </Tooltip>
        )}
      </div>

      <div className='flex flex-col gap-1'>
        {showLocationName && (
          <p className='text-xs text-gray-700 truncate' title={locationName}>
            {trimString(locationName, MAX_LOCATION_NAME_LENGTH)}
          </p>
        )}
        <p className='text-xs text-gray-600'>{`${city}, ${state}`}</p>

        {allEmails.length > 0 ? (
          <div className='flex items-start mt-1 border-t pt-1.5 border-gray-300'>
            <span className='text-xs font-medium text-gray-700 w-14 flex-shrink-0'>
              {allEmails.length === 1 ? 'Email:' : 'Emails:'}
            </span>
            <div className='flex flex-col flex-1 items-end overflow-hidden'>
              {allEmails.map((email, index) => (
                <span
                  key={index}
                  className='text-xs text-gray-600 truncate max-w-full'
                  title={email}
                >
                  {trimString(email, MAX_EMAIL_LENGTH)}
                  {index < allEmails.length - 1 && ','}
                </span>
              ))}
            </div>
          </div>
        ) : (
          <p className='text-xs mt-1 italic text-gray-500'>
            No email found in TMS
          </p>
        )}
      </div>

      {isSelected && (
        <div className='absolute top-[-6px] right-[-6px]'>
          <CircleCheck
            className='text-green-500 w-5 h-5 fill-white'
            strokeWidth={2}
          />
        </div>
      )}
    </div>
  );
};
