import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

enum EmailPlatform {
  Gmail = 'gmail',
  Outlook = 'outlook',
  Front = 'front',
}

export type User = {
  id: string;
  name: string;
  email: string;
  emailProvider: EmailPlatform;
  emailSignatureHTML: string;
  emailSignatureQuillDelta: string; // JSON string representation of Quill.Delta type
  useSignatureOnNewEmails: boolean;
  useSignatureOnRepliesForwards: boolean;
};

export async function getUser(email: string): Promise<Result<User, ApiError>> {
  try {
    const url = `/user?email=${encodeURIComponent(email)}`;
    const { data } = await axios.get<User>(url);

    return ok(data);
  } catch (error) {
    captureException(error, { functionName: 'getUser' });

    if (!isAxiosError(error)) {
      return err({ message: `${error}` });
    }

    return err({
      message: error?.response?.data,
    });
  }
}
