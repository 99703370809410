import { useRef, useState } from 'react';

import { Divider } from 'antd';
import { CheckIcon, XIcon } from 'lucide-react';

import { Maybe } from 'types/UtilityTypes';
import {
  GenericSuggestion,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import { flattenSuggestionChanges } from 'utils/flattenSuggestionChanges';
import { getSuggestionTitleByPipeline } from 'utils/suggestions/getSuggestionTitleByPipeline';

import { getValidChangeElements } from './SuggestionsCarousel';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';

type SuggestionCardProps = {
  suggestion: GenericSuggestion;
  clickedSuggestion: Maybe<GenericSuggestion>;
  handleClearSuggestion: (
    e: React.MouseEvent<SVGSVGElement>,
    suggestion: GenericSuggestion
  ) => Promise<void>;
  handleApplySuggestion: (suggestion: GenericSuggestion) => void;
};

export const SuggestionCard = ({
  suggestion,
  clickedSuggestion,
  handleClearSuggestion,
  handleApplySuggestion,
}: SuggestionCardProps) => {
  const [showAllChanges, setShowAllChanges] = useState(false);
  const timerRef = useRef<Maybe<NodeJS.Timeout>>(null);

  // Extracted logic to flatten and get change elements
  const changes =
    suggestion.pipeline === SuggestionPipelines.CheckCall
      ? suggestion.suggested.checkCallChanges
      : suggestion.suggested;

  const flattenedChanges = flattenSuggestionChanges(changes);
  const { elements, validChangesCount } = getValidChangeElements(
    flattenedChanges,
    suggestion
  );

  const hasHiddenChanges = validChangesCount > 2;
  const initialChangeElements = elements.slice(0, 2);
  const hiddenChangeElements = elements.slice(2);

  if (!initialChangeElements || !initialChangeElements.length) return null;

  const handleMouseEnterOtherChanges = () => {
    // set a timer to expand the card after 300ms
    timerRef.current = setTimeout(() => {
      setShowAllChanges(true);
      timerRef.current = null; // clear the timer reference
    }, 300);
  };

  const handleMouseLeaveOtherChanges = () => {
    // clear the timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const handleMouseLeaveCard = () => {
    // collapse the card when the mouse leaves the card
    setShowAllChanges(false);
  };

  return (
    <TooltipProvider>
      <div onClick={() => handleApplySuggestion(suggestion)}>
        <div
          className={`carousel-card ${
            clickedSuggestion?.id === suggestion.id
              ? 'bg-blue-lightbg drop-shadow-none'
              : 'bg-grayscale-bg-secondary drop-shadow-lg carousel-card-hover hover:border-[1.5px]'
          } px-3 py-3 my-1 mx-2 border border-blue-main rounded-[4px] cursor-pointer`}
          onMouseLeave={handleMouseLeaveCard}
        >
          <div
            className={`${
              clickedSuggestion?.id === suggestion.id
                ? ''
                : 'carousel-card-hover'
            }`}
          >
            <div className='flex justify-between items-baseline'>
              <h3 className='text-md font-bold mb-2'>
                {getSuggestionTitleByPipeline(suggestion.pipeline)}
              </h3>

              {clickedSuggestion?.id === suggestion.id ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <CheckIcon className='w-4 h-4 mt-1' />
                  </TooltipTrigger>
                  <TooltipContent side='left'>
                    Suggestion Applied
                  </TooltipContent>
                </Tooltip>
              ) : (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <XIcon
                      className='w-4 h-4 mt-1'
                      onClick={(e) => handleClearSuggestion(e, suggestion)}
                    />
                  </TooltipTrigger>
                  <TooltipContent side='left'>Clear Suggestion</TooltipContent>
                </Tooltip>
              )}
            </div>
            <div className='w-full'>{initialChangeElements}</div>
            <div
              style={{
                maxHeight: showAllChanges
                  ? `${hiddenChangeElements.length * 18}px`
                  : '0px',
              }}
              className='overflow-hidden transition-all duration-300 ease'
            >
              {hiddenChangeElements}
            </div>
          </div>

          {hasHiddenChanges ? (
            <div>
              <Divider className='my-2' />
              <div className='text-grayscale-content-3 text-xs text-center mt-2'>
                <div
                  className='font-semibold'
                  onMouseEnter={handleMouseEnterOtherChanges}
                  onMouseLeave={handleMouseLeaveOtherChanges}
                >
                  {`+${validChangesCount - 2} other change${
                    validChangesCount - 2 > 1 ? 's' : ''
                  }`}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </TooltipProvider>
  );
};
