import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

import { SelectedCarrierType } from './getQuickQuote';

export type GreenscreensQuote = {
  quoteRequestId: number;
  stops: {
    order: number;
    city: string;
    state: string;
    zip: string;
    country: string;
  }[];
  selectedRateName: SelectedCarrierType;
  networkLaneRateDistance: number;
  networkLaneRateTargetBuy: number;
  networkLaneRateConfidenceLevel: number;
  laneRateDistance: number;
  laneRateTargetBuy: number;
  laneRateConfidenceLevel: number;
};

export async function sendGreenscreensQuoteToService(
  quote: GreenscreensQuote
): Promise<Result<string, ApiError>> {
  try {
    const { data } = await axios.post<string>(
      `/quote/private/send-to-service/greenscreens`,
      quote
    );

    return ok(data);
  } catch (error) {
    captureException(error, { functionName: 'sendGreenscreensQuoteToService' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to send greenscreens quote to service' });
    }

    return err({
      message:
        error.response?.data.message ||
        'Failed to send greenscreens quote to service',
    });
  }
}
