import { useEffect, useState } from 'react';

import { ClipboardList, MailIcon } from 'lucide-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import ErrorBoundary from 'components/ErrorBoundary';
import { TooltipProvider } from 'components/Tooltip';
import SidebarLoader from 'components/loading/SidebarLoader';
import useFetchQuoteRequest from 'hooks/useFetchQuoteRequest';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import { useServiceFeatures } from 'hooks/useServiceContext';
import RequestCarrierQuotesSection from 'pages/QuoteView/Quoting/CarrierQuote/RequestCarrierQuotes';
import ReviewCarrierQuotesSection from 'pages/QuoteView/Quoting/CarrierQuote/ReviewCarrierQuotes';
import { Email } from 'types/Email';
import { Maybe, Undef } from 'types/UtilityTypes';
import Pageview from 'types/enums/Pageview';

enum CarrierQuoteSections {
  RequestQuotes = 'request-quotes',
  ReviewQuotes = 'review-quotes',
}

export default function CarrierQuoteTab({ email }: { email: Maybe<Email> }) {
  const { serviceID } = useServiceFeatures();

  useLogPostHogPageView(Pageview.CarrierQuote, {
    service_id: serviceID,
    email_id: email?.id || null,
  });

  const { request, isLoading } = useFetchQuoteRequest(email?.threadID ?? '');
  const [accordionTab, setAccordionTab] =
    useState<Undef<CarrierQuoteSections[]>>(undefined);

  useEffect(() => {
    if (!request) {
      setAccordionTab([CarrierQuoteSections.RequestQuotes]);
      return;
    }

    if (
      !request.carrierNetworkEmails ||
      request.carrierNetworkEmails.length === 0
    ) {
      setAccordionTab([CarrierQuoteSections.RequestQuotes]);
      return;
    }

    setAccordionTab([CarrierQuoteSections.ReviewQuotes]);
  }, [request]);

  return (
    <div className=''>
      <TooltipProvider>
        {isLoading && <SidebarLoader />}
        <Accordion
          type='multiple'
          value={accordionTab}
          onValueChange={(v) => setAccordionTab(v as CarrierQuoteSections[])}
        >
          {!isLoading && (
            <div>
              {/* Email Carriers Section */}
              <AccordionItem value={CarrierQuoteSections.RequestQuotes}>
                <AccordionTrigger
                  icon={<MailIcon className='h-6 w-6' strokeWidth={1} />}
                >
                  Email Carriers
                </AccordionTrigger>
                <AccordionContent>
                  <ErrorBoundary>
                    <RequestCarrierQuotesSection
                      email={email}
                      request={request}
                    />
                  </ErrorBoundary>
                </AccordionContent>
              </AccordionItem>

              {/* Review Carrier Responses Section */}
              {!isLoading &&
                request &&
                request.carrierNetworkEmails &&
                request.carrierNetworkEmails.length > 0 && (
                  <AccordionItem value={CarrierQuoteSections.ReviewQuotes}>
                    <AccordionTrigger
                      icon={
                        <ClipboardList className='h-6 w-6' strokeWidth={1} />
                      }
                    >
                      Review Responses
                    </AccordionTrigger>
                    <AccordionContent>
                      <ErrorBoundary>
                        <ReviewCarrierQuotesSection
                          requestId={request.id}
                          emails={request.carrierNetworkEmails}
                          carrierQuotes={request.carrierQuotes ?? []}
                        />
                      </ErrorBoundary>
                    </AccordionContent>
                  </AccordionItem>
                )}
            </div>
          )}
        </Accordion>
      </TooltipProvider>
    </div>
  );
}
