import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { MarginType } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { CarrierCostType } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { ApiError } from 'types/api/ApiError';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import captureException from 'utils/captureException';

export type SubmitFinalQuoteBody = {
  finalQuotePrice: number;
  finalMargin: number;
  marginType: MarginType;
  finalCarrierCost: number;
  carrierCostType: CarrierCostType;
  customerExternalTMSId?: string;
};

export async function updateQuoteRequestSuggestion(
  suggestionId: number,
  status:
    | SuggestionStatus.InFlight
    | SuggestionStatus.Accepted
    | SuggestionStatus.Rejected,
  finalQuoteData?: SubmitFinalQuoteBody
): Promise<Result<null, ApiError>> {
  try {
    const response = await axios.patch(
      `/suggestions/${suggestionId}/quote-request`,
      {
        status: status,
        ...(finalQuoteData && { finalQuoteData }),
      }
    );

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'updateQuoteRequestSuggestion' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
    });
  }
}
