import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import _ from 'lodash';
import {
  BadgeDollarSignIcon,
  CalendarIcon,
  ShieldCheckIcon,
} from 'lucide-react';

import { Button } from 'components/Button';
import { Calendar } from 'components/Calendar';
import { Checkbox } from 'components/Checkbox';
import { GenericLineChart } from 'components/GenericLineChart';
import { Label } from 'components/Label';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover';
import QuoteConfidenceLevel from 'components/QuoteConfidenceLevel';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import CarrierPriceCalculator from 'components/pricing/CarrierPriceCalculator';
import { STATE_ABBREVIATIONS } from 'constants/StateTimezones';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import {
  DrumkitPlatform,
  SidebarStateContext,
} from 'contexts/sidebarStateContext';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import GreenscreensLogo from 'icons/Greenscreens';
import { getCustomers } from 'lib/api/getCustomers';
import { LaneHistoryResponse, getLaneHistory } from 'lib/api/getLaneHistory';
import { submitQuoteToTMS } from 'lib/api/submitQuoteToTMS';
import {
  QuickQuoteInputs,
  QuickQuoteResponse,
  SelectedCarrierType,
  TransportType,
  updateQuickQuoteRequest,
} from 'lib/api/updateQuickQuoteRequest';
import { createMailClientInstance } from 'lib/mailclient/interface';
import { TMSCustomer } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';
import { chartConfig } from 'utils/laneHistoryChart';
import {
  GenericCompanySearchableFields,
  customerSearchHandler,
  mapCustomerToAntdOptions,
} from 'utils/loadInfoAndBuilding';
import { cn } from 'utils/shadcn';

import { formatCurrency } from './ReviewCarrierNetworkQuotes';

enum LocationType {
  Zip = 'Zip',
  CityState = 'CityState',
}

type QuickQuoteTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<QuickQuoteInputs> };
const QuickQuoteTextInput = (props: QuickQuoteTextInputProps) => (
  <RHFTextInput {...props} />
);

export default function QuickQuoteSection({
  isZipMissing,
}: {
  isZipMissing: boolean;
}) {
  const { toast } = useToast();
  const [customers, setCustomers] = useState<Maybe<TMSCustomer[]>>(null);
  const [initialCustomers, setInitialCustomers] =
    useState<Maybe<TMSCustomer[]>>(null);
  const [quote, setQuote] = useState<Maybe<QuickQuoteResponse>>(null);
  const [laneHistory, setLaneHistory] =
    useState<Maybe<LaneHistoryResponse[]>>(null);
  const [quoteNotConfident, setQuoteNotConfident] = useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] = useState<SelectedCarrierType>(
    SelectedCarrierType.NETWORK
  );
  const [carrierCost, setCarrierCost] = useState(1500);
  const [margin, setMargin] = useState(10);
  const [finalPrice, setFinalPrice] = useState(
    carrierCost * (1 + margin / 100)
  );
  const [draftResponse, setDraftResponse] = useState('');
  const [loadingDraftReply, setLoadingDraftReply] = useState(false);
  const [isSubmitToTMS, setIsSubmitToTMS] = useState<boolean>(false);
  const [createdQuoteId, setCreatedQuoteId] = useState<Maybe<number>>();
  const [tmsTenant, setTMSTenant] = useState<Maybe<string>>();
  const [locationType, setLocationType] = useState<LocationType>(
    LocationType.Zip
  );
  const [cityStateError, setCityStateError] = useState<boolean>(false);

  const scrollResultsIntoViewRef = useRef<HTMLDivElement>(null);

  const {
    currentState: {
      drumkitPlatform,
      threadItemId,
      isOutlookReply,
      clickedSuggestion,
    },
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: {
      isTMSQuoteSubmissionEnabled,
      isQuoteLaneHistoryEnabled,
    },
    tmsIntegrations,
  } = useServiceFeatures();

  useEffect(() => {
    if (!quote) return;

    const draft =
      `Thank you for your request from ` +
      `${toTitleCase(quote.stops[0].city)}, ${quote?.stops[0].state} ` +
      `to ` +
      `${toTitleCase(quote.stops[1].city)}, ${quote?.stops[1].state} ` +
      `on ` +
      `${new Date(getValues('pickupDate')).toDateString()} ` +
      `for a ` +
      `${getValues('transportType')?.toLowerCase()}. ` +
      `The rate would be ${formatCurrency(finalPrice, 'USD')}.`;

    setDraftResponse(draft);
  }, [quote, finalPrice]);

  const fetchCustomers = async () => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setInitialCustomers(res.value.customerList);
      setCustomers(res.value.customerList);
      setTMSTenant(res.value.tmsTenant);
    } else {
      toast({
        description: 'Error while fetching customer list.',
        variant: 'destructive',
      });
    }
  };

  const handleRefreshCustomers = async () => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id, true);
    if (res.isOk()) {
      setInitialCustomers(res.value.customerList);
      setCustomers(res.value.customerList);
      toast({
        description: 'Successfully refreshed customer list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing customer list.',
        variant: 'destructive',
      });
    }
  };

  const handleResetCustomerSearch = () => {
    setCustomers(initialCustomers);
  };

  const handleCustomerSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return customerSearchHandler({
      tmsID: tmsIntegrations?.[0]?.id,
      customers,
      setCustomers,
      field,
      value,
    });
  };

  // everytime a suggestion is clicked, if the zip is missing, set location type to CityState
  useEffect(() => {
    if (
      isZipMissing &&
      clickedSuggestion?.pipeline === SuggestionPipelines.QuickQuote
    ) {
      setLocationType(LocationType.CityState);
      return;
    }
  }, [isZipMissing, clickedSuggestion]);

  const memoizedDefaultValues = useMemo<QuickQuoteInputs>(() => {
    const suggestedFields = clickedSuggestion?.suggested as QuoteChanges;

    const parsedValues = {
      transportType: suggestedFields?.transportType ?? '',
      pickupDate: suggestedFields?.pickupDate,
      deliveryDate: suggestedFields?.deliveryDate,
      stops: [
        {
          city: suggestedFields?.pickupCity ?? '',
          state: suggestedFields?.pickupState ?? '',
          zip: suggestedFields?.pickupZip ?? '',
        },
        {
          city: suggestedFields?.deliveryCity ?? '',
          state: suggestedFields?.deliveryState ?? '',
          zip: suggestedFields?.deliveryZip ?? '',
        },
      ],
      customerName: '',
      isSubmitToTMS: false,
    };

    // Only need to fetch customers when submitting quotes is supported
    if (
      isTMSQuoteSubmissionEnabled &&
      drumkitPlatform === DrumkitPlatform.Outlook
    ) {
      fetchCustomers();
    }

    return parsedValues as QuickQuoteInputs;
  }, [clickedSuggestion]);

  // Update form values when suggestion changes
  useEffect(() => {
    if (memoizedDefaultValues) {
      reset(memoizedDefaultValues);
    }
    // Use setValue so as to not show AI-filled helper text
    if (!getValues('pickupDate')) {
      setValue('pickupDate', dayjs().add(1, 'day').toDate());
    }
    if (!getValues('deliveryDate')) {
      setValue('deliveryDate', dayjs().add(2, 'day').toDate());
    }
    if (!getValues('transportType')) {
      setValue('transportType', TransportType.VAN);
    }
  }, [memoizedDefaultValues]);

  useEffect(() => {
    quote &&
      setCarrierCost(
        _.round(
          selectedCarrier === SelectedCarrierType.BUYPOWER
            ? quote.laneRateTargetBuy * quote.laneRateDistance
            : quote.networkLaneRateTargetBuy * quote.networkLaneRateDistance
        )
      );
  }, [selectedCarrier]);

  const formMethods = useForm<QuickQuoteInputs>({
    defaultValues: memoizedDefaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = formMethods;

  // will trigger on isSubmitSuccessful state change
  useEffect(() => {
    if (isSubmitSuccessful) {
      scrollResultsIntoViewRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isSubmitSuccessful]);

  const onSubmitForm: SubmitHandler<QuickQuoteInputs> = async (formValues) => {
    setCityStateError(false);

    if (locationType === LocationType.CityState) {
      if (
        formValues.stops[0].city === '' ||
        formValues.stops[0].state === '' ||
        formValues.stops[1].city === '' ||
        formValues.stops[1].state === ''
      ) {
        setCityStateError(true);
        return;
      }
    }

    await updateQuickQuoteRequest(
      formValues,
      formMethods,
      setQuote,
      setQuoteNotConfident,
      setCarrierCost,
      setSelectedCarrier
    );

    if (isQuoteLaneHistoryEnabled) {
      const res = await getLaneHistory({
        destinationCity: formValues.stops[1].city,
        destinationState: formValues.stops[1].state,
        destinationZip: formValues.stops[1].zip,
        originCity: formValues.stops[0].city,
        originState: formValues.stops[0].state,
        originZip: formValues.stops[0].zip,
        transportType: formValues.transportType,
      });

      if (res.isOk()) {
        setLaneHistory(res.value);
      }
    }

    // edge case: user submits form and then submits again with the same values
    if (isSubmitSuccessful) {
      scrollResultsIntoViewRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  let handleDraftResponse: () => void;
  if (drumkitPlatform === DrumkitPlatform.Outlook) {
    const mailClient = createMailClientInstance(drumkitPlatform);
    handleDraftResponse = async () => {
      setLoadingDraftReply(true);

      // Using separate API calls for draft and TMS Quote submission since the draft call is conditional
      // e.g. we create the draft directly on the client-side in Outlook's Compose View.
      if (isTMSQuoteSubmissionEnabled && isSubmitToTMS) {
        const customerId = getValues('customerName');
        if (!customerId) return;

        const res = await submitQuoteToTMS({
          customerId: customerId.toString(),
          quotePrice: _.round(finalPrice),
          transportType: getValues('transportType'),
          pickupLocation: getValues('stops.0.zip'),
          pickupDate: getValues('pickupDate').toISOString(),
          deliveryLocation: getValues('stops.1.zip'),
          deliveryDate: getValues('deliveryDate').toISOString(),
        });

        if (res.isOk()) {
          setCreatedQuoteId(res.value.quoteId);
        } else {
          toast({
            description: 'Error creating Quote in TMS.',
            variant: 'destructive',
          });
        }
      }

      mailClient
        .draftReply({ threadItemId, draftBody: draftResponse })
        .then(() => {
          // When in Read View, there's a lag between when Outlook created the draft in the backend
          // and showing it in the client so wait for a moment before showing toaster.
          setTimeout(
            () => {
              toast({
                description: 'Successfully created draft reply.',
                variant: 'success',
              });
              setLoadingDraftReply(false);
            },
            isOutlookReply ? 1 : 3500
          );
        })
        .catch((err) => {
          toast({
            description:
              err?.message ?? 'Something went wrong creating draft reply',
            variant: 'destructive',
          });
          setLoadingDraftReply(false);
        });
    };
  }

  return (
    <div className='mb-4'>
      <ExtendedFormProvider aiDefaultValues={true}>
        <FormProvider {...formMethods}>
          <div>
            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className='grid gap-6 grid-cols-1 mt-4 mx-0 w-full'
            >
              <div className='grid gap-3 grid-cols-1 w-full mx-0 '>
                <div className='text-md text-grayscale-content-1 font-semibold mb-1'>
                  Load Information
                </div>
                <div>
                  <Label name={'transportType'}>Transport Type</Label>
                  <Controller
                    name='transportType'
                    control={control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger className='w-full mt-1'>
                          <SelectValue placeholder='Choose' />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.keys(TransportType).map((option) => (
                            <SelectItem key={option} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
              </div>

              {/* Pickup */}
              <div className='grid gap-3 grid-cols-1 w-full mx-0 '>
                <div className='text-md text-grayscale-content-1 font-semibold mb-1'>
                  Pickup
                </div>

                <div className='flex items-start flex-row gap-1 w-full'>
                  {locationType === LocationType.Zip ? (
                    <div className='w-full'>
                      <QuickQuoteTextInput
                        name='stops.0.zip'
                        label='ZIP Code'
                        required
                      />
                    </div>
                  ) : (
                    <div className='flex flex-row items-start w-full'>
                      <div className='w-full'>
                        <QuickQuoteTextInput
                          name='stops.0.city'
                          placeholder='City'
                          label='City, State'
                          required
                        />
                      </div>
                      <div className='flex flex-col'>
                        <Label name='state' className='invisible'>
                          State
                        </Label>
                        <Controller
                          name='stops.0.state'
                          control={control}
                          render={({ field }) => (
                            <Select
                              onValueChange={field.onChange}
                              value={field.value}
                            >
                              <SelectTrigger className='w-full mt-1'>
                                <SelectValue placeholder='State' />
                              </SelectTrigger>
                              <SelectContent>
                                {STATE_ABBREVIATIONS.map((option: string) => (
                                  <SelectItem key={option} value={option}>
                                    {option}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </div>
                    </div>
                  )}
                  <Select
                    onValueChange={(value: LocationType) =>
                      setLocationType(value)
                    }
                    value={locationType}
                  >
                    <SelectTrigger className='search-filter h-9 mt-6 flex w-fit border-none outline-none text-grayscale-content-2 text-[12px] rounded-l bg-transparent'>
                      <SelectValue placeholder='Choose' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={LocationType.Zip}>ZIP</SelectItem>
                      <SelectItem value={LocationType.CityState}>
                        City
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className='flex flex-col w-full'>
                  <Label name='pickupDate' className='text-base'>
                    Date
                  </Label>
                  <Controller
                    name={`pickupDate`}
                    control={control}
                    render={({ field }) => (
                      <div className='mt-1 flex flex-row gap-1'>
                        <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                          <div className='col-span-2'>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant='outline'
                                  size='sm'
                                  className={cn(
                                    'w-full justify-start text-left font-normal rounded border-orange-border py-3 h-10',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  <CalendarIcon className='mr-2 h-4 w-4 shrink-0' />
                                  <span className='text-sm text-grayscale-content-2'>
                                    {field.value
                                      ? dayjs(field.value as Date).format(
                                          'M/D/YY'
                                        )
                                      : 'Pick a date'}
                                  </span>
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className='w-auto p-0'>
                                <Calendar
                                  mode='single'
                                  selected={
                                    field.value
                                      ? (field.value as Date)
                                      : undefined
                                  }
                                  onSelect={(newValue: any) => {
                                    if (typeof newValue === 'undefined') {
                                      field.onChange(null);
                                      return;
                                    }

                                    if (field.value && newValue) {
                                      newValue.setHours(
                                        new Date(field.value).getHours()
                                      );
                                      newValue.setMinutes(
                                        new Date(field.value).getMinutes()
                                      );
                                    }

                                    field.onChange(newValue);
                                  }}
                                />
                              </PopoverContent>
                            </Popover>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>

              {/* Dropoff */}
              <div className='grid gap-3 grid-cols-1 w-full mx-0 '>
                <div className='text-md text-grayscale-content-1 font-semibold mb-1'>
                  Dropoff
                </div>

                <div className='flex items-start flex-row gap-1 w-full'>
                  {locationType === LocationType.Zip ? (
                    <div className='w-full'>
                      <QuickQuoteTextInput
                        name='stops.1.zip'
                        label='ZIP Code'
                        required
                      />
                    </div>
                  ) : (
                    <div className='flex flex-row items-start w-full'>
                      <div className='w-full'>
                        <QuickQuoteTextInput
                          name='stops.1.city'
                          placeholder='City'
                          label='City, State'
                          required
                        />
                      </div>
                      <div className='flex flex-col'>
                        <Label name='state' className='invisible'>
                          State
                        </Label>
                        <Controller
                          name='stops.1.state'
                          control={control}
                          render={({ field }) => (
                            <Select
                              onValueChange={field.onChange}
                              value={field.value}
                            >
                              <SelectTrigger className='w-full mt-1'>
                                <SelectValue placeholder='State' />
                              </SelectTrigger>
                              <SelectContent>
                                {STATE_ABBREVIATIONS.map((option: string) => (
                                  <SelectItem key={option} value={option}>
                                    {option}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </div>
                    </div>
                  )}
                  <Select
                    onValueChange={(value: LocationType) =>
                      setLocationType(value)
                    }
                    value={locationType}
                  >
                    <SelectTrigger className='search-filter h-9 mt-6 border-none outline-none flex w-fit text-grayscale-content-2 text-[12px] rounded-l bg-transparent'>
                      <SelectValue placeholder='Choose' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={LocationType.Zip}>ZIP</SelectItem>
                      <SelectItem value={LocationType.CityState}>
                        City
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className='flex flex-col w-full'>
                  <Label name='deliveryDate' className='text-base'>
                    Date
                  </Label>
                  <Controller
                    name={`deliveryDate`}
                    control={control}
                    render={({ field }) => (
                      <div className='mt-1 flex flex-row gap-1'>
                        <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                          <div className='col-span-2'>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant='outline'
                                  size='sm'
                                  className={cn(
                                    'w-full justify-start text-left font-normal rounded border-orange-border py-3 h-10',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  <CalendarIcon className='mr-2 h-4 w-4 shrink-0' />
                                  <span className='text-sm text-grayscale-content-2'>
                                    {field.value
                                      ? dayjs(field.value as Date).format(
                                          'M/D/YY'
                                        )
                                      : 'Pick a date'}
                                  </span>
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className='w-auto p-0'>
                                <Calendar
                                  mode='single'
                                  selected={
                                    field.value
                                      ? (field.value as Date)
                                      : undefined
                                  }
                                  onSelect={(newValue: any) => {
                                    if (typeof newValue === 'undefined') {
                                      field.onChange(null);
                                      return;
                                    }

                                    if (field.value && newValue) {
                                      newValue.setHours(
                                        new Date(field.value).getHours()
                                      );
                                      newValue.setMinutes(
                                        new Date(field.value).getMinutes()
                                      );
                                    }

                                    field.onChange(newValue);
                                  }}
                                />
                              </PopoverContent>
                            </Popover>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>

              {cityStateError && (
                <div className='flex justify-center'>
                  <div className='w-full text-destructive font-semibold text-sm bg-red-200 rounded-lg p-1 text-center'>
                    Please enter a valid city and state pair
                  </div>
                </div>
              )}

              {errors.root && (
                <div className='flex justify-center'>
                  <div className='w-full text-destructive font-semibold text-sm bg-red-200 rounded-lg p-1 text-center'>
                    {errors.root.message}
                  </div>
                </div>
              )}

              <Button
                buttonName={'Get Quick Quote'}
                buttonNamePosthog={'get_quick_quote'}
                type='submit'
                className='w-full'
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoader /> : 'Get Quick Quote'}
              </Button>

              {quoteNotConfident && !quote && (
                <div className='font-semibold text-sm rounded-lg p-1 text-center w-full'>
                  <p>
                    {
                      'Unable to provide an accurate quote at this time. Contact our team to discuss further.'
                    }
                  </p>
                </div>
              )}

              {quote && !quoteNotConfident && (
                <>
                  <div
                    className='flex flex-col items-center py-2'
                    ref={scrollResultsIntoViewRef}
                  >
                    <div className=' w-full text-left text-md text-grayscale-content-1 font-semibold'>
                      Quote for
                    </div>
                    <h3 className='text-sm w-full text-left'>{`
                      ${toTitleCase(quote.stops[0].city)}, ${quote.stops[0].state} to 
                      ${toTitleCase(quote.stops[1].city)}, ${quote.stops[1].state}
                    `}</h3>
                    <h3 className='text-sm w-full text-left'>
                      {`Distance: ${_.round(quote.networkLaneRateDistance)} miles`}
                    </h3>

                    <div className='w-3/4 h-0.5 bg-[#305645] mt-2' />
                    <div className='text-[12px] w-full text-right inline-block'>
                      based on{' '}
                      <GreenscreensLogo
                        height={12}
                        width={75}
                        className='inline-block'
                      />
                    </div>
                    <div className='flex flex-col gap-4 mt-4 w-full'>
                      <div
                        className={cn(
                          'flex-column w-full border-2 border-[#FE9659] rounded-[4px] p-2 transition-transform',
                          selectedCarrier === SelectedCarrierType.NETWORK &&
                            'bg-orange-bg',
                          selectedCarrier !== SelectedCarrierType.NETWORK &&
                            'cursor-pointer hover:scale-[1.05] bg-white'
                        )}
                        onClick={() =>
                          selectedCarrier !== SelectedCarrierType.NETWORK &&
                          setSelectedCarrier(SelectedCarrierType.NETWORK)
                        }
                      >
                        <div className='flex justify-between items-center'>
                          <h4>Network Quote</h4>
                        </div>
                        <div className='text-[#444] text-[14px]'>
                          <div className='flex justify-between mt-2'>
                            <div className='flex items-center gap-x-1.5'>
                              <BadgeDollarSignIcon className='h-4 w-4' />
                              {`Network Buy Rate: `}
                            </div>
                            {`$${_.round(quote.networkLaneRateTargetBuy * quote.networkLaneRateDistance)}`}
                          </div>
                          <div className='flex justify-between'>
                            <div className='flex items-center gap-x-1.5'>
                              <ShieldCheckIcon className='h-4 w-4' />
                              {`Network Confidence: `}
                            </div>
                            <QuoteConfidenceLevel
                              confidence={quote.networkLaneRateConfidenceLevel}
                              lowConfidenceThreshold={
                                quote.lowConfidenceThreshold
                              }
                              mediumConfidenceThreshold={
                                quote.mediumConfidenceThreshold
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={cn(
                          'flex-column w-full border-2 border-[#FE9659] rounded-[4px] p-2 transition-transform',
                          selectedCarrier === SelectedCarrierType.BUYPOWER &&
                            'bg-orange-bg',
                          selectedCarrier !== SelectedCarrierType.BUYPOWER &&
                            'cursor-pointer hover:scale-[1.05] bg-white'
                        )}
                        onClick={() =>
                          selectedCarrier !== SelectedCarrierType.BUYPOWER &&
                          setSelectedCarrier(SelectedCarrierType.BUYPOWER)
                        }
                      >
                        <div className='flex justify-between items-center'>
                          <h4>Your Buying Power Quote</h4>
                        </div>
                        <div className='text-[#444] text-[14px]'>
                          <div className='flex justify-between mt-2'>
                            <div className='flex items-center gap-x-1.5'>
                              <BadgeDollarSignIcon className='h-4 w-4' />
                              {`Target Buy: `}
                            </div>
                            {`$${_.round(quote.laneRateTargetBuy * quote.laneRateDistance)}`}
                          </div>
                          <div className='flex justify-between'>
                            <div className='flex items-center gap-x-1.5'>
                              <ShieldCheckIcon className='h-4 w-4' />
                              {`Confidence: `}
                            </div>
                            <QuoteConfidenceLevel
                              confidence={quote.laneRateConfidenceLevel}
                              lowConfidenceThreshold={
                                quote.lowConfidenceThreshold
                              }
                              mediumConfidenceThreshold={
                                quote.mediumConfidenceThreshold
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {isQuoteLaneHistoryEnabled && laneHistory?.length ? (
                        (() => {
                          const highestAverageRate = Math.max(
                            ...laneHistory.map((item) => item.averageRate)
                          );

                          return (
                            <GenericLineChart
                              data={laneHistory}
                              title='Lane History'
                              description='Rates from the last four weeks'
                              chartConfig={chartConfig}
                              yAxisDomainMax={highestAverageRate + 100}
                              yAxisDomainMin={0}
                              yAxisWidth={highestAverageRate > 999 ? 45 : 35}
                              thirdTooltipLabel='Loads'
                              dataKeys={['averageRate', 'lowestRate']}
                            />
                          );
                        })()
                      ) : (
                        <div className='flex justify-center'>
                          <p className='text-sm text-grayscale-content-2'>
                            No Lane History Available
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <CarrierPriceCalculator
                    showTitle={false}
                    finalPrice={finalPrice}
                    margin={margin}
                    carrierCost={carrierCost}
                    setCarrierCostHandler={setCarrierCost}
                    setMarkupHandler={setMargin}
                    setFinalPriceHandler={setFinalPrice}
                  />
                </>
              )}
            </form>

            {(quote || quoteNotConfident || !errors) && (
              <div className='flex-col justify-center'>
                <h3>Draft Response</h3>
                {quote && (
                  <div className='rounded bg-[#eaeaea] text-sm py-3 px-3 mt-1'>
                    Thank you for your request from{' '}
                    {toTitleCase(quote.stops[0].city)}, {quote?.stops[0].state}{' '}
                    to {toTitleCase(quote.stops[1].city)},{' '}
                    {quote?.stops[1].state} on{' '}
                    {new Date(getValues('pickupDate')).toDateString()} for a{' '}
                    {toTitleCase(getValues('transportType'))}. The rate would be{' '}
                    <b>{formatCurrency(finalPrice, 'USD')}</b>.
                  </div>
                )}

                {isTMSQuoteSubmissionEnabled ? (
                  <>
                    <div className='flex items-center space-x-2 mt-6'>
                      <Checkbox
                        onCheckedChange={() => setIsSubmitToTMS(!isSubmitToTMS)}
                        checked={isSubmitToTMS}
                      />
                      <label
                        htmlFor='isSubmitToTMS'
                        className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                      >
                        Submit Quote to TMS
                      </label>
                    </div>

                    {isSubmitToTMS ? (
                      <div className='mt-4'>
                        <RHFDebounceSelect
                          required={true}
                          name='customerName'
                          label='Customer'
                          control={control}
                          errors={errors}
                          data={customers}
                          isLoading={false}
                          showSearchParamDropdown={false}
                          refreshHandler={handleRefreshCustomers}
                          resetOptionsHandler={handleResetCustomerSearch}
                          fetchOptions={handleCustomerSearch}
                          mapOptions={mapCustomerToAntdOptions}
                        />
                        <a
                          className='underline text-[12px] text-orange-main'
                          href={`https://app.turvo.com/#/${tmsTenant}/accounts/cards?type=shipper`}
                        >
                          or create a new customer.
                        </a>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {createdQuoteId ? (
                  <>
                    <div className='whitespace-pre-wrap my-3 rounded py-3 text-grayscale-content-1 px-4 bg-green-bg'>
                      <p className='mb-2'>Quote Created 🎉</p>
                      <p className='mb-2 text-[14px]'>
                        <b className='text-[14px]'>Quote ID #: </b>
                        {createdQuoteId}
                      </p>
                      <p className='mb-1 text-[14px]'>
                        {/** Feature is only supported on Turvo for now */}
                        <a
                          className='underline'
                          href={`https://app.turvo.com/#/${tmsTenant}/shipments/${createdQuoteId}/details`}
                        >
                          Access the created quote for more details
                        </a>
                      </p>
                    </div>
                  </>
                ) : null}

                {/** Only supported on Outlook for now */}
                {drumkitPlatform === DrumkitPlatform.Outlook ? (
                  <Button
                    className='w-full mt-4'
                    type='button'
                    onClick={() => handleDraftResponse()}
                  >
                    {isOutlookReply ? (
                      'Add reply to current draft'
                    ) : loadingDraftReply ? (
                      <ButtonLoader />
                    ) : (
                      'Create reply draft'
                    )}
                  </Button>
                ) : null}
              </div>
            )}
          </div>
        </FormProvider>
      </ExtendedFormProvider>
    </div>
  );
}

function toTitleCase(str: string): string {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}
