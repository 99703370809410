import { useEffect, useState } from 'react';
import { Controller, FieldPath, UseFormReturn } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Select as AntdSelect } from 'antd';
import { BaseOptionType } from 'antd/es/select';

import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { useLoadContext } from 'hooks/useLoadContext';
import useTMSContext from 'hooks/useTMSContext';
import { NormalizedLoad } from 'types/Load';
import { getFieldAttribute } from 'types/LoadAttributes';
import { Maybe } from 'types/UtilityTypes';
import { titleCase } from 'utils/formatStrings';

import {
  LoadBuildingTextInput,
  RateType,
  devDisableRequiredFields,
} from '../McleodLoadBuildingForm';

export function RatesForm({
  formMethods,
  showCarrierFields,
}: {
  formMethods: UseFormReturn<NormalizedLoad>;
  showCarrierFields: boolean; // Show in Load Info, read-only mode. Not in Load Building bc it's broken rn
}): JSX.Element {
  const {
    control,
    formState: { errors },
  } = formMethods;
  //  TODO: Get from tms.GetLoadBuildingAttributes
  const revenueCodes = [
    { value: 'BRADE', label: 'Bradenton' },
    { value: 'CHATT', label: 'Chattanooga' },
    { value: 'DRAY', label: 'Drayage' },
    { value: 'ENT', label: 'Enterprise' },
    { value: 'MINNE', label: 'Minneapolis' },
    { value: 'TAMPA', label: 'Tampa' },
    { value: 'CHSC', label: 'CHARLESTON' },
  ];

  const collectionMethods = [
    { value: 'P', label: 'Prepaid' },
    { value: 'C', label: 'Collect' },
    { value: 'T', label: 'Third-Party' },
    { value: 'D', label: 'COD' },
  ];

  return (
    <div className='grid grid-cols-4 gap-2 mx-0 w-full'>
      {/* Customer Rates */}
      <>
        <div className='col-span-4 text-md text-grayscale-content-1 font-semibold mb-1'>
          Customer
        </div>
        <div className='col-span-2'>
          <Label name={'rateData.collectionMethod'}>Collection Method</Label>
          <Controller
            name='rateData.collectionMethod'
            control={control}
            rules={{ required: devDisableRequiredFields ? false : 'Required' }}
            render={({ field }) => (
              <div className=' text-grayscale-content-2'>
                <AntdSelect
                  showSearch
                  className='h-9 text-grayscale-content-2'
                  placeholder={'Choose'}
                  optionFilterProp='children'
                  filterOption={(
                    input: string,
                    option: BaseOptionType | undefined
                  ) =>
                    (option?.label.toLocaleLowerCase() ?? '').includes(
                      input.toLocaleLowerCase()
                    )
                  }
                  filterSort={(
                    optionA: BaseOptionType,
                    optionB: BaseOptionType
                  ) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={field.onChange}
                  value={field.value}
                  options={collectionMethods?.map((method) => ({
                    value: method.label,
                    label: method.label,
                  }))}
                />
              </div>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={'rateData.collectionMethod'}
            render={({ message }: { message: string }) => (
              <p className='text-red-500 text-xs'>{message}</p>
            )}
          />
        </div>
        <div className='col-span-2'>
          <Label name={'rateData.revenueCode'}>Revenue Code</Label>
          <Controller
            name='rateData.revenueCode'
            control={control}
            rules={{ required: devDisableRequiredFields ? false : 'Required' }}
            render={({ field }) => (
              <div className=' text-grayscale-content-2'>
                <AntdSelect
                  showSearch
                  className='h-9 text-grayscale-content-2'
                  placeholder={'Choose'}
                  optionFilterProp='children'
                  filterOption={(
                    input: string,
                    option: BaseOptionType | undefined
                  ) =>
                    (option?.label.toLocaleLowerCase() ?? '').includes(
                      input.toLocaleLowerCase()
                    )
                  }
                  filterSort={(
                    optionA: BaseOptionType,
                    optionB: BaseOptionType
                  ) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={field.onChange}
                  value={field.value}
                  options={revenueCodes?.map((code) => ({
                    value: code.label,
                    label: code.label,
                  }))}
                />
              </div>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={'rateData.revenueCode'}
            render={({ message }: { message: string }) => (
              <p className='text-red-500 text-xs'>{message}</p>
            )}
          />
        </div>
        {<RateGrid party='customer' formMethods={formMethods} />}
      </>
      {/* Carrier Rates */}
      {/* FIXME: Mcleod PUT carrier rates and dispatch not working */}
      {showCarrierFields && (
        <>
          <hr className='col-span-4 my-1' />
          <div className='col-span-4 text-md text-grayscale-content-1 font-semibold mb-1'>
            Carrier
          </div>

          <RateGrid party='carrier' formMethods={formMethods} />
        </>
      )}
    </div>
  );
}

{
  /* TODO: Add support for other charges and TOTAL charge (fuel surcharge) */
}

function RateGrid({
  party,
  formMethods,
}: {
  party: 'customer' | 'carrier';
  formMethods: UseFormReturn<NormalizedLoad>;
}): JSX.Element {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    setError,
  } = formMethods;

  const { fieldAttributes } = useLoadContext();
  const { tmsName } = useTMSContext();

  // Mcleod calculates distance after creating load
  const [isRatingTypeDistance, setIsRateTypeDistance] = useState(false);

  // Watch relevant fields from the form
  const watchedWeight = watch('specifications.totalWeight.val');
  const watchedDistance = watch('specifications.totalDistance.val');

  const watchedRateType = watch(`rateData.${party}RateType`);
  const watchedRate = watch(`rateData.${party}LineHaulRate`);

  function calculateInputs(
    party: 'customer' | 'carrier',
    rateType: string,
    weight: number,
    distance: number,
    rate: Maybe<number>
  ) {
    let ratingUnits: Maybe<number> = 1;
    setIsRateTypeDistance(false);
    clearErrors('specifications.totalWeight.val');
    clearErrors(
      `rateData.${party}LineHaulCharge.val` as FieldPath<NormalizedLoad>
    );

    switch (rateType) {
      case 'Flat':
        ratingUnits = 1; // Flat rate always has 1 unit
        break;

      case 'Distance':
        setIsRateTypeDistance(true);
        ratingUnits = null;
        // *After* load is created, Mcleod calculates distance so we can calculate rating units when user makes updates
        if (distance) {
          ratingUnits = distance;
        } else {
          // When user is load building, we don't know the distance yet, so we can't calculate rating units
          ratingUnits = null;
        }
        break;

      case 'CWT': // Hundredweight = 100 pounds
        if (weight) {
          ratingUnits = weight / 100;
        } else {
          setError('specifications.totalWeight.val', {
            message: 'Required for weight-based rate',
          });
          setError(
            `rateData.${party}LineHaulCharge.val` as FieldPath<NormalizedLoad>,
            {
              message: 'Missing weight',
            }
          );
        }
        break;

      case 'Tons': // Tons = 2000 pounds
        if (weight) {
          ratingUnits = weight / 2000;
        } else {
          setError('specifications.totalWeight.val', {
            message: 'Required for weight-based rate',
          });
          setError(
            `rateData.${party}LineHaulCharge.val` as FieldPath<NormalizedLoad>,
            {
              message: 'Missing weight',
            }
          );
        }
        break;

      default:
        ratingUnits = null;
        break;
    }

    // Set calculated values in the form
    setValue(
      `rateData.${party}RateNumUnits` as FieldPath<NormalizedLoad>,
      ratingUnits
    );

    if (ratingUnits && rate) {
      setValue(
        `rateData.${party}LineHaulCharge.val` as FieldPath<NormalizedLoad>,
        ratingUnits * rate
      );
    } else {
      setValue(
        `rateData.${party}LineHaulCharge.val` as FieldPath<NormalizedLoad>,
        null
      );
    }
  }

  useEffect(() => {
    calculateInputs(
      party,
      watchedRateType,
      watchedWeight,
      watchedDistance,
      watchedRate
    );
  }, [watch, setValue, watchedRate, watchedRateType, watchedWeight]);

  return (
    <>
      {/* Rate Type */}
      <div className='col-span-2'>
        <Label name={`rateData.${party}RateType` as FieldPath<NormalizedLoad>}>
          Rate Type
        </Label>
        <Controller
          name={`rateData.${party}RateType` as FieldPath<NormalizedLoad>}
          control={control}
          render={({ field }) => (
            <Select
              onValueChange={field.onChange}
              value={field.value as string}
              disabled={
                getFieldAttribute(fieldAttributes, `rateData.${party}RateType`)
                  ?.isReadOnly
              }
            >
              <SelectTrigger
                name={`rateData.${party}RateType` as FieldPath<NormalizedLoad>}
              >
                <SelectValue placeholder='Choose' />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(RateType).map(([key]) => (
                  <SelectItem key={key} value={key}>
                    {key}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
        <ErrorMessage
          errors={errors}
          name={`rateData.${party}RateType` as FieldPath<NormalizedLoad>}
          render={({ message }: { message: string }) => (
            <p className='text-red-500 text-xs'>{message}</p>
          )}
        />{' '}
      </div>
      {/* Rating Units (Read-only) */}
      <div className='col-span-2'>
        <LoadBuildingTextInput
          name={`rateData.${party}RateNumUnits` as FieldPath<NormalizedLoad>}
          label='Rating Units'
          // NOTE: Use disabled instead of readOnly so string placeholder is shown
          options={{ valueAsNumber: true, disabled: true }}
          placeholder={
            isRatingTypeDistance ? `${titleCase(tmsName)} TBD` : undefined
          }
        />
      </div>
      <div className='col-span-2'>
        {/* Line Haul Rate */}
        <LoadBuildingTextInput
          name={`rateData.${party}LineHaulRate` as FieldPath<NormalizedLoad>}
          label='Line Haul Rate'
          options={{ valueAsNumber: true }}
        />
      </div>
      {/* Line Haul Charge = Rate * Units */}
      <div className='col-span-2'>
        <LoadBuildingTextInput
          name={
            `rateData.${party}LineHaulCharge.val` as FieldPath<NormalizedLoad>
          }
          label='Line Haul Charge'
          placeholder={
            isRatingTypeDistance ? `${titleCase(tmsName)} TBD` : undefined
          }
          // NOTE: Use disabled instead of readOnly so string placeholder is shown
          options={{ valueAsNumber: true, disabled: true }}
        />
      </div>
    </>
  );
}
