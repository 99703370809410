import { useEffect } from 'react';
import { Controller, FieldPath, UseFormReturn } from 'react-hook-form';

import { Checkbox } from 'components/Checkbox';
import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { LoadDateTimeInput } from 'pages/LoadView/LoadInformationTab';
import { NormalizedLoad, TMSLocation } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import {
  GenericCompanySearchableFields,
  locationSearchHandler,
  mapLocationsToAntdOptions,
} from 'utils/loadInfoAndBuilding';

import { LoadBuildingTextInput } from '../McleodLoadBuildingForm';

export function StopForm({
  stop,
  formMethods,
  isLoadingLocations,
  locations,
  handleRefreshLocations,
  setLocations,
}: {
  stop: 'pickup' | 'consignee';
  formMethods: UseFormReturn<NormalizedLoad>;
  isLoadingLocations: boolean;
  locations: Maybe<TMSLocation[]>;
  handleRefreshLocations: () => void;
  setLocations: React.Dispatch<React.SetStateAction<Maybe<TMSLocation[]>>>;
}): JSX.Element {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const watchedLocationID = watch(`${stop}.externalTMSID`);
  const watchedLocObj = watch(stop);
  const { tmsIntegrations } = useServiceFeatures();

  const handleLocationSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return locationSearchHandler({
      tmsID: tmsIntegrations?.[0]?.id,
      locations,
      setLocations,
      field,
      value,
    });
  };

  useEffect(() => {
    if (watchedLocationID) {
      const selectedLoc = locations?.find(
        (loc) => loc.externalTMSID === watchedLocationID
      );
      if (!selectedLoc) {
        return;
      }

      Object.entries(selectedLoc).forEach(([key, value]) => {
        setValue(`${stop}.${key}` as FieldPath<NormalizedLoad>, value, {
          shouldDirty: true, // this ensures the AI-label is handled correctly
        });
      });
    }
  }, [watchedLocationID]);

  return (
    <>
      <RHFDebounceSelect
        required={true}
        name={`${stop}.externalTMSID`}
        label='Name'
        control={control}
        errors={errors}
        data={locations}
        isLoading={isLoadingLocations}
        refreshHandler={handleRefreshLocations}
        fetchOptions={handleLocationSearch}
        mapOptions={mapLocationsToAntdOptions}
      />

      <LoadBuildingTextInput
        name={`${stop}.addressLine1`}
        label='Address Line 1'
        readOnly={true}
      />

      {watchedLocObj?.addressLine2 && (
        <LoadBuildingTextInput
          name={`${stop}.addressLine2`}
          label='Address Line 2'
          readOnly={true}
        />
      )}
      <div className='grid grid-cols-2 w-full m-0 gap-2'>
        <LoadBuildingTextInput
          name={`${stop}.city`}
          label='City'
          readOnly={true}
        />
        <LoadBuildingTextInput
          name={`${stop}.state`}
          label='State'
          readOnly={true}
        />
      </div>

      <LoadBuildingTextInput
        name={`${stop}.zipCode`}
        label='Zip Code'
        placeholder='29838'
        readOnly={true}
      />
      {/* Not supported rn. Buggy duplication behavior on update */}
      {/* <LoadBuildingTextInput
        name={`${stop}.refNumber`}
        label='Ref #'
        placeholder='Comma-separated list (e.g. 123,456,789)'
      /> */}

      {watchedLocObj?.apptRequired && (
        <Controller
          name={`${stop}.apptRequired`}
          control={control}
          render={({ field }) => (
            <div className='flex items-center space-x-2'>
              <Checkbox
                onCheckedChange={(checked) => {
                  field.onChange(checked);
                }}
                checked={field.value || undefined}
                // NOTE: Even though we send `appt_required = true` to Mcleod, it doesn't seem to be respected.
                disabled={true}
              />
              <label
                htmlFor={`${stop}.apptRequired`}
                className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-grayscale-content-2'
              >
                {'Appointment Required?'}
              </label>
            </div>
          )}
        />
      )}

      <LoadDateTimeInput
        name={`${stop}.apptStartTime` as FieldPath<NormalizedLoad>}
        label='Appointment Start Time'
        required={true}
      />

      <LoadDateTimeInput
        name={`${stop}.apptEndTime` as FieldPath<NormalizedLoad>}
        label='Appointment End Time'
      />
    </>
  );
}
