import * as React from 'react';

import Mcleod from '../assets/Mcleod-Logo.png';

export default function McleodLogo(
  props: React.ImgHTMLAttributes<HTMLImageElement>
): JSX.Element {
  return (
    <img src={Mcleod} alt='McLeod Logo' height='12' width='75' {...props} />
  );
}
