import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FieldError,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { Divider, Dropdown } from 'antd';
import dayjs from 'dayjs';
import { flatMapDeep } from 'lodash';
import _ from 'lodash';
import {
  BadgeInfoIcon,
  CalendarIcon,
  FolderInputIcon,
  MapPinIcon,
  Trash2Icon,
  TriangleAlert,
  TruckIcon,
  XCircleIcon,
} from 'lucide-react';
import { validate as validateUUID } from 'uuid';

import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/Tooltip';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { ServiceContext } from 'contexts/serviceContext';
import { useToast } from 'hooks/useToaster';
import { submitTruckList } from 'lib/api/submitTruckList';
import { Email } from 'types/Email';
import { CarrierInformation, Truck, TruckListErrors } from 'types/Truck';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { EmailLabels } from 'types/enums/EmailLabels';
import { TruckListCarrier } from 'types/suggestions/TruckListSuggestions';
import { isValidNonDateObject } from 'utils/isValidObject';

import {
  TruckDateTimeInput,
  TruckListSectionAccordionItem,
  TruckTextInput,
} from '../TruckListFormFields';
import {
  TruckType,
  createEmptyTruck,
  getTmpIdByTruckId,
  getTruckIdByTmpId,
} from '../TruckListTab';
import {
  carrierContactErrorMessages,
  dropoffLocationErrorMessages,
  pickupDateErrorMessages,
  pickupLocationErrorMessages,
  postingErrorMessages,
} from './RedwoodTruckListErrors';

type RedwoodTruckListFormProps = {
  email: Maybe<Email>;
  trucksByDate: [string, Truck[]][];
  setTrucksByDate: React.Dispatch<React.SetStateAction<[string, Truck[]][]>>;
  truckListErrors: MaybeUndef<TruckListErrors>;
  setTruckListErrors: React.Dispatch<
    React.SetStateAction<MaybeUndef<TruckListErrors>>
  >;
  truckListCarrierInfo: MaybeUndef<TruckListCarrier>;
  serviceName: string;
};

export default function RedwoodTruckListForm({
  email,
  trucksByDate,
  setTrucksByDate,
  truckListErrors,
  setTruckListErrors,
  truckListCarrierInfo,
  serviceName,
}: RedwoodTruckListFormProps) {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [dateEditing, setDateEditing] = useState<string>('');

  const [carriers, setCarriers] = useState<CarrierInformation[]>([]);

  const [showCarrierSelector, setShowCarrierSelector] =
    useState<boolean>(false);
  const [showNoCarrierMessage, setShowNoCarrierMessage] =
    useState<boolean>(false);

  const [showInvalidPickupDate, setShowInvalidPickupDate] = useState<
    { dateGroup: string; err: string }[]
  >([]);

  const [activeTabs, setActiveTabs] = useState<string[]>([]);

  const { serviceID } = useContext(ServiceContext);
  // Redwood doesn't use dropoff date time input so we need to hide it for now.
  const isRedwoodService = serviceID === 529;

  // When truckListErrors is updated, we map the errors with known
  // error messages — both for carrier and individual truck errors —
  // and update the form state accordingly.
  useEffect(() => {
    if (truckListErrors?.carrierErrors) {
      if (!activeTabs.includes('carrier-details')) {
        setActiveTabs((oldActiveTabs) => [...oldActiveTabs, 'carrier-details']);
      }

      for (const [error, carriers] of Object.entries(
        truckListErrors.carrierErrors
      )) {
        switch (error) {
          case 'no-carriers-found':
            setShowNoCarrierMessage(true);
            break;
          case 'multiple-carriers-found':
            setCarriers(carriers);
            setShowCarrierSelector(true);
            break;
        }
      }
    } else {
      setShowNoCarrierMessage(false);
      setShowCarrierSelector(false);
    }

    if (truckListErrors?.truckErrors) {
      for (const [truck, truckError] of Object.entries(
        truckListErrors.truckErrors
      )) {
        const truckTmpId = getTmpIdByTruckId(trucksByDate, Number(truck));
        const truckDateGroup = trucksByDate
          .map((dateGroup) => {
            const [date, trucks] = dateGroup;
            return trucks.find((t) => t.id === Number(truck)) ? date : null;
          })
          .filter(Boolean)[0];

        if (!truckDateGroup || !truckTmpId) return;

        if (!activeTabs.includes(`truck-group-${truckDateGroup}`)) {
          setActiveTabs((oldActiveTabs) => [
            ...oldActiveTabs,
            `truck-group-${truckDateGroup}`,
          ]);
        }

        if (truckError.pickupLocationErrors.length) {
          truckError.pickupLocationErrors.forEach((err) => {
            const error = pickupLocationErrorMessages[err];
            if (error) {
              error.fields.forEach((field) =>
                setError(`${truckTmpId}.${field}`, {
                  type: 'value',
                  message: error.message,
                })
              );
            }
          });
        }
        if (truckError.dropoffLocationErrors.length) {
          truckError.dropoffLocationErrors.forEach((err) => {
            const error = dropoffLocationErrorMessages[err];
            if (error) {
              error.fields.forEach((field) =>
                setError(`${truckTmpId}.${field}`, {
                  type: 'value',
                  message: error.message,
                })
              );
            }
          });
        }
        if (truckError.pickupDateErrors.length) {
          truckError.pickupDateErrors.forEach((err) => {
            const error = pickupDateErrorMessages[err];
            if (error) {
              error.fields.forEach((_) =>
                setShowInvalidPickupDate((prevInvalidPickupDate) => [
                  ...prevInvalidPickupDate,
                  { dateGroup: truckDateGroup, err: error.message },
                ])
              );
            }
          });
        }
        if (truckError.postingErrors.length) {
          truckError.postingErrors.forEach((err) => {
            const error = postingErrorMessages[err];
            if (error) {
              error.fields.forEach((field) =>
                setError(`${truckTmpId}.${field}`, {
                  type: 'value',
                  message: error.message,
                })
              );
            }
          });
        }
      }
    }

    if (truckListErrors?.carrierContactErrors) {
      if (!activeTabs.includes('carrier-details')) {
        setActiveTabs((oldActiveTabs) => [...oldActiveTabs, 'carrier-details']);
      }

      truckListErrors.carrierContactErrors.forEach((err) => {
        const error = carrierContactErrorMessages[err];
        if (error) {
          error.fields.forEach((field) =>
            setError(field, { type: 'value', message: error.message })
          );
        }
      });
    }
  }, [truckListErrors]);

  const memoizedDefaultValues = useMemo(() => {
    // TODO: Re-enable manually-created Truck Lists with further testing
    // if (!trucksByDate.length) {
    //   const newDefaultPickupDate = new Date();
    //   newDefaultPickupDate.setDate(newDefaultPickupDate.getDate() + 1);
    //   newDefaultPickupDate.setHours(0, 0, 0, 0);

    //   const newDefaultPickupDateStr = newDefaultPickupDate
    //     .toISOString()
    //     .split('T')[0];

    //   const newDefaultTruckGroup = [
    //     newDefaultPickupDateStr,
    //     [createEmptyTruck(newDefaultPickupDateStr)],
    //   ] as [string, Truck[]];
    //   setTrucksByDate([newDefaultTruckGroup]);
    // }

    // setting all tabs open by default
    setActiveTabs([
      'carrier-details',
      ...trucksByDate.map((tGroup) => `truck-group-${tGroup[0]}`),
    ]);

    return trucksByDate.reduce(
      (acc, [date, truckGroup]) => {
        if (!truckGroup.length) return {};

        acc[`${date}-group.pickupDate`] = truckGroup[0].pickupDate || '';

        truckGroup.forEach((truck) => {
          acc[`${truck.tmpId}.pickupLocation.city`] =
            truck?.pickupLocation?.city || '';
          acc[`${truck.tmpId}.pickupLocation.state`] =
            truck?.pickupLocation?.state || '';
          acc[`${truck.tmpId}.dropoffLocation.city`] =
            truck?.dropoffLocation?.city || '';
          acc[`${truck.tmpId}.dropoffLocation.state`] =
            truck?.dropoffLocation?.state || '';
          //   truck?.dropoffLocation?.country || '';
          acc[`${truck.tmpId}.type`] = truck?.type || null;
          acc[`${truck.tmpId}.length`] = truck?.length || null;
          acc[`${truck.tmpId}.pickupDate`] = truck?.pickupDate || null;
          acc[`${truck.tmpId}.dropoffDate`] = truck?.dropoffDate || null;
        });
        return {
          ...acc,
          'carrierInformation.name': truckListCarrierInfo?.name,
          'carrierInformation.contact.name': truckListCarrierInfo?.contactName,
          'carrierInformation.contact.email':
            truckListCarrierInfo?.contactEmail,
          'carrierInformation.mc': truckListCarrierInfo?.mc,
          'carrierInformation.dot': truckListCarrierInfo?.dot,
        };
      },
      {} as Record<string, any>
    );
  }, [trucksByDate, truckListCarrierInfo]);

  // Set default values based on the grouped trucks
  const formMethods = useForm({
    defaultValues: memoizedDefaultValues,
  });

  useEffect(() => {
    if (memoizedDefaultValues) {
      formMethods.reset(memoizedDefaultValues);
    }
  }, [memoizedDefaultValues]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = formMethods;

  const updatedCarrierName = useWatch({
    control,
    name: 'updated.carrier',
  });

  useEffect(() => {
    if (updatedCarrierName) {
      const selectedCarrier = carriers.find(
        (carrier) => carrier.name === updatedCarrierName
      );

      if (selectedCarrier) {
        setValue('carrierInformation.name', selectedCarrier.name);
        setValue(
          'carrierInformation.contact.name',
          selectedCarrier.contactName
        );
        setValue(
          'carrierInformation.contact.email',
          selectedCarrier.contactEmail
        );
        setValue('carrierInformation.mc', selectedCarrier.mc);
        setValue('carrierInformation.dot', selectedCarrier.dot);
      }
    }
  }, [updatedCarrierName, carriers, setValue]);

  const onSubmit: SubmitHandler<Record<string, any>> = async (data) => {
    setLoading(true);
    if (email?.id == undefined || email?.threadID == undefined) {
      toast({
        description: 'Unable to submit truck list',
        variant: 'destructive',
      });
      setLoading(false);
      return;
    }

    const trucks: Truck[] = [];
    const truckIdToGroupDate: Record<string, string> = {};
    trucksByDate.forEach(([groupDate, truckGroup]) => {
      truckGroup.forEach(
        (truck) => (truckIdToGroupDate[truck.tmpId] = groupDate)
      );
    });

    Object.entries(data).forEach(([key, value]) => {
      if (validateUUID(key) && isValidNonDateObject(value)) {
        const groupDate = truckIdToGroupDate[key];
        const pickupDate = groupDate ? dayjs(groupDate).toISOString() : null;

        trucks.push({
          ...value,
          id: getTruckIdByTmpId(trucksByDate, key) ?? null,
          length: Number(value.length),
          pickupDate: pickupDate,
        });
      }
    });

    // Transform the carrier information to conform to Redwood's JSON schema.
    const carrierInformation = {
      name: data.carrierInformation.name,
      mc: data.carrierInformation.mc,
      dot: data.carrierInformation.dot,
      contactEmail: data.carrierInformation.contact.email,
      contactName: data.carrierInformation.contact.name,
    };

    const res = await submitTruckList(
      email.id,
      email.threadID,
      serviceName,
      carrierInformation, // use the transformed carrier information
      trucks
    );

    if (res.isOk()) {
      toast({ description: 'Submitted truck list!', variant: 'success' });
    } else {
      if (!res.error.truckListErrors) {
        toast({
          description: 'Unable to submit truck list',
          variant: 'destructive',
        });
        return;
      }

      setActiveTabs([]);
      setTruckListErrors(res.error.truckListErrors);

      const {
        carrierErrors = {},
        carrierContactErrors = [],
        truckErrors = {},
        postedByErrors = {},
      } = res.error.truckListErrors;

      const carrierErrorsCount = Object.keys(carrierErrors ?? {}).length;
      const carrierContactErrorsCount = carrierContactErrors?.length ?? 0;
      const postedByErrorsCount = Object.keys(postedByErrors ?? {}).length;
      const truckErrorsCount = Object.values(truckErrors ?? {})
        .map((errs) => flatMapDeep(errs).length)
        .reduce((acc, errsCount) => acc + errsCount, 0);

      if (carrierErrorsCount || carrierContactErrorsCount) {
        toast({
          description:
            'Carrier-related error(s) found. Please review carrier details.',
          variant: 'destructive',
        });
      } else {
        toast({
          description: [
            'The submitted truck list has',
            truckErrorsCount
              ? `${truckErrorsCount} truck error${truckErrorsCount > 1 ? 's' : ''},`
              : '',
            postedByErrorsCount
              ? `${postedByErrorsCount} posting error${postedByErrorsCount > 1 ? 's' : ''},`
              : '',
            'please review the information and try again.',
          ]
            .filter(Boolean)
            .join(' '),
          variant: 'destructive',
        });
      }
    }

    setLoading(false);
  };

  const onInvalid: SubmitErrorHandler<Record<string, any>> = async () => {
    toast({
      description: 'Some fields are invalid.',
      variant: 'destructive',
    });
  };

  const handleDeleteTruck = (truckTmpId: string, groupDate: string) => {
    setTrucksByDate((prevTruckGroups) =>
      prevTruckGroups.map((tGroup) =>
        tGroup[0] === groupDate
          ? [tGroup[0], tGroup[1].filter((truck) => truck.tmpId !== truckTmpId)]
          : tGroup
      )
    );
  };

  const handleAddTruckToGroup = (groupDate: string) => {
    const newTruck = createEmptyTruck(groupDate);

    setTrucksByDate((prevTruckGroups) =>
      prevTruckGroups.map((tGroup) =>
        tGroup[0] === groupDate ? [tGroup[0], [...tGroup[1], newTruck]] : tGroup
      )
    );
  };

  const handleMoveTruckToGroup = (truck: Truck, destGroup: string) => {
    if (!truck.pickupDate || !truck.tmpId) return;

    // date format follows (per backend) the format YYYY-MM-DDThh:mm:ss.SSSSSS-(tz offset)
    const truckCurrentGroup = truck.pickupDate.split('T')[0];

    const newPickupDate = `${destGroup}T${truck.pickupDate.split('T')[1]}`;
    const newTruck: Truck = { ...truck, pickupDate: newPickupDate };

    setTrucksByDate((prevTruckGroups) =>
      prevTruckGroups.map((tGroup) =>
        tGroup[0] === truckCurrentGroup
          ? [tGroup[0], tGroup[1].filter((t) => t.tmpId !== truck.tmpId)]
          : tGroup[0] === destGroup
            ? [tGroup[0], [...tGroup[1], newTruck]]
            : tGroup
      )
    );
  };

  const handleApplyDefaultDropoff = () => {
    const updatedTrucksByDate: [string, Truck[]][] = trucksByDate.map(
      (tGroup) => [
        tGroup[0],
        tGroup[1].map((truck) => ({
          ...truck,
          dropoffLocation: {
            name: formMethods.getValues('default.name'),
            addressLine1: formMethods.getValues('default.addressLine1'),
            addressLine2: formMethods.getValues('default.addressLine2'),
            zip: formMethods.getValues('default.zip'),
            city: formMethods.getValues('default.city'),
            state: formMethods.getValues('default.state'),
            country: formMethods.getValues('default.country'),
          },
          dropoffDate: formMethods.getValues('default.dropoffDate'),
        })),
      ]
    );

    formMethods.resetField('default.city');
    formMethods.resetField('default.state');
    formMethods.resetField('default.dropoffDate');

    setTrucksByDate(updatedTrucksByDate);
  };

  const handleUpdateGroupDate = (currentDate: string) => {
    const newGroup = new Date(formMethods.getValues('updated.pickupDate'))
      .toISOString()
      .split('T')[0];

    setTrucksByDate((prevTruckGroups) => {
      const updatedTruckGroups = prevTruckGroups.map((tGroup) => {
        return tGroup[0] === currentDate ? [newGroup, tGroup[1]] : tGroup;
      }) as [string, Truck[]][];
      return updatedTruckGroups.sort((a, b) => a[0].localeCompare(b[0]));
    });

    setDateEditing('');
    formMethods.setValue('updated.pickupDate', null);
  };

  const handleAddNewGroup = () => {
    const newGroupDate = formMethods
      .getValues('newGroup.pickupDate')
      .toISOString()
      .split('T')[0];
    if (!newGroupDate) return;

    const newTruck = createEmptyTruck(newGroupDate);

    setTrucksByDate((prevTruckGroups) => [
      ...prevTruckGroups,
      [newGroupDate, [newTruck]],
    ]);
  };

  return trucksByDate.length > 0 ? (
    <ExtendedFormProvider
      aiDefaultValues={email?.labels.includes(EmailLabels.TruckList) ?? false}
      aiIconOnly={true}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          {showCarrierSelector ? (
            <div className='rounded-md bg-yellow-50 mt-8 mb-8 p-4'>
              <div className='flex items-start'>
                <div className='flex-shrink-0 pt-1.5'>
                  <TriangleAlert
                    aria-hidden='true'
                    className='h-4 w-4 text-yellow-500'
                  />
                </div>
                <div className='ml-3'>
                  <h3 className='leading-8 text-sm font-medium text-yellow-600'>
                    Multiple carriers were found, please select one below:
                  </h3>
                  <div>
                    <Controller
                      name={`updated.carrier`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          onValueChange={field.onChange}
                          value={field.value as string}
                        >
                          <SelectTrigger className='w-full mt-1'>
                            <SelectValue placeholder={'Choose'} />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.values(carriers).map((carrier) => (
                              <SelectItem
                                key={carrier.name}
                                value={carrier.name}
                              >
                                {carrier.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {showNoCarrierMessage ? (
            <div className='rounded-md bg-red-50 mt-8 mb-8 p-4'>
              <div className='flex items-start'>
                <div className='flex-shrink-0 pt-0.5'>
                  <XCircleIcon
                    aria-hidden='true'
                    className='h-4 w-4 text-red-500'
                  />
                </div>
                <div className='ml-3'>
                  <h3 className='text-sm font-medium text-red-600'>
                    No carriers were found
                  </h3>
                  <div className='mt-2 text-base text-red-500'>
                    <ul role='list' className='list-disc space-y-1 pl-5'>
                      <li>Create a new one on the Redwood Platform first.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <Accordion
            type='multiple'
            value={activeTabs}
            onValueChange={setActiveTabs}
            className='w-full'
          >
            <TruckListSectionAccordionItem
              key={'carrier-details'}
              icon={<BadgeInfoIcon />}
              name={`carrier-details`}
              label={`Carrier Details`}
              activeTabs={activeTabs}
              contentClassName='px-2'
              showEditIcon={false}
            >
              <TruckTextInput
                name={`carrierInformation.name`}
                label='Carrier Name'
              />
              <div className='mt-3'>
                <TruckTextInput
                  name={`carrierInformation.contact.name`}
                  label='Contact Name'
                />
              </div>
              <div className='mt-3'>
                <TruckTextInput
                  name={`carrierInformation.contact.email`}
                  label='Contact Email'
                />
              </div>
              <div className='grid grid-cols-4 gap-4 mt-2 mx-0 w-full'>
                <div className='col-span-2'>
                  <TruckTextInput
                    name={`carrierInformation.mc`}
                    label='MC #'
                    aiIconOnly={true}
                  />
                </div>

                <div className='col-span-2'>
                  <TruckTextInput
                    name={`carrierInformation.dot`}
                    label='DOT #'
                    aiIconOnly={true}
                  />
                </div>
              </div>
            </TruckListSectionAccordionItem>

            {!isRedwoodService && (
              <TruckListSectionAccordionItem
                key={'default-dropoff'}
                icon={<MapPinIcon />}
                name={`default-dropoff`}
                label={`Default Dropoff`}
                activeTabs={activeTabs}
                contentClassName='px-2'
                showEditIcon={false}
              >
                <div className='grid grid-cols-3 gap-4 mt-2 mx-0 w-full'>
                  <div className='col-span-2'>
                    <TruckTextInput name={`default.city`} label='City' />
                  </div>

                  <div className='col-span-1'>
                    <TruckTextInput
                      name={`default.state`}
                      label='State'
                      aiIconOnly={true}
                    />
                  </div>
                </div>

                <TruckDateTimeInput
                  name={`default.dropoffDate`}
                  label='Dropoff Date'
                  includeTime={false}
                />

                <Button
                  className='w-full h-8 text-[14px] mt-2'
                  onClick={handleApplyDefaultDropoff}
                  type='button'
                >
                  Apply to all Trucks
                </Button>
              </TruckListSectionAccordionItem>
            )}

            {trucksByDate.map(([date, truckGroup]) => {
              if (!truckGroup.length) return;

              const dateObj = dayjs(date);
              const hasPickupDateError = showInvalidPickupDate.find(
                (invalidPickupDate) => invalidPickupDate.dateGroup === date
              );

              return (
                <TruckListSectionAccordionItem
                  key={date}
                  icon={<CalendarIcon />}
                  name={`truck-group-${date}`}
                  label={`${dateObj.format('MMM D - dddd')}`}
                  activeTabs={activeTabs}
                  contentClassName='px-2'
                  showEditIcon={true}
                  setDateEditing={setDateEditing}
                >
                  {dateEditing === `truck-group-${date}` ||
                  hasPickupDateError ? (
                    <div className='mb-6'>
                      <TruckDateTimeInput
                        name={`updated.pickupDate`}
                        label='Updated Pickup'
                        includeTime={false}
                      />

                      <p className='text-red-500 text-xs mb-2'>
                        {hasPickupDateError
                          ? `${hasPickupDateError.err}, please update Pickup`
                          : ''}
                      </p>

                      <Button
                        className='w-full h-8 text-[14px] mt-2'
                        onClick={() => handleUpdateGroupDate(date)}
                      >
                        {`Update ${truckGroup.length} truck${truckGroup.length > 1 ? 's' : ''}`}
                      </Button>
                    </div>
                  ) : null}

                  {truckGroup.map((truck, index) => (
                    <div className='mb-2' key={truck.tmpId}>
                      <TooltipProvider>
                        <div className='relative mb-2 flex justify-between'>
                          <h2 className='flex items-center gap-2 font-semibold'>
                            <TruckIcon className='h-5 w-5' />
                            {`Truck ${index + 1}`}
                          </h2>
                          <div className='flex gap-3'>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Dropdown
                                  menu={{
                                    items: Object.values(trucksByDate).map(
                                      ([group]) => ({
                                        label: `${group}`,
                                        key: `trucks-dropdown-${group}`,
                                        disabled: group === date,
                                        onClick: () =>
                                          handleMoveTruckToGroup(truck, group),
                                      })
                                    ),
                                  }}
                                  trigger={['click']}
                                >
                                  <FolderInputIcon className='h-[18px] w-4 cursor-pointer stroke-grayscale-content-2' />
                                </Dropdown>
                              </TooltipTrigger>
                              <TooltipContent>Move Truck To</TooltipContent>
                            </Tooltip>

                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Trash2Icon
                                  className='h-4 w-4 cursor-pointer stroke-grayscale-content-2'
                                  onClick={() =>
                                    truck.tmpId &&
                                    handleDeleteTruck(truck.tmpId, date)
                                  }
                                />
                              </TooltipTrigger>
                              <TooltipContent>Delete Truck</TooltipContent>
                            </Tooltip>
                          </div>
                        </div>

                        <div>
                          <Label name={`${truck.tmpId}.type`}>Truck Type</Label>
                          <Controller
                            name={`${truck.tmpId}.type`}
                            control={control}
                            render={({ field }) => {
                              const truckTypeError = errors[`${truck.tmpId}`]
                                ?.type as FieldError;

                              return (
                                <>
                                  <Select
                                    onValueChange={field.onChange}
                                    value={field.value as string}
                                  >
                                    <SelectTrigger className='w-full mt-1'>
                                      <SelectValue placeholder={'Choose'} />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Object.values(TruckType).map(
                                        (option) => (
                                          <SelectItem
                                            key={option}
                                            value={option}
                                          >
                                            {option}
                                          </SelectItem>
                                        )
                                      )}
                                    </SelectContent>
                                  </Select>

                                  {truckTypeError ? (
                                    <p className='text-red-500 text-xs mb-2'>
                                      {truckTypeError.message}
                                    </p>
                                  ) : null}
                                </>
                              );
                            }}
                          />
                        </div>

                        <div className='grid grid-cols-3 gap-4 mt-2 mx-0 w-full'>
                          <div className='col-span-2'>
                            <TruckTextInput
                              name={`${truck.tmpId}.pickupLocation.city`}
                              label='Origin City'
                            />
                          </div>

                          <div className='col-span-1'>
                            <TruckTextInput
                              name={`${truck.tmpId}.pickupLocation.state`}
                              label='State'
                            />
                          </div>
                        </div>

                        <div className='grid grid-cols-3 gap-4 my-2 mx-0 w-full'>
                          <div className='col-span-2'>
                            <TruckTextInput
                              name={`${truck.tmpId}.dropoffLocation.city`}
                              label='Dropoff City'
                            />
                          </div>
                          <div className='col-span-1'>
                            <TruckTextInput
                              name={`${truck.tmpId}.dropoffLocation.state`}
                              label='State'
                            />
                          </div>
                        </div>

                        <TruckTextInput
                          name={`${truck.tmpId}.length`}
                          label='Length'
                        />

                        {!isRedwoodService && (
                          <TruckDateTimeInput
                            name={`${truck.tmpId}.dropoffDate`}
                            label='Dropoff Date'
                            includeTime={false}
                          />
                        )}

                        <Divider className='my-4 after:border-b-[1px] after:w-[90%] after:mx-auto after:border-grayscale-content-3' />
                      </TooltipProvider>
                    </div>
                  ))}
                  <Button
                    className='w-full h-8 text-[14px] text-orange-main border border-orange-main hover:border-orange-main'
                    onClick={() => handleAddTruckToGroup(date)}
                    type='button'
                    variant='outline'
                  >
                    Add new Truck
                  </Button>
                </TruckListSectionAccordionItem>
              );
            })}
          </Accordion>

          <div className='grid grid-cols-10 items-center gap-2 w-full mx-0 mt-2'>
            <Button
              className='w-full mt-2 h-9 col-span-6 text-[14px] text-orange-main border border-orange-main hover:border-orange-main'
              onClick={handleAddNewGroup}
              disabled={!formMethods.getValues('newGroup.pickupDate')}
              type='button'
              variant='outline'
            >
              {formMethods.getValues('newGroup.pickupDate')
                ? `Create ${dayjs(formMethods.getValues('newGroup.pickupDate')).format('MM/DD/YY')} group`
                : `Create pickup group`}
            </Button>

            <div className='col-span-3'>
              <TruckDateTimeInput
                label=''
                name={`newGroup.pickupDate`}
                includeTime={false}
              />
            </div>
          </div>

          <Button
            buttonName={ButtonName.SubmitTruckList}
            buttonNamePosthog={ButtonNamePosthog.SubmitTruckList}
            type='submit'
            className='w-full mt-8'
          >
            {loading ? (
              <ButtonLoader />
            ) : serviceName != '' ? (
              `Submit to ${serviceName}`
            ) : (
              'Submit'
            )}
          </Button>
        </form>
      </FormProvider>
    </ExtendedFormProvider>
  ) : (
    <h3 className='flex justify-center py-5 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-grayscale-content-3'>
      No trucks available
    </h3>
  );
}
