import { Controller, useFormContext } from 'react-hook-form';

import { Select as AntdSelect } from 'antd';

import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { Textarea } from 'components/Textarea';
import { Input } from 'components/input/Input';
import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import { CompanyCoreInfo } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import {
  GenericCompanySearchableFields,
  mapCustomerToAntdOptions,
} from 'utils/loadInfoAndBuilding';

import { FormConditionProps } from './RequestCarrierQuotes';

const { Option } = AntdSelect;

export interface LoadInformationFormProps {
  formConditions: FormConditionProps;
  customers: Maybe<CompanyCoreInfo[]>;
  handleRefreshCustomers: () => Promise<void>;
  handleResetCustomerSearch: () => void;
  handleCustomerSearch: (
    field: GenericCompanySearchableFields,
    value: string
  ) => Promise<any[]>;
  tmsIntegrations: any[];
}

export default function LoadInformationForm({
  formConditions,
  customers,
  handleRefreshCustomers,
  handleResetCustomerSearch,
  handleCustomerSearch,
  tmsIntegrations,
}: LoadInformationFormProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const weightUnits = ['lbs', 'kg', 'ton'];

  return (
    <div className='grid gap-3 grid-cols-1 w-full mx-0'>
      <div className='text-md whitespace-nowrap text-grayscale-content-label font-semibold'>
        Load Information
      </div>

      {/* Customer Search */}
      {formConditions.showCustomerSearch &&
        tmsIntegrations &&
        tmsIntegrations.length > 0 && (
          <div>
            <RHFDebounceSelect
              required={false}
              name='customerId'
              label='Customer'
              control={control}
              errors={errors}
              data={customers}
              isLoading={false}
              showSearchParamDropdown={false}
              refreshHandler={handleRefreshCustomers}
              resetOptionsHandler={handleResetCustomerSearch}
              fetchOptions={handleCustomerSearch}
              mapOptions={mapCustomerToAntdOptions}
            />
          </div>
        )}

      {formConditions.showItemDescription ? (
        <div className='flex flex-col gap-1'>
          <Label name={'itemDescription'}>Item Description</Label>
          <Controller
            name='itemDescription'
            control={control}
            render={({ field }) => (
              <Textarea
                {...field}
                placeholder='Enter description'
                className='min-h-[60px]'
              />
            )}
          />
        </div>
      ) : (
        <>
          {/* Transport Type */}
          <div>
            <Label name={'transportType'}>Transport Type</Label>
            <Controller
              name='transportType'
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger className='w-full mt-1'>
                    <SelectValue placeholder='Choose' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value='VAN'>VAN</SelectItem>
                    <SelectItem value='REEFER'>REEFER</SelectItem>
                    <SelectItem value='FLATBED'>FLATBED</SelectItem>
                    <SelectItem value='POWER_ONLY'>POWER ONLY</SelectItem>
                  </SelectContent>
                </Select>
              )}
            />
          </div>

          {/* Weight */}
          <div className='flex items-center'>
            <div className='flex-grow'>
              <Label name='weightLbs'>Weight</Label>
              <Controller
                name='weightLbs'
                control={control}
                rules={{
                  required: 'Weight is required',
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type='number'
                    placeholder='Enter weight'
                    className='mt-1'
                  />
                )}
              />
            </div>
            <div className='flex-none ml-2'>
              <Label name='weightUnit'>Unit</Label>
              <Controller
                name='weightUnit'
                control={control}
                render={({ field }) => (
                  <AntdSelect
                    {...field}
                    showSearch
                    placeholder='Unit'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {weightUnits.map((unit) => (
                      <Option key={unit} value={unit}>
                        {unit}
                      </Option>
                    ))}
                  </AntdSelect>
                )}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
