import { createContext } from 'react';

import { AuthUser } from 'types/AuthUser';
import { Maybe } from 'types/UtilityTypes';

export type AuthContextType = {
  loading: boolean;
  user?: Maybe<AuthUser>;
  logout: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>({
  loading: true,
  logout: async () => {},
});
