// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import {
  IntegrationCore,
  Service,
  ServiceFeaturesListType,
} from 'contexts/serviceContext';

import { useServiceFeatures } from './useServiceContext';

type FetchServiceFeaturesResponse = {
  id: number;
  tmsIntegrations: IntegrationCore[];
  quotingIntegrations: IntegrationCore[];
} & ServiceFeaturesListType;

export default async function useFetchService(
  serviceID: number
): Promise<void> {
  const { setService } = useServiceFeatures();

  const axiosRes = await axios.get<FetchServiceFeaturesResponse>(
    `service/${encodeURIComponent(serviceID!)}/features`
  );

  const {
    data: { id, tmsIntegrations, quotingIntegrations, ...features },
  } = axiosRes;

  setService(
    (prev) =>
      ({
        ...prev,
        serviceID: id,
        tmsIntegrations,
        quotingIntegrations,
        serviceFeaturesEnabled: features,
      }) as Service
  );
}
