import { QuoteRequest } from 'types/QuoteRequest';
import { Maybe } from 'types/UtilityTypes';
import { useThrowableSWR } from 'utils/fetcher';

type QuoteRequestFetchResult = {
  request: Maybe<QuoteRequest>;
  isLoading: boolean;
  error: Maybe<Error>;
};

export default function useFetchQuoteRequest(
  threadID: string
): QuoteRequestFetchResult {
  if (!threadID) return { request: null, isLoading: false, error: null };

  const res = useThrowableSWR<QuoteRequest>(
    `quote/request/thread/${encodeURIComponent(threadID)}`,
    false
  );

  return { request: res.data ? res.data : null, ...res };
}
