// Email model for debugging purposes to pass to ingestion

export type IngestionMessage = {
  messageID: string;
  fromName: string;
  fromEmail: string;
  toName: string;
  toEmail: string;
  subject: string;
  body: string;
  date: string;
};

export const initialIngestionMessage: IngestionMessage = {
  messageID: '',
  fromName: '',
  fromEmail: '',
  toName: '',
  toEmail: '',
  subject: '',
  body: '',
  date: '',
};
