// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import CarrierVerificationCard from 'components/CarrierVerificationCard';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { createTMSInstance } from 'lib/hosts/interface';
import { Maybe } from 'types/UtilityTypes';
import TMS from 'types/enums/Integrations';

interface OverviewSectionProps {
  tmsName: TMS;
  freightTrackingID: string;
  externalTMSID: string;
  labels: string[];
  loadStatus: string;
  poNumbers: string[];
  fromEmail: Maybe<string>;
}

export default function OverviewSection({
  tmsName,
  freightTrackingID,
  externalTMSID,
  labels,
  loadStatus,
  poNumbers,
  fromEmail,
}: OverviewSectionProps) {
  const {
    serviceFeaturesEnabled: { isCarrierVerificationEnabled },
  } = useServiceFeatures();

  let externalTMSIDMoniker, freightTrackingIDMoniker;
  try {
    const tmsClient = createTMSInstance(tmsName);
    externalTMSIDMoniker = tmsClient.getFieldMoniker('externalTMSID');
    freightTrackingIDMoniker = tmsClient.getFieldMoniker('freightTrackingID');
  } catch (e) {
    if (!isProd()) console.error(e);
  }

  return (
    <div className='pb-4 flex flex-col gap-2 w-full text-sm'>
      {externalTMSID && externalTMSIDMoniker && (
        <div className='flex flex-row flex-wrap gap-2 justify-between px-4 items-center'>
          <h2 className='text-grayscale-content-1 text-sm'>
            {externalTMSIDMoniker}
          </h2>
          <span className='px-1 py-0.5 rounded border border-orange-border bg-orange-bg text-orange-main capitalize text-xs'>
            {externalTMSID}
          </span>
        </div>
      )}

      {freightTrackingID && freightTrackingIDMoniker && (
        <div className='flex flex-row flex-wrap gap-2 justify-between px-4 items-center'>
          <h2 className='text-grayscale-content-1 text-sm'>
            {freightTrackingIDMoniker}
          </h2>
          <span className='px-1 py-0.5 rounded border border-grayscale-border capitalize text-xs'>
            {freightTrackingID}
          </span>
        </div>
      )}

      <div className='flex flex-row flex-wrap gap-2 justify-between px-4 items-center'>
        <h2 className='text-grayscale-content-1 text-sm'>Load Status</h2>
        <span className='px-1 py-0.5 rounded border border-orange-border bg-orange-bg text-orange-main capitalize text-xs'>
          {loadStatus ? loadStatus : 'Undetermined'}
        </span>
      </div>
      {poNumbers.length > 0 && (
        <>
          <div className='flex flex-row flex-wrap gap-2 justify-between px-4 items-center'>
            <h2 className='text-grayscale-content-1 text-sm'>PO Numbers</h2>
            {poNumbers.map(
              (item, idx) =>
                item &&
                item.trim().length > 0 && (
                  <span
                    role='listitem'
                    key={idx}
                    className='text-xs text-grayscale-content-1 py-1 px-2 rounded border border-grayscale-border capitalize'
                  >
                    {item.trim()}
                  </span>
                )
            )}
          </div>
        </>
      )}

      {labels.length > 0 && (
        <>
          <div className='flex flex-row flex-wrap gap-2 justify-between px-4 items-center'>
            <h2 className='text-grayscale-content-1 text-sm'>Email Labels</h2>
            {labels.map((item, idx) => (
              <span
                role='listitem'
                key={idx}
                className='text-xs text-grayscale-content-1 py-1 px-2 rounded border border-grayscale-border capitalize'
              >
                {item.length > 0 ? item.trim() : 'none'}
              </span>
            ))}
          </div>
        </>
      )}

      {isCarrierVerificationEnabled && fromEmail && (
        <CarrierVerificationCard fromEmail={fromEmail} />
      )}
    </div>
  );
}
