import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { CarrierInformation, TruckListErrors } from 'types/Truck';
import { Truck } from 'types/Truck';
import { Maybe } from 'types/UtilityTypes';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type PostTruckListResponse = {
  errors: Maybe<TruckListErrors>;
  errorMessage: Maybe<string>;
};

export async function submitTruckList(
  emailID: number,
  threadID: string,
  serviceName: string,
  carrier: CarrierInformation,
  trucks: Truck[]
): Promise<Result<PostTruckListResponse, ApiError>> {
  const requestBody = {
    serviceName: serviceName,
    carrier: carrier,
    trucks: trucks,
  };

  try {
    const resp = await axios.post<PostTruckListResponse>(
      `/thread/${encodeURIComponent(threadID)}/email/${emailID}/trucks`,
      requestBody
    );

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'submitTruckList' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to submit truck list' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    let errorMessage = 'Failed to submit truck list';

    if (error.response?.data?.errorMessage) {
      errorMessage = error.response.data.errorMessage;
    }

    return err({
      truckListErrors: error.response?.data?.errors,
      message: errorMessage,
    });
  }
}
