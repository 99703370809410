import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { MarginType } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export type UserQuote = {
  quoteRequestId: number;
  carrierCost: number;
  margin: number;
  marginType: MarginType;
  targetSell: number;
  draftResponse: string;
  stops: {
    order: number;
    city: string;
    state: string;
    zip: string;
    country: string;
  }[];
  gsQuoteID: string;
};

export async function sendUserQuoteToService(
  quote: UserQuote
): Promise<Result<null, ApiError>> {
  try {
    const { data } = await axios.post(
      `/quote/private/send-to-service/user`,
      quote
    );

    return ok(data);
  } catch (error) {
    captureException(error, { functionName: 'sendUserQuoteToService' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to send user quote to service' });
    }

    return err({
      message:
        error.response?.data.message || 'Failed to send user quote to service',
    });
  }
}
