import React, { useContext, useEffect, useRef, useState } from 'react';

import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

import { LoadBuildingSuggestionCard } from 'components/AISuggestions/LoadBuildingCard';
import { QuickQuoteCard } from 'components/AISuggestions/QuickQuoteCard';
import {
  KeyValueElement,
  SuggestionCard,
} from 'components/AISuggestions/SuggestionsCard';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useAuth } from 'hooks/useAuth';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { skipSuggestion } from 'lib/api/skipSuggestion';
import { Undef } from 'types/UtilityTypes';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import {
  GenericSuggestion,
  SuggestionChangeRecord,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import { LoadBuildingSuggestions } from 'types/suggestions/LoadBuildingSuggestions';
import { SuggestedLoadChange } from 'types/suggestions/LoadSuggestions';
import { SuggestedQuoteChange } from 'types/suggestions/QuoteSuggestions';
import { flattenSuggestionChanges } from 'utils/flattenSuggestionChanges';
import { isValidNonDateObject } from 'utils/isValidObject';
import { isLikelyPhoneNumber } from 'utils/phoneAndDateParser';
import {
  getFieldOrder,
  getSuggestionFormattedLabel,
  reorderFields,
} from 'utils/suggestions/suggestionFormat';

// FIXME: Weird carousel glitch when switching to LB tab, but doesn't happen for other tabs/suggestion pipelines
export default function SuggestionsCarousel({
  suggestions: initRawSuggestions,
}: {
  suggestions: GenericSuggestion[];
}) {
  const carouselRef = useRef<CarouselRef>(null);

  const { user } = useAuth();
  const { toast } = useToast();
  const posthog = usePostHog();
  const {
    setCurrentState,
    currentState: { curSuggestionList, clickedSuggestion },
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: {
      isCarrierInfoSuggestionsEnabled,
      isAppointmentSuggestionsEnabled,
      isLoadBuildingEnabled,
    },
  } = useServiceFeatures();

  const [optionalSuggestions, setOptionalSuggestions] = useState<
    GenericSuggestion[]
  >([]);

  useEffect(() => {
    const optionalSuggestions = initRawSuggestions
      .sort(suggestionSortComparator)
      .filter(
        (suggestion) =>
          !(
            suggestion.pipeline === SuggestionPipelines.CarrierInfo &&
            !isCarrierInfoSuggestionsEnabled
          ) &&
          !(
            suggestion.pipeline === SuggestionPipelines.ApptConfirmation &&
            !isAppointmentSuggestionsEnabled
          ) &&
          !(
            suggestion.pipeline === SuggestionPipelines.LoadBuilding &&
            !isLoadBuildingEnabled
          )
      )
      .map((suggestion) => {
        let truthyFilterSuggestions;
        const isLoadSuggestion =
          suggestion.pipeline === SuggestionPipelines.CarrierInfo ||
          suggestion.pipeline === SuggestionPipelines.ApptConfirmation;

        const isLoadBuildingSuggestion =
          suggestion.pipeline === SuggestionPipelines.LoadBuilding;

        if (suggestion.pipeline === SuggestionPipelines.CheckCall) {
          truthyFilterSuggestions = Object.fromEntries(
            Object.entries(suggestion.suggested.checkCallChanges).filter(
              ([_, v]) => !!v
            )
          );
          return {
            ...suggestion,
            suggested: {
              checkCallChanges: truthyFilterSuggestions,
            },
          };
        }

        truthyFilterSuggestions = filterSuggestions(suggestion.suggested);
        if (isLoadBuildingSuggestion) {
          return {
            ...suggestion,
            suggested: truthyFilterSuggestions,
          } as LoadBuildingSuggestions;
        }
        return isLoadSuggestion
          ? ({
              ...suggestion,
              suggested: truthyFilterSuggestions,
            } as SuggestedLoadChange)
          : ({
              ...suggestion,
              suggested: truthyFilterSuggestions,
            } as SuggestedQuoteChange);
      });

    setOptionalSuggestions(optionalSuggestions);

    // Initialize currentState with filtered suggestions
    setCurrentState((prevState) => ({
      ...prevState,
      curSuggestionList: optionalSuggestions,
    }));
  }, [
    initRawSuggestions,
    isAppointmentSuggestionsEnabled,
    isCarrierInfoSuggestionsEnabled,
    isLoadBuildingEnabled,
  ]);

  // Update suggestion list when user applies/rejects a suggestion in the carousel
  useEffect(() => {
    setOptionalSuggestions(curSuggestionList);
  }, [curSuggestionList]);

  const handleGoToSuggestion = async ({
    suggestionID,
    suggestionPipeline,
  }: {
    suggestionID?: number;
    suggestionPipeline?: SuggestionPipelines;
  }) => {
    const targetSuggestionIndex = optionalSuggestions.findIndex((suggestion) =>
      suggestionID
        ? suggestion.id === suggestionID
        : suggestion.pipeline === suggestionPipeline
    );

    if (targetSuggestionIndex > -1 && carouselRef.current) {
      carouselRef.current.goTo(targetSuggestionIndex, true);
    }
  };

  const handleGetDisplayedSuggestion = () => {
    if (!carouselRef.current) return;

    return optionalSuggestions[
      carouselRef.current?.innerSlider.state.targetSlide
    ];
  };

  useEffect(() => {
    // Re-register goToSuggestionInCarousel when optionalSuggestions changes because
    // goToSuggestionInCarousel uses the optionalSuggestions array value when the function is defined/registered which is empty,
    // not the current value of optionalSuggestions when it's called
    setCurrentState((prevState) => ({
      ...prevState,
      goToSuggestionInCarousel: handleGoToSuggestion,
      getDisplayedSuggestion: handleGetDisplayedSuggestion,
    }));
  }, [carouselRef, optionalSuggestions]);

  const handleClearSuggestion = async (
    e: React.MouseEvent<SVGSVGElement>,
    suggestion: GenericSuggestion
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const res = await skipSuggestion(suggestion!.id);

    if (res.isOk()) {
      toast({
        description: 'Suggestion skipped.',
        variant: 'default',
      });

      setOptionalSuggestions(
        optionalSuggestions.filter((s) => s.id !== suggestion.id)
      );
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }
  };

  const handleApplySuggestion = (suggestion: GenericSuggestion): void => {
    setCurrentState((prevState) => ({
      ...prevState,
      clickedSuggestion: suggestion,
    }));

    const eventNamePosthog = getClickedSuggestionEventNamePosthog(
      suggestion.pipeline
    );
    if (suggestion.pipeline) {
      posthog?.capture(eventNamePosthog, {
        suggestionID: suggestion.id,
        serviceID: user?.service_id,
      });
    }
  };

  return (
    <>
      {optionalSuggestions.length > 0 && (
        <div className='w-[calc(100%-32px)] mt-0 mb-8 mx-auto relative'>
          <Carousel
            ref={carouselRef}
            arrows={optionalSuggestions.length > 1}
            dots={optionalSuggestions.length > 1}
            className={optionalSuggestions.length > 1 ? '' : 'single-card'}
          >
            {(() => {
              // Group suggestions by pipeline
              const groupedSuggestions = groupBy(
                optionalSuggestions,
                (s) => s.pipeline
              );

              // Flatten grouped suggestions into a single array with position within pipeline group
              const suggestionsWithPositions = Object.entries(
                groupedSuggestions
              ).flatMap(([_, pipelineSuggestions]) =>
                pipelineSuggestions.map((suggestion, index) => ({
                  ...suggestion,
                  position: index + 1,
                  total: pipelineSuggestions.length,
                }))
              );

              return suggestionsWithPositions.map(
                ({ position, total, ...suggestion }) => {
                  const changes =
                    suggestion.pipeline === SuggestionPipelines.CheckCall
                      ? suggestion.suggested.checkCallChanges
                      : suggestion.suggested;

                  const flattenedChanges = flattenSuggestionChanges(changes);

                  const { elements } = getValidChangeElements(
                    flattenedChanges,
                    suggestion
                  );

                  if (!elements || !elements.length || elements.length === 0)
                    return;

                  return (
                    <SuggestionCard
                      key={`${suggestion.pipeline}-${suggestion.id}`}
                      suggestion={suggestion}
                      clickedSuggestion={clickedSuggestion}
                      handleClearSuggestion={handleClearSuggestion}
                      handleApplySuggestion={handleApplySuggestion}
                      // Show 1 of X only if there are multiple suggestions
                      pipelineGroupIndex={
                        optionalSuggestions.length > 1 ? position : null
                      }
                      pipelineGroupTotal={
                        optionalSuggestions.length > 1 ? total : null
                      }
                    />
                  );
                }
              );
            })()}
          </Carousel>
        </div>
      )}
    </>
  );
}

/**
 * Generates a list of JSX elements displaying the valid changes between
 * `changesDisplayed` and `suggestion`, and returns the count of valid changes.
 *
 * A valid change includes:
 * - A label corresponding to a suggestion field.
 * - A valid value for that field.
 *
 * If a value is a date (detected by `dayjs`), it is formatted as 'MMM D, YYYY HH:mm A'.
 *
 * @param {SuggestionChangeRecord} changesDisplayed - The displayed changes object containing key-value pairs of changes.
 * @param {GenericSuggestion} suggestion - The suggestion data used to fetch the change label.
 * @returns {{ elements: (JSX.Element | undefined)[], validChangesCount: number }} An object with:
 *  - `elements`: An array of JSX elements representing valid changes.
 *  - `validChangesCount`: The count of valid change elements which is used in the `Show +X changes`/`Collapse` button.
 */
export const getValidChangeElements = (
  changesDisplayed: SuggestionChangeRecord,
  suggestion: GenericSuggestion
): { elements: Undef<JSX.Element>[]; validChangesCount: number } => {
  if (suggestion.pipeline === SuggestionPipelines.LoadBuilding) {
    const lbCard = LoadBuildingSuggestionCard(suggestion);
    if (lbCard) {
      return { elements: [lbCard], validChangesCount: 0 };
    }
  } else if (suggestion.pipeline === SuggestionPipelines.QuickQuote) {
    const qqCard = QuickQuoteCard(suggestion);
    if (qqCard) return { elements: [qqCard], validChangesCount: 0 };
  }

  const changesDisplayedList = Object.entries(changesDisplayed);

  if (!changesDisplayedList.length)
    return { elements: [], validChangesCount: 0 };

  let validChangesCount = 0;

  // Dynamically order fields: Priority fields first, then the rest
  const priorityOrder = getFieldOrder(suggestion.pipeline);
  const orderedFields = reorderFields(changesDisplayedList, priorityOrder);

  const elements = orderedFields
    .map(([name, val]) => {
      const changeLabel = getSuggestionFormattedLabel(
        suggestion.pipeline,
        name
      );

      const changeValue =
        val &&
        val.toString().length > 8 &&
        !isLikelyPhoneNumber(val) &&
        dayjs(val).isValid()
          ? dayjs(val).format('MMM D, YYYY HH:mm')
          : val;

      if (!changeLabel || !changeValue) return;

      validChangesCount += 1;

      return (
        <KeyValueElement
          name={name}
          changeLabel={changeLabel}
          changeValue={changeValue}
        />
      );
    })
    .filter(Boolean); // Remove null values

  return { elements, validChangesCount };
};

const getClickedSuggestionEventNamePosthog = (
  pipeline: SuggestionPipelines
) => {
  switch (pipeline) {
    case SuggestionPipelines.CarrierInfo:
      return ButtonNamePosthog.CarrierInfoSuggestionClick;
    case SuggestionPipelines.ApptConfirmation:
      return ButtonNamePosthog.ApptConfirmationSuggestionClick;
    case SuggestionPipelines.CheckCall:
      return ButtonNamePosthog.CheckCallSuggestionClick;
    case SuggestionPipelines.QuickQuote:
      return ButtonNamePosthog.QuickQuoteSuggestionClick;
    case SuggestionPipelines.LoadBuilding:
      return ButtonNamePosthog.LoadBuildingSuggestionClick;
  }
};

// util function to handle for nested objects
const filterSuggestions = (obj: any): any =>
  isValidNonDateObject(obj)
    ? Object.fromEntries(
        Object.entries(obj)
          .filter(([_, value]) => value !== '' && !!value) // Filters out falsy values
          .map(([key, value]) => [
            key,
            // Recursively filter if value is a nested object
            isValidNonDateObject(value) ? filterSuggestions(value) : value,
          ])
      )
    : obj;

const suggestionSortComparator = (
  a: GenericSuggestion,
  b: GenericSuggestion
) => {
  // This makes sure that QQ suggestions always show up before LB
  if (
    a.pipeline === 'quick_quote_pipeline' &&
    b.pipeline === 'load_building_pipeline'
  )
    return -1;
  if (
    a.pipeline === 'load_building_pipeline' &&
    b.pipeline === 'quick_quote_pipeline'
  )
    return 1;

  // For other pipelines, maintain default order
  return 0;
};
