export const formatCurrency = (
  amount: number,
  currencyCode: string,
  maxFractionDigits = 0
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits,
  });

  return formatter.format(amount);
};
