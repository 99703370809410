import React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

import { cn } from 'utils/shadcn';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-[4px] text-base font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-orange-main text-white text-md hover:bg-orange-hover',
        secondary: 'bg-pink-crimson text-white text-md hover:bg-pink-vivid',
        outline:
          'border bg-white text-black border-grayscale-border-outline hover:bg-gray-100 hover:border-gray-200 hover:border-grayscale-border-outline',
        ghost: 'hover:border hover:border-orange-main',
        underline: 'border-b border-orange-pressed rounded-none',
        titlebarActionIcon:
          'hover:border-0 hover:bg-orange-hover hover:text-white',
        destructive:
          'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white',
      },
      size: {
        default: 'h-11 px-4 py-2',
        xs: 'h-7 w-7 px-1',
        sm: 'h-9 px-3',
        lg: 'h-13 px-8',
        icon: 'h-10 min-h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

// TODO: add prop to turn button to link - href?: string;
interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  buttonNamePosthog?: string;
  logProperties?: { [key: string]: any };
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      buttonNamePosthog,
      logProperties,
      onClick,
      variant,
      size,
      asChild = false,
      ...props
    },
    ref
  ) => {
    const posthog = usePostHog();

    const handleOnClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (buttonNamePosthog) {
        posthog?.capture(buttonNamePosthog, { ...logProperties });
      }

      onClick && onClick(e);
    };

    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        onClick={handleOnClick}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
