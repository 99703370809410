import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type GetFuelPriceEIAResponse = {
  fuelPrice: string;
};

type FuelPriceEIANumeric = {
  fuelPrice: number;
};

export async function getFuelPriceEIA(): Promise<
  Result<FuelPriceEIANumeric, ApiError>
> {
  try {
    const url = `/quote/fuel/recent-price`;
    const { data } = await axios.get<GetFuelPriceEIAResponse>(url);

    return ok({ fuelPrice: Number(data.fuelPrice) });
  } catch (error) {
    captureException(error, { functionName: 'getFuelPriceEIA' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get EIA fuel price' });
    }

    return err({
      message: error.response?.data.message || 'Failed to get EIA fuel price',
    });
  }
}
