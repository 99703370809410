import { Dispatch, SetStateAction, createContext } from 'react';

import { Maybe } from 'types/UtilityTypes';
import { GenericSuggestion } from 'types/suggestions/CoreSuggestions';

export enum DrumkitPlatform {
  Gmail = 'Gmail',
  DelegatedGmail = 'DelegatedGmail',
  Aljex = 'Aljex',
  Relay = 'Relay',
  Outlook = 'Outlook',
  Front = 'Front',
  None = 'None',
}

export function isEmailPlatform(platform: DrumkitPlatform): boolean {
  return (
    platform === DrumkitPlatform.Gmail ||
    platform === DrumkitPlatform.Outlook ||
    platform === DrumkitPlatform.Front
  );
}
export type SidebarState = {
  serviceName: string;
  inboxEmailAddress: string;
  threadId: string;
  threadItemId: string;
  isOutlookReply: boolean;
  isChromeSidePanel: boolean;
  drumkitPlatform: DrumkitPlatform;
  drumkitAppContainer: Maybe<Element>;
  starredLoads: string[];
  viewedLoads: string[];
  clickedSuggestion: Maybe<GenericSuggestion>;
  // TODO: add load status here so we can auto refresh it after an info update
  // TODO: add operator list here so we don't need to fetch it every time we switch to load info tab
};

export const defaultSidebarState = {
  serviceName: '',
  inboxEmailAddress: '',
  threadId: '',
  threadItemId: '',
  isOutlookReply: false,
  isChromeSidePanel: false,
  drumkitPlatform: DrumkitPlatform.None,
  drumkitAppContainer: null,
  starredLoads: [],
  viewedLoads: [],
  clickedSuggestion: null,
};

type SidebarStateContextType = {
  currentState: SidebarState;
  setCurrentState: Dispatch<SetStateAction<SidebarState>>;
  // FIXME: Remove suggestion from carousel when it's been applied
};

export const SidebarStateContext = createContext<SidebarStateContextType>({
  currentState: defaultSidebarState,
  setCurrentState: async () => {},
});
