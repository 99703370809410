// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { useContext, useMemo } from 'react';

import _ from 'lodash';

import { SidebarStateContext } from 'contexts/sidebarStateContext';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { Email } from 'types/Email';
import { Maybe } from 'types/UtilityTypes';
import Pageview from 'types/enums/Pageview';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';

import QuickQuoteForm from './QuickQuoteForm';

export default function QuickQuoteTab({ email }: { email: Maybe<Email> }) {
  const { serviceID } = useServiceFeatures();
  const { currentState } = useContext(SidebarStateContext);

  // Check if there are any quick quote suggestions
  const hasQRSuggestions = useMemo(() => {
    return currentState.curSuggestionList.some(
      (suggestion) => suggestion.pipeline === SuggestionPipelines.QuickQuote
    );
  }, [currentState.curSuggestionList]);

  useLogPostHogPageView(Pageview.QuickQuote, {
    service_id: serviceID,
    email_id: email?.id || null,
    has_qr_suggestions: hasQRSuggestions,
  });

  return <QuickQuoteForm email={email} />;
}
