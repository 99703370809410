import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import { AlertOctagon, BookXIcon } from 'lucide-react';

import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import { ExceptionTimeline } from 'components/ExceptionTimeline';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import useTMSContext from 'hooks/useTMSContext';
import { useToast } from 'hooks/useToaster';
import { getExceptionHistory } from 'lib/api/getExceptionHistory';
import { submitException } from 'lib/api/submitException';
import { Exception } from 'types/Exception';
import { NormalizedLoad } from 'types/Load';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import TMS from 'types/enums/Integrations';

import { LoadSectionAccordionItem } from '../LoadInformationTab';
import { relaySourceEnums } from './CheckCalls';

type ExceptionSectionProps = { normalizedLoad: NormalizedLoad };

const aljexExceptionCodeEnums = [
  { value: 'HOLD - HD' },
  { value: 'PUAPPT - PU' },
  { value: 'LATEPU - LP' },
  { value: 'PUNUMB - PU' },
  { value: 'BRKDWNS - BK' },
  { value: 'DELAPPT - DA' },
  { value: 'LATEDEL - LD' },
  { value: 'DETENT - DT' },
  { value: 'DELNUMB - DN' },
  { value: 'TNU - TN' },
  { value: 'NOTREADY - NR' },
  { value: 'DAMAGE - DM' },
  { value: 'CLAIM - CL' },
  { value: 'ACCT - AC' },
  { value: 'LUMPER - LM' },
  { value: 'POD - PD' },
  { value: 'CARRINV - VR' },
  { value: 'CUSTINV - CI' },
  { value: 'OTHER - OT' },
];

const tmsToStatusEnumsMap: { [key: string]: { value: string }[] } = {
  [TMS.Aljex]: aljexExceptionCodeEnums,
  [TMS.Relay]: [],
};

interface ExceptionInputs {
  freightTrackingId: string;
  isOnTime: 'Yes' | 'No'; // primarily for Relay
  source: string; // primarily for Relay
  exceptionCode: string;
  driver: string;
  carrier: string;
  trailer: string;
  note: string;
  datetime: Date;
}

type ExceptionTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<ExceptionInputs> };
const ExceptionTextInput = (props: ExceptionTextInputProps) => (
  <RHFTextInput {...props} />
);

export default function ExceptionSection({
  normalizedLoad: load,
}: ExceptionSectionProps) {
  const { tmsName } = useTMSContext();
  const isRelay = tmsName === TMS.Relay;
  const isAljex = tmsName === TMS.Aljex;
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>(['']);
  const [history, setHistory] = useState<Array<Exception>>([]);
  const exceptionCodeEnums = tmsToStatusEnumsMap[tmsName] || [];

  const fetchExceptions = async (loadID: number) => {
    const res = await getExceptionHistory(loadID);
    if (res.isOk()) {
      setHistory(res.value);
    }
  };

  useEffect(() => {
    if (load.ID) {
      fetchExceptions(load.ID);
    }
  }, [load.ID]);

  const defaultValues = useMemo<ExceptionInputs>(() => {
    const now = new Date();
    const parsedValues = {
      freightTrackingId: load.freightTrackingID,
      datetime: now,
      driver: load.carrier.firstDriverName,
      carrier: load.carrier.name,
      trailer: load.carrier.trailerNumber,
      isOnTime: '',
    };

    return parsedValues as ExceptionInputs;
  }, [load]);

  const exceptionFormMethods = useForm<ExceptionInputs>({ defaultValues });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = exceptionFormMethods;

  // Set outside of default values so as to not be displayed as AI-filled.
  // TODO: Deprecate when exceptions AI suggestions enabled
  useEffect(() => {
    setValue('datetime', new Date());
  });

  const onSubmitExcpetion: SubmitHandler<ExceptionInputs> = async (data) => {
    setLoading(true);

    const res = await submitException(
      load.ID!,
      data.freightTrackingId,
      data.exceptionCode,
      data.isOnTime.toLowerCase() === 'yes',
      data.source,
      data.driver,
      data.carrier,
      data.trailer,
      data.note,
      // Submit in the correct time format MM/DD/YYYY, HH:mm:ss AM/PM
      dayjs(), // Default to now
      tmsName
    );

    if (res.isOk()) {
      if (res.value) {
        setHistory(res.value);
      }
      toast({
        description: 'Successfully submitted exception.',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setLoading(false);
  };

  return (
    <Accordion type='multiple' value={activeTabs} onValueChange={setActiveTabs}>
      <LoadSectionAccordionItem
        label='Submit Exception'
        icon={<AlertOctagon className='h-6 w-6' strokeWidth={1} />}
        name='submitException'
        activeTabs={activeTabs}
      >
        <div>
          <ExtendedFormProvider aiDefaultValues={true}>
            <FormProvider {...exceptionFormMethods}>
              <div>
                <form
                  onSubmit={handleSubmit(onSubmitExcpetion)}
                  className='grid gap-4 grid-cols-1 mt-0 mx-0 w-full'
                >
                  {/* TODO: Use generic functions if more TMS's added, similar to CheckCalls */}
                  {isRelay && (
                    <div>
                      <Label name='source'>Source</Label>
                      <Controller
                        name='source'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              {relaySourceEnums.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`source`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {isRelay && (
                    <div>
                      <Label name='isOnTime'>{'On Time?'}</Label>
                      <Controller
                        name='isOnTime'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem key={'yes'} value={'yes'}>
                                Yes
                              </SelectItem>
                              <SelectItem key={'no'} value={'no'}>
                                No
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`isOnTime`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {exceptionCodeEnums.length > 0 && (
                    <div>
                      <Label name='exceptionCode'>Exception Code</Label>
                      <Controller
                        name='exceptionCode'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-1'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              {exceptionCodeEnums.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                  )}

                  {isAljex && (
                    <ExceptionTextInput
                      name='driver'
                      label='Driver'
                      required={true}
                    />
                  )}

                  {isAljex && (
                    <ExceptionTextInput
                      name='carrier'
                      label='Carrier'
                      required={true}
                    />
                  )}

                  {isAljex && (
                    <ExceptionTextInput
                      name='trailer'
                      label='Trailer #'
                      required={true}
                    />
                  )}

                  <ExceptionTextInput
                    name='note'
                    label='Note'
                    required={true}
                  />
                  <Button
                    buttonName={ButtonName.SubmitException}
                    buttonNamePosthog={ButtonNamePosthog.SubmitException}
                    type='submit'
                    className='w-full'
                    disabled={loading}
                    logProperties={{
                      loadID: load.ID,
                      freightTrackingID: load.freightTrackingID,
                      serviceID: load.serviceID,
                    }}
                  >
                    {loading ? <ButtonLoader /> : ButtonName.SubmitException}
                  </Button>
                </form>
              </div>
            </FormProvider>
          </ExtendedFormProvider>
        </div>
      </LoadSectionAccordionItem>

      <LoadSectionAccordionItem
        label='Exception History'
        icon={<BookXIcon className='h-6 w-6' strokeWidth={1} />}
        name='exceptionHistory'
        activeTabs={activeTabs}
      >
        {/* TODO: refactor ExceptionTimeline to support other TMS's */}
        {history ? <ExceptionTimeline events={history} /> : null}
      </LoadSectionAccordionItem>
    </Accordion>
  );
}
