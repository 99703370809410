import React, { useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore this is installed on parent repos
import anime from 'animejs';
import {
  ChevronLastIcon,
  CircleDollarSignIcon,
  CirclePowerIcon,
  CircleUserIcon,
  TruckIcon,
} from 'lucide-react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore constants is in the parent dir
import DRUMKIT_AUTH_URL from '@constants/DrumkitAuthUrl';

import {
  DrumkitPlatform,
  SidebarStateContext,
} from 'contexts/sidebarStateContext';
import { SidebarViewContext } from 'contexts/sidebarViewContext';
import { useAuth } from 'hooks/useAuth';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { DRUMKIT_FULL_NAME_LOGO } from 'icons/DrumkitFullNameLogo';
import { removeStyles, stopObserving } from 'lib/hosts/relayStyling';
import SidebarView from 'types/enums/SidebarView';
import { cn } from 'utils/shadcn';

import { Button } from './Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './Dropdown';
import SearchBar from './SearchBar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';

interface TitlebarButtonsProps {
  outlookLogout?: () => void;
  disableCollapseButton?: boolean;
  hideSearchBar?: boolean;
}

export function TitlebarButtons({
  outlookLogout,
  disableCollapseButton,
  hideSearchBar = false,
}: TitlebarButtonsProps) {
  const { logout } = useAuth();
  const { currentView, setCurrentView } = useContext(SidebarViewContext);
  const {
    currentState: { drumkitPlatform },
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: { isQuoteViewEnabled, isLoadViewEnabled },
  } = useServiceFeatures();

  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const logoutHandler = async () => {
    if (outlookLogout) {
      outlookLogout();
    } else {
      window.open(`${DRUMKIT_AUTH_URL}/logout`);
      await logout();
    }
  };

  const handleToggleSidebar = () => {
    const pageContainer = document.querySelector('body');
    const drumkitSidebarElement = document.getElementById(
      'drumkit-content-view-root'
    );

    if (!drumkitSidebarElement) {
      return;
    }

    const drumkitSidebarWidth = drumkitSidebarElement.offsetWidth;

    anime({
      targets: drumkitSidebarElement,
      translateX: drumkitSidebarWidth,
      duration: 150,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      complete: () => {
        drumkitSidebarElement.style.visibility = 'hidden';
      },
    });

    // Disconnect observer so that we can reset styles
    stopObserving();
    removeStyles(pageContainer);
  };

  return (
    <>
      {currentView === SidebarView.Loads && !hideSearchBar && (
        <div className='max-w-[400px] min-w-[200px] flex-shrink'>
          <SearchBar />
        </div>
      )}
      <TooltipProvider>
        <>
          {isLoadViewEnabled && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='titlebarActionIcon'
                  size='xs'
                  className={cn(
                    currentView === SidebarView.Loads &&
                      'bg-orange-pressed text-white'
                  )}
                  onClick={() => setCurrentView(SidebarView.Loads)}
                >
                  <TruckIcon className='h-4 w-4' />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Loads</TooltipContent>
            </Tooltip>
          )}

          {isQuoteViewEnabled && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='titlebarActionIcon'
                  size='xs'
                  className={cn(
                    currentView === SidebarView.Quote &&
                      'bg-orange-pressed text-white'
                  )}
                  onClick={() => setCurrentView(SidebarView.Quote)}
                >
                  <CircleDollarSignIcon className='h-4 w-4' />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Quotes</TooltipContent>
            </Tooltip>
          )}
        </>
        <Tooltip>
          <TooltipTrigger asChild>
            <DropdownMenu
              open={profileDropdownOpen}
              onOpenChange={setProfileDropdownOpen}
            >
              <DropdownMenuTrigger asChild>
                <Button
                  variant='titlebarActionIcon'
                  size='xs'
                  className={cn(
                    profileDropdownOpen && 'bg-orange-pressed text-white'
                  )}
                >
                  <CircleUserIcon className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='w-40'>
                <DropdownMenuItem onClick={() => logoutHandler()}>
                  <CirclePowerIcon className='mr-2 h-4 w-4' />
                  <span>Log Out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </TooltipTrigger>
          <TooltipContent>Profile</TooltipContent>
        </Tooltip>
        {(drumkitPlatform === DrumkitPlatform.Relay ||
          drumkitPlatform === DrumkitPlatform.Sidepanel ||
          drumkitPlatform === DrumkitPlatform.DelegatedGmail) &&
          !disableCollapseButton && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='titlebarActionIcon'
                  size='xs'
                  className='only:ml-auto'
                  onClick={() => handleToggleSidebar()}
                >
                  <ChevronLastIcon className='h-5 w-5' />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Hide Drumkit</TooltipContent>
            </Tooltip>
          )}
      </TooltipProvider>
    </>
  );
}

export default function Titlebar({ children }: { children: React.ReactNode }) {
  return (
    <div className='flex flex-col'>
      <div className='w-full min-h-12 h-12 flex flex-row items-center px-3 bg-orange-bg'>
        <img src={DRUMKIT_FULL_NAME_LOGO} className='h-6' />
        <div className='flex-1' />
        <div className='flex flex-row justify-end gap-3 mt-1 pl-3 z-'>
          {/* Render TitlebarButtons in header, assuming it's the first child */}
          {Array.isArray(children) ? children[0] : children}
        </div>
      </div>
      <div className='px-3 py-2'>
        {/* Render SearchBar below header, assuming it's the second child */}
        {Array.isArray(children) && children[1]}
      </div>
    </div>
  );
}
