// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { UseFormReturn } from 'react-hook-form/dist/types/form';

import { isAxiosError } from 'axios';
import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { Maybe } from 'types/UtilityTypes';

export enum TransportType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  FLATBED = 'FLATBED',
}

export enum SelectedCarrierType {
  NETWORK = 'NETWORK',
  BUYPOWER = 'BUYPOWER',
}

export interface Stop {
  zip: string;
  city: string;
  state: string;
}

export interface QuickQuoteInputs {
  transportType: TransportType;
  pickupDate: Date;
  deliveryDate: Date;
  stops: Array<Stop>;
  isSubmitToTMS: boolean;
  customerName: string;
}

export interface QuickQuoteResponse {
  id: number;
  stops: {
    order: number;
    city: string;
    state: string;
    zip: string;
    country: string;
  }[];
  selectedRateName: SelectedCarrierType;
  networkLaneRateDistance: any;
  networkLaneRateTargetBuy: any;
  networkLaneRateConfidenceLevel: any;
  laneRateDistance: any;
  laneRateTargetBuy: any;
  laneRateConfidenceLevel: any;
  lowConfidenceThreshold: any;
  mediumConfidenceThreshold: any;
}

export async function updateQuickQuoteRequest(
  formValues: QuickQuoteInputs,
  formMethods: UseFormReturn<QuickQuoteInputs>,
  setQuoteHandler: React.Dispatch<
    React.SetStateAction<Maybe<QuickQuoteResponse>>
  >,
  setQuoteNotConfidentHandler: React.Dispatch<React.SetStateAction<boolean>>,
  setCarrierCostHandler: React.Dispatch<React.SetStateAction<number>>,
  setSelectedCarrierHandler: React.Dispatch<
    React.SetStateAction<SelectedCarrierType>
  >
): Promise<void> {
  setQuoteNotConfidentHandler(false);
  setQuoteHandler(null);

  try {
    const { data } = await axios.post(`/quote/private/quickquote`, formValues);
    setQuoteHandler(data);
    setCarrierCostHandler(
      _.round(
        data.selectedRateName === SelectedCarrierType.NETWORK
          ? data.networkLaneRateTargetBuy * data.networkLaneRateDistance
          : data.laneRateTargetBuy * data.laneRateDistance
      )
    );
    setSelectedCarrierHandler(
      data.selectedRateName === SelectedCarrierType.NETWORK
        ? SelectedCarrierType.NETWORK
        : SelectedCarrierType.BUYPOWER
    );
  } catch (error) {
    const defaultErrorMessage =
      'Something went wrong. Please try again in a few moments or contact our team.';

    if (isAxiosError(error) && error.response?.status) {
      if (
        error.response.status === 422 &&
        error.response.data.includes('unable') &&
        error.response.data.includes('confident')
      ) {
        setQuoteNotConfidentHandler(true);
        return;
      } else if (
        error.response.status === 400 &&
        error.response?.data.toLowerCase().includes('invalid email')
      ) {
        // API returns "Invalid email" for invalid domains
        formMethods.setError('root', {
          message: error.response?.data,
        });
        return;
      }
    }

    formMethods.setError('root', {
      message: defaultErrorMessage,
    });
  }
}
