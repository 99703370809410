import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import Login from '@auth/Login';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore constants is in the parent dir
import APP_VERSION from '@constants/AppVersion';

import SidebarLoader from 'components/loading/SidebarLoader';
import { useAuth } from 'hooks/useAuth';
import useFetchService from 'hooks/useFetchService';

type RequireAuthProps = {
  children: JSX.Element;
  admin?: boolean;
};

type IdentifiedUserProps = {
  [key: string]: any;
};

function RequireAuth({ children }: RequireAuthProps) {
  const { loading, user } = useAuth();
  const posthog = usePostHog();

  /**
   * using memoization is pretty safe here, since switching accounts
   * requires a sidebar reload in the first place
   */
  useMemo(() => {
    if (!loading && user?.service_id) {
      useFetchService(user.service_id);
    }

    const personProperties: IdentifiedUserProps = {
      email: user?.email,
    };

    if (APP_VERSION) {
      personProperties.$drumkit_version = APP_VERSION;
    }

    posthog?.identify(user?.email, personProperties);
  }, [user]);

  if (loading) {
    return <SidebarLoader />;
  }

  return user ? children : <Login />;
}

export default RequireAuth;
