import * as React from 'react';

import { cn } from 'utils/shadcn';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    const textareaClass = cn(
      'flex min-h-[80px] w-full rounded-[4px] border border-grayscale-border-input bg-white text-[13px] text-grayscale-content-input',
      'px-3 py-2 ring-offset-white scroll-mt-20',
      'file:border-0 file:bg-transparent file:text-[14px] file:font-medium',
      'placeholder:text-grayscale-content-input/30 focus-visible:outline-orange-border-main focus-visible:read-only:outline-none',
      'disabled:cursor-not-allowed read-only:cursor-not-allowed read-only:bg-grayscale-bg-secondary read-only:border-grayscale-border-disabled focus-visible:read-only:border-grayscale-border-disabled read-only:text-grayscale-content-disabled',
      props.disabled ? 'resize-none' : '',
      className
    );

    return <textarea className={textareaClass} ref={ref} {...props} />;
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
